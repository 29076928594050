import Request from './request';

export default class ProductHttpService {
    private static uri = 'erp/products';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static show(params: any) {
        return Request.get(
            `${this.uri}/${params.sellerId}/${params.productId}/${params.type}`,
        );
    }

    public static get(data: any) {
        return Request.get(this.uri, data);
    }

    public static getPlatepowers(data: any) {
        return Request.get(`${this.uri}/panels/powers`, data);
    }

    public static getManufactures(data: any) {
        return Request.get(`${this.uri}/manufacturs`, data);
    }

    public static getSystemPowers(data: any) {
        return Request.get(`${this.uri}/systems/powers`, data);
    }

    public static getInverterModels(data: any) {
        return Request.get(`${this.uri}/inverters/models`, data);
    }
}
