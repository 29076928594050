import formatMask from '../../../../utils/formatMask';
import { StyledData, StyledDataHeader, StyledSectionTitle } from '../styles';

interface PersonalData {
    name: string;
    phone: string;
    email: string;
    document: string;
}

interface Props {
    personalData: PersonalData;
}

const mobilePhoneSize = 11;
const mobile = '(##) #####-####';
const landline = '(##) ####-####';

const cnpjSize = 14;
const cpf = '###.###.###-##';
const cnpj = '##.###.###/####-##';

export default function InvoicePersonalData({ personalData }: Props) {
    return (
        <>
            <StyledSectionTitle>Dados pessoais</StyledSectionTitle>

            <StyledDataHeader>Nome/Razão social</StyledDataHeader>
            <StyledData>{personalData.name || '-'}</StyledData>

            <StyledDataHeader>Telefone</StyledDataHeader>
            <StyledData>
                {personalData.phone
                    ? formatMask(
                          personalData.phone,
                          personalData.phone.length === mobilePhoneSize
                              ? mobile
                              : landline,
                      )
                    : '-'}
            </StyledData>

            <StyledDataHeader>E-mail</StyledDataHeader>
            <StyledData>{personalData.email || '-'}</StyledData>

            <StyledDataHeader>CPF/CNPJ</StyledDataHeader>
            <StyledData>
                {personalData.document
                    ? formatMask(
                          personalData.document,
                          personalData.document.length === cnpjSize
                              ? cnpj
                              : cpf,
                      )
                    : '-'}
            </StyledData>
        </>
    );
}
