import { SelectOption } from '../components/Select';
import getTriangulationStatusText from '../utils/getTriangulationStatusText';
import { InvoiceTriangulationStatus } from './invoiceStatus';

const defaultTriangulationStatusOptions: Array<SelectOption<string>> =
    Object.values(InvoiceTriangulationStatus).map((status) => ({
        label: getTriangulationStatusText(status),
        value: status,
    }));

const triangulationStatusOptions: Array<SelectOption<string>> = [
    ...defaultTriangulationStatusOptions,
    {
        label: 'NÃO CADASTRADA',
        value: 'NOT_CREATED',
    },
];

export default triangulationStatusOptions;
