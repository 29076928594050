import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import {
    StyledImage,
    StyledObject,
    StyledPlaceholder,
    StyledPreview,
    StyledButtonLink,
} from './styles';
import { NEXEN_BUCKET_SRC_URL } from '../../../../config/webservice';

interface AttachmentFilePreviewProps {
    file?: File | string;
}

export default function AttachmentFilePreview({
    file,
}: AttachmentFilePreviewProps) {
    const [filePreview, setFilePreview] = useState<string | null>(null);

    useEffect(() => {
        if (!file) {
            setFilePreview(null);
        } else if (typeof file === 'string') {
            const fileUrl = `${NEXEN_BUCKET_SRC_URL}attachments/${file}`;
            setFilePreview(fileUrl);
        } else {
            const reader = new FileReader();

            reader.onloadend = async () => {
                if (typeof reader.result === 'string') {
                    const base64Response = await fetch(reader.result);
                    const blob = await base64Response.blob();
                    const url = URL.createObjectURL(blob);

                    setFilePreview(url);
                }
            };

            reader.readAsDataURL(file);
        }
        return () => setFilePreview(null);
    }, [file]);

    const handleDownload = () => {
        const a = document.createElement('a');
        if (typeof file === 'string') {
            a.href = filePreview;
            a.download = file;
        } else {
            a.href = URL.createObjectURL(file);
            a.download = file.name;
        }

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const fileType =
        typeof file === 'string'
            ? file.split('.').pop().toLowerCase()
            : file?.type.split('/').pop();

    return (
        <StyledPreview>
            {!file ? (
                <StyledPlaceholder>
                    O documento do comprovante será exibido aqui
                </StyledPlaceholder>
            ) : (
                <>
                    {!filePreview && (
                        <Spinner animation="border" className="mr-2 mt-1" />
                    )}
                    {fileType === 'pdf' && filePreview && (
                        <StyledObject
                            data={filePreview}
                            type="application/pdf"
                            width="100%"
                            height="650"
                        >
                            <StyledButtonLink onClick={handleDownload}>
                                Realizar download do arquivo
                            </StyledButtonLink>
                        </StyledObject>
                    )}
                    {fileType !== 'pdf' && (
                        <StyledImage src={filePreview} alt="Preview" />
                    )}
                </>
            )}
        </StyledPreview>
    );
}
