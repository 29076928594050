/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Image, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import fileUploadImg from '../../../../assets/icons/fileUpload.png';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { StyledLink, StyledRow } from './styles';
import closeIcon from '../../../../assets/icons/clear_24px.png';
import { ATTACHMENTS_SRC_URL } from '../../../../config/webservice';
import { InvoiceStatus } from '../../../../constants/invoiceStatus';
import { Role } from '../../../../constants/role';
import userHasRoles from '../../../../utils/userHasRoles';
import { useAuth } from '../../../../contexts/authContext';
import fileToBuffer from '../../../../utils/fileToBuffer';
import getFileExtension from '../../../../utils/getFileExtension';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import InvoiceAttachment from '../../../../shared/interfaces/invoiceAttachment.interface';

interface Props {
    disabled: any;
    reducedFee: boolean;
    financialBv: boolean;
}

const Financing: React.FC<Props> = ({ disabled, reducedFee, financialBv }) => {
    const { setShowFinacingModal, cart, setCart } = useOnlineStore();
    const [selectected, setSelected] = useState(cart?.financingBank);
    const [loading, setLoading] = useState(false);

    const { user } = useAuth();

    const isFinancial = userHasRoles(user, [Role.Financial]);
    const isAdministrator = userHasRoles(user, [Role.Administrator]);

    const loadInvoice = async () => {
        const response = await InvoiceHttpService.show(cart?.id);

        setCart(response.data.data);
    };

    const handleChangeFinancingBank = async (bank: string) => {
        setSelected(bank);

        let newCart = cart;

        newCart.financingBank = bank;

        try {
            await InvoiceHttpService.update(newCart);
        } catch (error) {
            toast.error('Erro ao selecionar banco');
        }

        loadInvoice();
    };

    const saveFiles = async (files: any[]) => {
        files.forEach(async (file: any) => {
            setLoading(true);

            if (
                cart?.invoiceAttachments &&
                cart?.invoiceAttachments.find(
                    (item: any) =>
                        item.filename?.substring(
                            item?.filename.indexOf('_') + 1,
                        ) === file.name,
                )
            ) {
                toast.error(
                    'Não é possível adicionar um mesmo arquivo duas vezes',
                );
                setLoading(false);

                return null;
            }

            await InvoiceHttpService.addAttachment(cart?.id, {
                type: 'FINANCING',
                file: await fileToBuffer(file),
                fileExtension: getFileExtension(file),
                voucherAmount: null,
                voucherDate: null,
                voucherNumber: null,
            });

            loadInvoice();

            setLoading(false);
        });
    };

    const disable = isFinancial || isAdministrator ? false : disabled;

    useEffect(() => {
        loadInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeMutation = useMutation({
        mutationFn: async (attachmentId: number) => {
            setLoading(true);
            InvoiceHttpService.removeAttachment(cart.id, attachmentId);
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }
            toast.error('Ocorreu um erro ao remover o comprovante');
        },
        onSuccess: async () => {
            loadInvoice();
            toast.success('Comprovante removido com sucesso');
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    const attachmentsToList: InvoiceAttachment[] | undefined =
        cart?.invoiceAttachments?.filter(
            (attachment: InvoiceAttachment) => !attachment.deletedAt,
        );

    return (
        <>
            <Form
                style={{
                    width: '100%',
                }}
            >
                <Form.Group controlId="formBasicCep" className="mt-3">
                    {financialBv && (
                        <Form.Check
                            disabled
                            checked
                            name="radios"
                            id="formHorizontalRadios2"
                            label="BV Financeira"
                            type="radio"
                        />
                    )}
                    {reducedFee && (
                        <Form.Check
                            disabled
                            checked
                            name="radios"
                            id="formRadioReducedFee"
                            label="Financiamento 60x taxa reduzida"
                            type="radio"
                        />
                    )}
                    <Form.Check
                        disabled={disabled}
                        checked={selectected === 'OTHERS'}
                        onChange={() => handleChangeFinancingBank('OTHERS')}
                        name="radios"
                        id="formHorizontalRadios3"
                        label="Outros bancos"
                        type="radio"
                    />
                </Form.Group>

                {selectected === 'BV' && (
                    <Form.Group>
                        <Button
                            onClick={() => setShowFinacingModal(true)}
                            variant={'primary'}
                        >
                            {disabled
                                ? 'Ver dados de Análise de Crédito'
                                : cart?.financing
                                ? 'Editar Análise de Crédito'
                                : 'Solicitar análise de crédito'}
                        </Button>
                    </Form.Group>
                )}

                <Form.Group className={'mt-3 pl-3 pr-3'}>
                    <Row>
                        <Form.Label>
                            Já possui uma autorização de faturamento?
                        </Form.Label>
                    </Row>
                    <Row className={'mb-3'}>
                        <Dropzone
                            disabled={disable}
                            accept={['image/png', 'image/jpeg', '.pdf']}
                            onDropRejected={() => {
                                toast.error(
                                    'Arquivo muito grande ou formato incorreto',
                                );
                            }}
                            multiple
                            maxSize={5242880}
                            onDrop={(files) => {
                                saveFiles(files);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="custom-file-upload">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Row className={'dropzone-title'}>
                                            <Col
                                                className={'dropzone-title'}
                                                sm={10}
                                            >
                                                <h5
                                                    style={{
                                                        color: '#F2994A',
                                                        fontSize: '1rem',
                                                    }}
                                                    className={
                                                        'dropzone-title p-4'
                                                    }
                                                >
                                                    {!disable ? (
                                                        <>
                                                            Clique aqui para
                                                            selecionar um
                                                            arquivo ou arraste-o
                                                        </>
                                                    ) : (
                                                        <>
                                                            Lista de
                                                            comprovantes já
                                                            enviados
                                                        </>
                                                    )}
                                                </h5>
                                            </Col>
                                            <Col>
                                                {loading ? (
                                                    <Spinner
                                                        className={
                                                            'float-right mr-4 mt-4'
                                                        }
                                                        animation={'border'}
                                                    />
                                                ) : (
                                                    <Image
                                                        className={
                                                            'float-right mr-5 mt-3'
                                                        }
                                                        src={fileUploadImg}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className={'mt-n4 ml-4'}>
                                            <h6
                                                style={{
                                                    fontSize: '0.813rem',
                                                }}
                                            >
                                                arquivos PDF, jpg, pngs até 5 MB
                                            </h6>
                                        </Row>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Row>

                    {attachmentsToList?.map((attachment) => {
                        return (
                            <StyledRow
                                key={attachment.id}
                                className={'p-3 mt-3 mb-2'}
                            >
                                <Col>
                                    <StyledLink
                                        href={`${ATTACHMENTS_SRC_URL}/${attachment?.filename}`}
                                        className={'mt-2'}
                                        key={attachment?.filename}
                                    >
                                        {attachment?.filename.substring(
                                            attachment?.filename.indexOf('_') +
                                                1,
                                        )}
                                    </StyledLink>
                                </Col>
                                <Col>
                                    {((isFinancial &&
                                        cart?.status ===
                                            InvoiceStatus.InvoiceApproved) ||
                                        !disabled) && (
                                        <Image
                                            onClick={async () => {
                                                removeMutation.mutateAsync(
                                                    attachment.id,
                                                );
                                            }}
                                            style={{
                                                width: 8,
                                                cursor: 'pointer',
                                            }}
                                            className={'float-right mt-2'}
                                            src={closeIcon}
                                        />
                                    )}
                                </Col>
                            </StyledRow>
                        );
                    })}
                </Form.Group>
            </Form>
        </>
    );
};

Financing.propTypes = {
    disabled: PropTypes.bool,
};

export default Financing;
