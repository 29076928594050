/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Role } from '../../constants/role';
import { AuthValues, useAuth } from '../../contexts/authContext';
import { IntegratorPartnerModal } from '../../pages/Integrators/IntegratorInfo';
import userHasRoles from '../../utils/userHasRoles';
import NavbarHeader from '../Navbar';
import Sidebar from '../Sidebar';

const BaseLayout: React.FC<any> = ({ children }) => {
    const { user }: AuthValues = useAuth();

    const isIntegrator = userHasRoles(user, [Role.Integrator]);

    const isSmallScreen = useMediaQuery({ query: '(max-width: 1439px)' });

    const [showSidebar, setShowSidebar] = useState(false);

    const [showSellerCompanyInfoModal, setShowSellerCompanyInfoModal] =
        useState(false);

    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden';

        if (document.body.clientHeight > window.innerHeight) {
            document.body.style.paddingRight = '17px';
        }
    }, []);

    const unlockScroll = useCallback(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }, []);

    const handleToggle = () => {
        if (showSidebar) {
            unlockScroll();
        } else {
            lockScroll();
        }

        setShowSidebar((showSidebar) => !showSidebar);
    };

    useEffect(() => {
        if (!isSmallScreen) {
            setShowSidebar(false);
            unlockScroll();
        }
    }, [isSmallScreen, unlockScroll]);

    useEffect(() => {
        if (isIntegrator && !user.seller?.companyInfo) {
            setShowSellerCompanyInfoModal(true);
        }
    }, [isIntegrator, user.seller?.companyInfo]);

    return (
        <>
            <Container
                fluid
                style={{ overflowY: showSidebar ? 'hidden' : null }}
            >
                <NavbarHeader small={isSmallScreen} toggle={handleToggle} />
                {children}
                {isSmallScreen && (
                    <Sidebar onHide={handleToggle} show={showSidebar} />
                )}
            </Container>

            <br />

            <IntegratorPartnerModal
                show={showSellerCompanyInfoModal}
                onHide={() => {
                    setShowSellerCompanyInfoModal(false);
                }}
            />
        </>
    );
};

export default BaseLayout;
