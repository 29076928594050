import { Image, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledDesc = styled.h6`
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    line-height: 131.43%;
    word-wrap: break-word;
    letter-spacing: 0.0275em;
    color: #19191d;
    min-width: 100%;
    max-width: 100%;
`;

export const StyledImage = styled(Image)`
    width: 70%;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledOverlayImage = styled(Image)`
    position: absolute;
    top: 10%;
    left: 14%;
    transform: translate(-50%, -50%);
    &:hover {
        cursor: pointer;
    }
`;

export const StyledOverlayText = styled.h3`
    position: absolute;
    top: 10%;
    left: 14%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;

    position: absolute;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledId = styled.h6`
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.018em;
    color: #787885;
`;

export const StyledDiv = styled.div`
    position: absolute;
    bottom: -30px;
`;

export const StyledButton = styled(Button)`
    bottom: 50;
    min-width: 35%;
    max-width: 35%;
    max-height: 100%;
`;
