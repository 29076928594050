import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { useInfiniteQuery } from 'react-query';
import { useRef } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { StyledTh } from './styles';
import { rowsPerPage } from '../../../../constants/pagination';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import PaymentHttpService from '../../../../services/http/payment-http';
import { OthersPaymentMethodsFilterFields } from '../../../../pages/OthersPaymentMethodsOverview';
import OthersPaymentMethodsTableRow from './OthersPaymentMethodsTableRow';

interface OthersPaymentMethodsTableProps {
    filterForm: UseFormMethods<OthersPaymentMethodsFilterFields>;
}

export default function OthersPaymentMethodsTable({
    filterForm,
}: OthersPaymentMethodsTableProps) {
    const formData = filterForm.watch();

    const othersPaymentMethodsQuery = useInfiniteQuery({
        queryKey: ['othersPaymentMethods', formData.filterTerm],
        queryFn: async ({ pageParam = 0 }) => {
            const params = {
                term: formData.filterTerm,
                skip: pageParam,
                take: rowsPerPage,
            };

            const response =
                await PaymentHttpService.readManyOthersPaymentMethods(params);

            return {
                data: response.data.results,
                currentPage: pageParam,
                total: response.data.metadata.total,
            };
        },
        getNextPageParam: ({ total, currentPage }) =>
            currentPage * rowsPerPage < total ? currentPage + 1 : undefined,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const target = useRef();

    useIntersectionObserver({
        target,
        onIntersect: othersPaymentMethodsQuery.fetchNextPage,
        enabled: othersPaymentMethodsQuery.hasNextPage,
    });

    const showLoading =
        othersPaymentMethodsQuery.isLoading ||
        othersPaymentMethodsQuery.isFetchingNextPage ||
        !othersPaymentMethodsQuery.isFetched;

    const othersPaymentMethods = othersPaymentMethodsQuery.data?.pages.reduce(
        (accumulator, page) => accumulator.concat(page.data),
        [],
    );

    return (
        <Row>
            <Col className="ml-2 mr-2">
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>CÓDIGO</StyledTh>
                            <StyledTh>DESCRIÇÃO</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {othersPaymentMethods?.map((paymentMethod) => (
                            <OthersPaymentMethodsTableRow
                                paymentMethod={paymentMethod}
                                filterTerm={formData.filterTerm}
                                key={paymentMethod.id}
                            />
                        ))}
                    </tbody>
                </Table>
            </Col>
            <Col md={12} className="text-center" ref={target}>
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {!showLoading && !othersPaymentMethods?.length && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Col>
        </Row>
    );
}
