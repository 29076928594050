import { Col, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import BaseLayout from '../../../components/BaseLayout';
import InvoiceHttpService from '../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import { invoiceAttachmentsListRoute } from '../../../routes/config/invoices.route';
import AttachmentFilePreview from '../../../components/Invoices/InvoiceAttachments/AttachmentFilePreview';
import NewAttachmentForm, {
    AttachmentFields,
} from '../../../components/Invoices/InvoiceAttachments/NewAttachmentForm';
import {
    StyledButtonArea,
    StyledGrid,
    StyledHeader,
    StyledPageTitle,
} from './styles';
import fileToBuffer from '../../../utils/fileToBuffer';
import getFileExtension from '../../../utils/getFileExtension';
import useInvoiceAttachmentPageAccess from '../../../hooks/useInvoiceAttachmentPageAccess';

export default function New() {
    const { id: invoiceId } = useParams<{ id: string }>();

    const history = useHistory();

    useInvoiceAttachmentPageAccess(invoiceId);

    const [attachmentFields, setAttachmentFields] = useState<AttachmentFields>({
        number: '',
        bankAccount: null,
    });

    const goToAttachmentList = () =>
        history.push(invoiceAttachmentsListRoute.build({ id: invoiceId }));

    const addAttachmentMutation = useMutation({
        mutationFn: async () => {
            if (!attachmentFields.file) {
                throw new Error(
                    'O arquivo é obrigatório para a criação de um comprovante',
                );
            }

            await InvoiceHttpService.addAttachment(invoiceId, {
                type: 'DEPOSIT',
                file: await fileToBuffer(attachmentFields.file),
                fileExtension: getFileExtension(attachmentFields.file),
                voucherNumber: attachmentFields.number || null,
                voucherDate: attachmentFields.date?.toISOString() || null,
                voucherAmount: attachmentFields.amount
                    ? parseFloat(attachmentFields.amount.replace(',', '.'))
                    : null,
                bankAccount: attachmentFields.bankAccount,
            });
        },
        onSuccess: () => {
            toast.success('Comprovante adicionado com sucesso');

            goToAttachmentList();
        },
        onError: (err: any) => {
            if (isFriendlyHttpError(err)) {
                toast.error(err.message);
            }

            toast.error('Ocorreu um erro ao adicionar o comprovante');
        },
    });

    return (
        <BaseLayout>
            <StyledHeader>
                <Col>
                    <StyledPageTitle>Adicionar comprovante</StyledPageTitle>
                </Col>
                <Col>
                    <StyledButtonArea>
                        <Button
                            onClick={goToAttachmentList}
                            variant="outline-primary"
                            className="pl-4 pr-4 mr-2"
                            style={{ minWidth: 125 }}
                            disabled={addAttachmentMutation.isLoading}
                        >
                            Cancelar
                        </Button>
                        {attachmentFields.file ? (
                            <Button
                                className="pl-4 pr-4"
                                onClick={() => addAttachmentMutation.mutate()}
                                style={{ minWidth: 125 }}
                                disabled={addAttachmentMutation.isLoading}
                            >
                                {addAttachmentMutation.isLoading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    'Salvar'
                                )}
                            </Button>
                        ) : (
                            <OverlayTrigger
                                key="disabled-save-new-attachment-button"
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        É necesário adiconar um arquivo para
                                        salvar um novo comprovante.
                                    </Tooltip>
                                }
                            >
                                <Button
                                    className="pl-4 pr-4"
                                    disabled
                                    style={{ minWidth: 125 }}
                                >
                                    Salvar
                                </Button>
                            </OverlayTrigger>
                        )}
                    </StyledButtonArea>
                </Col>
            </StyledHeader>
            <StyledGrid>
                <NewAttachmentForm
                    mode="create"
                    onValuesChange={setAttachmentFields}
                    values={attachmentFields}
                />
                <AttachmentFilePreview file={attachmentFields.file} />
            </StyledGrid>
        </BaseLayout>
    );
}
