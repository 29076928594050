import { Badge, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { InvoiceStatus } from '../../../constants/invoiceStatus';

export const StyledBadge = styled(Badge)<{ status: InvoiceStatus }>`
    font-size: 14px;
    background: ${({ status }) => {
        if (status === InvoiceStatus.InvoiceApproved) {
            return 'rgba(33, 150, 83, 0.1)';
        }

        if (
            status === InvoiceStatus.InvoiceReproved ||
            status === InvoiceStatus.InvoiceCanceled ||
            status === InvoiceStatus.InvoiceCanceledPaid ||
            status === InvoiceStatus.InvoicePaymentError
        ) {
            return 'rgba(235, 87, 87, 0.1)';
        }

        if (status === InvoiceStatus.Invoice) {
            return 'rgba(47, 128, 237, 0.1)';
        }

        if (status === InvoiceStatus.InvoiceValidated) {
            return 'rgba(184, 125, 0, 0.1)';
        }

        return 'rgba(112, 112, 112, 0.1)';
    }};

    color: ${({ status }) => {
        if (status === InvoiceStatus.InvoiceApproved) {
            return '#219653';
        }

        if (
            status === InvoiceStatus.InvoiceReproved ||
            status === InvoiceStatus.InvoiceCanceled ||
            status === InvoiceStatus.InvoiceCanceledPaid ||
            status === InvoiceStatus.InvoicePaymentError
        ) {
            return '#EB5757';
        }

        if (status === InvoiceStatus.Invoice) {
            return '#2F80ED';
        }

        if (status === InvoiceStatus.InvoiceValidated) {
            return '#B87D00';
        }

        return '#707070';
    }};
`;

export const StyledApproveButton = styled(Button)`
    height: 30px;
    margin: 0 10px 0 15px;
    padding: 0 8px;
    text-align: center;
    background-color: #6fcf97;
    border-color: #57c786;

    :hover {
        background-color: #3ebb72;
        border-color: #38a968;
    }

    :focus {
        background-color: #3ebb72;
        border-color: #38a968;
        box-shadow: 0 0 0 0.2rem rgba(56, 169, 104, 0.5) !important;
    }

    :active {
        background-color: #3ebb72 !important;
        border-color: #38a968 !important;
    }

    :disabled {
        background-color: #e0e0e0;
        border-color: #dddddd;
    }

    :disabled:active {
        background-color: #e0e0e0 !important;
        border-color: #dddddd !important;
    }
`;
