import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { UseFormMethods, Controller } from 'react-hook-form';
import { InvoiceValidationFields } from '../InvoiceValidationForm';

interface DeliveryDateInputProps {
    form: UseFormMethods<InvoiceValidationFields>;
    disabled: boolean;
    minDeliveryDate: Date;
}

export default function DeliveryDateInput({
    form,
    disabled,
    minDeliveryDate,
}: DeliveryDateInputProps) {
    return (
        <Form.Group controlId="formBasicDeliveryDate">
            <Form.Label>Previsão de entrega</Form.Label>
            <Controller
                control={form.control}
                name="deliveryDate"
                render={({ value, onChange, onBlur }) => (
                    <ReactDatePicker
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        onSelect={onChange}
                        selected={value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        minDate={minDeliveryDate}
                        disabled={disabled}
                    />
                )}
                rules={{
                    required: 'Campo obrigatório',
                }}
            />
            {form.errors.deliveryDate && (
                <Form.Text className="text-danger">
                    {form.errors.deliveryDate.message}
                </Form.Text>
            )}
        </Form.Group>
    );
}
