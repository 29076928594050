import React from 'react';
import { Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { StyledTh, StyledTdLink } from '../../../pages/OnlineStore/styles';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import NumericInput from '../../NumericInput';
import ISaveInvoiceItemDto from '../../../dtos/ISaveInvoiceItemDto';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { formatToQueryParam } from '../../../utils/strings';
import AvailabilityBadge from '../../AvailabilityBadge';
import { useAuth } from '../../../contexts/authContext';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';
import ProductVisibilityBadge from '../../ProductVisibilityBadge';

interface Props {
    data: any;
}

const GeneratorsTable: React.FC<Props> = ({ data }) => {
    const {
        setSelectedProduct,
        setSelectedProductId,
        setShowDetailsModal,
        cart,
        term,
    } = useOnlineStore();

    function toFixed(num: number, fixed: number) {
        const regex = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
        const value = num.toString(2).match(regex)[0];

        return parseFloat(value);
    }

    const { user } = useAuth();

    const isAllowedToViewProductVisibility = userHasRoles(user, [
        Role.Administrator,
        Role.PointsAdministrator,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.Financial,
        Role.Logistics,
        Role.CustomerSuccess,
        Role.ShippingCompany,
        Role.Production,
        Role.Registration,
        Role.Purchase,
        Role.Marketing,
    ]);

    return (
        <Table hover bordered className="mx-0 text-center" size="sm">
            <thead>
                <tr>
                    {isAllowedToViewProductVisibility && <StyledTh />}
                    <StyledTh>CÓDIGO</StyledTh>
                    <StyledTh>DESCRIÇÃO</StyledTh>
                    <StyledTh>DISPONIBILIDADE</StyledTh>
                    <StyledTh>VALOR UNITÁRIO</StyledTh>
                    <StyledTh>QUANTIDADE</StyledTh>
                    <StyledTh>VALOR TOTAL</StyledTh>
                </tr>
            </thead>
            <tbody>
                {data?.map((item: any, index: any) => (
                    <tr
                        style={{
                            backgroundColor:
                                item?.quantity > 0
                                    ? 'rgba(242, 153, 74, 0.08)'
                                    : '#ffffff',
                        }}
                        key={index}
                    >
                        {isAllowedToViewProductVisibility && (
                            <td>
                                <ProductVisibilityBadge
                                    isInternalProduct={
                                        item?.BLK_INT.toUpperCase() === 'S'
                                    }
                                />
                            </td>
                        )}

                        <td>
                            <Highlighter
                                autoEscape
                                highlightClassName="highlight-term"
                                searchWords={[formatToQueryParam(term)]}
                                textToHighlight={item?.B1_COD}
                            />
                        </td>
                        <td>
                            <StyledTdLink
                                onClick={() => {
                                    setSelectedProduct(item);
                                    setSelectedProductId(item?.URL_ID);
                                    setShowDetailsModal(true);
                                }}
                            >
                                <Highlighter
                                    autoEscape
                                    highlightClassName="highlight-term"
                                    searchWords={[formatToQueryParam(term)]}
                                    textToHighlight={item?.B1_DESC}
                                />
                            </StyledTdLink>
                        </td>
                        <td>
                            <AvailabilityBadge product={item} />
                        </td>
                        <td>
                            {toFixed(item?.VLR_TOT, 2).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </td>
                        <td
                            style={{
                                verticalAlign: 'middle',
                                width: '10%',
                            }}
                        >
                            <NumericInput
                                disabled={false}
                                tableItem={item ?? {}}
                                item={
                                    {
                                        status: InvoiceStatus.Cart,
                                        isCart: true,
                                        invoiceId: cart?.id,
                                        id: item?.INVOICE_ITEM_ID,
                                        productErpCode: item?.B1_COD,
                                        productName: item?.B1_DESC,
                                        productCategory: item?.CAT_DESC,
                                        unitValue: item?.VLR_TOT,
                                        availability: item?.boardingDate,
                                    } as ISaveInvoiceItemDto
                                }
                            />
                        </td>
                        <td>
                            {(item?.quantity * item?.VLR_TOT)?.toLocaleString(
                                'pt-br',
                                {
                                    style: 'currency',
                                    currency: 'BRL',
                                },
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default GeneratorsTable;
