export default function getMaxAvailabilityDate(invoice: any): string {
    const { invoiceItems = [] } = invoice;

    if (invoiceItems.length === 0) return '';

    const parseAndFilterDates = (items: any[]) =>
        items
            .map((item) => item.availability)
            .filter(
                (date): date is string =>
                    Boolean(date) &&
                    date !== 'IMMEDIATE' &&
                    !Number.isNaN(Date.parse(date)),
            )
            .map((date) => new Date(date));

    const availabilityDates = parseAndFilterDates(invoiceItems);

    const maxDate =
        availabilityDates.length > 0
            ? new Date(
                  Math.max(...availabilityDates.map((date) => date.getTime())),
              )
            : new Date();

    maxDate.setHours(maxDate.getHours() + 72);

    return maxDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
}
