/* eslint-disable prefer-const */
import { DateTime } from 'luxon';
import formatDate from './formatDate';

export default function getDeliveryDate(invoice: any) {
    let deliveryCompanyEstimatedDate = invoice?.estimatedDeliveryDate;

    if (deliveryCompanyEstimatedDate) {
        return formatDate(deliveryCompanyEstimatedDate);
    }

    if (invoice?.invoiceProduction?.waybillDate) {
        let calculatedDeliveryDate = DateTime.fromISO(
            invoice?.invoiceProduction?.waybillDate,
        );

        calculatedDeliveryDate = calculatedDeliveryDate.plus({
            days: invoice.deliveryDeadline ?? 0,
        });

        return formatDate(calculatedDeliveryDate.toISODate());
    }

    let calculatedDeliveryDate = DateTime.fromISO(invoice?.deliveryDate);

    calculatedDeliveryDate = calculatedDeliveryDate.plus({
        days: invoice.deliveryDeadline ?? 0,
    });

    return formatDate(calculatedDeliveryDate.toISODate());
}
