import { InvoiceTriangulationStatus } from '../constants/invoiceStatus';

export default function getTriangulationStatusText(status: string) {
    return (
        {
            [InvoiceTriangulationStatus.Created]: 'CADASTRADA',
            [InvoiceTriangulationStatus.Completed]: 'COMPLETA',
            [InvoiceTriangulationStatus.Processing]: 'PROCESSANDO',
            [InvoiceTriangulationStatus.Approved]: 'LIBERADA',
        }[status] || ''
    );
}
