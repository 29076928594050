import { Controller, UseFormMethods } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { InvoiceValidationFields } from '../InvoiceValidationForm';

interface FeedbackTextAreaProps {
    form: UseFormMethods<InvoiceValidationFields>;
    disabled: boolean;
}

export default function FeedbackTextArea({
    form,
    disabled,
}: FeedbackTextAreaProps) {
    return (
        <Form.Group controlId="formBasicFeedback">
            <Form.Label>Comentário</Form.Label>
            <Controller
                control={form.control}
                name="feedback"
                render={({ value, onChange }) => (
                    <textarea
                        value={value}
                        onChange={onChange}
                        className="form-control"
                        placeholder="Insira aqui sua mensagem..."
                        style={{
                            height: 100,
                            resize: 'none',
                        }}
                        disabled={disabled}
                    />
                )}
            />
        </Form.Group>
    );
}
