import * as Yup from 'yup';

export const customerUserRules = {
    document: Yup.string().required('Campo obrigatório'),
    name: Yup.string().required('Campo obrigatório'),
    ie: Yup.string().when('$hasIe', {
        is: true,
        then: Yup.string().required('Campo obrigatório'),
    }),
    phone: Yup.string().required('Campo obrigatório'),
};

export const customerAddressRules = {
    addressCep: Yup.string()
        .required('Campo obrigatório')
        .min(8, 'CEP inválido'),
    addressDescription: Yup.string().required('Campo obrigatório'),
    addressNumber: Yup.string().required('Campo obrigatório'),
    addressNeighborhood: Yup.string()
        .required('Campo obrigatório')
        .test(
            'len',
            'O campo Bairro pode conter no máximo 30 caracteres',
            (val) => val.length <= 30,
        ),
    erpState: Yup.string().required('Campo obrigatório'),
    erpCity: Yup.string().required('Campo obrigatório'),
};

export const customerRules = { ...customerUserRules, ...customerAddressRules };

export default customerRules;
