/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
    Form,
    InputGroup,
    Modal,
    ModalFooter,
    Table,
    Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Pagination from '../../components/Pagination';
import { StyledTh } from '../OnlineStore/styles';
import formatMask from '../../utils/formatMask';
import { SellerStatus } from '../../constants/sellerStatus';
import { SellerValues, useSeller } from '../../contexts/sellerContext';
import InvoiceHttpService from '../../services/http/invoice-http';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import SellerHttpService from '../../services/http/seller-http';

interface Props {
    show: boolean;
    onHide: Function;
    id?: any;
}

const List: React.FC<Props> = (props: Props) => {
    const { setCart, setStep, productType, setGroupId } = useOnlineStore();
    const { seller, setSeller }: SellerValues = useSeller();
    const [integrators, setIntegrators] = useState([]);
    const [selectedIntegrator, setSelectedIntegrator] = useState({} as any);
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [perPage] = useState(5);

    async function loadIntegrators(): Promise<void> {
        const params = {
            statusFilter: SellerStatus.Approved,
            term,
            skip: page - 1,
            take: perPage,
            isSellerSelection: true,
        };

        const response = await SellerHttpService.readMany(params);

        setPages(Math.ceil(response.data.metadata.total / perPage));
        setIntegrators(response.data.results);
    }

    const emptyCart = async () => {
        try {
            const response = await InvoiceHttpService.emptyCart();

            setCart(response.data);
        } catch (error) {
            toast.error('Erro ao esvaziar carrinho');
        }
    };

    const handleSubmit = async () => {
        if (
            Object.keys(selectedIntegrator).length === 0 &&
            selectedIntegrator.constructor === Object
        ) {
            toast.info('Selecione um integrador');

            return;
        }

        localStorage.setItem('seller', JSON.stringify(selectedIntegrator));

        setSeller(selectedIntegrator);

        await emptyCart();

        toast.success('Integrador alterado com sucesso!');

        if (productType === 'PA' || productType === 'BF') {
            setStep(2);
        } else {
            setGroupId('1001');
            setStep(1);
        }

        props.onHide();
    };

    useEffect(() => {
        if (props.show) {
            setPage(1);
            setTerm('');

            loadIntegrators();

            console.log('localStorage');

            if (localStorage.seller) {
                setSelectedIntegrator(JSON.parse(localStorage.seller));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    useEffect(() => {
        if (props.show) {
            const timer = setTimeout(async () => {
                setPage(1);

                loadIntegrators();
            }, 500);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (props.show) {
            loadIntegrators();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => {
                    if (seller) props.onHide();
                }}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton={seller}>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Selecionar integrador
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'ml-3 mr-3'}>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search"></i>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                            placeholder="Pesquisar..."
                        />
                    </InputGroup>

                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh></StyledTh>
                                <StyledTh>CNPJ</StyledTh>
                                <StyledTh>NOME</StyledTh>
                                <StyledTh>CIDADE</StyledTh>
                            </tr>
                        </thead>
                        <tbody>
                            {integrators &&
                                integrators.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Check
                                                checked={
                                                    selectedIntegrator &&
                                                    selectedIntegrator.id ===
                                                        item.id
                                                }
                                                disabled={props.id}
                                                type={'radio'}
                                                name="selectedIntegrator"
                                                value={item.id}
                                                onChange={() => {}}
                                                onClick={() => {
                                                    setSelectedIntegrator(item);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {formatMask(
                                                item.cnpj,
                                                '"##.###.###/####-##',
                                            )}
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.erpCityName}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>

                    <Pagination
                        pages={pages}
                        page={page}
                        onPageChange={(page: any) => setPage(page)}
                    ></Pagination>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            handleSubmit();
                        }}
                        disabled={JSON.stringify(selectedIntegrator) === ''}
                    >
                        Selecionar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export { List };
