import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledImediateBadge = styled(Badge)`
    font-size: 14px;
    background: rgba(111, 207, 15, 0.1);
    color: #6fcf97;
    cursor: default;
`;

export const StyledOnDemandBadge = styled(Badge)`
    font-size: 14px;
    background-color: rgba(120, 0, 225, 0.1);
    color: #7800e1;
    cursor: default;
`;

export const StyledDateBadge = styled(Badge)`
    font-size: 14px;
    background-color: rgba(109, 122, 135, 0.1);
    color: #212529;
    font-weight: normal;
    cursor: default;
`;
