import styled from 'styled-components';

export const StyledDropzoneTitle = styled.h5`
    font-size: 12px;
    color: #a2a2a2;
    text-align: center;
    transition: all 0.5s ease;
    height: 100%;
`;

export const StyledDropzoneSection = styled.section`
    height: 80px;
    width: 100%;
    margin: 15px 0;
    border-radius: 4px;
    transition: all 0.5s ease;

    :hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08),
            0px 4px 16px rgba(0, 0, 0, 0.12);
    }

    :hover ${StyledDropzoneTitle} {
        color: #707070;
    }
`;

export const StyledDiv = styled.div`
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-stretch;

    @media (max-width: 1023px) {
        width: 100%;
        margin-bottom: 16px;
    }

    @media (min-width: 1024px) {
        width: 49%;
    }
`;

export const StyledMainDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 8px;
    margin-top: 40px;

    @media (max-width: 1023px) {
        flex-direction: column;
    }
`;
