/* eslint-disable import/prefer-default-export */
export enum PaymentMethod {
    CreditCard = 'CREDIT_CARD',
    CreditCardAndCreditCard = 'CREDIT_CARD, CREDIT_CARD',
    CreditCardAndBarcode = 'CREDIT_CARD, BARCODE',
    Barcode = 'BARCODE',
    Pix = 'PIX',
    Deposit = 'DEPOSIT',
    Financing = 'FINANCING',
    Others = 'OTHERS',
}
