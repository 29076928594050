/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Image, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import {
    StyledCard,
    StyledCardBody,
    StyledCardTitle,
    StyledCardText,
    StyledCardSmallText,
} from './styles';

import PointHttpService from '../../../services/http/point-http';
import arrowUp from '../../../assets/icons/arrow_up.svg';
import { SellerValues, useSeller } from '../../../contexts/sellerContext';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import ConfigurationReference from '../../../constants/configurationReference';

const InfoCard: React.FC = () => {
    const [gainedInMonth, setGainedInMonth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [actualSeason, setActualSeason] = useState(null);
    const { seller }: SellerValues = useSeller();

    function formatNumber(num: any) {
        const valueToFormat = num ?? '0';

        return valueToFormat
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    async function loadGainedInMonth(): Promise<void> {
        const response = await PointHttpService.showGainedInSeason();

        setGainedInMonth(formatNumber(response.data.value));
    }

    const getActualSeason = async () => {
        setLoading(true);

        try {
            const response = await ConfigurationHttpService.readOne(
                ConfigurationReference.ActiveSeason,
            );

            if (response.data.value !== null) {
                const season = await PointHttpService.getSeason(
                    response.data.value,
                );

                setActualSeason(season.data);
            }
        } catch (error) {
            let err = error as any;

            toast.error(err.message.toString());
        }

        setLoading(false);
    };

    useEffect(() => {
        getActualSeason();
    }, []);

    useEffect(() => {
        loadGainedInMonth();
    }, [seller]);

    return (
        <React.Fragment>
            <Col className={'my-4 ml-n3'} md={5} xl={3} sm={6}>
                <StyledCard>
                    <StyledCardBody className="mb-3">
                        <StyledCardTitle>
                            Pontos ganhos na temporada
                        </StyledCardTitle>
                        {loading ? (
                            <Spinner
                                animation="border"
                                className="mr-2 mt-1"
                                size={'sm'}
                            />
                        ) : (
                            <>
                                {actualSeason && (
                                    <StyledCardSmallText>
                                        Período{' '}
                                        {actualSeason &&
                                            DateTime.fromISO(
                                                actualSeason.seasonStart,
                                            ).toLocaleString()}{' '}
                                        até{' '}
                                        {actualSeason &&
                                            DateTime.fromISO(
                                                actualSeason.seasonFinish,
                                            ).toLocaleString()}{' '}
                                    </StyledCardSmallText>
                                )}

                                <StyledCardText>
                                    <Image
                                        className="float-left img-fluid mt-2 mr-2 "
                                        src={
                                            +gainedInMonth !== 0
                                                ? arrowUp
                                                : null
                                        }
                                        width="14px"
                                    />
                                    {gainedInMonth}
                                </StyledCardText>
                            </>
                        )}
                    </StyledCardBody>
                </StyledCard>
            </Col>
        </React.Fragment>
    );
};

InfoCard.propTypes = {
    sellerId: PropTypes.number,
};

export default InfoCard;
