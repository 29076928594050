/* eslint-disable import/prefer-default-export */
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledFormControl = styled(Form.Control)`
    &:disabled {
        color: gray;
        ::placeholder {
            color: gray;
        }
    }
`;
