/* eslint-disable @typescript-eslint/no-shadow */
export const control = (base: any, state: any) => ({
    ...base,
    borderRadius: 3,
    minHeight: 48,
    fontSize: 12,
    border: state.isFocused ? '1px solid rgba(242, 153, 74, 0.233)' : null,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(242, 153, 74, 0.8)' : null,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
});

export const invoiceOrderingControl = (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? '1px solid rgba(47, 204, 139, 0.233)' : null,
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    minHeight: 40,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
    fontSize: 12,
});

export const tradeControl = (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? '1px solid rgba(47, 204, 139, 0.233)' : null,
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    minHeight: 48,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
    fontSize: 12,
});

export const optionsTheme = (base: any) => ({
    ...base,
    color: '#000000',
});

export const theme = (theme: any) => ({
    ...theme,

    colors: {
        ...theme.colors,
        primary: 'rgba(242, 153, 74, 0.8)',
        primary25: 'rgba(242, 153, 74, 0.4)',
        primary50: 'rgba(242, 153, 74, 0.6)',
        boxShadow: 'none',
    },
});
