import { ReactNode } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { StyledCard, StyledCardText } from './styles';

export interface HomeCardProps {
    onClick: () => void;
    icon: ReactNode;
    title: string;
    description: string;
}

export default function HomeCard({
    onClick,
    icon,
    title,
    description,
}: HomeCardProps) {
    return (
        <StyledCard onClick={onClick}>
            <Card.Body>
                <Row>
                    <Col className="my-4" md={3}>
                        {icon}
                    </Col>
                    <Col className="my-4">
                        <Card.Subtitle className="mb-2 text-muted">
                            <strong>{title}</strong>
                        </Card.Subtitle>
                        <StyledCardText>{description}</StyledCardText>
                    </Col>
                </Row>
            </Card.Body>
        </StyledCard>
    );
}
