import arrayBufferToBase64 from './arrayBufferToBase64';

export default async function fileToBuffer(
    file: File,
): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = () => {
            resolve(arrayBufferToBase64(reader.result));
        };

        reader.onerror = () => {
            reject(new Error('Error reading the file'));
        };
    });
}
