import { Col, NavDropdown, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { StyledDropdownTitle, StyledLink } from './styles';
import ConfirmationDialog from '../../../ConfirmationDialog';
import NotificationHttpService from '../../../../services/http/notification-http';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import { NotificationIcon } from '../../../../constants/notificationIcon';
import useNotifications from '../../../../hooks/useNotififcations';

export default function NotificationHeader() {
    const { notificationsQuery } = useNotifications();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const removeAllNotificationsMutation = useMutation({
        mutationFn: async () => NotificationHttpService.deleteMany(),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao remover as notificações');
        },
        onSettled: () => notificationsQuery.refetch(),
    });

    return (
        <NavDropdown.Header style={{ width: '420px' }}>
            <Row>
                <Col>
                    <StyledDropdownTitle className="text-wrap">
                        Notificações
                    </StyledDropdownTitle>
                </Col>
                {Boolean(notificationsQuery.data?.length) && (
                    <Col className="text-right">
                        <StyledLink onClick={() => setShowConfirmation(true)}>
                            Limpar
                        </StyledLink>
                    </Col>
                )}
            </Row>

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={async () =>
                        removeAllNotificationsMutation.mutateAsync()
                    }
                    title="Excluir notificações"
                    text="Deseja excluir todas as notificações?"
                    icon={NotificationIcon.Warning}
                />
            )}
        </NavDropdown.Header>
    );
}
