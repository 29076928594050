export const cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const cnpjMask = [
    /[0-9]/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];
export const activityTimeMask = [
    /[0-9]/,
    /\d/,
    ' ',
    'A',
    'n',
    'o',
    's',
    ' ',
    'e',
    ' ',
    /\d/,
    /\d/,
    ' ',
    'M',
    'e',
    's',
    'e',
    's',
];
export const phoneMask = [
    '(',
    /[0-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const cpfMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];

export const creditCardNumber = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const creditCardNumberAmex = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const creditCardExpiringDate = [
    /[0-9]/,
    /\d/,
    '/',
    '2',
    '0',
    /\d/,
    /\d/,
];

export const creditCardSecurityCode = [/[0-9]/, /\d/, /\d/];

export const creditCardSecurityCodeAmex = [/[0-9]/, /\d/, /\d/, /\d/];

export const rgMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const dateMask = [
    /[0-9]/,
    '/',
    /[0-9]/,
    /\d/,
    '/',
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
];
