import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    margin-top: 8px;
    background-color: #fff !important;
    font-size: 16px;
    color: #f2994a !important;
    border: none !important;
    font-weight: normal;
    padding: 0px;

    :hover {
        color: #df7210 !important;
        text-decoration: underline;
    }

    :focus {
        border: none !important;
        box-shadow: none !important;
    }
`;

export const StyledP = styled.p<{ sizeUp?: boolean }>`
    font-style: italic;
    padding-top: 20px;
    text-align: center;
    height: ${(props) => (props.sizeUp ? '630px' : '530px')};
    width: 100%;
`;

export const StyledNewSpan = styled.span`
    padding-right: 8px;
    color: #03cea4;
    font-weight: bold;
`;

export const StyledLink = styled.span<{ close?: boolean }>`
    display: flex;
    align-items: center;
    color: #707070;
    cursor: pointer;

    :hover {
        color: ${(props) => (props.close ? '#f2994a' : '#707070')};
        text-decoration: none;
    }
`;

export const StyledFileDiv = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 15px;
    margin: 0 10px 10px 0;
    border-radius: 20px;
    font-size: 12px;
    border: 1px solid #ced4da;
    background-image: ${(props) =>
        props.selected
            ? 'linear-gradient(to right, rgba(242, 153, 74, 0.3),  rgba(255, 255, 255, 0.2))'
            : 'none'};
`;

export const StyledFilesDiv = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
`;

export const StyledDisplayDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledMainDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
