import Request from './request';

export default class SellerHttpService {
    private static uri = 'sellers';

    public static readMany(params: {}) {
        return Request.get(this.uri, params);
    }

    public static updateAuth(data: {}) {
        return Request.patch(this.uri, data);
    }
}
