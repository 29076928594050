import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background: #fafafa;
    border-radius: 4px;
`;

export const StyledLink = styled.a`
    color: #2f80ed;
    font-size: 0.75rem;
    cursor: pointer;
`;
