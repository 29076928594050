import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNav = styled(Nav)`
    padding: 15px;
`;

export const StyledTitle = styled.h3`
    font-size: 24px !important;
    font-weight: bolder !important;
    line-height: 30px;
    text-align: left;
`;

export const StyledNavLink = styled(Nav.Link)`
    color: ${(props) => (props.selected ? '#F2994A' : '#707070')};

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    line-height: 37px;
`;
