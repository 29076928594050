import NumericInput from 'react-numeric-input';
import styled from 'styled-components';

interface Props {
    decoration: string;
}

export const StyledTitle = styled.strong<Props>`
    font-size: 18px !important;
    font-weight: 700 !important;
    &:hover {
        text-decoration: ${(p) => p.decoration};
    }
`;

export const StyledSubtitle = styled.h6`
    font-size: 16px !important;
    font-weight: 900 !important;
    color: #707070;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledTdText = styled.h5`
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.05em;
    color: #828282;
`;

export const StyledDesc = styled.h6`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    word-wrap: break-word;
    /* or 24px */
    letter-spacing: 0.0275em;
    /* Gray / 100 */
    color: #19191d;
`;

export const StyledPrice = styled.h6`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    display: flex;
    align-items: center;

    /* Dark Color */

    color: rgba(0, 0, 0, 0.87);
`;

export const StyledText = styled.h6`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    /* or 140% */

    color: #707070;
`;

export const StyledDivider = styled.hr`
    margin-right: 2%;
    margin-left: 2%;
`;

export const StyledDetailValue = styled.h6`
    font-size: 2rem;
    font-weight: 700;
    @media (max-width: 991.98px) {
        font-size: 2rem;
        font-weight: 700;
    }
`;

export const StyledNumericInput = styled(NumericInput)`
    height: 45px !important;
    :focus {
        outline: none;
        border-color: #f2994a;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f2994a;
    }
    ~ b[style] {
        cursor: pointer !important;
    }
`;
