import { Controller, UseFormMethods } from 'react-hook-form';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { InvoiceValidationFields } from '../InvoiceValidationForm';

interface FutureSaleToggleProps {
    form: UseFormMethods<InvoiceValidationFields>;
    disabled: boolean;
}

export default function FutureSaleToggle({
    form,
    disabled,
}: FutureSaleToggleProps) {
    const { isTriangulation, isFutureSale } = form.watch();

    if (isTriangulation) {
        return (
            <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip id="overlay-example">
                        Operação não permitida para pedidos que se enquadram
                        como conta e ordem
                    </Tooltip>
                }
            >
                <Form.Group controlId="formBasicIsFutureSale">
                    <Form.Label style={{ margin: 0 }}>
                        Operação de venda para entrega futura
                    </Form.Label>
                    <Controller
                        control={form.control}
                        name="isFutureSale"
                        render={() => (
                            <Form.Check
                                type="switch"
                                className="float-left"
                                checked={false}
                                disabled
                            />
                        )}
                    />
                </Form.Group>
            </OverlayTrigger>
        );
    }

    return (
        <Form.Group controlId="formBasicIsFutureSale">
            <Form.Label style={{ margin: 0 }}>
                Operação de venda para entrega futura
            </Form.Label>
            <Controller
                control={form.control}
                name="isFutureSale"
                render={({ value }) => (
                    <Form.Check
                        type="switch"
                        className="float-left"
                        onChange={() => {
                            form.setValue('isFutureSale', !isFutureSale);
                        }}
                        checked={value}
                        disabled={disabled}
                    />
                )}
            />
        </Form.Group>
    );
}
