/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { Form, Row, Col, Image, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import fileUploadImg from '../../../../assets/icons/fileUpload.png';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { StyledLink, StyledRow } from './styles';
import closeIcon from '../../../../assets/icons/clear_24px.png';
import { ATTACHMENTS_SRC_URL } from '../../../../config/webservice';
import { InvoiceStatus } from '../../../../constants/invoiceStatus';
import { Role } from '../../../../constants/role';
import userHasRoles from '../../../../utils/userHasRoles';
import { useAuth } from '../../../../contexts/authContext';
import fileToBuffer from '../../../../utils/fileToBuffer';
import getFileExtension from '../../../../utils/getFileExtension';

const Others = () => {
    const { cart, setCart } = useOnlineStore();
    const [loading, setLoading] = useState(false);

    const { user } = useAuth();

    const isFinancial = userHasRoles(user, [Role.Financial]);

    const loadInvoice = async () => {
        const response = await InvoiceHttpService.show(cart?.id);

        setCart(response.data.data);
    };

    const saveFiles = async (files: any[]) => {
        files.forEach(async (file: any) => {
            setLoading(true);

            if (
                cart?.invoiceAttachments &&
                cart?.invoiceAttachments.find(
                    (item: any) =>
                        item.filename?.substring(
                            item?.filename.indexOf('_') + 1,
                        ) === file.name,
                )
            ) {
                toast.error(
                    'Não é possível adicionar um mesmo arquivo duas vezes',
                );
                setLoading(false);

                return null;
            }

            await InvoiceHttpService.addAttachment(cart?.id, {
                type: 'OTHERS',
                file: await fileToBuffer(file),
                fileExtension: getFileExtension(file),
                voucherAmount: null,
                voucherDate: null,
                voucherNumber: null,
            });

            loadInvoice();

            setLoading(false);
        });
    };

    const disable = false;

    useEffect(() => {
        loadInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                style={{
                    width: '100%',
                }}
            >
                <Form.Group className={'mt-5 pl-3 pr-3'}>
                    <Row className={'mb-3'}>
                        <Dropzone
                            disabled={disable}
                            accept={['image/png', 'image/jpeg', '.pdf']}
                            onDropRejected={() => {
                                toast.error(
                                    'Arquivo muito grande ou formato incorreto',
                                );
                            }}
                            multiple
                            maxSize={5242880}
                            onDrop={(files) => {
                                saveFiles(files);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="custom-file-upload">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Row className={'dropzone-title'}>
                                            <Col
                                                className={'dropzone-title'}
                                                sm={10}
                                            >
                                                <h5
                                                    style={{
                                                        color: '#F2994A',
                                                        fontSize: '1rem',
                                                    }}
                                                    className={
                                                        'dropzone-title p-4'
                                                    }
                                                >
                                                    {!disable ? (
                                                        <>
                                                            Clique aqui para
                                                            selecionar um
                                                            arquivo ou arraste-o
                                                        </>
                                                    ) : (
                                                        <>
                                                            Lista de
                                                            comprovantes já
                                                            enviados
                                                        </>
                                                    )}
                                                </h5>
                                            </Col>
                                            <Col>
                                                {loading ? (
                                                    <Spinner
                                                        className={
                                                            'float-right mr-4 mt-4'
                                                        }
                                                        animation={'border'}
                                                    />
                                                ) : (
                                                    <Image
                                                        className={
                                                            'float-right mr-5 mt-3'
                                                        }
                                                        src={fileUploadImg}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className={'mt-n4 ml-4'}>
                                            <h6
                                                style={{
                                                    fontSize: '0.813rem',
                                                }}
                                            >
                                                arquivos PDF, jpg, pngs até 5 MB
                                            </h6>
                                        </Row>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Row>

                    {cart?.invoiceAttachments?.map((file: any) => (
                        <StyledRow className={'p-3 mt-3 mb-2'}>
                            <Col>
                                <StyledLink
                                    href={`${ATTACHMENTS_SRC_URL}/${file?.filename}`}
                                    className={'mt-2'}
                                    key={file?.filename}
                                >
                                    {file?.filename.substring(
                                        file?.filename.indexOf('_') + 1,
                                    )}
                                </StyledLink>
                            </Col>

                            <Col>
                                {((isFinancial &&
                                    cart?.status ===
                                        InvoiceStatus.InvoiceApproved) ||
                                    !disable) && (
                                    <Image
                                        onClick={async () => {
                                            setLoading(true);
                                            await InvoiceHttpService.removeAttachment(
                                                cart?.id,
                                                file.id,
                                            );

                                            const response =
                                                await InvoiceHttpService.show(
                                                    cart?.id,
                                                );

                                            setCart(response.data.data);

                                            setLoading(false);
                                        }}
                                        style={{ width: 8, cursor: 'pointer' }}
                                        className={'float-right mt-2'}
                                        src={closeIcon}
                                    />
                                )}
                            </Col>
                        </StyledRow>
                    ))}
                </Form.Group>
            </Form>
        </>
    );
};

Others.propTypes = {
    paymentMethod: PropTypes.string,
};

export default Others;
