import { Badge, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    height: 48px;
    width: 20%;
    color: #f2994a !important;
    background-color: #ffffff;
    border: 1px solid #f2994a;
`;

export const StyledBadgeSeller = styled(Badge)`
    color: #707070 !important;
    padding: 0em 0em 1.5em 0em;
`;

export const StyledBadgeSellerlight = styled(Badge)`
    font-weight: lighter;
    font-size: 12px;
`;
