import { Button } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import getInvoiceStatusText from '../../../utils/getInvoiceStatusText';
import { formatCurrency } from '../../../utils/strings';
import useInvoices from '../../../hooks/useInvoices';
import { ReactComponent as ReactFeedbackIcon } from '../../../assets/icons/feedback.svg';
import { ReactComponent as ReactMagnifierIcon } from '../../../assets/icons/magnifier.svg';
import { ReactComponent as ReactPaperClipIcon } from '../../../assets/icons/paperclip.svg';
import { Role } from '../../../constants/role';
import userHasRoles from '../../../utils/userHasRoles';
import { useAuth } from '../../../contexts/authContext';
import formatDate from '../../../utils/formatDate';
import getDeliveryDate from '../../../utils/getDeliveryDate';
import { PaymentMethod } from '../../../constants/paymentMethod';
import InvoiceTableRowDropdown from '../InvoiceTableRowDropdown';
import { StyledBadge, StyledApproveButton } from './styles';

interface InvoiceTableRowProps {
    invoice: any;
}

export default function InvoiceTableRow({ invoice }: InvoiceTableRowProps) {
    const { user } = useAuth();

    const history = useHistory();

    const {
        filterTerm,
        setSelectedInvoice,
        setShowInvoiceApprovalModal,
        setShowFeedbackInvoiceModal,
        setShowPaymentMethodModal,
        setShowListAttachmentsModal,
    } = useInvoices();

    const isIntegrator = userHasRoles(user, [
        Role.Integrator,
        Role.Contributor,
        Role.Manager,
    ]);

    const isAdminOrComSupOrCom = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Commercial,
    ]);

    const isRegistrationOrAdmin = userHasRoles(user, [
        Role.Registration,
        Role.Administrator,
    ]);

    const canAproveInvoice =
        invoice.status === InvoiceStatus.InvoiceValidated &&
        (invoice.paymentMethod === PaymentMethod.Financing
            ? isRegistrationOrAdmin
            : isAdminOrComSupOrCom);

    const paymentIsAvailable =
        invoice.status === InvoiceStatus.InvoiceApproved &&
        invoice.paymentMethod !== PaymentMethod.Financing &&
        invoice.paymentMethod !== PaymentMethod.Others;

    const showPaymentColumn = isIntegrator || isAdminOrComSupOrCom;

    return (
        <tr>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={invoice.id.toString()}
                />
            </td>
            <td>{formatDate(invoice.approvedAt) || '-'}</td>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={invoice.customerName}
                />
            </td>
            {!isIntegrator && (
                <td>
                    <Highlighter
                        autoEscape
                        highlightClassName="highlight-term"
                        searchWords={[filterTerm]}
                        textToHighlight={invoice.seller?.name}
                    />
                </td>
            )}
            <td>{formatCurrency(invoice.totalInvoice)}</td>
            {!isIntegrator && (
                <td>
                    {invoice.approvalFeedback && (
                        <ReactFeedbackIcon
                            fill="#f2994a"
                            width="25"
                            height="25"
                            onClick={() => {
                                setSelectedInvoice(invoice);
                                setShowFeedbackInvoiceModal(true);
                            }}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    )}
                </td>
            )}
            {isIntegrator && (
                <td>
                    {formatDate(
                        invoice.invoiceProduction?.actualEstimatedDeliveryDate,
                    ) || getDeliveryDate(invoice)}
                </td>
            )}
            {isAdminOrComSupOrCom && (
                <td>
                    {invoice.invoiceAttachments.length >= 1 && (
                        <ReactPaperClipIcon
                            fill="#f2994a"
                            width="30"
                            height="30"
                            onClick={() => {
                                setSelectedInvoice(invoice.id);
                                setShowListAttachmentsModal(true);
                            }}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    )}
                </td>
            )}
            <td>
                <StyledBadge status={invoice.status}>
                    {getInvoiceStatusText(
                        invoice.status,
                        invoice.paymentMethod,
                    )}
                </StyledBadge>
            </td>
            {showPaymentColumn && (
                <td>
                    {paymentIsAvailable && (
                        <Button
                            onClick={() => {
                                if (invoice.barcodeUrl) {
                                    window.open(invoice.barcodeUrl, '_blank');

                                    return;
                                }

                                setSelectedInvoice(invoice);
                                setShowPaymentMethodModal(true);
                            }}
                        >
                            Fazer pagamento
                        </Button>
                    )}
                </td>
            )}
            <td className="d-flex">
                <InvoiceTableRowDropdown invoice={invoice} />
                <ReactMagnifierIcon
                    fill="#a3a3a3"
                    width="18"
                    height="18"
                    className="my-auto"
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        history.push(`/pedidos/${invoice.id}`);
                    }}
                />
                {canAproveInvoice ? (
                    <StyledApproveButton
                        className="my-auto"
                        onClick={() => {
                            setSelectedInvoice(invoice);
                            setShowInvoiceApprovalModal(true);
                        }}
                    >
                        Aprovar
                    </StyledApproveButton>
                ) : (
                    <StyledApproveButton className="my-auto" disabled>
                        Aprovar
                    </StyledApproveButton>
                )}
            </td>
        </tr>
    );
}
