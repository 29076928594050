import styled from 'styled-components';

export const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;
