/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import {
    StyledDesc,
    StyledPrice,
    StyledId,
    StyledImage,
} from '../../../pages/OnlineStore/styles';
import NumericInput from '../../NumericInput';
import ISaveInvoiceItemDto from '../../../dtos/ISaveInvoiceItemDto';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { formatToQueryParam } from '../../../utils/strings';
import { IMG_SRC_URL, NEXEN_BUCKET_SRC_URL } from '../../../config/webservice';
import { toFixed } from '../../../utils/toFixed';
import { ReactComponent as ReactDangerIcon } from '../../../assets/icons/danger.svg';

interface Props {
    data: any;
}

const GeneratorsCard: React.FC<Props> = ({ data }) => {
    const {
        setSelectedProduct,
        setShowDetailsModal,
        setSelectedProductId,
        cart,
        term,
        productType,
    } = useOnlineStore();

    return (
        <Row className="mx-4 justify-content-md-center">
            {data?.map((item: any, index: any) => (
                <Col key={index} sm={12} lg={4} md={6} xl={3}>
                    <div>
                        <Row>
                            <StyledImage
                                className={'ml-n1'}
                                onClick={() => {
                                    setSelectedProductId(item.URL_ID);
                                    setSelectedProduct(item);
                                    setShowDetailsModal(true);
                                }}
                                src={
                                    item.IMG_CAPA &&
                                    (productType !== 'PA' ||
                                        productType !== 'BF')
                                        ? `${IMG_SRC_URL}${item.IMG_CAPA}`
                                        : productType === 'PA'
                                        ? `${NEXEN_BUCKET_SRC_URL}products/geral/geral1.jpeg`
                                        : productType === 'BF'
                                        ? `${NEXEN_BUCKET_SRC_URL}products/geral/geral2.jpeg`
                                        : ''
                                }
                            />
                        </Row>
                        <Row>
                            <StyledId>
                                Cod.
                                <Highlighter
                                    autoEscape
                                    highlightClassName="highlight-term"
                                    searchWords={[formatToQueryParam(term)]}
                                    textToHighlight={item.B1_COD}
                                />
                            </StyledId>
                        </Row>
                        <Row className={'w-75'}>
                            <StyledDesc>
                                <Highlighter
                                    autoEscape
                                    highlightClassName="highlight-term"
                                    searchWords={[formatToQueryParam(term)]}
                                    textToHighlight={item.B1_DESC}
                                />
                            </StyledDesc>
                        </Row>

                        <Row>
                            <StyledPrice>
                                {toFixed(item.VLR_TOT, 2).toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    },
                                )}
                            </StyledPrice>
                        </Row>
                        <Row className="pb-2">
                            <Col sm={4} className="p-0">
                                <NumericInput
                                    disabled={item.remainingQuantity <= 0}
                                    tableItem={item ?? {}}
                                    item={
                                        {
                                            status: InvoiceStatus.Cart,
                                            isCart: true,
                                            invoiceId: cart?.id,
                                            id: item?.INVOICE_ITEM_ID,
                                            productErpCode: item?.B1_COD,
                                            productName: item?.B1_DESC,
                                            productCategory:
                                                productType === 'MP'
                                                    ? item?.BM_DESC
                                                    : item?.CAT_DESC,
                                            unitValue: item?.VLR_TOT,
                                            availability: item?.boardingDate,
                                        } as ISaveInvoiceItemDto
                                    }
                                />
                            </Col>
                            <Col>
                                {item.remainingQuantity <= 0 && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={'tooltip'}>
                                                Produto fora de estoque!
                                            </Tooltip>
                                        }
                                    >
                                        <ReactDangerIcon
                                            fill="#F00"
                                            width="30"
                                            height="30"
                                        />
                                    </OverlayTrigger>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

GeneratorsCard.propTypes = {
    data: PropTypes.array,
};

export default GeneratorsCard;
