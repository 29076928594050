/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { StyledRow } from './styles';
import './styles.scss';

interface Props {
    children?: any;
}

const BreadcrumbItem: React.FC<Props> = ({ children }) => (
    <StyledRow>{children}</StyledRow>
);

BreadcrumbItem.propTypes = {
    children: PropTypes.node,
};

export default BreadcrumbItem;
