/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import * as Yup from 'yup';

function validateCPF(cpf: string) {
    cpf = cpf.replace(/\D/g, '');

    let sum;
    let rest;

    sum = 0;

    if (cpf === '00000000000') {
        return false;
    }

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
    }

    return true;
}

function validateCNPJ(cnpj: any) {
    cnpj = cnpj ? cnpj.replace(/[^\d]+/g, '') : '';

    if (cnpj === '') {
        return false;
    }

    if (cnpj.length !== 14) {
        return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);

    const digitos = cnpj.substring(tamanho);

    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2) {
            pos = 9;
        }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(0)) {
        return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2) {
            pos = 9;
        }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado.toString() !== digitos.charAt(1)) {
        return false;
    }

    return true;
}

export const financingDataRules: any = {
    equipmentValue: Yup.string()
        .required('Campo obrigatório')
        .test(
            'invalid',
            'O valor deve ser maior que 0',
            (value) =>
                value.replace('R', '').replace('$', '').replace(',', '') > 0,
        ),
    inputValue: Yup.string()
        .required('Campo obrigatório')
        .test(
            'invalid',
            'O valor deve ser maior que 0',
            (value) =>
                value.replace('R', '').replace('$', '').replace(',', '') > 0,
        ),
    financedValue: Yup.string().required('Campo obrigatório'),
    waitingPeriod: Yup.string().required('Campo obrigatório'),
    installments: Yup.string().required('Campo obrigatório'),
    installmentsValue: Yup.string().required('Campo obrigatório'),
};

export const juridicPersonRules: any = {
    entityCompanyName: Yup.string().required('Campo obrigatório'),
    entityFancyName: Yup.string().required('Campo obrigatório'),
    entityCnpj: Yup.string()
        .required('Campo obrigatório')
        .test('invalid', 'CNPJ inválido', (value) => validateCNPJ(value)),
    entityCapitalStock: Yup.string()
        .required('Campo obrigatório')
        .test(
            'invalid',
            'O valor deve ser maior que 0',
            (value) =>
                value.replace('R', '').replace('$', '').replace(',', '') > 0,
        ),
    entityLastYearRevenue: Yup.string()
        .required('Campo obrigatório')
        .test(
            'invalid',
            'O valor deve ser maior que 0',
            (value) =>
                value.replace('R', '').replace('$', '').replace(',', '') > 0,
        ),
    entityUptime: Yup.string().required('Campo obrigatório'),
    entityMainActivity: Yup.string().required('Campo obrigatório'),
    entityEmail: Yup.string().required('Campo obrigatório'),
    entityPhone: Yup.string().required('Campo obrigatório'),
    entityCellphone: Yup.string().required('Campo obrigatório'),
    entityAddress: Yup.string().required('Campo obrigatório'),
    entityNeighborhood: Yup.string().required('Campo obrigatório'),
    entityCityState: Yup.string().required('Campo obrigatório'),
    entityOwnProperty: Yup.string().required('Campo obrigatório'),
    entityAddressCep: Yup.string().required('Campo obrigatório'),
};

export const representativeRules: any = {
    personName: Yup.string().required('Campo obrigatório'),
    personCpf: Yup.string()
        .required('Campo obrigatório')
        .test('invalid', 'CPF inválido', (value) => validateCPF(value)),
    personRg: Yup.string().required('Campo obrigatório'),
    personRgIssuer: Yup.string().required('Campo obrigatório'),
    personBirthdate: Yup.date().required('Campo obrigatório').nullable(),
    personSex: Yup.string().required('Campo obrigatório'),
    personCivilStatus: Yup.string().required('Campo obrigatório'),
    personBirthStateCity: Yup.string().required('Campo obrigatório'),
    personFatherName: Yup.string().required('Campo obrigatório'),
    personMotherName: Yup.string().required('Campo obrigatório'),
    personSchooling: Yup.string().required('Campo obrigatório'),
    personPatrimony: Yup.string().required('Campo obrigatório'),
    personMontlyIncome: Yup.string().required('Campo obrigatório'),
    personPhone: Yup.string().required('Campo obrigatório'),
    personCellphone: Yup.string().required('Campo obrigatório'),
    personProfessionNature: Yup.string().required('Campo obrigatório'),
    personProfession: Yup.string().required('Campo obrigatório'),
    personCompanyName: Yup.string().required('Campo obrigatório'),
    personEmail: Yup.string()
        .required('Campo obrigatório')
        .email('E-mail inválido'),
    personCompanyCnpj: Yup.string()
        .required('Campo obrigatório')
        .test('invalid', 'CNPJ inválido', (value) => validateCNPJ(value)),
};

export const financingRules = {
    ...financingDataRules,
    ...juridicPersonRules,
    ...representativeRules,
};

export default financingRules;
