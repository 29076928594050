/* eslint-disable prefer-template */
import { DateTime } from 'luxon';
import { InvoiceStatus } from '../constants/invoiceStatus';

export default function getInvoiceExpirationDate(date: any, status: string) {
    if (status === InvoiceStatus.BudgetExpired) {
        return 'Expirado';
    }

    const actualDate = DateTime.local();

    const invoiceDate = DateTime.fromISO(date);
    const expirationDate = invoiceDate.plus({ days: 7 });
    const diff = expirationDate.diff(actualDate, [
        'years',
        'months',
        'days',
        'hours',
    ]);

    if (diff.days >= 1) {
        return diff.days + ' dia(s)';
    }

    if (diff.hours > 0) {
        return diff.hours.toFixed(2) + ' hora(s)';
    }

    return 'Expirado';
}
