import { Card, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const FloatContainer = styled(ListGroup)`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: row-reverse;
    position: fixed;
    right: 0em;
    bottom: 5em;
    height: 10em;
    max-height: 52px;

    li {
        border-radius: 10%;
        box-shadow: 0 3px 6px lightgrey;
        display: grid;
        place-items: center;
        margin: 8px 0;
        margin-left: 5px;
        font-size: 20px;
        padding: 12px;
        cursor: pointer;
        position: relative;
        height: 2em;

        .span {
            opacity: 1;
        }
    }

    .float-button {
        background-color: white;
        display: ${(props) => (props.showFloat === true ? 'flex' : 'none')};

        svg {
            fill: #ef8526;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        span {
            display: none;
        }

        &:hover {
            .tooltip {
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateY(-0.5em) scale(1);
                opacity: 1;
                width: 140px;
                right: 50%;
                top: 20 px;
            }
            span {
                display: flex;
            }
        }

        .tooltip {
            padding: 4px 6px;
            font-size: 12px;
            position: absolute;
            top: -50px;
            transform: translateX(-75%);
            background-color: #353b48;
            border-radius: 4px;
            color: white;
            opacity: 1;
            transition: transform 300ms, opacity 300ms;
            user-select: none;
            max-height: 25px;
        }
    }

    .float-action {
        transform: translateX(200%) scale(1);
        transition: transform 300ms, opacity 300ms;
        opacity: 0;
        background-color: #2aca65;
        padding: 0%;
        width: 2em;
        display: ${(props) => (props.showFloat === true ? 'flex' : 'none')};
        align-items: center;
        justify-content: center;

        svg {
            fill: white;
        }

        &:hover {
            .tooltip {
                transform: translateY(-4em) scale(1);
                opacity: 1;
            }
        }

        &.open {
            transform: translateY(0) scale(1);
            opacity: 1;
        }

        .tooltip {
            padding: 4px 6px;
            font-size: 12px;
            position: absolute;
            left: -12px;
            transform: translateX(-75%);
            background-color: #353b48;
            border-radius: 4px;
            color: white;
            opacity: 0;
            transition: transform 300ms, opacity 300ms;
            user-select: none;
        }
    }
`;

export const FloatCloseContainer = styled(Card)`
    display: flex;
    right: 0em;
    border-radius: 25% 0% 0% 25%;
    width: 15px;
    background-color: #ef8526;
    box-shadow: 3px 3px 6px lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        fill: white;
    }

    &:hover {
        width: 20px;
    }
`;
