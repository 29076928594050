import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTitle = styled.h3`
    font-size: 24px !important;
    font-weight: bolder !important;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledNavLink = styled(Nav.Link)`
    color: inherit !important;
    font-size: 1rem !important;

    :active {
        color: red;
    }
`;

export const StyledDivInvoiceType = styled.div`
    background: #f2f2f2;
    width: 140px;
    float: left;
    padding: 4px;
    font-size: 12px;
`;
