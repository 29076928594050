/* eslint-disable import/prefer-default-export */
/* eslint-disable react/self-closing-comp */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Row, Col, InputGroup, Form } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import {
    StyledRow,
    StyledTitle,
    StyledTdText,
    StyledPageTitle,
    StyledSubtitle,
} from './styles';

import BaseLayout from '../../../components/BaseLayout';
import SideNavBar from '../../../components/Points/SideNavBar/SideNavBar';
import { usePoint } from '../../../contexts/pointContext';
import { ReactComponent as DownloadIconSvg } from '../../../assets/icons/downloadIcon.svg';

import pdf from '../../../assets/pdf/regulamento_pontos.pdf';

const Regulation: React.FC = () => {
    const [term, setTerm] = useState('');
    const { regulationPage } = usePoint();

    return (
        <div>
            <BaseLayout>
                <Row style={{ height: '100vh' }}>
                    <SideNavBar />

                    <Col className="ml-5 mr-5 mt-5 mb-5">
                        <StyledRow className="mt-3">
                            <Col sm={12}>
                                <StyledPageTitle>Regulamento</StyledPageTitle>
                            </Col>
                        </StyledRow>
                        <StyledRow className="mt-1">
                            <Col sm={9}>
                                <StyledTitle>
                                    Programa de Pontos Nexen
                                </StyledTitle>
                            </Col>
                            <Col sm={3}>
                                <div className="float-right">
                                    <DownloadIconSvg
                                        fill="#2F80ED"
                                        className="mr-3 mb-2"
                                    />
                                    <a
                                        style={{ color: '#2F80ED' }}
                                        href={pdf}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Baixar em PDF
                                    </a>
                                </div>
                            </Col>
                        </StyledRow>
                        <StyledRow className="mt-3">
                            <Col sm={12}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <i className="fas fa-search"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        value={term}
                                        onChange={(event) =>
                                            setTerm(event.target.value)
                                        }
                                        placeholder="Digite aqui o que procura..."
                                    />
                                </InputGroup>
                            </Col>
                        </StyledRow>

                        {regulationPage === 0 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`
                                                A empresa Nexen Energias Renováveis Ltda., inscrita no CNPJ 31.841.964/0001-96, situada
                                                à Rua Quintino Bocaiuva, 139, na cidade de Chapecó/SC, disponibiliza aos seus integradores
                                                o regulamento referente ao “Programa de pontos Nexen”, o Nexup. Todos os Integradores
                                                cadastrados e ativos de acordo com a política comercial Nexen, automaticamente participam
                                                e usufruem dos benefícios do programa de pontos, a partir do atingimento dos níveis
                                                estipulados, bem como o acúmulo de pontos através das compras realizadas dentro da
                                                plataforma da Nexen.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            1. Programa de pontos
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`
                                                O Programa de Pontos Nexen é estipulado e baseado no acúmulo de pontos gerados por
                                                meio de campanhas realizadas pela Nexen e também por compras realizadas na plataforma
                                                Nexen, pelos integradores que estiverem dentro da política comercial.
                                                `}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 1 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            2. Para participar
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`A Empresa Integradora deve realizar seu cadastro por meio da Plataforma do
                                                Integrador, no endereço https://contas.nexen.com.br/login.
                                                2.1 A Empresa Integradora se responsabiliza pelo aceite deste Regulamento, que ocorre
                                                no momento do cadastro na Plataforma do Integrador. Ao cadastrar-se, a Empresa
                                                confirma sua adesão e concordância aos termos e condições contidos neste regulamento,
                                                que poderá ser modificado ou atualizado pela Nexen, mediante comunicação prévia
                                                pelos canais de comunicação disponibilizados no cadastro realizado pelas Empresas
                                                Integradoras.
                                                2.2 Para o cadastramento serão solicitados nome e CNPJ da empresa credenciada, nome
                                                da pessoa física responsável pelo cadastro, que obrigatoriamente deverá ser de um dos
                                                sócios da Empresa Integradora, nacionalidade, profissão, endereços comercial e
                                                residencial, números de telefone, RG, CPF, data de nascimento, e-mail, entre outros
                                                dados que a Nexen achar necessários.
                                                2.3 É permitido apenas um cadastro por Empresa Integradora, sendo que a Nexen se
                                                reserva o direito de cancelar, excluir ou transferir seu conteúdo para a conta principal da
                                                Empresa Integradora, sendo considerado como principal o cadastro mais antigo. Contas
                                                secundárias serão consideradas somente aos parceiros que atuam por meio de franquias
                                                ou revendedoras. Porém, os recursos podem ser limitados para garantir a segurança das
                                                informações.
                                                2.4 O acesso à Plataforma do Integrador é de uso pessoal e exclusivo. Caso a Empresa
                                                Integradora compartilhe a senha com terceiros, a Nexen se reserva o direito de cancelar
                                                e excluir a conta; nesse caso, os pontos da Empresa Integradora deixam de existir. O uso
                                                da conta é de inteira responsabilidade do usuário. Assim, a Nexen se exime de qualquer
                                                incidente derivado do uso indevido.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            3. Validade
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`O programa iniciado em 01/01/2024 terá validade até 31/12/2024.

                                                3.1 Com o término do Programa, os pontos acumulados poderão ser automaticamente
                                                extintos, sem qualquer tipo de indenização às empresas participantes.
                                                3.2 No caso de substituição do Programa de Pontos, a Nexen poderá transferir a
                                                pontuação para o novo programa, de acordo com os critérios estipulados. A
                                                transferência será automática, sendo responsabilidade da Empresa Integradora estar de
                                                acordo com os critérios.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            4. Cancelamento, suspensão e
                                            exclusão
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`

                                                A Empresa Integradora pode solicitar via e-mail o cancelamento da participação do
                                                programa de pontos sem necessidade de justificativa.
                                                4.1 Em casos de suspeita de fraude, má conduta ou má fé, falsidade ideológica,
                                                violação aos termos ou não preenchimento dos requisitos, a Nexen se reserva o direito
                                                de cancelar ou excluir o cadastro da Empresa Integradora.
                                                4.2 . Em casos de fraude, a Empresa Integradora estará sujeita a sanções de
                                                responsabilidade civil e criminal pelos seus atos.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 2 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            5. Pontos adquiridos
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Para a Empresa Integradora acumular pontos ‘qualificáveis’ é necessário obter vendas,
                                                exclusivamente de geradores fotovoltaicos Nexen, mediante faturamento e pagamento
                                                da nota fiscal. Os pontos serão creditados após o pagamento/liquidação integral do
                                                valor.
                                                5.1 Com aceite deste programa, a Empresa Integradora autoriza a vinculação de todas
                                                as movimentações realizadas de acordo com este regulamento.
                                                5.2 Compras realizadas anteriormente à data de início deste programa não serão
                                                computadas.
                                                5.3 Geradores faturados como brindes ou cortesia não geram pontos qualificáveis ou
                                                não qualificáveis.
                                                5.4 Campanhas realizadas pela Nexen que computam pontos qualificáveis e não
                                                envolvam a compra de geradores fotovoltaicos serão informadas pela Nexen pelos
                                                canais de comunicação.
                                                5.5 Os pontos serão disponibilizados na Plataforma do Integrador em até 30 (trinta)
                                                dias após a confirmação do pagamento.
                                                5.6 Caso haja alguma inconsistência no que se refere à pontuação creditada ou
                                                debitada, a Empresa Integradora deverá informar no prazo máximo de 05 (cinco) dias,
                                                contados da data de crédito/débito dos pontos.
                                                5.7 Compras realizadas pela Empresa Integradora que forem canceladas resultam no
                                                estorno dos pontos creditados anteriormente.
                                                5.8 Os pontos adquiridos não são transferíveis para outro CNPJ do Programa de Pontos.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            6. Pontuação
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Os Pontos serão concedidos e creditados à Empresa Integradora de acordo com o valor
                                                comercializado.
                                                6.1 Cada real comercializado gera 1 ponto no Programa de Pontos Nexen.
                                                6.2 Na hipótese de ocorrer a troca de equipamentos integralmente por meio da
                                                utilização de pontos, a Empresa Integradora não acumulará novos pontos decorrentes
                                                dessa transação.
                                                6.3 Tal bonificação mencionada poderá ser reduzida após o efetivo registro dos Pontos
                                                na Conta da Empresa Integradora conforme decisão da Nexen, que informará as
                                                Empresas Integradoras com a devida antecedência.
                                                6.4 Os Pontos nas Contas são propriedade do Programa de Pontos, e sua utilização
                                                somente poderá ser feita de acordo com os termos e condições descritos neste
                                                Regulamento.
                                                6.5 Em casos de suspeita de fraude, a Nexen se reserva o direito de estornar os pontos
                                                já registrados, caso não seja efetuado o pagamento integral do gerador fotovoltaico ou
                                                em outras situações que atestem que a Empresa Integradora não tem direito aos Pontos
                                                de acordo com este Regulamento.
                                                6.6 Em caso de tentativa de violação da Empresa Integradora, para obtenção de
                                                qualquer tipo de vantagem, a Nexen reserva-se o direito de zerar sua pontuação e
                                                excluí-la do Programa.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 3 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            7. Troca de pontos
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Os pontos acumulados em sua Conta podem ser utilizados para trocar por equipamentos
                                                fotovoltaicos e demais produtos disponibilizados na Plataforma do Integrador, de
                                                acordo com as regras estabelecidas neste Regulamento.
                                                7.1 Os valores correspondentes aos produtos disponíveis na plataforma para troca,
                                                podem ser alterados a qualquer momento, sem necessidade de comunicação prévia,
                                                sendo que para realizar um resgate deve ser considerado o valor disponível na
                                                plataforma.
                                                7.2 É responsabilidade da Empresa Integradora verificar os prêmios disponíveis e os
                                                pontos necessários para o resgate.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>

                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            8. Qualificação dos pontos
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Os pontos não qualificáveis e os qualificáveis terão validade de 12 meses, contados de
                                                1º de janeiro de 2024 a 31 de dezembro de 2024.
                                                8.1 São considerados pontos qualificáveis: pontos necessários para a progressão de
                                                nível nas categorias, conforme item 9.
                                                8.2 São considerados pontos não qualificáveis: não válidos para progressão de nível,
                                                podendo apenas ser utilizados para resgate de produtos na plataforma.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 4 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            9. Níveis e categorias
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`O Programa possui 4 (quatro) categorias, cada uma com vantagens e pontuação
                                                diferenciadas, a critério único e exclusivo da Nexen:
                                                Categoria Bronze: para integradores que acumularem até 500.000;
                                                Categoria Prata: para integradores que acumularem entre 500.001 a 1.000.000;
                                                Categoria Ouro: para integradores que acumularem entre 1.000.001 a 2.000.000;
                                                Categoria Diamante: para integradores que acumularem acima de 2.000.000.
                                                Observação: Os pontos serão concedidos e válidos conforme prazo de vigência deste
                                                regulamento. Possíveis pontos adicionais recebidos, em virtude de alteração da categoria ou qualquer outro meio que não seja a aquisição de gerador fotovoltaico, não
                                                serão considerados como pontos qualificáveis para progressão de nível.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            10. Benefícios das categorias
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Os benefícios concedidos em virtude do Programa de Pontos poderão ser alterados a
                                                qualquer momento pela Nexen, mediante comunicação prévia com antecedência de 15
                                                (quinze) dias.
                                                a) Categoria Bronze
                                                Acesso à Plataforma do Integrador no site Nexen.
                                                Suporte da equipe técnica e comercial da Nexen.
                                                Acesso aos materiais digitais de divulgação.
                                                Possibilidade de resgatar produtos Nexen, conforme disponibilidade na Plataforma do
                                                Integrador, desde que possua pontos suficientes.
                                                Frete grátis.
                                                b) Categoria Prata
                                                Além dos prêmios já concedidos, serão creditados 20 mil pontos (ao atingir os pontos
                                                necessários para a categoria, a Empresa Integradora receberá vinte mil pontos não
                                                qualificáveis) para trocar por produtos na Plataforma do Integrador.
                                                c) Categoria Ouro
                                                Além dos prêmios já concedidos, serão creditados 50 mil pontos (ao atingir os pontos
                                                necessários para a categoria, a Empresa Integradora receberá cinquenta mil pontos não
                                                qualificáveis) para trocar por produtos na Plataforma do Integrador.
                                                Treinamento técnico online.
                                                d) Categoria Diamante
                                                Além dos prêmios já concedidos, serão creditados 80 mil pontos (ao atingir os pontos
                                                necessários para a categoria, a Empresa Integradora receberá oitenta mil pontos não
                                                qualificáveis) para trocar por produtos na Plataforma do Integrador.
                                                Treinamento técnico presencial, previamente agendado.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 5 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            11. Prazo de entrega dos resgates de
                                            produtos
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Prêmios não físicos serão concedidos no prazo de 15 (quinze) dias. Prêmios físicos
                                                serão enviados ou disponibilizados no endereço de cadastro em até 30 dias úteis.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            12. Validade do programa
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Este programa terá vigência legal a partir de sua data de lançamento (28/03/2024), com
                                                previsão de término em 31/12/2024. Podendo ser prorrogado por prazo a ser
                                                determinado pela Nexen e informado a sua base de integradores pelos canais de
                                                comunicação.
                                            `}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            13. Privacidade e tratamento de
                                            dados
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`Ao aceitar este regulamento, a Empresa Integradora, legitimamente de acordo, está
                                                ciente e manifesta seu consentimento sobre coleta, tratamento e uso de seus dados
                                                descritos neste regulamento.
                                                13.1 A Nexen coletará todas as informações de cadastro fornecidas pela Empresa
                                                Integradora no cadastro, como qualquer outro dado disponibilizado ativamente pela
                                                Empresa Integradora durante a utilização do Programa.
                                                13.2 A Nexen poderá, ainda, coletar automaticamente, mas não se limitando a, número
                                                de IP com data e hora, características do dispositivo e do navegador, informações sobre
                                                cliques, páginas acessadas, dentre outros. Desde já, a Empresa Integradora autoriza e
                                                concorda com esses termos.
                                                13.3 As informações mencionadas serão utilizadas para a prestação dos serviços
                                                fornecidos pela Nexen, relacionados ao Programa de Pontos, bem como para manter,
                                                operar e aprimorar o Programa e para fins publicitários com prévia autorização.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 6 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            14. Disposições gerais
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`14.1 Os pontos acumulados são de propriedade da Nexen e só poderão ser utilizados em
                                                conformidade com o previsto neste Regulamento.
                                                14.2 A Nexen tem o prazo de até 90 (noventa) dias, a partir do término das campanhas
                                                promocionais, para realizar o crédito dos Pontos na plataforma do integrador.
                                                14.3 Este Regulamento foi elaborado conforme a legislação brasileira.
                                                14.4 Este Regulamento terá vigência a partir de 28 de março de 2024.
                                                14.5 A Empresa participante deste programa reconhece suas responsabilidades e
                                                sujeição à Lei Federal 9.613/98.
                                                14.6 Quanto às limitações deste Regulamento, se a Nexen ficar impossibilitada de
                                                cumpri-lo, devido à situação de força maior, a mesma ficará suspensa ou escusada, em
                                                decorrência da situação de força maior.
                                                14.7 O presente Regulamento não concede à Empresa Integradora qualquer direito à
                                                propriedade intelectual da Nexen, devendo seu uso ocorrer de maneira que a Nexen seja
                                                reconhecida como titular e proprietária.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                        {regulationPage === 7 && (
                            <>
                                <StyledRow className="mt-5">
                                    <Col sm={12}>
                                        <StyledSubtitle>
                                            15. Para fins deste Regulamento, as
                                            expressões abaixo listadas terão os
                                            seguintes significados:
                                        </StyledSubtitle>
                                        <StyledTdText className="mt-3">
                                            <Highlighter
                                                autoEscape
                                                highlightClassName="highlight-term"
                                                searchWords={[term]}
                                                textToHighlight={`
                                                - Programa de Fidelidade ou Programa de Pontos Nexen: é o programa de vantagens
                                                Nexen.
                                                - Plataforma do Integrador: é a plataforma disponibilizada pela Nexen para uso
                                                exclusivo dos seus integradores, cadastrados e aprovados de acordo com a política
                                                comercial.
                                                - Empresa Integradora: é a pessoa jurídica devidamente cadastrada e aprovada pela
                                                equipe Nexen.
                                                - Conta é o perfil de CNPJ dos integradores cadastrados na plataforma Nexen, na qual
                                                são armazenados e registrados.
                                                - Prêmio/produto é qualquer resgate com pontos através da Plataforma do Integrador por
                                                meio dos pontos acumulados de acordo com este regulamento.
                                                - Pontos acumulados é o procedimento descrito e especificado no item 6.
                                                - Regulamento: é o que rege as regras deste programa.
                                                - Níveis ou Categoria: classes de pontuação para a concessão de premiação e/ou
                                                benefícios.
                                                - Pontos qualificáveis: pontos necessários para a progressão de nível.
                                                - Pontos não qualificáveis: não válidos para progressão de nível, podendo apenas ser
                                                utilizados para resgate de produtos na plataforma.
                                                - As imagens das premiações disponíveis no portal são apenas ilustrativas, podendo ocorrer
                                                alteração do modelo do produto.`}
                                            />
                                        </StyledTdText>
                                    </Col>
                                </StyledRow>
                            </>
                        )}
                    </Col>
                </Row>
            </BaseLayout>
        </div>
    );
};

export { Regulation };
