import Highlighter from 'react-highlight-words';
import OtherPaymentMethod from '../../../../../shared/interfaces/otherPaymentMethods.interface';

interface OthersPaymentMethodsTableRowProps {
    paymentMethod: OtherPaymentMethod;
    filterTerm: string;
}

export default function OthersPaymentMethodsTableRow({
    paymentMethod,
    filterTerm,
}: OthersPaymentMethodsTableRowProps) {
    return (
        <tr key={paymentMethod.id}>
            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={paymentMethod.code}
                >
                    {paymentMethod.code}
                </Highlighter>
            </td>

            <td>
                <Highlighter
                    autoEscape
                    highlightClassName="highlight-term"
                    searchWords={[filterTerm]}
                    textToHighlight={paymentMethod.description}
                >
                    {paymentMethod.description}
                </Highlighter>
            </td>
        </tr>
    );
}
