/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createContext, useContext } from 'react';

const StructureCalculationContext = createContext<any>({});
const useStructureCalculation = () => useContext(StructureCalculationContext);

function StructureCalculationProvider(props: any) {
    const [tab, setTab] = useState();
    const [lines, setLines] = useState([]);
    const [structureCalculationValues, setStructureCalculationValues] =
        useState([]);

    return (
        <StructureCalculationContext.Provider
            value={{
                tab,
                setTab,
                lines,
                setLines,
                structureCalculationValues,
                setStructureCalculationValues,
            }}
            {...props}
        />
    );
}

export { StructureCalculationProvider, useStructureCalculation };
