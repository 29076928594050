/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledDivInvoiceDelivery = styled.div`
    background: #f2f2f2;
    width: 140px;
    padding: 4px;
    float: right;
    font-size: 12px;
`;

export const SubText = styled.span`
    font-size: 10px;
    color: #2f81ed;
    margin-left: 8px;
    display: inline;
`;
