/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable react/self-closing-comp */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../services/http/invoice-http';

interface Props {
    invoice: any;
    disabled: any;
}

const AdditionalData: React.FC<Props> = ({ invoice, disabled }) => {
    let { setReloadCart } = useOnlineStore();
    const [additionalData, setAdditionalData] = useState(
        invoice.additionalData,
    );

    return (
        <>
            <Form>
                <Form.Group className={'pt-4'} controlId="formBasicCep">
                    <Form.Label>Dados adicionais da Nota Fiscal</Form.Label>
                    <Form.Control
                        disabled={disabled}
                        onBlur={async () => {
                            invoice.additionalData = additionalData;
                            setReloadCart((reload: boolean) => !reload);

                            await InvoiceHttpService.update(invoice).catch(() =>
                                toast.error('Erro ao salvar!'),
                            );
                        }}
                        as="textarea"
                        name="power"
                        onChange={(event: any) => {
                            setAdditionalData(
                                event.target.value.replace(/[<>"'&]/g, ''),
                            );
                        }}
                        value={additionalData ? additionalData : ''}
                        rows={3}
                    ></Form.Control>
                </Form.Group>
            </Form>
        </>
    );
};

AdditionalData.propTypes = {
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
};

export default AdditionalData;
