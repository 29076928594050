import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    .modal-title {
        margin: 10px;
    }

    .modal-body svg {
        height: 100px;
        width: 100px;
        fill: #ef8526;
        opacity: 1;
        transform: scale(1);
        animation: fadeAnimation 5s ease;
    }

    .pre {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        color: #707070;
    }

    .textarea {
        margin-bottom: 10px;
        border: 1px solid #ced4da;
    }

    @keyframes fadeAnimation {
        0% {
            opacity: 0;
            transform: scale(0);
        }
        10% {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

export const StyledConfirmButton = styled(Button)`
    margin: 10px;
    width: 100px;
`;

export const StyledCancelButton = styled(Button)`
    width: 100px;
    background-color: #ffffff;
    border: 1px solid #f2994a;
    color: #f2994a !important;

    :hover {
        background-color: #fefaf6;
        color: #e67511 !important;
        border-color: #e67511;
    }

    :disabled {
        background-color: #ffffff;
        color: #f6b77f !important;
        border-color: #f6b77f;
    }
`;
