/* eslint-disable no-return-await */
import Request from './request';

export default class SalesCampaignHttpService {
    private static uri = 'blackfriday';

    public static async indexSeason(params: {}) {
        return await Request.get(`${this.uri}/season`, params);
    }
}
