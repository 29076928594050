import { Col, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledPaymentMethodCard = styled(Col)`
    cursor: pointer;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
`;

export const StyledInterestLabel = styled(FormLabel)`
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    color: #f2994a;
    text-align: center;
`;

export const StyledValueLabel = styled(FormLabel)`
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
`;

export const StyledSpan = styled.span`
    font-weight: bolder;
    font-size: 12px;
    color: #f2994a;
`;

export const StyledLink = styled(Link)`
    font-size: 0.75rem;
    cursor: pointer;
    margin-left: 3px;
    color: #f6b44f;

    :hover {
        color: #e0814e;
        text-decoration: none;
    }
`;
