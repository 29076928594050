import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledValidateButton = styled(Button)`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

export default StyledValidateButton;
