export default function getFreightValue(
    netInvoiceValue: number,
    freightPercentage: number = 0,
): number {
    if (
        Number.isNaN(netInvoiceValue) ||
        Number.isNaN(freightPercentage) ||
        freightPercentage === 0
    ) {
        return 0;
    }

    const cent = 100;
    const freightValue = netInvoiceValue * (freightPercentage / cent);

    return Math.round(freightValue * 100) / 100;
}
