import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background-color: #ffffff !important;
`;

export const StyledTh = styled.th`
    background-color: #fafafa;
    color: #333333;
    text-align: left;
    font-style: normal;
    font-weight: bolder;
    font-size: 15px;
    line-height: 21px;
`;

export const StyledTd = styled.td`
    background-color: #ffffff;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #696a72;
`;

export const StyledTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #707070;
`;

export const StyledTr = styled.tr`
    background-color: #fbfbfb;
    font-weight: bolder;
`;

export const StyledTdText = styled.h5`
    font-weight: normal;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    color: #828282;
`;

export const StyledTdLink = styled.a`
    font-weight: normal;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    color: #828282;
    :hover {
        cursor: pointer !important;
        text-decoration: underline;
        color: #828282;
    }
`;
