import styled from 'styled-components';
import { Card } from 'react-bootstrap';

interface Props {
    decoration: string;
}

export const StyledTitle = styled.strong<Props>`
    font-size: 18px !important;
    font-weight: 700 !important;
    &:hover {
        text-decoration: ${(p) => p.decoration};
    }
`;

export const StyledSubtitle = styled.h6`
    font-size: 16px !important;
    font-weight: 900 !important;
    color: #707070;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledTdText = styled.h5`
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.05em;
    color: #828282;
`;

export const StyledDesc = styled.h6`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    word-wrap: break-word;
    /* or 24px */
    letter-spacing: 0.0275em;
    /* Gray / 100 */
    color: #19191d;
`;

export const StyledPrice = styled.h6`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    display: flex;
    align-items: center;

    /* Dark Color */

    color: rgba(0, 0, 0, 0.87);
`;

export const StyledText = styled.h6`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    /* or 140% */

    color: #707070;
`;

export const StyledDivider = styled.hr`
    margin-right: 2%;
    margin-left: 2%;
`;

export const StyledCardBody = styled(Card.Body)`
    min-width: 50%;
    max-width: 50%;
`;

export const StyledCardTitle = styled(Card.Title)`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #707070;
    white-space: nowrap;
`;

export const StyledCardText = styled(Card.Text)`
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    color: #2f80ed;
`;

export const StyledCardPriceText = styled(Card.Text)`
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    color: #eb5757;
`;

export const StyledCard = styled.div`
    background: #ffffff;
    border: 1px solid #e3e7ee;
    border-radius: 1px !important;
    box-sizing: border-box;

    min-width: 30%;
    max-width: 100%;
`;
