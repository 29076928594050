import { ChangeEvent, DragEvent, useState } from 'react';
import { toast } from 'react-toastify';
import StyledLabel from './styles';

interface AttachmentFileInputProps {
    attachedFile?: File;
    onChange: (file: File) => void;
}

export default function AttachmentFileInput({
    onChange,
    attachedFile,
}: AttachmentFileInputProps) {
    const [isDragOver, setDragOver] = useState(false);

    const handleDragEnter = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const isValidFile = (file?: File): boolean => {
        if (!file) return false;

        const maxSizeInMb = 5 * 1024 * 1024;

        if (file.size > maxSizeInMb) return false;

        return true;
    };

    const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragOver(false);

        const file = event.dataTransfer.files[0];

        if (!isValidFile(file)) {
            toast.error('Arquivos com mais de 5Mb não são permitidos');
            return;
        }

        onChange(file);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const file = event.target.files[0];

        if (!isValidFile(file)) {
            toast.error('Arquivos com mais de 5Mb não são permitidos');
            return;
        }

        onChange(file);
    };

    const labelClasses = ['upload-input', isDragOver ? 'drag-over' : ''].filter(
        Boolean,
    );

    return (
        <>
            <StyledLabel
                className={labelClasses.join(' ')}
                htmlFor="file"
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {attachedFile?.name || (
                    <>
                        Arraste e solte um arquivo aqui ou clique para <br />{' '}
                        fazer seleção a partir do seu computador
                    </>
                )}
                <input
                    name="file"
                    id="file"
                    type="file"
                    accept="image/*,.pdf"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                />
            </StyledLabel>
        </>
    );
}
