import { useRef } from 'react';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { useInfiniteQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InvoiceFilterStatus } from '../../../constants/invoiceStatus';
import { NotificationIcon } from '../../../constants/notificationIcon';
import { rowsPerPage } from '../../../constants/pagination';
import { Role } from '../../../constants/role';
import { useAuth } from '../../../contexts/authContext';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { budgetsDetailRoute } from '../../../routes/config';
import InvoiceHttpService from '../../../services/http/invoice-http';
import ReturnsHttpService from '../../../services/http/return-http';
import ConfirmationDialog from '../../ConfirmationDialog';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import userHasRoles from '../../../utils/userHasRoles';
import ReleasePaymentMethodModal from '../../OnlineStore/Invoice/ReleasePaymentMethodModal';
import InvoiceApprovalModal from '../InvoiceApprovalModal';
import { StyledTh } from './styles';
import useInvoices from '../../../hooks/useInvoices';
import InvoiceTableRow from '../InvoiceTableRow';
import InvoiceCancelmentModal from '../InvoiceCancelmentModal';

export default function InvoiceTable() {
    const history = useHistory();

    const { user } = useAuth();

    const {
        filterForm,
        filterTerm,
        selectedInvoice,
        setSelectedInvoice,
        showRemakeConfirmation,
        setShowRemakeConfirmation,
        showReproveConfirmation,
        setShowReproveConfirmation,
        showReproveValidatedConfirmation,
        setShowReproveValidatedConfirmation,
        showReturnConfirmation,
        setShowReturnConfirmation,
        showInvoiceCancelmentModal,
        setShowInvoiceCancelmentModal,
        showReleasePaymentMethodInvoiceModal,
        setShowReleasePaymentMethodInvoiceModal,
        showInvoiceApprovalModal,
        setShowInvoiceApprovalModal,
    } = useInvoices();

    const isIntegrator = userHasRoles(user, [
        Role.Integrator,
        Role.Contributor,
        Role.Manager,
    ]);

    const isAdminOrComSupOrCom = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Commercial,
    ]);

    const canAccessAttachments = isAdminOrComSupOrCom;

    const canAccessPayment = isIntegrator || isAdminOrComSupOrCom;

    const formData = filterForm.watch();

    const invoicesQuery = useInfiniteQuery({
        queryKey: [
            'invoices',
            filterTerm,
            formData.filterModules,
            formData.filterInverters,
            formData.filterOrder,
            formData.filterOrderType,
            formData.filterFutureSale,
            formData.filterStatus,
            formData.filterRegion,
            formData.filterSeller,
            formData.filterPaymentMethod,
            formData.filterTriangulationStatus,
            formData.filterAttachment,
        ],
        queryFn: async ({ pageParam = 0 }) => {
            const filterStatusValues = formData.filterStatus
                ?.map((item) => item.value)
                .join(',');

            const defaultFilterStatusValues =
                Object.values(InvoiceFilterStatus).join(',') || null;

            const filterPaymentMethodValues = formData.filterPaymentMethod
                ?.map((item) => item.value)
                .join('/');

            const filterTriangulationStatusValues =
                formData.filterTriangulationStatus
                    ?.map((item) => item.value)
                    .join(',');

            const params = {
                term: filterTerm,
                skip: pageParam,
                take: rowsPerPage,
                modules: formData.filterModules || '',
                inverters: formData.filterInverters || '',
                orderby: formData.filterOrder?.value || '',
                ordertype: formData.filterOrderType?.value || '',
                futureSale: formData.filterFutureSale?.value ?? 99,
                status: filterStatusValues || defaultFilterStatusValues,
                regions: formData.filterRegion || [],
                sellers: formData.filterSeller || [],
                paymentMethods: filterPaymentMethodValues || '',
                triangulationStatus: filterTriangulationStatusValues || '',
                attachment: formData.filterAttachment?.value,
            };

            const response = await InvoiceHttpService.index(params);
            return {
                data: response.data.data,
                currentPage: pageParam,
                total: response.data.meta.total,
            };
        },
        getNextPageParam: ({ total, currentPage }) =>
            total !== 0 ? currentPage + 1 : undefined,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const target = useRef();

    useIntersectionObserver({
        target,
        onIntersect: invoicesQuery.fetchNextPage,
        enabled: invoicesQuery.hasNextPage,
    });

    const showLoading =
        invoicesQuery.isLoading ||
        invoicesQuery.isFetchingNextPage ||
        !invoicesQuery.isFetched;

    const remakeMutation = useMutation({
        mutationKey: ['remake-invoice'],
        mutationFn: async (keepAttachments: boolean) =>
            InvoiceHttpService.remake(selectedInvoice.id, keepAttachments),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao refazer o pedido');
        },
        onSuccess: (data) => {
            toast.success('Pedido refeito com sucesso');
            history.push(budgetsDetailRoute.build(data.data));
        },
        onSettled: () => invoicesQuery.refetch(),
    });

    const cancelMutation = useMutation({
        mutationFn: async (reason: string) =>
            InvoiceHttpService.cancel(selectedInvoice.id, { reason }),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao cancelar o pedido.');
        },
        onSuccess: () => {
            setShowInvoiceCancelmentModal(false);
            toast.success('Pedido cancelado com sucesso!');
        },
        onSettled: () => {
            invoicesQuery.refetch();
            setSelectedInvoice(null);
        },
    });

    const returnMutation = useMutation({
        mutationFn: async (feedback: string) => {
            await ReturnsHttpService.saveReturn(selectedInvoice.id.toString(), {
                observation: feedback,
            });
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao solicitar a pré-devolução');
        },
        onSuccess: () => {
            toast.success('Sucesso ao solicitar a pré-devolução!');
        },
        onSettled: () => invoicesQuery.refetch(),
    });

    const invalidateMutation = useMutation({
        mutationFn: async (feedback: string) => {
            await InvoiceHttpService.invalidate(selectedInvoice.id.toString(), {
                feedback,
            });
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao reprovar o pedido');
        },
        onSuccess: () => {
            toast.success('Pedido reprovado com sucesso');
        },
        onSettled: () => invoicesQuery.refetch(),
    });

    const reproveMutation = useMutation({
        mutationFn: (feedback: string) =>
            InvoiceHttpService.reprove(selectedInvoice.id.toString(), {
                observation: feedback,
            }),
        onError: (error) => {
            let message = 'Ocorreu um erro ao aprovar o pedido';

            if (isFriendlyHttpError(error)) {
                message = (error as { message: string }).message;
            }

            toast.error(message);
        },
        onSuccess: async () => {
            invoicesQuery.refetch();
            toast.success('Pedido aprovado com sucesso');
        },
    });

    const invoices = invoicesQuery.data?.pages.reduce(
        (accumulator, page) => accumulator.concat(page.data),
        [],
    );

    const selectedInvoiceHasAttachments =
        selectedInvoice?.invoiceAttachments?.filter(
            (attachment: any) => !attachment.deletedAt,
        ).length;

    return (
        <>
            <Row>
                <Col className="ml-2 mr-2">
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh>Nº DO PEDIDO</StyledTh>
                                <StyledTh>DATA DE APROVAÇÃO</StyledTh>
                                <StyledTh>CLIENTE</StyledTh>
                                {!isIntegrator && (
                                    <StyledTh>RESPONSÁVEL</StyledTh>
                                )}
                                <StyledTh>VALOR TOTAL</StyledTh>
                                {!isIntegrator && <StyledTh>FEEDBACK</StyledTh>}
                                {isIntegrator && (
                                    <StyledTh>PREVISÃO DE ENTREGA</StyledTh>
                                )}
                                {canAccessAttachments && (
                                    <StyledTh>COMPROVANTES</StyledTh>
                                )}
                                <StyledTh>STATUS</StyledTh>
                                {canAccessPayment && (
                                    <StyledTh>PAGAMENTO</StyledTh>
                                )}
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {invoices?.map((invoice: any) => (
                                <InvoiceTableRow
                                    key={invoice.id}
                                    invoice={invoice}
                                />
                            ))}
                        </tbody>
                    </Table>
                </Col>

                <Col md={12} className="text-center" ref={target}>
                    {showLoading && (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    )}

                    {!showLoading && !invoices?.length && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>

            {showInvoiceCancelmentModal && (
                <InvoiceCancelmentModal
                    show={showInvoiceCancelmentModal}
                    onHide={() => {
                        setShowInvoiceCancelmentModal(false);
                    }}
                    onCancel={(reason: string) => {
                        cancelMutation.mutateAsync(reason);
                    }}
                />
            )}

            {showInvoiceApprovalModal && (
                <InvoiceApprovalModal
                    show={showInvoiceApprovalModal}
                    onHide={() => setShowInvoiceApprovalModal(false)}
                    refetch={invoicesQuery.refetch}
                />
            )}

            {showReleasePaymentMethodInvoiceModal && (
                <ReleasePaymentMethodModal
                    show={showReleasePaymentMethodInvoiceModal}
                    onHide={() => {
                        invoicesQuery.refetch();
                        setShowReleasePaymentMethodInvoiceModal(false);
                    }}
                    invoiceId={selectedInvoice.id}
                    erpTenantId={selectedInvoice.erpTenantId}
                />
            )}

            {showRemakeConfirmation && (
                <ConfirmationDialog
                    show={showRemakeConfirmation}
                    onHide={() => {
                        setShowRemakeConfirmation(false);
                    }}
                    onConfirmWithCheckbox={
                        selectedInvoiceHasAttachments
                            ? (keepAttachments) =>
                                  remakeMutation.mutateAsync(keepAttachments)
                            : undefined
                    }
                    onConfirm={
                        !selectedInvoiceHasAttachments
                            ? () => remakeMutation.mutateAsync(false)
                            : undefined
                    }
                    title="Refazer pedido"
                    text="Deseja refazer o pedido selecionado?"
                    icon={NotificationIcon.Info}
                    label="Desejo manter os anexos do pedido original"
                />
            )}

            {showReproveConfirmation && (
                <ConfirmationDialog
                    show={showReproveConfirmation}
                    onHide={() => setShowReproveConfirmation(false)}
                    onConfirmWithFeedback={async (feedback: string) =>
                        invalidateMutation.mutateAsync(feedback)
                    }
                    title="Reprovar pedido"
                    text="Deseja reprovar o pedido selecionado?"
                    icon={NotificationIcon.Warning}
                    placeholder="Insira o motivo da reprovação..."
                    required={false}
                />
            )}

            {showReproveValidatedConfirmation && (
                <ConfirmationDialog
                    show={showReproveValidatedConfirmation}
                    onHide={() => setShowReproveValidatedConfirmation(false)}
                    onConfirmWithFeedback={async (feedback: string) =>
                        reproveMutation.mutateAsync(feedback)
                    }
                    title="Reprovar pedido"
                    text="Deseja reprovar o pedido selecionado?"
                    icon={NotificationIcon.Warning}
                    placeholder="Insira o motivo da reprovação..."
                    required
                />
            )}

            {showReturnConfirmation && (
                <ConfirmationDialog
                    show={showReturnConfirmation}
                    onHide={() => setShowReturnConfirmation(false)}
                    onConfirmWithFeedback={async (feedback: string) =>
                        returnMutation.mutateAsync(feedback)
                    }
                    title="Solicitar devolução"
                    text="Deseja solicitar a devolução do pedido selecionado?"
                    icon={NotificationIcon.Warning}
                    placeholder="Insira o motivo da devolução..."
                    required
                />
            )}
        </>
    );
}
