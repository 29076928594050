/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { theme, control } from '../../../styles/react-select-config';
import Select from '../../Select';

interface filters {
    question1Data: any;
    question2Data: any;
    question3Data: any;
    question4Data: any;
    question5Data: any;
    question6Data: any;
}

const IntegratorCompanyInfo = (props: any) => {
    const { control: formControl, watch } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: {
            question1Data: 0,
            question2Data: 0,
            question3Data: 0,
            question4Data: 0,
            question5Data: 0,
            question6Data: 0,
        },
    });

    const questionsData = watch();

    const question1: any[] = [
        { label: 'Acima de 3 anos', value: 'a', points: 15 },
        { label: 'Até 1 ano', value: 'b', points: 5 },
        { label: 'De 1 a 3 anos', value: 'c', points: 15 },
    ];

    const question2: any[] = [
        { label: 'Acima de 100', value: 'a', points: 20 },
        { label: 'Até 50', value: 'b', points: 10 },
        { label: 'De 50 a 100', value: 'c', points: 15 },
    ];

    const question3: any[] = [
        { label: 'Comercial ', value: 'a', points: 15 },
        { label: 'Grandes Usinas', value: 'b', points: 25 },
        { label: 'Residencial', value: 'c', points: 10 },
    ];

    const question4: any[] = [
        { label: 'Acima de 100 kWp', value: 'a', points: 20 },
        { label: 'Até 20 kWp', value: 'b', points: 10 },
        { label: 'De 20 kWp a 100 kWp', value: 'c', points: 15 },
    ];

    const question5: any[] = [
        { label: 'Acima de 15', value: 'a', points: 20 },
        { label: 'Até 5', value: 'b', points: 5 },
        { label: 'De 5 a 15', value: 'c', points: 10 },
    ];

    useEffect(() => {
        if (props.seller.profilePoints) {
            if (props.seller.profilePoints <= 40) {
                props.seller.profile = 'Integrador Volume';
            } else if (
                props.seller.profilePoints > 40 &&
                props.seller.profilePoints <= 70
            ) {
                props.seller.profile = 'Integrador Crescimento';
            } else if (props.seller.profilePoints > 70) {
                props.seller.profile = 'Integrador Valor';
            }
        }

        let total = 0;

        if (questionsData.question1Data.label) {
            props.seller.question1 = questionsData.question1Data.label;
        }

        if (questionsData.question2Data.label) {
            props.seller.question2 = questionsData.question2Data.label;
        }

        if (questionsData.question3Data.label) {
            props.seller.question3 = questionsData.question3Data.label;
        }

        if (questionsData.question4Data.label) {
            props.seller.question4 = questionsData.question4Data.label;
        }

        if (questionsData.question5Data.label) {
            props.seller.question5 = questionsData.question5Data.label;
        }

        Object.values(questionsData).forEach((item) => {
            if (item !== 0) {
                total += item.points;
            }
        });

        if (
            questionsData.question1Data !== 0 &&
            questionsData.question2Data !== 0 &&
            questionsData.question3Data !== 0 &&
            questionsData.question4Data !== 0 &&
            questionsData.question5Data !== 0
        ) {
            props.seller.profilePoints = total;

            if (props.seller.profilePoints <= 40) {
                props.seller.profile = 'Integrador Volume';
            } else if (
                props.seller.profilePoints > 40 &&
                props.seller.profilePoints <= 70
            ) {
                props.seller.profile = 'Integrador Crescimento';
            } else if (props.seller.profilePoints > 70) {
                props.seller.profile = 'Integrador Valor';
            }
        }
    }, [questionsData, props.seller]);

    useEffect(() => {
        if (questionsData.question1Data.label) {
            props.integrator.question1 = questionsData.question1Data.label;
        }

        if (questionsData.question2Data.label) {
            props.integrator.question2 = questionsData.question2Data.label;
        }

        if (questionsData.question3Data.label) {
            props.integrator.question3 = questionsData.question3Data.label;
        }

        if (questionsData.question4Data.label) {
            props.integrator.question4 = questionsData.question4Data.label;
        }

        if (questionsData.question5Data.label) {
            props.integrator.question5 = questionsData.question5Data.label;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsData]);

    // const handleQuestionChanges = async (value: any, name: string) => {
    //     props.handleChanges([{ target: { name, value: value.label } }]);
    // };

    return (
        <>
            <Row>
                <Col sm="6">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>
                            Há quanto tempo atua no mercado de GD
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question1}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question1Data"
                            options={question1}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder={'Tempo de atuação no mercado'}
                            defaultValue={null}
                        />
                        {props.errors.question1 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question1}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm="6">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>
                            Quantas instalações sua empresa já realizou
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question2}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question2Data"
                            options={question2}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder={'N° de instalações já realizadas'}
                            defaultValue={null}
                        />
                        {props.errors.question2 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question2}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>
                            Qual o foco das instalações que sua empresa executa
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question1}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question3Data"
                            options={question3}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder={'Foco das instalações'}
                            defaultValue={null}
                        />
                        {props.errors.question3 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question3}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm="6">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>
                            Qual a potência dos kits instalados pela sua
                            empresa?
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question4}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question4Data"
                            options={question4}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder={'Potência dos Kits vendidos'}
                            defaultValue={null}
                        />
                        {props.errors.question4 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question4}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>
                            Quantos funcionários sua empresa possui?
                        </Form.Label>
                        <Form.Control
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.question5}
                        />
                        <Controller
                            isClearable={false}
                            className="form-control-nexen"
                            as={Select}
                            control={formControl}
                            name="question5Data"
                            options={question5}
                            isMulti={false}
                            styles={{
                                control,
                            }}
                            theme={theme}
                            placeholder={'N° de funcionários'}
                            defaultValue={null}
                        />
                        {props.errors.question5 && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.question5}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm="6" />
            </Row>
            <Form.Group controlId="formBasicName">
                <Form.Label>
                    Quais marcas de módulo e inversor sua empresa trabalha?
                </Form.Label>
                <Form.Control
                    value={props.integrator.question6}
                    isInvalid={!!props.errors.question6}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.question6 &&
                        !props.errors.question6 &&
                        props.signUp
                    }
                    name="question6"
                    type="text"
                    placeholder="Trabalha com quais marcas de módulos e inversor?"
                />
                {props.errors.question6 && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.question6}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default IntegratorCompanyInfo;
