/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-fragments */
/* eslint-disable indent */
import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { StyledTdText, StyledTh, StyledTdLink } from './styles';
import { ReactComponent as HeartIconSvg } from '../../../assets/icons/heartIcon.svg';
import { ReactComponent as UnheartIconSvg } from '../../../assets/icons/unheartIcon.svg';
import { usePoint } from '../../../contexts/pointContext';
import WishlistHttpService from '../../../services/http/wishlist-http';
import formatDate from '../../../utils/formatDate';
import { ProductAvailability } from '../../../constants/productAvailability';

const GeneratorsTable: React.FC = () => {
    const {
        products,
        setProducts,
        setSelectedProduct,
        term,
        setShowDetailModal,
    } = usePoint();

    async function wishlistProduct(item: any) {
        if (item.wishlist) {
            await WishlistHttpService.destroy(item.B1_COD);
            item.wishlist = false;
        } else {
            await WishlistHttpService.store(item.B1_COD);
            item.wishlist = true;
        }

        setProducts([]);
        setProducts(products);
    }

    return (
        <React.Fragment>
            <Table bordered hover size="sm" className="text-center">
                <thead>
                    <tr>
                        <StyledTh>CÓDIGO</StyledTh>
                        <StyledTh>DESCRIÇÃO</StyledTh>
                        <StyledTh>DISPONIBILIDADE</StyledTh>
                        <StyledTh>LISTA DE DESEJOS</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {products &&
                        products?.map((item: any, index: any) => (
                            <tr
                                style={{
                                    backgroundColor:
                                        item?.quantity > 0
                                            ? 'rgba(242, 153, 74, 0.08)'
                                            : '#ffffff',
                                }}
                                key={index}
                            >
                                <td>
                                    <StyledTdText>
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item?.B1_COD}
                                        />
                                    </StyledTdText>
                                </td>
                                <td>
                                    <StyledTdLink
                                        onClick={() => {
                                            setSelectedProduct(item);
                                            setShowDetailModal(true);
                                        }}
                                    >
                                        <Highlighter
                                            autoEscape
                                            highlightClassName="highlight-term"
                                            searchWords={[term]}
                                            textToHighlight={item?.B1_DESC}
                                        />
                                    </StyledTdLink>
                                </td>
                                <td>
                                    <StyledTdLink>
                                        {item?.boardingDate ===
                                        ProductAvailability.Immediate ? (
                                            <Badge
                                                style={{
                                                    fontSize: '14px',
                                                    backgroundColor:
                                                        'rgba(111, 207, 15, 0.1)',
                                                    color: '#6FCF97',
                                                }}
                                                variant={'primary'}
                                            >
                                                Imediato
                                            </Badge>
                                        ) : (
                                            formatDate(item?.boardingDate)
                                        )}
                                    </StyledTdLink>
                                </td>
                                <td>
                                    <StyledTdLink
                                        onClick={() => {
                                            wishlistProduct(item);
                                        }}
                                    >
                                        {item.wishlist ? (
                                            <UnheartIconSvg />
                                        ) : (
                                            <HeartIconSvg />
                                        )}
                                    </StyledTdLink>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default GeneratorsTable;
