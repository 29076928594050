import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import { registerLocale } from 'react-datepicker';
import { QueryClient, QueryClientProvider } from 'react-query';
import br from 'date-fns/locale/pt-BR';
import Routes from './routes';
import history from './services/history';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { OnlineStoreProvider } from './contexts/onlineStoreContext';
import { RouterProvider } from './contexts/routerContext';
import { AuthProvider } from './contexts/authContext';
import { SellerProvider } from './contexts/sellerContext';
import { PointProvider } from './contexts/pointContext';
import { PointManagementProvider } from './contexts/pointManagementContext';
import { GA_TRACKING_ID } from './config/ga';
import 'react-datepicker/dist/react-datepicker.css';
import { NotificationProvider } from './contexts/notificationContext';
import { StructureCalculationProvider } from './contexts/structureCalculationContext';
import { ConsultantContactProvider } from './contexts/contactContext';
import { InvoicePaycreditProvider } from './contexts/invoicePaycreditContext';
import InvoicesProvider from './contexts/invoicesContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: () => false,
        },
    },
});

registerLocale('br', br);

ReactGA.initialize(GA_TRACKING_ID);

const App: React.FC = () => {
    // Google Analytics
    history.listen(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <RouterProvider>
            <SellerProvider>
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <NotificationProvider>
                            <InvoicesProvider>
                                <OnlineStoreProvider>
                                    <PointManagementProvider>
                                        <PointProvider>
                                            <StructureCalculationProvider>
                                                <ConsultantContactProvider>
                                                    <InvoicePaycreditProvider>
                                                        <Router
                                                            history={history}
                                                        >
                                                            <Routes />
                                                        </Router>
                                                    </InvoicePaycreditProvider>
                                                </ConsultantContactProvider>
                                            </StructureCalculationProvider>
                                        </PointProvider>
                                    </PointManagementProvider>
                                    <ToastContainer />
                                </OnlineStoreProvider>
                            </InvoicesProvider>
                        </NotificationProvider>
                    </QueryClientProvider>
                </AuthProvider>
            </SellerProvider>
        </RouterProvider>
    );
};

export default App;
