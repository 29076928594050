import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTitle = styled.span`
    background: #ffffff;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #707070;
    min-width: 225px;
    white-space: nowrap;
`;

export const StyledText = styled.h2`
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    color: #2f80ed;
    white-space: nowrap;
`;

export const StyledCard = styled.div`
    background: #ffffff;
    border: 1px solid #e3e7ee;
    border-radius: 1px !important;
    box-sizing: border-box;
    cursor: pointer;

    min-width: 30%;
    max-width: 100%;
`;

export const StyledCardBody = styled(Card.Body)`
    min-width: 50%;
    max-width: 50%;
`;

export const StyledCardTitle = styled(Card.Title)`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #707070;
    white-space: nowrap;
`;

export const StyledCardText = styled(Card.Text)`
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    color: #2f80ed;
`;
