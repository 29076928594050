import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { OthersPaymentMethodsFilterFields } from '../../../../pages/OthersPaymentMethodsOverview';

interface OthersPaymentMethodsFilterProps {
    setFilter: (
        name: keyof OthersPaymentMethodsFilterFields,
        value: unknown,
    ) => void;
}
export default function OthersPaymentMethodsFilter({
    setFilter,
}: OthersPaymentMethodsFilterProps) {
    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Row className="mt-4">
                <Col className="ml-2 mr-2">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder="Digite aqui o que procura..."
                            type="text"
                            name="filterTerm"
                            onChange={(event) => {
                                setFilter('filterTerm', event.target.value);
                            }}
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );
}
