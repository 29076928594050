import { PiPencilSimpleLineBold } from 'react-icons/pi';
import { useHistory } from 'react-router-dom';
import { invoiceDetailRoute } from '../../../../routes/config';
import formatMask from '../../../../utils/formatMask';
import {
    StyledData,
    StyledDataHeader,
    StyledSectionTitle,
    StyledWarningMessage,
} from '../styles';

interface DeliveryData {
    invoiceId: string;
    invoiceBranch: string;
    sellerBranch: string;
    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    date: string;
    isFutureSale: boolean;
    isTriangulation: boolean;
}

interface InvoiceDeliveryDataProps {
    deliveryData: DeliveryData;
    disabled: boolean;
}

const cep = '#####-###';

export default function InvoiceDeliveryData({
    deliveryData,
    disabled,
}: InvoiceDeliveryDataProps) {
    const history = useHistory();

    return (
        <>
            <StyledSectionTitle>Detalhes da entrega</StyledSectionTitle>

            <StyledDataHeader>Filial de expedição</StyledDataHeader>

            {deliveryData.invoiceBranch !== deliveryData.sellerBranch && (
                <StyledWarningMessage>
                    Filial associada ao estado de origem do integrador:{' '}
                    {deliveryData.sellerBranch}
                </StyledWarningMessage>
            )}

            <StyledData>
                {deliveryData.invoiceBranch || 'Filial não encontrada'}

                <PiPencilSimpleLineBold
                    style={{
                        marginLeft: '6px',
                        marginBottom: '3px',
                        pointerEvents: disabled ? 'none' : 'auto',
                    }}
                    size={16}
                    onClick={() =>
                        history.push({
                            pathname: invoiceDetailRoute.build({
                                id: deliveryData.invoiceId,
                            }),
                            state: {
                                tab: 'branch',
                            },
                        })
                    }
                />
            </StyledData>

            <StyledDataHeader>Endereço</StyledDataHeader>

            <StyledData>
                {`${deliveryData.address}${
                    deliveryData.number ? `, ${deliveryData.number}` : ''
                }${
                    deliveryData.complement
                        ? `, ${deliveryData.complement}`
                        : ''
                }`}
                <br />
                {`${deliveryData.neighborhood}`}
                <br />
                {`${deliveryData.city}-${deliveryData.state}`}
                <br />

                {deliveryData.cep ? formatMask(deliveryData.cep, cep) : '-'}
            </StyledData>

            <StyledDataHeader>Estimativa de embarque</StyledDataHeader>

            <StyledData>
                {new Date(deliveryData.date)?.toLocaleDateString() || '-'}
            </StyledData>

            <StyledDataHeader>Venda futura</StyledDataHeader>

            <StyledData>{deliveryData.isFutureSale ? 'Sim' : 'Não'}</StyledData>

            <StyledDataHeader>Conta e ordem</StyledDataHeader>

            <StyledData>
                {deliveryData.isTriangulation ? 'Sim' : 'Não'}
            </StyledData>
        </>
    );
}
