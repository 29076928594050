/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable indent */
import React from 'react';
import { Form, Row, Button, Modal } from 'react-bootstrap';

const CartAlertModal = (props: any) => (
    <Modal {...props} size="xl" centered>
        <Modal.Header closeButton>
            <Modal.Title className={'ml-3'} id="contained-modal-title-vcenter">
                Atenção
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'ml-3'}>
            Para que sua compra seja autorizada, é necessário adicionar um total
            de no mínimo 1 inversor e 1 Painel solar.
            <Form className={'mt-4'}>
                <Form.Check
                    onChange={() => localStorage.setItem('showCartAlert', '0')}
                    name={'radios'}
                    id="formHorizontalRadios2"
                    label="Não exibir mais esta mensagem"
                    type={'radio'}
                />
            </Form>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
            <Row className="justify-content-center">
                <Button
                    onClick={props.onHide}
                    style={{ height: '42px', width: '134px' }}
                    size="sm"
                >
                    Entendi
                </Button>
            </Row>
        </Modal.Footer>
    </Modal>
);

export default CartAlertModal;
