/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-useless-path-segments */
import React from 'react';
import { Switch } from 'react-router-dom';
import {
    GeneralInfo,
    PointStatement,
    PointStore,
    Wishlist,
    Regulation,
    PointManagement,
} from '../../pages/Points/';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const pointGeneralInfoRoute = new Route('/pontos/');
export const pointStatementRoute = new Route('/pontos/extrato');
export const pointStoreRoute = new Route('/pontos/loja');
export const wishlistRoute = new Route('/pontos/desejos');
export const regulationRoute = new Route('/pontos/regulamento');
export const pointManagementRoute = new Route('/pontos/gerenciar');

export const pointRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={pointGeneralInfoRoute.path}
            component={GeneralInfo}
            isPrivate={true}
            exact
        />

        <RouteWrapper
            path={pointStatementRoute.path}
            component={PointStatement}
            isPrivate={true}
            exact
        />

        <RouteWrapper
            path={pointStoreRoute.path}
            component={PointStore}
            isPrivate={true}
            exact
        />

        <RouteWrapper
            path={wishlistRoute.path}
            component={Wishlist}
            isPrivate={true}
            exact
        />

        <RouteWrapper
            path={regulationRoute.path}
            component={Regulation}
            isPrivate={true}
            exact
        />

        <RouteWrapper
            path={pointManagementRoute.path}
            component={PointManagement}
            isPrivate={true}
            exact
        />
    </Switch>
);
