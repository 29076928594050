/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable indent */
import React, { useEffect } from 'react';

import GeneratorsCard from '../../../components/OnlineStore/Card';
import GeneratorsTable from '../../../components/OnlineStore/Table';

import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import Base from '../../../components/OnlineStore/Base';

const List: React.FC = (props: any) => {
    const {
        products,
        viewType,
        setProductType,
        setGroupId,
        setViewType,
        setStep,
    } = useOnlineStore();

    useEffect(() => {
        setGroupId('');
        setStep(2);
        setProductType('PA');
        setViewType('card');
    }, [setProductType, setStep, setViewType, setGroupId]);

    useEffect(() => {
        if (props?.location?.state?.viewSummary) {
            setStep(3);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Base
            productType={'PA'}
            content={
                viewType === 'card' ? (
                    <GeneratorsCard data={products ?? []} />
                ) : (
                    <GeneratorsTable data={products ?? []} />
                )
            }
        />
    );
};

export { List };
