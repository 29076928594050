import { NavDropdown } from 'react-bootstrap';
import { StyledDropdownButton } from '../styles';
import NotificationsList from './NotificationList';
import NotificationHeader from './NotificationHeader';
import NotificationIcon from './NotificationIcon';
import useNotifications from '../../../hooks/useNotififcations';

export default function NotificationsDropdown() {
    const { notificationsQuery } = useNotifications();

    const unreadNotifications = notificationsQuery.data?.filter(
        (item) => !item.readed,
    );

    const readNotifications = notificationsQuery.data?.filter(
        (item) => item.readed,
    );

    return (
        <StyledDropdownButton
            title={
                <NotificationIcon
                    unreadNotificationsAmount={unreadNotifications?.length || 0}
                />
            }
            size="lg"
            menuAlign={{ lg: 'right' }}
            disabled={notificationsQuery.isLoading}
        >
            <NotificationHeader />

            <NavDropdown.Divider />
            <NotificationsList
                title="Não lidas"
                notifications={unreadNotifications}
            />

            <NavDropdown.Divider />
            <NotificationsList
                title="Lidas"
                notifications={readNotifications}
            />
        </StyledDropdownButton>
    );
}
