import { Nav } from 'react-bootstrap';
import Group from '../../../shared/interfaces/group.interface';
import GroupMenuItem from '../GroupMenuItem';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';

export default function GroupMenu() {
    const { groups, groupId, setGroupId, viewType, setViewType, setBrandId } =
        useOnlineStore();

    const handleSelect = (newGroupId: string) => {
        if (groupId !== newGroupId) {
            setBrandId('');
            setGroupId(newGroupId);
        }

        if (viewType === 'detail') {
            setViewType('card');
        }
    };

    return (
        <>
            <Nav
                activeKey={groupId}
                className="ma-0 pt-3 ml-n3"
                onSelect={handleSelect}
            >
                {groups?.map((group: Group) => (
                    <GroupMenuItem
                        key={group.BM_GRUPO}
                        group={group}
                        active={groupId === group.BM_GRUPO}
                    />
                ))}
            </Nav>
        </>
    );
}
