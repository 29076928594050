import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledSpan = styled.span`
    font-size: 12px !important;
`;

export const StyledDivInvoiceDelivery = styled.div`
    background: #f2f2f2;
    width: 140px;
    float: left;
    padding: 4px;
    font-size: 12px;
`;

export const StyledCloseButton = styled(Button)`
    width: 200px;
`;

export const StyledRemakeButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #f2994a;
    color: #f2994a !important;

    :hover {
        background-color: #f2994a;
        color: #ffffff !important;
    }
`;
