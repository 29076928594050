import { Modal, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { StyledButton, StyledDisplayDiv } from './styles';
import InvoiceHttpService from '../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import useInvoices from '../../../hooks/useInvoices';

interface InvoiceNfDisplayModalProps {
    type: 'SR' | 'CO';
    show: boolean;
    onHide(): void;
}

export default function InvoiceNfDisplayModal({
    type,
    show,
    onHide,
}: InvoiceNfDisplayModalProps) {
    const { selectedInvoice } = useInvoices();

    const nf = useQuery({
        queryKey: ['nf', selectedInvoice],
        queryFn: async () => {
            const response =
                type === 'CO'
                    ? await InvoiceHttpService.getNfeTriangulation(
                          selectedInvoice.id,
                      )
                    : await InvoiceHttpService.getNfeSimpleShipment(
                          selectedInvoice.id,
                      );

            return `data:application/pdf;base64,${response.data.data}`;
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message as string);
            } else {
                toast.error('Ocorreu um erro ao buscar a nota fiscal');
            }
            onHide();
        },
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        refetchOnReconnect: false,
        retry: false,
    });

    const handleClick = (fileToDownload: string) => {
        const downloadLink = document.createElement('a');

        downloadLink.href = fileToDownload;
        downloadLink.download =
            type === 'CO' ? 'nfe_conta_ordem.pdf' : 'nfe_simples_remessa.pdf';
        downloadLink.click();
    };

    return (
        <Modal show={show} onHide={onHide} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {`Nota Fiscal Eletrônica${
                        type === 'CO' ? ' de Remessa por Conta e Ordem' : ''
                    }`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StyledDisplayDiv>
                    {nf.isLoading ? (
                        <Spinner animation="border" />
                    ) : (
                        <object
                            data={nf?.data}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                            }}
                        >
                            <StyledButton onClick={() => handleClick(nf?.data)}>
                                Realizar download do arquivo
                            </StyledButton>
                        </object>
                    )}
                </StyledDisplayDiv>
            </Modal.Body>
        </Modal>
    );
}
