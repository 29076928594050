import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ProductVisibilityBadgeProps {
    isInternalProduct: boolean;
}

export default function ProductVisibilityBadge({
    isInternalProduct,
}: ProductVisibilityBadgeProps) {
    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip id="tooltip-available-qty">
                    <span>
                        {isInternalProduct
                            ? 'Este produto só pode ser visualizado por usuários internos'
                            : 'Este produto pode ser visualizado por todos os usuários'}

                        <br />
                    </span>
                </Tooltip>
            }
        >
            <span>
                <i
                    className="fas fa-circle"
                    style={{ color: isInternalProduct ? '#f2994a' : '#6fcf97' }}
                />
            </span>
        </OverlayTrigger>
    );
}
