import { Nav } from 'react-bootstrap';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import BrandMenuItem from '../BrandMenuItem';
import Brand from '../../../shared/interfaces/brand.interface';

export default function BrandMenu() {
    const { brands, brandId, viewType, setViewType, setBrandId } =
        useOnlineStore();

    const handleSelect = (newBrandId: string) => {
        setBrandId(newBrandId);

        if (viewType === 'detail') {
            setViewType('card');
        }
    };

    return (
        <Nav
            activeKey={brandId}
            className="ma-0 pt-2 ml-n3"
            onSelect={handleSelect}
        >
            {brands?.map((brand: Brand) => (
                <BrandMenuItem
                    key={brand.ZAM_COD}
                    brand={brand}
                    active={brandId === brand.ZAM_COD}
                />
            ))}
        </Nav>
    );
}
