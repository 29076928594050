/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';
import { List as ListBlackFriday } from '../../pages/OnlineStore/BlackFriday';
import { List } from '../../pages/OnlineStore/ReadyKits';
import { Detail } from '../../pages/OnlineStore/Detail';

import { List as ListGenerator } from '../../pages/OnlineStore/AssembleGenerator';

export const listBlackFridayRoute = new Route('/sales');
export const listReadyKits = new Route('/kits-prontos');
export const listGeneratorsRoute = new Route('/monte-seu-gerador');

export const detailRoute = new Route('/detalhes/:id');

/* export const integratorEditRoute = new Route('/integrators/:id/edit');

export const integratorCreateRoute = new Route('/integrators/new'); */

export const OnlineRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={detailRoute.path}
            component={Detail}
            isPrivate={true}
        />
        <RouteWrapper
            path={listReadyKits.path}
            component={List}
            isPrivate={true}
        />
        <RouteWrapper
            path={listGeneratorsRoute.path}
            component={ListGenerator}
            isPrivate={true}
        />
        <RouteWrapper
            path={listBlackFridayRoute.path}
            component={ListBlackFriday}
            isPrivate={true}
        />
    </Switch>
);
