export enum InvoiceStatus {
    Cart = 'CART',
    Budget = 'BUDGET',
    Invoice = 'INVOICE',
    Processing = 'PROCESSING',
    InvoiceValidated = 'INVOICE_VALIDATED',
    InvoiceApproved = 'INVOICE_APPROVED',
    InvoiceReproved = 'INVOICE_REPROVED',
    InvoiceCanceled = 'INVOICE_CANCELED',
    InvoiceCanceledPaid = 'INVOICE_CANCELED_PAID',
    InvoicePayed = 'INVOICE_PAYED',
    InvoicePayedErp = 'INVOICE_PAYED_ERP',
    FutureSale = 'FUTURE_SALE',
    InvoiceBilled = 'INVOICE_BILLED',
    InvoiceBillingErp = 'INVOICE_BILLING_ERP',
    InvoiceSeparate = 'INVOICE_SEPARATE',
    InvoiceDispatched = 'INVOICE_DISPATCHED',
    InvoiceDeliveryInTransit = 'DELIVERY_IN_TRANSIT',
    InvoiceDeliveryEnRoute = 'DELIVERY_EN_ROUTE',
    InvoiceDelivered = 'INVOICE_DELIVERED',
    InvoicePaymentError = 'INVOICE_PAYMENT_ERROR',
    BudgetExpired = 'BUDGET_EXPIRED',
    ConfirmingPaymentErp = 'CONFIRMING_PAYMENT_ERP',
    ConfirmingBillingErp = 'CONFIRMING_BILL_ERP',
    InvoiceBilling = 'INVOICE_BILLING',
    ProcessingCancellation = 'PROCESSING_CANCELLATION',
    WaitingApproval = 'WAITING_APPROVAL',
    InvoiceAnalysis = 'INVOICE_ANALYSIS',
}

export enum BudgetStatus {
    Budget = 'BUDGET',
    BudgetExpired = 'BUDGET_EXPIRED',
}

export enum InvoiceFilterStatus {
    Invoice = 'INVOICE',
    Processing = 'PROCESSING',
    InvoiceValidated = 'INVOICE_VALIDATED',
    InvoiceApproved = 'INVOICE_APPROVED',
    InvoiceReproved = 'INVOICE_REPROVED',
    InvoiceCanceled = 'INVOICE_CANCELED',
    InvoiceCanceledPaid = 'INVOICE_CANCELED_PAID',
    InvoicePayed = 'INVOICE_PAYED',
    InvoicePayedErp = 'INVOICE_PAYED_ERP',
    FutureSale = 'FUTURE_SALE',
    InvoiceBilled = 'INVOICE_BILLED',
    InvoiceBillingErp = 'INVOICE_BILLING_ERP',
    InvoiceSeparate = 'INVOICE_SEPARATE',
    InvoiceDispatched = 'INVOICE_DISPATCHED',
    InvoiceDeliveryInTransit = 'DELIVERY_IN_TRANSIT',
    InvoiceDeliveryStarted = 'DELIVERY_START',
    InvoiceAtAffiliate = 'DELIVERY_AT_AFFILIATE',
    InvoiceEnRoute = 'DELIVERY_EN_ROUTE',
    InvoiceDelivered = 'INVOICE_DELIVERED',
    InvoicePaymentError = 'INVOICE_PAYMENT_ERROR',
    ConfirmingPaymentErp = 'CONFIRMING_PAYMENT_ERP',
    ConfirmingBillingErp = 'CONFIRMING_BILL_ERP',
    InvoiceBilling = 'INVOICE_BILLING',
    ProcessingCancellation = 'PROCESSING_CANCELLATION',
    WaitingApproval = 'WAITING_APPROVAL',
    InvoiceAnalysis = 'INVOICE_ANALYSIS',
}

export enum InvoicePaycreditStatus {
    InvoiceAwaitingAnalysis = 'AWAITING_ANALYSIS',
    InvoiceAnalysisDone = 'ANALYSIS_DONE',
    InvoiceFinishedAnalysis = 'FINISHED_ANALYSIS',
}

export enum InvoiceTriangulationStatus {
    Created = 'CREATED',
    Completed = 'COMPLETED',
    Processing = 'PROCESSING',
    Approved = 'APPROVED',
}
