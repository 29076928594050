/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable object-shorthand */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Spinner } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { cepMask } from '../../../utils/masks';
import { control, theme } from '../../../styles/react-select-config';
import ValidationHttpService from '../../../services/http/validation-http';
import StateService, { IState } from '../../../services/state';
import normalizeString, { formatCurrency } from '../../../utils/strings';
import CityHttpService from '../../../services/http/city-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import { StyledDivInvoiceDelivery, SubText } from './styles';
import { ReactComponent as ReactTruckIcon } from '../../../assets/icons/truck.svg';
import getFreightValue from '../../../utils/getFreightValue';
import getTotalInvoice from '../../../utils/getTotalInvoice';
import getMaxAvailabilityDate from '../../../utils/getMaxAvailabilityDate';
import useInvoiceFreight from '../../../hooks/useInvoiceFreight';

interface Props {
    invoice: any;
    disabled: any;
}
const DeliveryAddress: React.FC<Props> = ({ invoice, disabled }) => {
    const { setReloadCart, setCart, willPickup, setWillPickup } =
        useOnlineStore();
    const [states, setStates] = useState([] as Array<IState>);
    const [loading, setLoading] = useState(false);
    const [netInvoiceValue, setNetInvoiceValue] = useState(
        getTotalInvoice(invoice),
    );

    const newAddress = () => ({
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: states.find(
            (item: any) => item.value === invoice.chargeAddressErpState,
        ),
        erpCity: { value: '', label: '' },
        erpCityName: '',
        addressType: '',
    });
    const [cities, setCities] = useState([]);
    const [address, setAddress] = useState(newAddress());
    const [addressType, setAddressType] = useState(invoice.addressType);
    const [deadline, setDeadline] = useState(invoice.deliveryDeadline);
    const getCitiesByState = async (state: string) => {
        const response = await CityHttpService.get({ state: state });
        // eslint-disable-next-line arrow-body-style
        const cities = response.data.map((obj: any) => {
            return {
                label: obj.name,
                value: obj.code,
            };
        });
        cities.unshift({ name: 'Selecione uma cidade', value: '' });
        return cities;
    };
    const loadCities = async (state: string) => {
        if (!state) {
            return;
        }
        const cities = await getCitiesByState(state);
        cities.unshift({ name: 'Selecione uma cidade', value: '' });
        setCities(cities);
    };
    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        let events = event;
        if (!Array.isArray(event)) {
            events = [event];
        }
        events.forEach((item: any) => {
            if (item.target.name === 'erpState') {
                changes['erpState'] = item.target.value.value;
                changes['erpCity'] = '';
            }
            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });
        setAddress({ ...address, ...changes });
    };
    const save = async (data: any) => {
        if (disabled) {
            return;
        }
        try {
            const response = await InvoiceHttpService.update(data);
            if (response.data) {
                setNetInvoiceValue(getTotalInvoice(response.data));
                setDeadline(response.data.deliveryDeadline);
                setReloadCart((reload: boolean) => !reload);
            }
        } catch (error) {
            let err = error as any;
            if (isFriendlyHttpError(err)) {
                return toast.error(err.message);
            }
            return toast.error('Erro ao salvar dados do pedido!');
        }
    };
    const loadAddressByCep = async (cep: string) => {
        const changes: any = [{ target: { name: 'addressCep', cep } }];
        try {
            const data: any = await ValidationHttpService.validateCep(
                cep.replace(/\D/g, ''),
            );
            const street = data.data.street;
            const neighborhood = data.data.neighborhood;
            const state: any = states.find(
                (item: any) => item.value === data.data.state,
            );
            const cities = await getCitiesByState(state.value);
            const city: any = cities.find(
                (item: any) =>
                    item.label ===
                    normalizeString(data.data.city).toUpperCase(),
            );
            if (state.value !== address.erpState.value) {
                toast.error(
                    'O estado do endereço de entrega precisa ser o mesmo que o estado de cobrança.',
                );
                return;
            }
            changes.push({ target: { name: 'erpCity', value: city } });
            changes.push({
                target: { name: 'erpCityName', value: city.label },
            });
            changes.push({
                target: { name: 'addressDescription', value: street },
            });
            changes.push({
                target: {
                    name: 'addressNeighborhood',
                    value: neighborhood,
                },
            });
            let newInvoice = invoice;
            newInvoice.deliveryAddressCep = cep;
            newInvoice.deliveryAddressErpCity = city.value;
            newInvoice.deliveryAddressErpCityName = city.label;
            newInvoice.deliveryAddressDescription = street;
            newInvoice.deliveryAddressNeighborhood = neighborhood;
            save(newInvoice);
        } catch (err) {
            changes.push({ target: { name: 'erpCity', value: {} } });
            changes.push({
                target: { name: 'addressDescription', value: '' },
            });
            changes.push({
                target: { name: 'addressNeighborhood', value: '' },
            });
        }
        handleChanges(changes);
    };
    const handleCepChanges = async (event: any) => {
        const value: any = event.target.value;
        const changes = [{ target: { name: 'addressCep', value } }];
        handleChanges(changes);
        if (value.replace(/\D/g, '').length === 8) {
            setLoading(true);
            await loadAddressByCep(value);
            setLoading(false);
        }
    };
    const prepareData = (data: any) => {
        invoice.deliveryAddressCep = data.addressCep;
        invoice.deliveryAddressErpCity = data.addressErpCity;
        invoice.deliveryAddressErpCityName = data.erpCityName;
        invoice.deliveryAddressErpState = data.erpState.value;
        invoice.deliveryAddressNumber = data.addressNumber;
        invoice.deliveryAddressDescription = data.addressDescription;
        invoice.deliveryAddressNeighborhood = data.addressNeighborhood;
        invoice.deliveryAddressComplement = data.addressComplement;
        invoice.addressType = data.addressType;
        return invoice;
    };
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (
            invoice.chargeAddressErpState &&
            invoice.chargeAddressErpState === address.erpState.value
        ) {
            const data = prepareData(address);
            await save(data);
        } else {
            setAddress(newAddress());
            toast.error(
                'O estado do endereço de entrega precisa ser o mesmo que o estado de cobrança.',
            );
        }
    };
    useEffect(() => {
        if (disabled) {
            return;
        }
        if (invoice?.addressType === 'same') {
            InvoiceHttpService.useSameAddressForDelivery(invoice.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        loadCities(invoice.deliveryAddressErpState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice.deliveryAddressErpState]);
    useEffect(() => {
        const loadErpStates = async () => {
            const results = await StateService.get();
            // eslint-disable-next-line array-callback-return
            setStates(results);
        };
        loadErpStates();
    }, []);
    useEffect(() => {
        async function loadData(): Promise<void> {
            const states = await StateService.get();
            const state = states.find(
                (item: any) => item.value === invoice.chargeAddressErpState,
            );
            setAddress({
                addressCep: invoice.deliveryAddressCep,
                addressNumber: invoice.deliveryAddressNumber,
                addressDescription: invoice.deliveryAddressDescription,
                addressNeighborhood: invoice.deliveryAddressNeighborhood,
                addressComplement: invoice.deliveryAddressComplement,
                erpState: state,
                erpCity: {
                    label: invoice.deliveryAddressErpCityName,
                    value: invoice.deliveryAddressErpCity,
                },
                erpCityName: invoice.deliveryAddressErpCityName,
                addressType: invoice.addressType,
            });

            setWillPickup(invoice.addressType === 'pickup');
        }

        loadData();
    }, [
        invoice,
        invoice.deliveryAddresErpCity,
        invoice.deliveryAddresErpCityName,
        invoice.deliveryAddressCep,
        invoice.deliveryAddressComplement,
        invoice.deliveryAddressDescription,
        invoice.deliveryAddressErpCity,
        invoice.deliveryAddressErpCityName,
        invoice.deliveryAddressErpState,
        invoice.deliveryAddressNeighborhood,
        invoice.deliveryAddressNumber,
        setWillPickup,
        willPickup,
    ]);
    const handleStateChanges = async (value: any) => {
        handleChanges([{ target: { name: 'erpState', value: value } }]);
        loadCities(value.value);
    };
    const handleCityChanges = async (value: any) => {
        handleChanges([
            { target: { name: 'erpCity', value: value } },
            { target: { name: 'erpCityName', value: value.label } },
        ]);
    };

    const handlePickupAddressChange = async () => {
        if (disabled) return;

        setAddressType('pickup');
        setWillPickup(true);

        handleChanges({
            target: {
                name: 'addressType',
                value: 'pickup',
            },
        });

        let newCart = {
            ...invoice,
            addressType: 'pickup',
        };

        try {
            await save(newCart);

            const resp = await InvoiceHttpService.useSameAddressForDelivery(
                invoice.id,
            );

            setCart(resp.data);

            setReloadCart((reload: boolean) => !reload);
        } catch (error) {
            toast.error('Erro ao utilizar o mesmo endereço');
            console.log(error);
        }
    };

    const isPickup = addressType === 'pickup';

    const { data: freightPercentage = 0 } = useInvoiceFreight(
        invoice.id,
        invoice.freightPercentage,
    );

    const freightSavings = getFreightValue(netInvoiceValue, freightPercentage);

    const canEditAddress =
        invoice.addressType === 'same' ||
        invoice.addressType === 'pickup' ||
        disabled;

    const noDeliveryMessage = willPickup
        ? `Disponível para entrega em ${getMaxAvailabilityDate(invoice)}`
        : 'Prazo de entrega indisponível';

    return (
        <>
            <Form>
                <Row className="justify-content-around pt-4">
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Check
                                disabled={disabled}
                                checked={isPickup}
                                onChange={handlePickupAddressChange}
                                name="radios"
                                id="formHorizontalRadios4"
                                label={
                                    <>
                                        Retirar mercadoria{' '}
                                        {isPickup && (
                                            <SubText>
                                                Você está economizando{' '}
                                                {formatCurrency(freightSavings)}
                                            </SubText>
                                        )}
                                    </>
                                }
                                type="radio"
                            />
                            <Form.Check
                                disabled={disabled}
                                checked={addressType === 'same'}
                                onChange={async () => {
                                    if (disabled) {
                                        return;
                                    }
                                    handleChanges({
                                        target: {
                                            name: 'addressType',
                                            value: 'same',
                                        },
                                    });
                                    setAddressType('same');
                                    setWillPickup(false);

                                    let newCart = invoice;

                                    newCart.addressType = 'same';

                                    try {
                                        await save(newCart);
                                        const resp =
                                            await InvoiceHttpService.useSameAddressForDelivery(
                                                invoice.id,
                                            );

                                        setDeadline(resp.data.deliveryDeadline);

                                        setCart(resp.data);

                                        setReloadCart(
                                            (reload: boolean) => !reload,
                                        );
                                    } catch (error) {
                                        toast.error('erro');
                                    }
                                }}
                                name={'radios'}
                                id="formHorizontalRadios2"
                                label="Utilizar o mesmo endereço de cobrança do cliente"
                                type={'radio'}
                            />
                            <Form.Check
                                disabled={disabled}
                                checked={addressType === 'different'}
                                onChange={() => {
                                    handleChanges({
                                        target: {
                                            name: 'addressType',
                                            value: 'different',
                                        },
                                    });
                                    setAddressType('different');
                                    setWillPickup(false);

                                    let newCart = invoice;

                                    newCart.addressType = 'different';
                                    newCart.deliveryAddresErpCity = '';
                                    newCart.deliveryAddresErpCityName = '';
                                    newCart.deliveryAddressCep = '';
                                    newCart.deliveryAddressComplement = '';
                                    newCart.deliveryAddressDescription = '';
                                    newCart.deliveryAddressErpCity = '';
                                    newCart.deliveryAddressErpCityName = '';
                                    newCart.deliveryAddressErpState = '';
                                    newCart.deliveryAddressNeighborhood = '';
                                    newCart.deliveryAddressNumber = '';
                                    save(newCart);
                                    setAddress(newAddress());
                                }}
                                name={'radios'}
                                id="formHorizontalRadios3"
                                label="Utilizar um endereço diferente para a entrega"
                                type={'radio'}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <StyledDivInvoiceDelivery className="text-center mr-4">
                            {deadline && !willPickup ? (
                                <>
                                    <strong>Prazo de entrega</strong>
                                    <div className="d-block mt-2">
                                        <ReactTruckIcon
                                            fill="#707070"
                                            width="18"
                                            height="18"
                                            className="mr-1 mt-n1"
                                        />
                                        {deadline} dias úteis
                                    </div>
                                </>
                            ) : (
                                <strong>{noDeliveryMessage}</strong>
                            )}
                        </StyledDivInvoiceDelivery>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>
                                CEP{' '}
                                {loading && (
                                    <Spinner animation={'border'} size={'sm'} />
                                )}
                            </Form.Label>
                            <Form.Control
                                autoComplete={'new-password'}
                                onBlur={handleSubmit}
                                disabled={
                                    invoice.addressType === 'same' ||
                                    invoice.addressType === 'pickup' ||
                                    disabled
                                }
                                value={
                                    address?.addressCep
                                        ? address.addressCep
                                        : ''
                                }
                                as={MaskedInput}
                                mask={cepMask}
                                onChange={handleCepChanges}
                                name="addressCep"
                                type="text"
                                className={'nexen-form-control'}
                                placeholder="Informe o cep"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        {' '}
                        <Form.Group controlId="formBasicState">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                className={'form-control'}
                                style={{ display: 'none' }}
                            />
                            <ReactSelect
                                isDisabled={true}
                                onBlur={handleSubmit}
                                placeholder="Selecione..."
                                noOptionsMessage={() => 'Sem opções'}
                                name={'erpState'}
                                onChange={handleStateChanges}
                                value={
                                    address?.erpState ? address.erpState : null
                                }
                                options={states}
                                isMulti={false}
                                styles={{ control }}
                                theme={theme}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCity">
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                                className={'nexen-form-control'}
                                style={{ display: 'none' }}
                            />
                            <ReactSelect
                                isDisabled={canEditAddress}
                                onBlur={handleSubmit}
                                placeholder="Selecione..."
                                noOptionsMessage={() => 'Sem opções'}
                                name={'erpCity'}
                                onChange={handleCityChanges}
                                value={address?.erpCity}
                                options={cities}
                                isMulti={false}
                                styles={{ control }}
                                theme={theme}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Endereço</Form.Label>
                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                disabled={canEditAddress}
                                onChange={handleChanges}
                                value={
                                    address?.addressDescription
                                        ? address.addressDescription
                                        : ''
                                }
                                name="addressDescription"
                                type="text"
                                maxLength={30}
                                placeholder="Informe o endereço"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <Form.Group controlId="formBasicNeighborhood">
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                disabled={canEditAddress}
                                onChange={handleChanges}
                                value={
                                    address?.addressNeighborhood
                                        ? address.addressNeighborhood
                                        : ''
                                }
                                name="addressNeighborhood"
                                type="text"
                                maxLength={30}
                                placeholder="Informe o bairro"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={3}>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                value={
                                    address?.addressNumber
                                        ? address.addressNumber
                                        : ''
                                }
                                disabled={canEditAddress}
                                onChange={handleChanges}
                                name="addressNumber"
                                type="text"
                                maxLength={5}
                                placeholder="Informe o número"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicComplement">
                            <Form.Label>Complemento / Referência</Form.Label>
                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                disabled={canEditAddress}
                                onChange={handleChanges}
                                name="addressComplement"
                                value={
                                    address?.addressComplement
                                        ? address.addressComplement
                                        : ''
                                }
                                type="text"
                                placeholder="Informe o complemento"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
DeliveryAddress.propTypes = {
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
};
export default DeliveryAddress;
