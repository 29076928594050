/* eslint-disable import/prefer-default-export */
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background-color: #ffffff;
    box-shadow: 0px 0px 15px #cccccc;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 7vh;
`;
