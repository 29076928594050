import { PaymentMethod } from '../constants/paymentMethod';
import {
    InvoicePaycreditStatus,
    InvoiceStatus,
} from '../constants/invoiceStatus';

export default function getInvoiceStatusText(
    status: InvoiceStatus | InvoicePaycreditStatus,
    paymentMethod?: PaymentMethod,
) {
    const isFinancingOrOthers =
        paymentMethod &&
        [PaymentMethod.Financing, PaymentMethod.Others].includes(paymentMethod);

    const statusMap: Partial<
        Record<InvoiceStatus | InvoicePaycreditStatus, string>
    > = {
        [InvoiceStatus.Invoice]: 'Pedido em análise',
        [InvoiceStatus.Processing]: 'Processando',
        [InvoiceStatus.ProcessingCancellation]: 'Processando cancelamento',
        [InvoiceStatus.InvoiceApproved]: 'Pedido aprovado',
        [InvoiceStatus.InvoiceValidated]: 'Pedido validado',
        [InvoiceStatus.InvoiceReproved]: 'Pedido reprovado',
        [InvoiceStatus.InvoiceCanceled]: 'Pedido cancelado',
        [InvoiceStatus.InvoiceCanceledPaid]: 'Pedido cancelado pago',
        [InvoiceStatus.InvoicePaymentError]: 'Erro no pagamento',
        [InvoiceStatus.InvoicePayed]: isFinancingOrOthers
            ? 'Pagamento liberado'
            : 'Pagamento efetuado',
        [InvoiceStatus.InvoicePayedErp]: isFinancingOrOthers
            ? 'Pagamento avalizado'
            : 'Pagamento confirmado',
        [InvoiceStatus.InvoiceDispatched]: 'Expedido',
        [InvoiceStatus.InvoiceDeliveryEnRoute]: 'Em rota de entrega',
        [InvoiceStatus.InvoiceDeliveryInTransit]: 'Em trânsito',
        [InvoiceStatus.InvoiceSeparate]: 'Em separação',
        [InvoiceStatus.InvoiceBilled]: 'Faturado',
        [InvoiceStatus.InvoiceBillingErp]: 'Faturamento liberado',
        [InvoiceStatus.InvoiceDelivered]: 'Entregue',
        [InvoiceStatus.ConfirmingPaymentErp]: isFinancingOrOthers
            ? 'Avalizando pagamento'
            : 'Confirmando Pagamento',
        [InvoiceStatus.ConfirmingBillingErp]: 'Confirmando faturamento',
        [InvoiceStatus.InvoiceBilling]: 'Liberando faturamento',
        [InvoiceStatus.FutureSale]: 'Venda futura',
        [InvoiceStatus.WaitingApproval]: 'Em processo de Pré-Devolução',
        [InvoicePaycreditStatus.InvoiceAwaitingAnalysis]: 'Aguardando análise',
        [InvoicePaycreditStatus.InvoiceAnalysisDone]: 'Consulta realizada',
        [InvoicePaycreditStatus.InvoiceFinishedAnalysis]: 'Consulta finalizada',
        [InvoiceStatus.InvoiceAnalysis]: 'Em análise de crédito',
    };

    return statusMap[status] || '';
}
