import styled from 'styled-components';

const StyledKitPowerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.75rem;
    font-weight: 600;
    color: #707070;
    margin-top: 0.5rem;
    padding-right: 15px;
`;

export default StyledKitPowerInfo;
