import { PaymentMethod } from '../../../constants/onlineStore';
import Checkout from './Checkout';
import Pix from './Pix';

interface PaymentMethodModalProps {
    show: boolean;
    onHide: () => void;
    selectedInvoice: any;
}

export default function PaymentMethodModal({
    show,
    onHide,
    selectedInvoice,
}: PaymentMethodModalProps) {
    const isBarCodeOrCreditCard =
        selectedInvoice.paymentMethod.includes(PaymentMethod.Barcode) ||
        selectedInvoice.paymentMethod.includes(PaymentMethod.CreditCard);

    return (
        <>
            {isBarCodeOrCreditCard && (
                <Checkout
                    invoice={selectedInvoice}
                    show={show}
                    onHide={onHide}
                />
            )}
            {selectedInvoice.paymentMethod === PaymentMethod.Pix && (
                <Pix invoice={selectedInvoice} show={show} onHide={onHide} />
            )}
        </>
    );
}
