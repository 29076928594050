/* eslint-disable prefer-template */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth, AuthValues } from '../contexts/authContext';
import { CRM_URL } from '../config/crm';
import { useOnlineStore } from '../contexts/onlineStoreContext';
import { listBlackFridayRoute, listReadyKits } from './config';
import userHasRoles from '../utils/userHasRoles';
import { Role } from '../constants/role';
import { SellerStatus } from '../constants/sellerStatus';
import AuthHttpService from '../services/http/auth-http';

interface RouteWrapperProps extends RouteProps {
    component?: any;
    isPrivate?: any;
    redirectIfNotExact?: any;
    path: any;
    redirectTo?: any;
}

const RouteWrapper: React.FC<RouteWrapperProps> = ({
    component: Component,
    isPrivate = false,
    path,
    redirectTo,
    ...rest
}) => {
    const [canAccess, setCanAccess] = useState(undefined as any);
    const { user, setUser }: AuthValues = useAuth();
    const { setGroupId } = useOnlineStore();

    useEffect(() => {
        const check = async () => {
            if (isPrivate) {
                try {
                    if (!user) {
                        const response = await AuthHttpService.getUser();

                        setUser(response.data);
                    }

                    setCanAccess(true);
                } catch (err) {
                    setCanAccess(false);
                }

                return;
            }

            setCanAccess(true);
        };

        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrivate]);

    useEffect(() => {
        const isIntegrator = userHasRoles(user, [Role.Integrator]);

        if (isIntegrator && user?.seller?.status !== SellerStatus.Approved) {
            setCanAccess(false);
        }
    }, [user]);

    useEffect(() => {
        const check = async () => {
            if (
                path === listReadyKits.path ||
                path === listBlackFridayRoute.path
            ) {
                setGroupId('');
            }
        };

        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToLogin: any = () => {
        window.location.href = CRM_URL + '/login';

        return '';
    };

    return canAccess === undefined ? (
        <></>
    ) : canAccess === true && !redirectTo ? (
        <Route {...rest} path={path} component={Component} />
    ) : canAccess === true && redirectTo ? (
        <Redirect to={redirectTo} />
    ) : (
        <Route path={path} component={redirectToLogin} />
    );
};

RouteWrapper.propTypes = {
    component: PropTypes.any,
    isPrivate: PropTypes.any,
    redirectIfNotExact: PropTypes.any,
    path: PropTypes.string,
    redirectTo: PropTypes.any,
};

export default RouteWrapper;
