/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import { usePoint } from '../../../contexts/pointContext';
import {
    StyledDesc,
    StyledImage,
    StyledOverlayImage,
    StyledOverlayText,
    StyledButton,
    StyledDiv,
} from './styles';
import { IMG_SRC_URL } from '../../../config/webservice';
import cardRectangle from '../../../assets/icons/cardRectangle.svg';
import { ReactComponent as HeartIconSvg } from '../../../assets/icons/heartIcon.svg';
import { ReactComponent as UnheartIconSvg } from '../../../assets/icons/unheartIcon.svg';
import WishlistHttpService from '../../../services/http/wishlist-http';

interface Props {
    col?: number;
}

const GeneratorsCard: React.FC<Props> = ({ col }) => {
    const {
        setProducts,
        setSelectedProduct,
        products,
        term,
        setShowDetailModal,
    } = usePoint();

    async function wishlistProduct(item: any) {
        if (item.wishlist) {
            await WishlistHttpService.destroy(item.B1_COD);
            item.wishlist = false;
        } else {
            await WishlistHttpService.store(item.B1_COD);
            item.wishlist = true;
        }

        setProducts([]);
        setProducts(products);
    }

    return (
        <React.Fragment>
            {products?.map((item: any, index: any) => (
                <Col
                    className={'col-3 p-2 mb-5'}
                    key={index}
                    sm={12}
                    lg={col ? col : 4}
                    md={col ? col : 6}
                    xl={col ? col : 3}
                >
                    <Row>
                        <StyledImage
                            className={'mx-5 mt-5'}
                            onClick={() => {
                                setSelectedProduct(item);
                                setShowDetailModal(true);
                            }}
                            src={
                                item.IMG_CAPA
                                    ? `${IMG_SRC_URL}/${item.IMG_CAPA}`
                                    : ''
                            }
                        />
                        <StyledOverlayImage
                            className={'ml-2'}
                            src={cardRectangle}
                        />
                        <StyledOverlayText className={'ml-2'}>
                            {item.VLR_TOT?.toString().replace(
                                /(\d)(?=(\d{3})+(?!\d))/g,
                                '$1.',
                            )}
                        </StyledOverlayText>
                    </Row>
                    <Row className={'ml-2 mb-3 w-75'}>
                        <StyledDesc className={'ml-4 w-75'}>
                            <Highlighter
                                autoEscape
                                highlightClassName="highlight-term"
                                searchWords={[term]}
                                textToHighlight={item.B1_DESC}
                            />
                        </StyledDesc>
                    </Row>
                    <Row className={'ml-1 mt-1'}>
                        <StyledDiv className="w-100">
                            <StyledButton
                                className={'float-left ml-4 mr-2'}
                                onClick={() => {
                                    wishlistProduct(item);
                                }}
                                variant={'outline-primary'}
                            >
                                {item.wishlist ? (
                                    <UnheartIconSvg />
                                ) : (
                                    <HeartIconSvg />
                                )}
                            </StyledButton>

                            <StyledButton
                                className={'float-left'}
                                onClick={() => {
                                    setSelectedProduct(item);
                                    setShowDetailModal(true);
                                }}
                            >
                                Trocar
                            </StyledButton>
                        </StyledDiv>
                    </Row>
                </Col>
            ))}
        </React.Fragment>
    );
};

GeneratorsCard.propTypes = {
    col: PropTypes.number,
};

export default GeneratorsCard;
