/* eslint-disable no-return-await */
import Request from './request';

export default class WishlistHttpService {
    private static uri = 'wishlists';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static show(productId: number) {
        return Request.get(`${this.uri}/${productId}`);
    }

    public static store(productId: number) {
        return Request.post(`${this.uri}/${productId}`);
    }

    public static async destroy(id: number) {
        return await Request.del(`${this.uri}/${id}`);
    }
}
