/* eslint-disable @typescript-eslint/no-shadow */
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    height: 48px;
    width: 100%;
    color: #f2994a !important;
    background-color: #ffffff;
    border: 1px solid #f2994a;

    :hover {
        color: #ffffff !important;
        background-color: #f2994a;
    }

    :focus {
        background-color: #ffffff;
        border: 1px solid #f2994a;
        box-shadow: none;
    }

    :focus:hover {
        color: #ffffff !important;
        background-color: #f2994a;
    }

    :active {
        color: #ffffff !important;
        background-color: #f2994a !important;
    }

    :disabled {
        color: #f2994a !important;
        background-color: #ffffff;
        border: 1px solid #f2994a;
    }

    :disabled:active {
        background-color: #ffffff !important;
    }

    @media (max-width: 767px) {
        margin-top: 16px;
    }
`;

export const control = {
    control: (base: any, state: any) => ({
        ...base,
        height: 48,
        fontSize: 12,
        border: state.isFocused
            ? '1px solid rgba(242, 153, 74, 0.233)'
            : '1px solid #ced4da',
        borderRadius: '4px',
        boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
        backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',

        '&:hover': {
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.30)',
        },
    }),
    menu: (base: any) => ({
        ...base,
        marginTop: 0,
    }),
};

export const theme = (theme: any) => ({
    ...theme,

    colors: {
        ...theme.colors,
        primary: 'rgba(242, 153, 74, 0.8)',
        primary25: 'rgba(242, 153, 74, 0.4)',
        boxShadow: 'none',
    },
});
