import styled from 'styled-components';

export const StyledTitle = styled.h4`
    font-size: 22px;
    font-weight: 700;
`;

export const StyledLinkAvatar = styled.a`
    color: #2f80ed;
    font-size: 0.75rem;
    cursor: pointer;
`;
