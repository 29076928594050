import { Button, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledSpan = styled.span`
    font-size: 12px !important;
`;

export const StyledRow = styled(Row)`
    background: #fafafa;
    border-radius: 4px;
    margin: 1px;
`;

export const StyledLink = styled.a`
    color: #2f80ed;
    font-size: 0.75rem;
    cursor: pointer;
`;

export const StyledDivInvoiceDelivery = styled.div`
    background: #f2f2f2;
    width: 140px;
    float: left;
    padding: 4px;
    font-size: 12px;
`;

export const StyledCloseButton = styled(Button)`
    width: 200px;
`;
