import BannerLocation from '../../constants/bannerLocation';
import Request from './request';

export default class BannerHttpService {
    private static uri = 'banners';

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
        active?: boolean;
        location?: BannerLocation;
    }) {
        return Request.get(`${this.uri}`, params);
    }
}
