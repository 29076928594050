import { FormEvent, useRef } from 'react';
import { Form, Col, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledConfirmButton, StyledCloseButton } from './styles';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import { invoicesListRoute } from '../../../../routes/config';
import history from '../../../../services/history';
import InvoiceSummary from '../../../Invoices/InvoiceSummary';
import { getPaymentMethodText } from '../../../../utils/getPaymentMethodText';
import { InvoiceStatus } from '../../../../constants/invoiceStatus';

interface ConfirmPaymentInvoiceModalProps {
    show: boolean;
    option: string;
    invoice: any;
    onHide: () => void;
}

export default function ConfirmPaymentInvoiceModal({
    show,
    option,
    invoice,
    onHide,
}: ConfirmPaymentInvoiceModalProps) {
    const formRef = useRef<HTMLFormElement>(null);

    let modalTitle: string;
    let submitButtonText: string;
    let errorMessage: string;
    let successMessage: string;

    switch (option) {
        case InvoiceStatus.InvoiceBilling:
            modalTitle = 'Confirmar faturamento';
            submitButtonText = 'Confirmar';
            errorMessage = 'Ocorreu um erro ao confirmar o faturamento';
            successMessage = 'Pagamento confirmado com sucesso';
            break;
        default:
            modalTitle = 'Liberar expedição';
            submitButtonText = 'Liberar';
            errorMessage = 'Ocorreu um erro ao liberar a expedição';
            successMessage = 'Expedição liberada com sucesso';
    }

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        try {
            await InvoiceHttpService.confirmPayment(invoice?.id, { option });

            toast.success(successMessage);

            onHide();

            history.push(invoicesListRoute.path);
        } catch (err: any) {
            if (isFriendlyHttpError(err)) {
                toast.error(err.message);
            }

            toast.error(errorMessage);
        }
    };

    return (
        <Modal
            size="lg"
            aria-labelledby="modal-confirm-payment"
            centered
            onExit={() => false}
            show={show}
            onHide={onHide}
        >
            <Form ref={formRef} onSubmit={handleFormSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="modal-confirm-payment">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nome do cliente</Form.Label>
                                <Form.Control
                                    disabled
                                    value={invoice?.customerName}
                                    name="customerName"
                                    type="text"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPaymentMethod">
                                <Form.Label>
                                    Forma de pagamento selecionada
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    value={getPaymentMethodText(
                                        invoice?.paymentMethod,
                                    )}
                                    name="paymentMethod"
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="1" />
                        <Col md={4}>
                            <InvoiceSummary invoice={invoice} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <StyledCloseButton onClick={onHide}>
                        Fechar
                    </StyledCloseButton>
                    <StyledConfirmButton type="submit">
                        {submitButtonText}
                    </StyledConfirmButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
