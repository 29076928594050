import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledSectionTitle = styled.h5`
    font-weight: bold;
    margin-bottom: 15px;
`;

export const StyledDataHeader = styled.h6`
    font-size: 12px;
    color: #a4a4a4;
    margin-bottom: 5px;
`;

export const StyledData = styled.p`
    font-size: 14px !important;
    color: #707070;
    margin-bottom: 15px;

    > :hover {
        cursor: pointer;
        color: #f2994a;
    }
`;

export const StyledWarningMessage = styled.p`
    font-size: 10px !important;
    margin-bottom: 5px;
    color: #f2994a;
    font-weight: bold;
`;

export const StyledFooterDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 9px;
    margin-bottom: 10px;
`;

export const StyledButtonDiv = styled.div`
    display: flex;
    justify-content: flex-end;

    * {
        margin-left: 15px;
    }
`;

export const StyledApproveButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
        width: 62%;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
        width: 54%;
    }

    @media (min-width: 1281px) and (max-width: 1440px) {
        width: 46%;
    }

    @media (min-width: 1441px) and (max-width: 1800px) {
        width: 38%;
    }

    @media (min-width: 1801px) {
        width: 30%;
    }

    span {
        margin: 0px 15px 0px 10px;
    }
`;
