/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MaskedInput from 'react-text-mask';
import CurrencyInput from 'react-currency-input-field';
import { cpfMask, cnpjMask, phoneMask, rgMask } from '../../../../utils/masks';
import {
    schoolingsItems,
    sexItems,
    maritalStatusItems,
} from '../../../../constants/onlineStore';
import DatePicker from '../../../DatePicker';

const Representative = (props: any) => {
    dayjs.extend(utc);

    const handlePersonBirthdate = (value: any) => {
        props.handleChanges([
            { target: { name: 'personBirthdate', value: value } },
        ]);
    };

    return (
        <Form
            style={{
                width: '100%',
            }}
        >
            <fieldset disabled={props.disabled}>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Nome*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personName}
                                onChange={props.handleChanges}
                                name="personName"
                                type="text"
                                max={200}
                                min={0}
                                value={props.financing.personName}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>CPF*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personCpf}
                                onChange={props.handleChanges}
                                name="personCpf"
                                type="text"
                                as={MaskedInput}
                                mask={cpfMask}
                                value={props.financing.personCpf}
                            />
                            {props.errors.personCpf && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.personCpf}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>RG*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personRg}
                                onChange={props.handleChanges}
                                name="personRg"
                                type="text"
                                as={MaskedInput}
                                mask={rgMask}
                                value={props.financing.personRg}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>UF/Orgão Emissor*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personRgIssuer}
                                onChange={props.handleChanges}
                                name="personRgIssuer"
                                type="text"
                                value={props.financing.personRgIssuer}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Data de Nascimento*</Form.Label>
                            {/* <Form.Control
                            className={'nexen-form-control'}
                            style={{ display: 'none' }}
                            isInvalid={!!props.errors.personBirthdate}
                        /> */}
                            <DatePicker
                                onSelect={handlePersonBirthdate}
                                onChange={handlePersonBirthdate}
                                value={
                                    props.financing.personBirthdate
                                        ? new Date(
                                              props.financing.personBirthdate,
                                          )
                                        : null
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Sexo*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personSex}
                                onChange={props.handleChanges}
                                name="personSex"
                                type="text"
                                as="select"
                                value={props.financing.personSex}
                            >
                                <option value="">Selecione</option>
                                {sexItems.map((state) => (
                                    <option
                                        key={state.value}
                                        value={state.value}
                                    >
                                        {state.desc}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Estado Civil*</Form.Label>
                            <Form.Control
                                onChange={props.handleChanges}
                                name="personCivilStatus"
                                type="text"
                                isInvalid={!!props.errors.personCivilStatus}
                                as={'select'}
                                value={props.financing.personCivilStatus}
                            >
                                <option value="">Selecione</option>
                                {maritalStatusItems.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>
                                Cidade e estado em que nasceu*
                            </Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personBirthStateCity}
                                onChange={props.handleChanges}
                                name="personBirthStateCity"
                                type="text"
                                placeholder={'Criciúma / Santa Catarina'}
                                value={props.financing.personBirthStateCity}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Nome do pai*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personFatherName}
                                onChange={props.handleChanges}
                                name="personFatherName"
                                type="text"
                                max={200}
                                min={0}
                                value={props.financing.personFatherName}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Nome da mãe*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personMotherName}
                                onChange={props.handleChanges}
                                name="personMotherName"
                                type="text"
                                max={200}
                                min={0}
                                value={props.financing.personMotherName}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Escolaridade*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personSchooling}
                                onChange={props.handleChanges}
                                name="personSchooling"
                                as="select"
                                value={props.financing.personSchooling}
                            >
                                <option value="">Selecione</option>
                                {schoolingsItems.map((state) => (
                                    <option
                                        key={state.value}
                                        value={state.value}
                                    >
                                        {state.desc}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Valor total do patrimônio*</Form.Label>
                            <Form.Control
                                as={CurrencyInput}
                                intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                }}
                                isInvalid={!!props.errors.personPatrimony}
                                onValueChange={props.handleCurrencyChanges}
                                name="personPatrimony"
                                value={props.financing.personPatrimony}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Renda mensal comprovada*</Form.Label>
                            <Form.Control
                                as={CurrencyInput}
                                intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                }}
                                isInvalid={!!props.errors.personMontlyIncome}
                                onValueChange={props.handleCurrencyChanges}
                                name="personMontlyIncome"
                                value={props.financing.personMontlyIncome}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Telefone com DDD</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personPhone}
                                as={MaskedInput}
                                mask={phoneMask}
                                onChange={props.handleChanges}
                                name="personPhone"
                                type="text"
                                value={props.financing.personPhone}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Celular com DDD*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personCellphone}
                                as={MaskedInput}
                                mask={phoneMask}
                                onChange={props.handleChanges}
                                name="personCellphone"
                                type="text"
                                value={props.financing.personCellphone}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Natureza da ocupação*</Form.Label>
                            <Form.Control
                                isInvalid={
                                    !!props.errors.personProfessionNature
                                }
                                onChange={props.handleChanges}
                                name="personProfessionNature"
                                type="text"
                                max={200}
                                min={0}
                                value={props.financing.personProfessionNature}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Profissão*</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personProfession}
                                onChange={props.handleChanges}
                                name="personProfession"
                                type="text"
                                max={200}
                                min={0}
                                value={props.financing.personProfession}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>
                                Nome do empregador ou empresa*
                            </Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personCompanyName}
                                onChange={props.handleChanges}
                                name="personCompanyName"
                                type="text"
                                max={200}
                                min={0}
                                value={props.financing.personCompanyName}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>
                                CNPJ do empregador ou empresa*
                            </Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personCompanyCnpj}
                                onChange={props.handleChanges}
                                name="personCompanyCnpj"
                                type="text"
                                as={MaskedInput}
                                mask={cnpjMask}
                                value={props.financing.personCompanyCnpj}
                            />
                            {props.errors.personCompanyCnpj && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.personCompanyCnpj}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                isInvalid={!!props.errors.personEmail}
                                onChange={props.handleChanges}
                                name="personEmail"
                                type="text"
                                value={props.financing.personEmail}
                            />
                            {props.errors.personEmail && (
                                <Form.Control.Feedback type="invalid">
                                    {props.errors.personEmail}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
        </Form>
    );
};

export default Representative;
