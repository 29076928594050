export default function parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    const parsedDate = new Date(year, month - 1, day);

    if (Number.isNaN(parsedDate.getTime())) {
        return new Date();
    }

    return parsedDate;
}
