/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from 'react';
import PointHttpService from '../services/http/point-http';
import { AuthValues, useAuth } from './authContext';
import { SellerValues, useSeller } from './sellerContext';

export interface SellerCategory {
    name: string;
    nextCategoryName: string;
    nextCategoryRequirement: number;
}

const PointContext = createContext<any>({});
const usePoint = () => useContext(PointContext);

function PointProvider(props: any) {
    const { user }: AuthValues = useAuth();
    const { seller }: SellerValues = useSeller();

    const [sellerCategory, setSellerCategory] = useState();
    const [tradeableBalance, setTradeableBalance] = useState();
    const [qualifiableBalance, setQualifiableBalance] = useState();

    const [term, setTerm] = useState('');
    const [viewType, setViewType] = useState('card');
    const [regulationPage, setRegulationPage] = useState(0);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showTradeInfoModal, setShowTradeInfoModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});

    function formatNumber(num: any) {
        const formatedValue = num ?? 0;

        return formatedValue
            ?.toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    async function loadSellerCategory(): Promise<void> {
        let response = await PointHttpService.showSellerCategory();

        response.data.nextCategoryRequirement = formatNumber(
            response.data.nextCategoryRequirement,
        );

        setSellerCategory(response.data);
    }

    async function loadQualifiableBalance(): Promise<void> {
        const response = await PointHttpService.showQualifiableBalance();

        setQualifiableBalance(formatNumber(response.data.value));
    }

    async function loadTradeableBalance(): Promise<void> {
        const response = await PointHttpService.showTradeableBalance();

        setTradeableBalance(formatNumber(response.data.value));
    }

    async function loadBalanceAndCategory(): Promise<void> {
        loadTradeableBalance();
        loadQualifiableBalance();
        loadSellerCategory();
    }

    useEffect(() => {
        if (
            seller?.pointsEnabled ||
            user?.seller?.pointsEnabled ||
            user?.owner?.pointsEnabled
        ) {
            loadBalanceAndCategory();
        }
    }, [seller, user]);

    return (
        <PointContext.Provider
            value={{
                sellerCategory,
                products,
                setProducts,
                selectedProduct,
                setSelectedProduct,
                term,
                setTerm,
                viewType,
                setViewType,
                regulationPage,
                setRegulationPage,
                showDetailModal,
                setShowDetailModal,
                showTradeInfoModal,
                setShowTradeInfoModal,
                tradeableBalance,
                qualifiableBalance,
                loadBalanceAndCategory,
            }}
            {...props}
        />
    );
}

export { PointProvider, usePoint };
