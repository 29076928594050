import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { control, theme } from '../../styles/react-select-config';

const StyledSelect = styled.div`
    .valid-select > div {
        border-color: #28a745;
    }
    .invalid-select > div {
        border-color: #dc3545;
    }
`;

export interface SelectOption<T = string | number> {
    value: T;
    label: string;
}

const Select = forwardRef(
    ({
        isValid,
        isInvalid,
        placeholder = 'Selecione...',
        name,
        onChange,
        value,
        options,
        isMulti,
        path,
        disabled,
        onInputChange,
        noOptionsMessage = 'Sem opções',
        isLoading,
        isClearable,
        filterOption,
        loadingMessage = 'Carregando...',
    }: any) => {
        let className = '';
        if (isValid) {
            className = 'valid-select';
        } else if (isInvalid) {
            className = 'invalid-select';
        }

        return (
            <StyledSelect>
                <ReactSelect
                    placeholder={placeholder || 'Selecione...'}
                    noOptionsMessage={() => noOptionsMessage}
                    name={name}
                    onChange={onChange}
                    value={value}
                    options={options}
                    isMulti={isMulti}
                    styles={{ control }}
                    theme={theme}
                    className={className}
                    path={path}
                    onInputChange={onInputChange}
                    isDisabled={disabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    filterOption={filterOption}
                    loadingMessage={() => loadingMessage}
                />
            </StyledSelect>
        );
    },
);

export default Select;
