import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { StyledTitle } from './styles';

const SecurityData = () => {
    const { register, errors, getValues } = useFormContext();

    const strongPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*$/;

    return (
        <>
            <StyledTitle>Senha e segurança</StyledTitle>
            <hr />

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group controlId="securityDataForm.password">
                        <Form.Label>Cadastrar nova senha</Form.Label>
                        <Form.Control
                            ref={register({
                                minLength: {
                                    value: 8,
                                    message:
                                        'A senha deve conter no mínimo 8 caracteres',
                                },
                                pattern: {
                                    value: strongPasswordRegex,
                                    message:
                                        'A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais',
                                },
                            })}
                            name="password"
                            type="password"
                            isInvalid={!!errors.password}
                        />
                        {errors?.password && (
                            <Form.Control.Feedback type="invalid">
                                {errors.password.message}{' '}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group controlId="securityDataForm.confirmPassword">
                        <Form.Label>Confirmar nova senha</Form.Label>
                        <Form.Control
                            ref={register({
                                validate: (value) =>
                                    value === getValues().password ||
                                    'Senhas não coincidem',
                            })}
                            isInvalid={!!errors.password_confirmation}
                            name="password_confirmation"
                            type="password"
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.password_confirmation?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export default SecurityData;
