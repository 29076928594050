/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import * as Yup from 'yup';

function validateCPF(cpf: string) {
    cpf = cpf.replace(/\D/g, '');

    let sum;
    let rest;

    sum = 0;

    if (cpf === '00000000000') {
        return false;
    }

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
    }

    return true;
}

export const partnerRules = {
    partnerName: Yup.string().required('Nome do sócio é obrigatório'),
    partnerDocument: Yup.string()
        .required('Campo obrigatório')
        .test('invalid', 'CPF inválido', (value) => validateCPF(value)),
    partnerBirthDate: Yup.string().required('Data de nascimento é obrigatório'),
    partnerEmail: Yup.string()
        .required('Email é obrigatório')
        .email('E-mail inválido'),
    partnerPhone: Yup.string().required('Telefone é obrigatório'),
};

export default partnerRules;
