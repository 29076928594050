import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background-color: #ffffff !important;
`;

export const StyledTh = styled.th`
    background-color: #fafafa;
`;
