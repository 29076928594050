/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Col, Row, InputGroup, Form, Spinner } from 'react-bootstrap';
import { StyledRow } from './styles';

import BaseLayout from '../../../../components/BaseLayout';
import SideNavBar from '../../../../components/Points/SideNavBar/SideNavBar';
import BalanceCard from '../../../../components/Points/BalanceCard/BalanceCard';
import InfoCard from '../../../../components/Points/InfoCard/InfoCard';
import PointTable from '../../../../components/Points/PointTable/PointTable';

import { SellerValues, useSeller } from '../../../../contexts/sellerContext';
import PointHttpService from '../../../../services/http/point-http';

const PointStatement: React.FC = () => {
    let rowsPerPage = 25;
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [term, setTerm] = useState('');
    const [points, setPoints] = useState([]);
    const { seller }: SellerValues = useSeller();

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage((p: any) => (p += 1)),
    });

    async function loadPoints(): Promise<void> {
        setLoading(true);

        if (seller) {
            const response = await PointHttpService.index({
                term: term,
                skip: page - 1,
                take: rowsPerPage,
                sellerId: seller.id,
            });

            const totalItems = response.data.meta.total;

            setPoints((points: any) => [...points, ...response.data.points]);
            setHasNextPage(+points?.length >= +totalItems ? false : true);
        } else {
            const response = await PointHttpService.index({
                term: term,
                skip: page - 1,
                take: rowsPerPage,
            });

            const totalItems = response.data.meta.total;

            setPoints((points: any) => [...points, ...response.data.points]);
            setHasNextPage(+points?.length >= +totalItems ? false : true);
        }

        setLoading(false);
    }

    useEffect(() => {
        setIsFirstRender(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setPoints([]);
                setPage(1);
                loadPoints();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadPoints();
        }
    }, [page]);

    useEffect(() => {
        setPoints([]);
        setPage(1);
        loadPoints();
    }, [seller]);

    return (
        <BaseLayout>
            <Row style={{ height: '100vh' }}>
                <SideNavBar />

                <Col className="ml-5 mt-5 mb-5 mr-5">
                    <StyledRow className="ml-0">
                        <BalanceCard tradeable qualifiable slim />
                    </StyledRow>

                    <StyledRow className="ml-0">
                        <InfoCard />
                    </StyledRow>

                    <StyledRow className="ml-0">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className="fas fa-search"></i>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                value={term}
                                onChange={(event) =>
                                    setTerm(event.target.value)
                                }
                                placeholder="Digite aqui o que procura..."
                            />
                        </InputGroup>
                    </StyledRow>
                    <PointTable
                        infiniteRef={infiniteRef}
                        points={points}
                        term={term}
                    />
                    <StyledRow className="mx-4">
                        <br />
                        {loading && hasNextPage && (
                            <Col md={12} className="text-center">
                                <Spinner animation={'border'} />
                            </Col>
                        )}

                        {!hasNextPage && !loading && (
                            <Col md={12} className="text-center">
                                <strong style={{ color: '#adadad' }}>
                                    Sem mais itens para carregar
                                </strong>
                            </Col>
                        )}
                    </StyledRow>
                </Col>
            </Row>
        </BaseLayout>
    );
};

export { PointStatement };
