/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Row, Spinner, Table, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PointHttpService from '../../../services/http/point-http';

interface Props {
    show: boolean;
    onHide(): void;
    pointVisualization: number;
    setPointVisualization(e: any): void;
}

export function TradeModal(props: Props) {
    const [loading, setLoading] = useState(false);
    const [trade, setTrade] = useState<any>(null);

    async function loadPointTraded() {
        try {
            setLoading(true);

            const { data } = await PointHttpService.showTrade(
                props.pointVisualization,
            );

            setTrade(data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!props.show) return;
        if (trade) setTrade(null);
        loadPointTraded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    useEffect(() => {
        if (!loading && !trade && props.show) {
            toast.error('Não é possível visualizar esta troca!');
            props.onHide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trade, loading]);

    return !loading && trade ? (
        <Modal {...props} size={'xl'} centered style={{ overflow: 'auto' }}>
            <Modal.Header closeButton>
                <Modal.Title
                    className={'ml-3'}
                    id="contained-modal-title-vcenter"
                >
                    Troca #{trade.point.id} -{' '}
                    {new Date(trade.point.createdAt).toLocaleDateString()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'mt-3 overflow-auto'}>
                <h5>Identificação do cliente</h5>
                <Table striped bordered hover className={'responsive'}>
                    <thead>
                        <tr>
                            <th>Integrador(a) responsável</th>
                            <th>Nome do cliente</th>
                            <th>CPF/CNPJ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{trade.point.seller.name}</td>
                            <td>{trade.point.seller.user.name}</td>
                            <td>{trade.point.seller.cnpj}</td>
                        </tr>
                    </tbody>
                </Table>
                <h5>Resumo da troca</h5>
                <Table striped bordered hover className={'responsive'}>
                    <thead>
                        <tr>
                            <th>Equipamentos</th>
                            <th>Descrição</th>
                            <th>Quantidade</th>
                            <th>Valor em pontos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{trade.products[0].CAT_DESC}</td>
                            <td>{trade.products[0].B1_DESC}</td>
                            <td>1</td>
                            <td>{Math.round(trade.products[0].VLR_TOT)}</td>
                        </tr>
                    </tbody>
                </Table>
                <h5>Endereço de entrega</h5>
                <Table striped bordered hover className={'responsive'}>
                    <thead>
                        <tr>
                            <th>CEP</th>
                            <th>Estado</th>
                            <th>Cidade</th>
                            <th>Endereço</th>
                            <th>Bairro</th>
                            <th>Número</th>
                            <th>Complemento/Referência</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{trade.point.seller.addressCep}</td>
                            <td>{trade.point.seller.erpState}</td>
                            <td>{trade.point.seller.erpCityName}</td>
                            <td>{trade.point.seller.addressDescription}</td>
                            <td>{trade.point.seller.addressNeighborhood}</td>
                            <td>{trade.point.seller.addressNumber}</td>
                            <td>{trade.point.seller.addressComplement}</td>
                        </tr>
                    </tbody>
                </Table>
                <h5>Saldo de pontos</h5>
                <Table striped bordered hover className={'responsive'}>
                    <thead>
                        <tr>
                            <th>Antes da troca</th>
                            <th>Após troca</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{trade.point.balance + trade.point.value}</td>
                            <td>{trade.point.balance}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className={'float-right'}
                    onClick={props.onHide}
                    disabled={false}
                >
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <Modal {...props} size={'sm'} centered>
            <Modal.Body>
                <Row className={'align-items-center justify-content-center'}>
                    <Spinner
                        animation={'border'}
                        size={'sm'}
                        style={{ color: '#666' }}
                    />
                    <h5 className={'align-items-center text-center pt-2 pl-2'}>
                        Carregando...
                    </h5>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
