/* eslint-disable no-nested-ternary */
import { Badge, Button, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { InvoiceStatus } from '../../constants/invoiceStatus';

interface Props {
    status: string;
}

export const StyledTitle = styled.h3`
    font-size: 24px !important;
    font-weight: bolder !important;
`;

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledNavLink = styled(Nav.Link)`
    color: inherit !important;
    font-size: 1rem !important;

    :active {
        color: red;
    }
`;

export const StyledBadge = styled(Badge)<Props>`
    background: rgba(235, 87, 87, 0.1);
    color: ${(props: any) =>
        props.status === InvoiceStatus.InvoiceApproved
            ? '#219653'
            : props.status === InvoiceStatus.InvoiceReproved ||
              props.status === InvoiceStatus.InvoiceCanceled
            ? '#EB5757'
            : props.status === InvoiceStatus.Invoice
            ? '#2F80ED'
            : ''};
`;

export const StyledDivInvoiceType = styled.div`
    background: #f2f2f2;
    width: 140px;
    float: left;
    padding: 4px;
    font-size: 12px;
    min-height: 63px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButtonsDiv = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 767px) {
        justify-content: space-between;
    }

    @media (min-width: 768px) {
        justify-content: flex-end;
    }
`;

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: ${(props) => (props.$hasSideButton ? '10px' : '0')};

    @media (max-width: 767px) {
        width: ${(props) =>
            props.$hasSideButton || props.$hasSideButton === undefined
                ? '49%'
                : '100%'};
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        width: ${(props) =>
            props.$hasSideButton || props.$hasSideButton === undefined
                ? '49%'
                : '75%'};
    }

    @media (min-width: 1200px) {
        width: ${(props) =>
            props.$hasSideButton || props.$hasSideButton === undefined
                ? '40%'
                : '50%'};
    }
`;

export const StyledStatusBadge = styled(Badge)<{ $approved?: boolean }>`
    background-color: ${(props) => (props.$approved ? '#6fcf97' : '#febf00')};
    color: #fff;
    margin-right: 10px;
    padding: 6px 8px 6px 8px;
    font-size: 12px;
`;
