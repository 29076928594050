import styled from 'styled-components';

interface Props {
    background: string;
}
interface PropsTitle {
    color: string;
}

export const StyledTitleNav = styled.h6<PropsTitle>`
    font-size: 12px !important;
    font-weight: 900 !important;
    color: ${(p) => p.color};
`;

export const StyledSubTitleNav = styled.h6<PropsTitle>`
    font-size: 12px !important;
    color: ${(p) => p.color};
`;

export const StyledModalSub = styled.p`
    font-size: 10px;
    line-height: 15px;
    /* identical to box height, or 150% */

    letter-spacing: 0.25px;

    color: #707070;

    mix-blend-mode: normal;
`;

export const StyledSquare = styled.div<Props>`
    width: 25px;
    text-align: center;
`;

export const StyledOutlinedSquare = styled.div`
    cursor: pointer;
    width: 54px;
    height: 54px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background-color: #f2994a;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

export const StyledBottomText = styled.h6`
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #ffffff;
`;

export const StyledValueBottomText = styled.h6`
    font-weight: 600;
    text-align: left;
    font-size: 14px;

    color: #ffffff;
`;

export const StyledFooter = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f2994a;
    text-align: center;
    z-index: 10;
`;

export const StyledSideCart = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    left: 75%;
    bottom: 0;
    height: 92%;
    width: 25%;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 9;
`;

export const StyledSideCartValue = styled.h6`
    font-weight: 600;
    font-size: 16px;
    /* identical to box height */

    color: #707070;
`;

export const StyledSideCartSubtitle = styled.h6`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #707070;
`;

export const StyledPaymentTitle = styled.h6`
    font-weight: bold;
    font-size: 0.75rem;
    color: #707070;
`;
