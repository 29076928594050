/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';

const InvoicePaycreditContext = createContext<any>({});
const useInvoicePaycredit = () => useContext(InvoicePaycreditContext);

function InvoicePaycreditProvider(props: any) {
    const { control, watch, reset, formState } = useForm({
        shouldUnregister: false,
        defaultValues: {},
    });

    const formData = watch();

    return (
        <InvoicePaycreditContext.Provider
            value={{
                control,
                watch,
                reset,
                formState,
                formData,
            }}
            {...props}
        />
    );
}

export { InvoicePaycreditProvider, useInvoicePaycredit };
