import { Button, Card } from 'react-bootstrap';
import styled from 'styled-components';

interface Props {
    status: string;
}

export const StyledCardBody = styled(Card.Body)`
    min-height: 150px;
`;

export const StyledCardText = styled(Card.Text)`
    font-size: 10px !important;
`;

export const StyledCardBudget = styled.div<Props>`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 5px !important;
    border-color: ${(props: any) =>
        props.status === 'BUDGET' ? '#F2994A' : ''};
`;

export const StyledCardInvoice = styled.div<Props>`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 5px !important;
    border-color: ${(props: any) =>
        props.status === 'INVOICE' ? '#F2994A' : ''};
`;

export const StyledConfirmButton = styled(Button)`
    width: 200px;
`;

export const StyledCancelButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #f2994a;
    color: #f2994a !important;

    :hover {
        background-color: #f2994a;
        color: #ffffff !important;
    }
`;
