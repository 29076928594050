/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-fragments */
/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import MaskedInput from 'react-text-mask';
import {
    StyledTitle,
    StyledSubtitle,
    StyledText,
    StyledDivider,
    StyledDetailValue,
    StyledNumericInput,
} from './styles';

import toc from '../../assets/icons/toc.png';

import ProductHttpService from '../../services/http/product-http';
import Base from '../../components/OnlineStore/Base';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import { IMG_SRC_URL } from '../../config/webservice';
import { getSelectedSeller } from '../../utils/getSelectedSeller';
import { cepMask } from '../../utils/masks';

interface Product {
    BM_GRUPO: string;
    BM_DESC: string;
    B1_COD: string;
    B1_DESC: string;
    VLR_TOT: string;
    DETALHES: String;
    FT: any;
    IMG_CAPA: string;
}

const Detail: React.FC = () => {
    const [product, setProduct] = useState({} as Product);
    const [productPrice, setProductPrice] = useState('');
    const { setViewType, setSelectedProduct, productType } = useOnlineStore();
    const { id } = useParams<{ id: string }>();

    const seller = getSelectedSeller();

    useEffect(() => {
        if (!product?.VLR_TOT) {
            return;
        }

        let re = new RegExp('^-?\\d+(?:.\\d{0,' + (2 || -1) + '})?');
        const value = product.VLR_TOT.match(re)[0];

        setProductPrice(
            parseFloat(value).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            }),
        );
    }, [product]);

    useEffect(() => {
        setViewType('detail');

        return () => {
            setSelectedProduct({});
            setViewType('card');
        };
    }, [setSelectedProduct, setViewType]);

    const loadProducts = async () => {
        const response = await ProductHttpService.show({
            sellerId: seller ? seller.erpCode : '0',
            productId: id,
            type: productType,
        });

        setProduct(response.data.data.products[0]);
    };

    useEffect(() => {
        loadProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Base
            productType={'PA'}
            content={
                !product ? (
                    <div
                        className={'mx-auto'}
                        style={{
                            marginTop: '20%',
                        }}
                    >
                        <StyledTitle decoration={'none'}>
                            Sem detalhes para exibição
                        </StyledTitle>
                    </div>
                ) : (
                    <React.Fragment>
                        <StyledDivider />
                        <Row>
                            <h6
                                className={'mx-lg-n3'}
                                style={{
                                    fontWeight: 600,
                                    fontSize: 18,
                                    color: '#F2994A',
                                }}
                            >
                                Ficha Técnica
                            </h6>
                        </Row>
                        <Row>
                            <Col style={{ paddingLeft: '10%' }} xl={6} lg={12}>
                                <br />
                                <br />
                                <Row>
                                    <div className={'mx-xl-n5 gallery'}>
                                        <ImageGallery
                                            thumbnailPosition={'left'}
                                            showNav={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            items={[
                                                {
                                                    original: product?.IMG_CAPA
                                                        ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                        : '',
                                                    thumbnail: product?.IMG_CAPA
                                                        ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                        : '',
                                                },
                                                {
                                                    original: product?.IMG_CAPA
                                                        ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                        : '',
                                                    thumbnail: product?.IMG_CAPA
                                                        ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                        : '',
                                                },
                                            ]}
                                        />
                                    </div>
                                </Row>
                                <br />
                                <br />
                                <br />
                                <br />
                                <Row>
                                    <StyledTitle
                                        decoration={'underline'}
                                        style={{ color: '#707070' }}
                                    >
                                        {product.B1_DESC}
                                    </StyledTitle>
                                </Row>
                                <br />
                                <Row>
                                    <StyledSubtitle>Descrição</StyledSubtitle>
                                </Row>
                                <Row>
                                    <StyledText style={{ width: '60%' }}>
                                        {product.DETALHES}
                                    </StyledText>
                                </Row>
                                <br />
                                <Row>
                                    <StyledSubtitle>
                                        Ficha Técnica
                                    </StyledSubtitle>
                                </Row>
                                <Row>
                                    <Table style={{ width: '80%' }} striped>
                                        <thead></thead>
                                        <tbody>
                                            {product.FT &&
                                                product.FT.map(
                                                    (item: any, index: any) => (
                                                        <tr key={index}>
                                                            {' '}
                                                            <td>{`${item.ATRIB} - ${item.VALOR}`}</td>
                                                        </tr>
                                                    ),
                                                )}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Col>
                            <Col xl={3} lg={12}>
                                <StyledDetailValue>
                                    {productPrice}
                                </StyledDetailValue>
                                <Form.Group style={{ width: '40%' }}>
                                    <Form.Label style={{ color: '#707070' }}>
                                        Quantidade
                                    </Form.Label>
                                    <div>
                                        <StyledNumericInput
                                            // style={{
                                            //     'input:focus': {
                                            //         outline: 'none',
                                            //         borderColor: '#f2994a',
                                            //         boxShadow:
                                            //             '0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f2994a ',
                                            //     },
                                            // }}
                                            min={0}
                                            max={999}
                                            step={1}
                                            size={5}
                                            mobile
                                        />
                                    </div>
                                </Form.Group>
                                <Row>
                                    <StyledSubtitle style={{ marginLeft: 15 }}>
                                        Calcular prazo
                                    </StyledSubtitle>
                                    <div
                                        style={{
                                            float: 'left',
                                            display: 'flex',
                                        }}
                                    >
                                        <Form
                                            className={'ml-3 mb-3'}
                                            style={{ height: 50 }}
                                        >
                                            <InputGroup
                                                size="lg"
                                                //style={{ width: '156vh' }}
                                                className="mb-5"
                                            >
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text
                                                        style={{
                                                            backgroundColor:
                                                                '#ffffff',
                                                            border: '1px solid #F2994A',
                                                        }}
                                                    >
                                                        <img src={toc} alt="" />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    type="text"
                                                    style={{
                                                        border: '1px solid #E0E0E0',
                                                        borderLeftColor:
                                                            '#F2994A',
                                                    }}
                                                    as={MaskedInput}
                                                    mask={cepMask}
                                                    placeholder="Digite o CEP"
                                                />
                                            </InputGroup>
                                        </Form>
                                        <Button
                                            size="lg"
                                            className={'d-block ml-2 mb-3'}
                                        >
                                            <i className="fas fa-search"></i>
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )
            }
        />
    );
};

export { Detail };
