/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import React, { useState } from 'react';
import { BiSolidConversation } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';
import { PiCaretLeftFill } from 'react-icons/pi';
import { FloatCloseContainer, FloatContainer } from './styles';

interface Props {
    consultantNumber: string;
    showFloat: boolean;
    toggle: () => void;
}

const FloatConsultantContactButton = (props: Props) => {
    const { consultantNumber, showFloat, toggle: handleToggleFloat } = props;
    const [open, setOpen] = useState(false);
    const mouseEnter = () => setOpen(true);
    const mouseLeave = () => setOpen(false);

    const handleClick = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=${consultantNumber}&text=Olá,%20Nexen.%20Estou%20no%20portal%20e%20preciso%20de%20ajuda.`,
            '_blank',
        );
    };

    return (
        <>
            <FloatContainer
                showFloat={showFloat}
                className="float-container"
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
            >
                {showFloat && (
                    <li className="float-button" onClick={handleToggleFloat}>
                        <BiSolidConversation />
                        <span className="tooltip">Fale com um consultor</span>
                    </li>
                )}
                <li
                    className={cn('float-action', { open })}
                    onClick={() => handleClick()}
                >
                    <BsWhatsapp />
                    <span className="tooltip">WhatsApp</span>
                </li>
            </FloatContainer>
            {!showFloat && (
                <FloatContainer>
                    <FloatCloseContainer onClick={handleToggleFloat}>
                        <PiCaretLeftFill />
                    </FloatCloseContainer>
                </FloatContainer>
            )}
        </>
    );
};

export default FloatConsultantContactButton;
