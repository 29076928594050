/* eslint-disable react/jsx-props-no-spreading */
import { useState, createContext, useContext } from 'react';

export interface AuthValues {
    user: any;
    setUser: any;
}

const AuthContext = createContext<any>({});
const useAuth = () => useContext(AuthContext);

function AuthProvider(props: any) {
    const [user, setUser] = useState();

    return (
        <AuthContext.Provider
            value={{ user, setUser } as AuthValues}
            {...props}
        />
    );
}

export { AuthProvider, useAuth };
