/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from 'react';

const PointManagementContext = createContext<any>({});
const usePointManagement = () => useContext(PointManagementContext);

function PointManagementProvider(props: any) {
    const [hasNewRow, setHasNewRow] = useState(false);
    const [showPointManager, setShowPointManager] = useState(false);
    const [selectedIntegrator, setSelectedIntegrator] = useState({} as any);

    return (
        <PointManagementContext.Provider
            value={{
                showPointManager,
                setShowPointManager,
                selectedIntegrator,
                setSelectedIntegrator,
                hasNewRow,
                setHasNewRow,
            }}
            {...props}
        />
    );
}

export { PointManagementProvider, usePointManagement };
