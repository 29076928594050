/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import {
    StyledSideCart,
    StyledSideCartValue,
    StyledSideCartSubtitle,
} from '../Base/styles';
import cartImg from '../../../assets/icons/greyCart.png';
import closeImg from '../../../assets/icons/clear_24px.png';
import { IMG_SRC_URL } from '../../../config/webservice';

const SideCart: React.FC = () => {
    const { cart, products, setSideCartVisible } = useOnlineStore();
    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {
        const calc = async () => {
            setTotalValue(0);
            cart?.invoiceItems?.forEach((item: any) => {
                setTotalValue((total) => (total += item.totalValue));
            });
        };

        calc();
    }, [cart]);

    return (
        <StyledSideCart>
            <Row>
                <Col sm={3}>
                    <Image className={'p-4'} src={cartImg} />
                </Col>
                <Col sm={7}>
                    <StyledSideCartValue className={'mt-4'}>
                        {totalValue?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </StyledSideCartValue>
                </Col>

                <Col sm={1}>
                    <Button
                        onClick={() =>
                            setSideCartVisible((visible: any) => !visible)
                        }
                        className={'mt-3 ml-n4'}
                        variant={'light'}
                        style={{
                            backgroundColor: '#ffffff',
                        }}
                    >
                        <Image src={closeImg} />
                    </Button>
                    {/* <Image className={'mt-4 ml-n4'} src={} /> */}
                </Col>
            </Row>

            <Row>
                <hr />
            </Row>
            {cart &&
                cart?.invoiceItems?.map((item: any, index: any) => (
                    <Row key={index} className={'pl-4 mt-2'}>
                        <Col sm={3}>
                            <Image
                                width={'100%'}
                                src={
                                    products && item.erpCode
                                        ? `${IMG_SRC_URL}/${
                                              products?.find(
                                                  (prod: any) =>
                                                      prod.B1_COD ===
                                                      item.productErpCode,
                                              )?.IMG_CAPA
                                          }`
                                        : ''
                                }
                            />
                        </Col>
                        <Col sm={4}>
                            <Row>
                                <StyledSideCartSubtitle>
                                    {item.productName}
                                </StyledSideCartSubtitle>
                            </Row>
                            <Row>
                                <StyledSideCartValue>
                                    {item.totalValue?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </StyledSideCartValue>
                            </Row>
                        </Col>
                        <Col sm={3}>
                            <input
                                className={'text-center'}
                                style={{
                                    height: 40,
                                    width: 70,
                                    backgroundColor: '#ffffff',
                                    border: '1px solid #E0E0E0',
                                }}
                                disabled={true}
                                value={item.quantity}
                            />
                        </Col>
                    </Row>
                ))}
        </StyledSideCart>
    );
};

export default SideCart;
