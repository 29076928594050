/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Form,
    Row,
    Col,
    InputGroup,
    ButtonGroup,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { StyledButtonGroup } from './styles';
import GroupMenu from '../GroupMenu';
import { ReactComponent as GridIcon } from '../../../assets/icons/gridIcon.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/listIcon.svg';
import BrandMenu from '../BrandMenu';

const ProductFilter: React.FC = () => {
    const { term, setTerm, productType, viewType, setViewType, brands } =
        useOnlineStore();

    return (
        <>
            <Row>
                <Col>
                    {productType === 'MP' ? (
                        <>
                            <GroupMenu />
                            {brands.length > 0 && <BrandMenu />}
                        </>
                    ) : (
                        <label className="mt-3 mb-0">
                            Pesquisar kits prontos
                        </label>
                    )}
                </Col>
            </Row>

            <Row className={'mt-2'}>
                <Col md="6" lg="8">
                    <Form>
                        <Form.Group>
                            <InputGroup size="lg">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search"></i>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={term}
                                    onChange={(event) =>
                                        setTerm(event.target.value)
                                    }
                                    style={{
                                        borderColor: '#F2F2F2',
                                    }}
                                    placeholder="Digite aqui o que procura..."
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Col>
                <Col className="text-right">
                    <div className="d-inline-block">
                        <ButtonGroup>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip color="red" id="tooltip-disabled">
                                        Visualizar lista
                                    </Tooltip>
                                }
                            >
                                <StyledButtonGroup
                                    variant={'light'}
                                    onClick={() => {
                                        setViewType('list');
                                    }}
                                >
                                    <ListIcon
                                        fill={
                                            viewType === 'list'
                                                ? '#F2994A'
                                                : 'rgba(112, 112, 112, 0.3)'
                                        }
                                    />
                                </StyledButtonGroup>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip color="red" id="tooltip-disabled">
                                        Visualizar grade
                                    </Tooltip>
                                }
                            >
                                <StyledButtonGroup
                                    variant={'light'}
                                    onClick={() => {
                                        setViewType('card');
                                    }}
                                >
                                    <GridIcon
                                        fill={
                                            viewType === 'list'
                                                ? 'rgba(112, 112, 112, 0.3)'
                                                : '#F2994A'
                                        }
                                    />
                                </StyledButtonGroup>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ProductFilter;
