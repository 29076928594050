import { ATTACHMENTS_SRC_URL } from '../config/webservice';

export default async function downloadFileFromMinio(
    filename: string,
): Promise<void> {
    const url = `${ATTACHMENTS_SRC_URL}/${filename}`;

    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    link.click();

    window.URL.revokeObjectURL(link.href);
}
