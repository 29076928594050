/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { StyledLinkSeller, StyledH6 } from '../../../pages/OnlineStore/styles';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { Role } from '../../../constants/role';
import userHasRoles from '../../../utils/userHasRoles';
import { List } from '../../../pages/Integrators/List';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import {
    listBlackFridayRoute,
    listGeneratorsRoute,
    listReadyKits,
} from '../../../routes/config';
import { StyledDiv } from './styles';

interface Props {
    hidden?: boolean;
    sidebar?: boolean;
}

const Responsable = (props: Props) => {
    const { hidden, sidebar } = props;

    const { user }: AuthValues = useAuth();
    const { setProducts, setTerm, groupId, setGroupId, reload, setReload } =
        useOnlineStore();
    const [showModal, setShowModal] = useState(false);
    const isCommercialOrAdministrator = userHasRoles(user, [
        Role.Commercial,
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.CustomerSuccess,
    ]);

    const selectedSeller =
        localStorage.seller && JSON.parse(localStorage.seller);

    const pathname = window.location.pathname;

    const onHideIntegratorsModal = () => {
        if (
            pathname === listReadyKits.path ||
            pathname === listBlackFridayRoute.path
        ) {
            setProducts([]);
            setTerm('');
        }

        if (pathname === listGeneratorsRoute.path) {
            if (groupId !== '1001') {
                setGroupId('1001');
            } else {
                setReload(!reload);
            }
        }

        setShowModal(false);
    };

    useEffect(() => {
        if (!selectedSeller && isCommercialOrAdministrator) {
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!hidden && !sidebar && isCommercialOrAdministrator && (
                <div className={'mr-4 mt-n1'} style={{ maxWidth: '180px' }}>
                    <StyledLinkSeller
                        title={selectedSeller && selectedSeller.name}
                        className="text-truncate"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        {selectedSeller
                            ? selectedSeller.name
                            : 'Selecione um integrador...'}
                    </StyledLinkSeller>
                    <StyledH6 className="pl-2 mt-n1">Solicitante</StyledH6>
                </div>
            )}

            {sidebar && isCommercialOrAdministrator && (
                <>
                    <StyledDiv onClick={() => setShowModal(true)}>
                        <p>
                            {selectedSeller
                                ? selectedSeller.name
                                : 'Selecione um integrador...'}
                        </p>
                        <p>Solicitante</p>
                    </StyledDiv>
                    <hr />
                </>
            )}

            <List
                show={showModal}
                onHide={() => {
                    onHideIntegratorsModal();
                }}
            />
        </>
    );
};

export default Responsable;
