import BaseLayout from '../../components/BaseLayout';
import InvoicesFilter from '../../components/Invoices/FilterSection';
import ListAttachmentsModal from '../../components/Invoices/InvoiceAttachments/ListAttachmentsModal';
import InvoiceHeader from '../../components/Invoices/InvoiceHeader';
import InvoiceNfDisplayModal from '../../components/Invoices/InvoiceNfDisplayModal';
import InvoiceTable from '../../components/Invoices/InvoiceTable/InvoiceTable';
import PaymentMethodModal from '../../components/Invoices/PaymentMethods';
import FeedbackInvoiceModal from '../../components/OnlineStore/Invoice/FeedbackModal';
import useInvoices from '../../hooks/useInvoices';

export default function List() {
    const {
        selectedInvoice,
        showPaymentMethodModal,
        setShowPaymentMethodModal,
        showFeedbackInvoiceModal,
        setShowFeedbackInvoiceModal,
        showInvoiceTriangulationNfDisplayModal,
        setShowInvoiceTriangulationNfDisplayModal,
        showInvoiceSimpleShipmentNfDisplayModal,
        setShowInvoiceSimpleShipmentNfDisplayModal,
        showListAttachmentsModal,
        setShowListAttachmentsModal,
    } = useInvoices();

    return (
        <BaseLayout>
            <InvoiceHeader />
            <InvoicesFilter />
            <InvoiceTable />
            {showPaymentMethodModal && (
                <PaymentMethodModal
                    show={showPaymentMethodModal}
                    onHide={() => setShowPaymentMethodModal(false)}
                    selectedInvoice={selectedInvoice}
                />
            )}
            {showFeedbackInvoiceModal && (
                <FeedbackInvoiceModal
                    show={showFeedbackInvoiceModal}
                    onHide={() => {
                        setShowFeedbackInvoiceModal(false);
                    }}
                    selectedInvoice={selectedInvoice}
                />
            )}
            {showListAttachmentsModal && (
                <ListAttachmentsModal
                    show={showListAttachmentsModal}
                    invoiceId={selectedInvoice}
                    onHide={() => {
                        setShowListAttachmentsModal(false);
                    }}
                />
            )}
            {showInvoiceTriangulationNfDisplayModal && (
                <InvoiceNfDisplayModal
                    type="CO"
                    show={showInvoiceTriangulationNfDisplayModal}
                    onHide={() =>
                        setShowInvoiceTriangulationNfDisplayModal(false)
                    }
                />
            )}

            {showInvoiceSimpleShipmentNfDisplayModal && (
                <InvoiceNfDisplayModal
                    type="SR"
                    show={showInvoiceSimpleShipmentNfDisplayModal}
                    onHide={() =>
                        setShowInvoiceSimpleShipmentNfDisplayModal(false)
                    }
                />
            )}
        </BaseLayout>
    );
}
