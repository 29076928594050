/* eslint-disable react/require-default-props */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import { Col, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { StyledTitle, StyledLink } from './styles';
import ArrowRight from '../../../assets/icons/arrow_back_ios_24px.png';

interface Props {
    text: string;
    link: string;
}

const TitleLink: React.FC<Props> = ({ text, link }) => {
    const history = useHistory();

    return (
        <React.Fragment>
            <Col>
                <StyledTitle className="float-left">{text}</StyledTitle>
                <StyledLink
                    className="float-left ml-5"
                    onClick={async () => {
                        history.push(link);
                    }}
                >
                    Ver tudo
                </StyledLink>

                <Image src={ArrowRight} className="ml-2 mt-3"></Image>
            </Col>
        </React.Fragment>
    );
};

TitleLink.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
};

export default TitleLink;
