/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
import React, { useState, useRef } from 'react';
import { Overlay, Tooltip, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    StyledCard,
    StyledText,
    StyledTitle,
    StyledCardBody,
    StyledCardTitle,
    StyledCardText,
} from './styles';

import { pointStatementRoute } from '../../../routes/config';
import { usePoint } from '../../../contexts/pointContext';
import info from '../../../assets/icons/info.svg';

interface Props {
    tradeable?: boolean;
    qualifiable?: boolean;
    slim?: boolean;
}

const BalanceCard: React.FC<Props> = ({ tradeable, qualifiable, slim }) => {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const { tradeableBalance, qualifiableBalance } = usePoint();

    return (
        <React.Fragment>
            {tradeable && (
                <>
                    {slim ? (
                        <div>
                            <StyledTitle>Pontos para troca</StyledTitle>
                            <StyledText>{tradeableBalance || '0'}</StyledText>
                        </div>
                    ) : (
                        <StyledCard
                            onClick={async () => {
                                history.push(pointStatementRoute.path);
                            }}
                        >
                            <StyledCardBody>
                                <StyledCardTitle>
                                    Pontos para troca
                                </StyledCardTitle>
                                <StyledCardText>
                                    {tradeableBalance || '0'}
                                </StyledCardText>
                            </StyledCardBody>
                        </StyledCard>
                    )}
                </>
            )}

            {qualifiable && (
                <>
                    {slim ? (
                        <div className="ml-5">
                            <StyledTitle className="ml-n3">
                                Pontos acumulados{' '}
                                <Image
                                    className="float-right ml-2 mt-1"
                                    src={info}
                                    ref={target}
                                    onMouseOver={() => setShow(!show)}
                                    onMouseLeave={() => setShow(false)}
                                ></Image>
                                <Overlay
                                    target={target.current}
                                    show={show}
                                    placement="top-start"
                                >
                                    {(props) => (
                                        <Tooltip
                                            id="overlay-example"
                                            {...props}
                                        >
                                            Os pontos acumulados são os pontos
                                            para troca de categoria.
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </StyledTitle>

                            <StyledText className="ml-n3">
                                {qualifiableBalance || '0'}
                            </StyledText>
                        </div>
                    ) : (
                        <StyledCard
                            className="ml-3"
                            onClick={async () => {
                                history.push(pointStatementRoute.path);
                            }}
                        >
                            <StyledCardBody>
                                <StyledCardTitle>
                                    Pontos acumulados
                                </StyledCardTitle>
                                <StyledCardText>
                                    {qualifiableBalance || '0'}
                                </StyledCardText>
                            </StyledCardBody>
                        </StyledCard>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

BalanceCard.propTypes = {
    tradeable: PropTypes.bool,
    qualifiable: PropTypes.bool,
    slim: PropTypes.bool,
};

export default BalanceCard;
