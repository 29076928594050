import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import useBankAccounts from '../../../../hooks/useBankAccounts';
import Select, { SelectOption } from '../../../Select';
import extractNumericChars from '../../../../utils/extractNumericChars';
import DatePicker from '../../../DatePicker';
import AttachmentFileInput from '../AttachmentFileInput';
import StyledFormArea from './styles';

export interface AttachmentFields {
    file?: File;
    date?: Date;
    amount?: string;
    number: string;
    bankAccount: number | null;
}

interface NewAttachmentFormProps {
    values: AttachmentFields;
    onValuesChange: Dispatch<SetStateAction<AttachmentFields>>;
    disabled?: boolean;
    mode: 'edit' | 'create';
}

export default function NewAttachmentForm({
    values,
    onValuesChange,
    disabled = false,
    mode,
}: NewAttachmentFormProps) {
    const { bankAccountsOptions } = useBankAccounts();

    const selectedBankAccountOption: SelectOption<number> | null =
        bankAccountsOptions?.find(
            (bankAccount: SelectOption<number>) =>
                bankAccount.value === values.bankAccount,
        ) || null;

    const handleNumberFieldChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) =>
        onValuesChange({
            ...values,
            number: extractNumericChars(value),
        });

    const handleDateFieldChange = (newDate: Date) =>
        onValuesChange({ ...values, date: newDate });

    const handleAmountFieldChange = (newAmount: string) => {
        onValuesChange({
            ...values,
            amount: newAmount?.replace(/[^\d,]/g, ''),
        });
    };

    const handleBankAccountFieldChange = (
        newBankAccount: SelectOption<number>,
    ) =>
        onValuesChange({
            ...values,
            bankAccount: newBankAccount.value || null,
        });

    const handleFileFieldChange = (file: File) => {
        onValuesChange({
            ...values,
            file,
        });
    };

    return (
        <StyledFormArea>
            {mode === 'create' && (
                <Form.Group>
                    <AttachmentFileInput
                        onChange={handleFileFieldChange}
                        attachedFile={values.file}
                    />
                </Form.Group>
            )}
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Número do comprovante</Form.Label>
                        <Form.Control
                            disabled={disabled}
                            as="input"
                            name="number"
                            placeholder="Ex.: 0034"
                            value={values.number}
                            onChange={handleNumberFieldChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Valor do comprovante</Form.Label>
                        <Form.Control
                            disabled={disabled}
                            as={CurrencyInput}
                            name="amount"
                            type="text"
                            placeholder="Valor do comprovante"
                            value={values.amount}
                            onValueChange={handleAmountFieldChange}
                            intlConfig={{
                                locale: 'pt-BR',
                                currency: 'BRL',
                            }}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Data do comprovante</Form.Label>
                        <DatePicker
                            disabled={disabled}
                            name="date"
                            onSelect={handleDateFieldChange}
                            value={values.date}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group>
                <Form.Label>Conta bancária</Form.Label>
                <Select
                    disabled={disabled}
                    options={bankAccountsOptions || []}
                    value={selectedBankAccountOption}
                    name="bankAccount"
                    type="text"
                    onChange={handleBankAccountFieldChange}
                    placeholder="Selecione..."
                />
            </Form.Group>
        </StyledFormArea>
    );
}
