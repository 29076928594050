import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledBoxDelivery = styled.div`
    width: 100%;
    height: 40px;
    background: #fef7d6;
    border: 1px solid #f2c94c;
    padding-top: 10px;
    p {
        font-size: 12px !important;
    }
`;

export const StyledH5 = styled.h5`
    font-size: 16px;
    font-weight: bolder;
    color: #707070;
`;

export const StyledLink = styled.a`
    color: #2f80ed;
    font-size: 0.75rem;
    cursor: pointer;
`;

export const StyledLinkAttachment = styled.a`
    color: #2f80ed;
    font-size: 0.75rem;
    cursor: pointer;
`;

export const StyledConfirmButton = styled(Button)`
    width: 200px;
`;

export const StyledCloseButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #f2994a;
    color: #f2994a !important;

    :hover {
        background-color: #f2994a;
        color: #ffffff !important;
    }
`;
