/* eslint-disable no-return-await */
import Request from './request';

export default class NotificationHttpService {
    private static uri = 'notifications';

    public static readMany() {
        return Request.get(this.uri);
    }

    public static update(id: number) {
        return Request.patch(`${this.uri}/${id}`);
    }

    public static async delete(id: number) {
        return await Request.del(`${this.uri}/${id}`);
    }

    public static async deleteMany() {
        return await Request.del(`${this.uri}/all`);
    }
}
