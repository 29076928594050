/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import React, { useRef, useState } from 'react';
import {
    Form,
    Spinner,
    Col,
    Modal,
    Row,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { StyledApproveButton } from './styles';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import InvoiceHttpService from '../../../services/http/invoice-http';

interface Props {
    selectedAnalysis: boolean;
    show: boolean;
    onHide(): void;
    refetch(): void;
}

const SendAnalysisModal = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [analysis, setAnalysis] = useState<string>('');

    const formRef: any = useRef<FormHandles>(null);

    const sendAnalysis = async (event: any) => {
        event.preventDefault();

        setLoading(true);

        const body = {
            selectedAnalysis: props.selectedAnalysis,
            analysis: analysis,
        };

        try {
            const response = await InvoiceHttpService.sendCreditCardAnalysis(
                body,
            );

            if (response.status === 201) {
                toast.success('Pedido atualizado com sucesso');
                props.refetch();
                props.onHide();
            }
        } catch (err) {
            let error = err as any;

            if (isFriendlyHttpError(error)) {
                return toast.error(error.message);
            }

            toast.error(
                'Ocorreu um erro ao enviar a análise, por favor tente novamente mais tarde',
            );
        } finally {
            setLoading(false);
        }
    };

    const formIsInvalid = !analysis.trim().length;

    return (
        <Modal
            {...props}
            backdrop="static"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form ref={formRef} onSubmit={sendAnalysis}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Parecer da análise
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="align-items-center">
                        <Col md={12}>
                            <div>
                                <Form.Label style={{ fontSize: 16 }}>
                                    Por favor, descreva o parecer da análise
                                </Form.Label>
                                <textarea
                                    style={{ width: '100%', height: '8rem' }}
                                    onChange={(event) =>
                                        setAnalysis(event.target.value)
                                    }
                                ></textarea>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <StyledApproveButton
                        style={{ backgroundColor: 'white', borderWidth: '1px' }}
                        onClick={() => props.onHide()}
                    >
                        <span style={{ color: '#EF8526' }}>Cancelar</span>
                    </StyledApproveButton>
                    {formIsInvalid ? (
                        <OverlayTrigger
                            placement="right"
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Tooltip id="overlay-example">
                                    O preenchimento do campo acima é obrigatório
                                </Tooltip>
                            }
                        >
                            <StyledApproveButton disabled>
                                Enviar
                            </StyledApproveButton>
                        </OverlayTrigger>
                    ) : (
                        <StyledApproveButton type="submit">
                            {loading ? (
                                <Spinner animation="border" />
                            ) : (
                                'Enviar'
                            )}
                        </StyledApproveButton>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SendAnalysisModal;
