/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { control, theme } from '../../../styles/react-select-config';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import CustomerHttpService from '../../../services/http/customer-http';
import { getSelectedSeller } from '../../../utils/getSelectedSeller';
import {
    StyledButton,
    StyledBadgeSeller,
    StyledBadgeSellerlight,
} from './styles';
import { New } from '../Customers/New';
import { cnpjMask, cpfMask, phoneMask } from '../../../utils/masks';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import formatMask from '../../../utils/formatMask';

interface Props {
    invoice: any;
    disabled: any;
}

const CustomerSelection: React.FC<Props> = ({ invoice, disabled }) => {
    const seller = getSelectedSeller();

    let { setReloadCart, setCart } = useOnlineStore();

    const newCustomer = () => ({
        sellerId: '',
        customerErpCode: '',
        customerName: '',
        customerDocument: '',
        customerEmail: '',
        customerPhone: '',
        customer: { label: '', value: '' },
    });
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(newCustomer());
    const [selectedCustomer, setSelectedCustomer] = useState({
        label: invoice?.customerName,
        value: invoice?.customerDocument,
        document: invoice?.customerDocument,
        name: invoice?.customerName,
        phone: invoice?.customerPhone,
        email: invoice?.customerEmail,
    });

    const [showModal, setShowModal] = useState(false);

    const handleChanges = async (customer: any) => {
        invoice.chargeAddressCep = customer.addressCep;
        invoice.chargeAddressErpCity = customer.erpCity;
        invoice.chargeAddressErpCityName = customer.erpCityName;
        invoice.chargeAddressErpState = customer.erpState;
        invoice.chargeAddressNumber = customer.addressNumber;
        invoice.chargeAddressDescription = customer.addressDescription;
        invoice.chargeAddressNeighborhood = customer.addressNeighborhood;
        invoice.chargeAddressComplement = customer.addressComplement;

        invoice.sellerId = invoice.sellerId || seller?.id;
        invoice.customerErpCode = customer.id;
        invoice.customerName = customer.name;
        invoice.customerDocument = customer.document;
        invoice.customerEmail = customer.email;
        invoice.customerPhone = `(${customer.phoneCode}) ${customer.phone}`;

        await InvoiceHttpService.update(invoice);

        if (invoice?.addressType === 'same') {
            const resp = await InvoiceHttpService.useSameAddressForDelivery(
                invoice?.id,
            );

            setCart(resp.data);
        }

        setReloadCart((reload: boolean) => !reload);

        setSelectedCustomer({
            label: customer.name,
            value: customer.document,
            document: customer.document,
            name: customer.name,
            email: customer.email,
            phone: `${customer.phoneCode}${customer.phone}`,
        });
    };

    const loadCustomers = async () => {
        let response: any;

        try {
            const term = '';
            const params = {
                term,
                sellerCode: invoice?.seller?.erpCode || '',
            };

            response = await CustomerHttpService.readMany(params);
        } catch (error) {
            console.log(error);
        }

        if (response) {
            const customers = response.data.map((customer: any) => ({
                ...customer,
                label: customer.fancyName,
                value: customer.document,
            }));

            setCustomers(customers);
        }
    };

    useEffect(() => {
        loadCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice?.customerType]);

    const prepareData = (data: any) => ({ ...invoice, ...data });

    const save = async () => {
        try {
            const data = prepareData(customer);

            let response = null;

            response = await InvoiceHttpService.update(data);

            if (response) {
                toast.success(' salvo com sucesso!');
            }
        } catch (error) {
            let err = error as any;

            if (isFriendlyHttpError(err)) {
                return toast.error(err.message);
            }

            return toast.error('Erro ao salvar dados do integrador!');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        await save();
    };

    useEffect(() => {
        async function loadData(): Promise<void> {
            setCustomer({
                sellerId: seller?.id,
                customerErpCode: invoice?.customerErpCode
                    ? invoice.customerErpCode
                    : '',
                customerName: invoice?.customerName ? invoice.customerName : '',
                customerDocument: invoice?.customerDocument
                    ? invoice?.customerDocument
                    : '',
                customerEmail: invoice?.customerEmail
                    ? invoice?.customerEmail
                    : '',
                customerPhone: invoice?.customerPhone
                    ? invoice?.customerPhone
                    : '',
                customer: {
                    label: invoice?.customerName ? invoice?.customerName : '',
                    value: invoice?.customerDocument
                        ? invoice?.customerDocument
                        : '',
                },
            });

            setSelectedCustomer({
                label: invoice?.customerName ? invoice.customerName : '',
                value: invoice?.customerDocument
                    ? invoice.customerDocument
                    : '',
                document: invoice?.customerDocument
                    ? invoice.customerDocument
                    : '',
                name: invoice?.customerName ? invoice.customerName : '',
                phone: invoice?.customerPhone,
                email: invoice?.customerEmail,
            });
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        invoice.customerDocument,
        invoice.customerEmail,
        invoice.customerErpCode,
        invoice.customerName,
        invoice.customerPhone,
    ]);

    const sellerCNPJFormatted = formatMask(
        invoice?.seller?.cnpj,
        '##.###.###/####-##',
    );

    return (
        <>
            <Form>
                <Form.Group className={'pt-4'} controlId="formBasicCep">
                    {invoice.status !== 'CART' ? (
                        <StyledBadgeSeller>
                            Integrador:{' '}
                            <StyledBadgeSellerlight
                                className={'text-uppercase'}
                            >
                                {`${sellerCNPJFormatted} (${invoice?.seller?.name})`}
                            </StyledBadgeSellerlight>
                        </StyledBadgeSeller>
                    ) : (
                        ''
                    )}
                    <Form.Check
                        disabled={
                            disabled ||
                            (invoice?.status &&
                                invoice?.status !== InvoiceStatus.Cart &&
                                invoice?.status !== InvoiceStatus.Budget &&
                                invoice?.status !== InvoiceStatus.Invoice)
                        }
                        checked={invoice?.customerType === 'INTEGRATOR'}
                        onChange={async () => {
                            let newCart = invoice;

                            newCart.customerType = 'INTEGRATOR';

                            try {
                                await InvoiceHttpService.update(newCart);

                                const resp =
                                    await InvoiceHttpService.updateInvoiceCustomer(
                                        invoice.sellerId,
                                        invoice.id,
                                    );

                                setCart(resp.data);
                                setReloadCart((reload: boolean) => !reload);
                            } catch (error) {
                                toast.error('erro');
                            }
                        }}
                        name={'radios'}
                        id="formHorizontalRadios2"
                        label="Selecionar meu CNPJ"
                        type={'radio'}
                    />
                    <Form.Check
                        disabled={
                            disabled ||
                            (invoice?.status &&
                                invoice?.status !== InvoiceStatus.Cart &&
                                invoice?.status !== InvoiceStatus.Budget &&
                                invoice?.status !== InvoiceStatus.Invoice)
                        }
                        checked={invoice.customerType === 'CUSTOMER'}
                        onChange={async () => {
                            let newCart = invoice;

                            newCart.customerType = 'CUSTOMER';
                            newCart.customerName = '';
                            newCart.customerPhone = '';
                            newCart.customerErpCode = '';
                            newCart.customerDocument = '';
                            newCart.customerEmail = '';

                            setSelectedCustomer({
                                label: '',
                                value: '',
                                document: '',
                                name: '',
                                phone: '',
                                email: '',
                            });
                        }}
                        name={'radios'}
                        id="formHorizontalRadios3"
                        label="Selecionar um cliente"
                        type={'radio'}
                    />
                </Form.Group>

                <div
                    style={{
                        display:
                            invoice?.customerType === 'CUSTOMER'
                                ? 'block'
                                : 'none',
                    }}
                >
                    <Form.Group controlId="formBasicCity">
                        <Form.Label>Selecione um cliente</Form.Label>
                        <Form.Control
                            className={'nexen-form-control'}
                            style={{ display: 'none' }}
                        />
                        <ReactSelect
                            isDisabled={
                                disabled ||
                                (invoice.status &&
                                    invoice?.status !== InvoiceStatus.Cart &&
                                    invoice?.status !== InvoiceStatus.Budget &&
                                    invoice?.status !== InvoiceStatus.Invoice)
                            }
                            placeholder="Selecione..."
                            noOptionsMessage={() => 'Sem opções'}
                            name={'customerDocument'}
                            onChange={handleChanges}
                            options={customers}
                            isMulti={false}
                            styles={{ control }}
                            theme={theme}
                            value={{
                                label: invoice.customerName,
                                value: invoice.customerErpCode,
                            }}
                        />
                    </Form.Group>
                </div>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Nome</Form.Label>

                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                disabled
                                value={
                                    selectedCustomer?.name
                                        ? selectedCustomer.name
                                        : ''
                                }
                                name="customerName"
                                type="text"
                                maxLength={30}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>CPF/CNPJ</Form.Label>

                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                as={MaskedInput}
                                mask={
                                    selectedCustomer?.document?.length <= 11
                                        ? cpfMask
                                        : cnpjMask
                                }
                                disabled
                                value={selectedCustomer.document}
                                name="customerDocument"
                                type="text"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                disabled
                                name="customerEmail"
                                value={selectedCustomer.email}
                                type="text"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formBasicCep">
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                                onBlur={handleSubmit}
                                className={'nexen-form-control'}
                                as={MaskedInput}
                                mask={phoneMask}
                                disabled
                                value={selectedCustomer.phone}
                                name="customerPhone"
                                type="text"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            {!(
                disabled ||
                (invoice.status !== InvoiceStatus.Cart &&
                    invoice.status !== InvoiceStatus.Budget)
            ) && (
                <StyledButton
                    onClick={() => setShowModal(true)}
                    className={'mt-2'}
                    variant={'light'}
                >
                    Cadastrar um novo cliente
                </StyledButton>
            )}

            <New
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                    loadCustomers();
                }}
            />
        </>
    );
};

CustomerSelection.propTypes = {
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
};

export default CustomerSelection;
