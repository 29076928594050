import React, { useState } from 'react';
import Select from 'react-select';
import { Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../styles/react-select-config';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { StyledDiv } from './styles';
import BranchHttpService from '../../../services/http/branch-http';

interface Props {
    invoice: any;
}

interface BranchProps {
    label: string;
    value: string;
}

const Branch = (props: Props) => {
    const { invoice } = props;
    const { setCart } = useOnlineStore();

    const [loading, setLoading] = useState(false);

    async function loadBranches() {
        const response: any = await BranchHttpService.readMany({});

        const branches: BranchProps[] = [];

        if (response?.data?.results) {
            response.data.results.map((branch: any) =>
                branches.push({
                    label: branch.name,
                    value: `${branch.companyCode},${branch.branchCode}`,
                }),
            );
        }

        return branches;
    }

    const branches = useQuery<BranchProps[]>('branches', loadBranches);

    const isAllowedStatus = () =>
        invoice.status === InvoiceStatus.Invoice ||
        invoice.status === InvoiceStatus.InvoiceValidated;

    const handleBranchChange = async (event: any) => {
        if (event.value !== invoice.erpTenantId) {
            setLoading(true);

            try {
                const response = await InvoiceHttpService.update({
                    ...invoice,
                    erpTenantId: event.value,
                });

                setCart(response.data);
            } catch (error) {
                toast.error('Erro ao atualizar filial');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <StyledDiv>
            {branches.isLoading ? (
                <Spinner animation="border" />
            ) : (
                <div style={{ width: '100%' }}>
                    <Form.Label>Filial responsável pela expedição</Form.Label>
                    <Select
                        placeholder="Selecione..."
                        className="form-control-nexen"
                        onChange={handleBranchChange}
                        value={
                            loading
                                ? { value: '', label: 'Atualizando...' }
                                : branches.data.find(
                                      (branch: any) =>
                                          branch?.value === invoice.erpTenantId,
                                  )
                        }
                        options={branches.data}
                        styles={{
                            control: tradeControl,
                            option: optionsTheme,
                        }}
                        theme={theme}
                        isDisabled={!isAllowedStatus() || loading}
                    />
                </div>
            )}
        </StyledDiv>
    );
};

export default Branch;
