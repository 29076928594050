import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

interface StyledBadgeProps {
    status: string;
}

const StyledBadge = styled(Badge)<StyledBadgeProps>`
    background: ${({ status }) =>
        status === 'CONSULT' ? 'rgb(78,172,118)' : '#edbf69'};
    color: ${({ status }) =>
        status === 'CONSULT' ? 'rgb(241,250,245)' : '#fdfbee'};
`;

export default StyledBadge;
