/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable object-shorthand */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Table, Button } from 'react-bootstrap';
import InvoiceHttpService from '../../services/http/invoice-http';
import { StyledTh } from './styles';
import AnalysisTypeBadge from '../../components/CreditCardAnalysis/AnalysisTypeBadge';
import formatDateTime from '../../utils/formatDateTime';

interface Props {
    id: any;
    onHide: any;
}

export function Logs({ id, onHide }: Props) {
    const [logs, setLogs] = useState([]);

    async function loadLogs() {
        try {
            const { data } = await InvoiceHttpService.getInvoicesPaycreditLogs({
                id: id,
            });

            let allLogs = [];

            for (let i = 0; i < data.data.length; i++) {
                const item = data.data[i];

                for (let j = 0; j < item.invoicePaycreditLogs.length; j++) {
                    const itemLog = item.invoicePaycreditLogs[j];

                    allLogs.push(itemLog);
                }
            }

            setLogs(allLogs);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (id) {
            loadLogs();
        }
        // eslint-disable-next-line
    }, [id]);

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={id}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Logs de Análise
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="ml-2 mr-2">
                        <Table bordered hover size="sm" className="text-center">
                            <thead>
                                <tr>
                                    <StyledTh>DESCRIÇÃO</StyledTh>
                                    <StyledTh>USUÁRIO</StyledTh>
                                    <StyledTh>CRIADO EM</StyledTh>
                                    <StyledTh>TIPO</StyledTh>
                                </tr>
                            </thead>
                            <tbody>
                                {logs?.map((log, i) => (
                                    <tr key={i}>
                                        <td>{log?.description || '-'}</td>

                                        <td>{log?.user?.name}</td>
                                        <td>{formatDateTime(log.createdAt)}</td>
                                        <td>
                                            <AnalysisTypeBadge
                                                type={log?.type}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    {!logs.length && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Nenhum log encontrado
                            </strong>
                        </Col>
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {/* <p>Clique no botão fechar para não exibir mais esta mensagem</p> */}

                <Button
                    onClick={onHide}
                    style={{ width: '134px' }}
                    variant={'outline-primary'}
                    size="sm"
                >
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
