/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import {
    Button,
    Form,
    InputGroup,
    Modal,
    ModalFooter,
    Spinner,
    Table,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledTh } from './styles';
import PaymentHttpService from '../../../../services/http/payment-http';
import InvoiceHttpService from '../../../../services/http/invoice-http';

interface ReleasePaymentMethodModalProps {
    show: boolean;
    onHide: () => void;
    invoiceId: number;
    erpTenantId: string;
}

export default function ReleasePaymentMethodModal({
    show,
    onHide,
    invoiceId,
    erpTenantId,
}: ReleasePaymentMethodModalProps) {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
        code: '',
        description: '',
    });
    const [term, setTerm] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

    const clearReactStates = () => {
        setPaymentMethods([]);
        setSelectedPaymentMethod({
            code: '',
            description: '',
        });
        setTerm('');
    };

    const loadPaymentMethods = async () => {
        setLoading(true);

        try {
            const paramsTest = {
                erpTenantId,
            };

            const response =
                await PaymentHttpService.listProtheusPaymentMethods(paramsTest);

            setPaymentMethods(response.data);
        } catch (error) {
            toast.error(
                'Houve um erro ao tentar listar os métodos de pagamento, tente novamente mais tarde.',
            );
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (
            Object.keys(selectedPaymentMethod).length === 0 &&
            selectedPaymentMethod.constructor === Object
        ) {
            return toast.info('Selecione uma forma de pagamento');
        }

        setButtonLoading(true);

        try {
            const response = await InvoiceHttpService.selectOthersPaymentMethod(
                invoiceId,
                selectedPaymentMethod,
            );

            if (response.status === 201) {
                toast.success('Forma de pagamento escolhida com sucesso!');
            }
        } catch (error) {
            toast.error(
                'Houve um erro ao selecionar este método de pagamento, tente novamente mais tarde.',
            );
        } finally {
            setButtonLoading(false);
            onHide();
        }
    };

    useEffect(() => {
        if (show) {
            const timer = setTimeout(async () => {
                loadPaymentMethods();
            }, 500);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (show) {
            clearReactStates();
            loadPaymentMethods();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={() => false}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    Selecione uma forma de pagamento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="ml-3 mr-3">
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <i className="fas fa-search" />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={term}
                        onChange={(event) => setTerm(event.target.value)}
                        placeholder="Pesquisar..."
                    />
                </InputGroup>

                {isLoading ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh />
                                <StyledTh>OPÇÃO</StyledTh>
                                <StyledTh>FORMA DE PAGAMENTO</StyledTh>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentMethods?.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check
                                            checked={
                                                selectedPaymentMethod &&
                                                selectedPaymentMethod.code ===
                                                    item.code
                                            }
                                            type="radio"
                                            name="selectedPaymentMethod"
                                            value={item.code}
                                            onChange={() => {
                                                setSelectedPaymentMethod(item);
                                            }}
                                        />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{item.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Modal.Body>
            <ModalFooter>
                <Button
                    variant="primary"
                    onClick={() => {
                        handleSubmit();
                    }}
                    disabled={!selectedPaymentMethod.code || isButtonLoading}
                >
                    {isButtonLoading ? (
                        <Spinner animation="border" />
                    ) : (
                        'Selecionar'
                    )}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
