/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable indent */
import React from 'react';
import {
    Col,
    Form,
    Image,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { insuranceItems } from '../../../constants/onlineStore';
import infoIcon from '../../../assets/info.png';
import { StyledAbleDiv, StyledDisabledDiv } from './styles';

interface Props {
    invoice: any;
}

const InsuranceMethod: React.FC<Props> = ({ invoice }) => (
    <>
        <Form
            style={{
                width: '100%',
                backgroundColor: '#fafafa',
            }}
        >
            <Row className="justify-content-center">
                {insuranceItems.map((item) => (
                    <Col
                        aria-disabled={true}
                        style={{
                            border: '1px solid #FAFAFA',
                            borderLeftColor: '#FFFFFF',
                        }}
                        key={item.name}
                    >
                        <Form.Check className={'p-4'}>
                            <Row className="justify-content-center">
                                <Form.Label>{item.name}</Form.Label>
                            </Row>
                            <Row className="justify-content-center">
                                <Image
                                    className={'mt-3 mb-3'}
                                    style={{ height: '3rem' }}
                                    src={item.image}
                                />
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            Caso deseje realizar o pedido no
                                            próprio CNPJ, não haverá seguro!
                                        </Tooltip>
                                    }
                                >
                                    <Image
                                        className={'mt-2 mb-2 ml-2'}
                                        style={{
                                            cursor: 'pointer',
                                            height: '1.2rem',
                                        }}
                                        src={infoIcon}
                                    />
                                </OverlayTrigger>
                            </Row>

                            <Row className="justify-content-center">
                                {invoice.insurance ? (
                                    <StyledAbleDiv>
                                        <i className="fas fa-check" />
                                    </StyledAbleDiv>
                                ) : (
                                    <StyledDisabledDiv>
                                        <i className="fas fa-times" />
                                    </StyledDisabledDiv>
                                )}
                            </Row>

                            <Row className="justify-content-center mt-3">
                                <p>
                                    Pedido{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`${
                                            invoice.insurance ? '' : 'NÃO'
                                        } POSSUI`}{' '}
                                    </span>{' '}
                                    seguro
                                </p>
                            </Row>
                        </Form.Check>
                    </Col>
                ))}
            </Row>
        </Form>
    </>
);

InsuranceMethod.propTypes = {
    invoice: PropTypes.object,
};

export default InsuranceMethod;
