import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import useInvoices from '../../../hooks/useInvoices';
import FilterCard from '../FilterCard';

interface FilterSectionProps {
    isBudgetFilter?: boolean;
}

export default function FilterSection({
    isBudgetFilter = false,
}: FilterSectionProps) {
    const { setFilterTerm, filterTermWithoutDelay, showFilterSection } =
        useInvoices();

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
                <Col>
                    {showFilterSection && (
                        <FilterCard isBudgetFilter={isBudgetFilter} />
                    )}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="ml-2 mr-2">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder="Digite aqui o que procura..."
                            type="text"
                            name="filterTerm"
                            onChange={(event) => {
                                setFilterTerm(event.target.value);
                            }}
                            value={filterTermWithoutDelay}
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );
}
