/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { cpfMask, phoneMask } from '../../../utils/masks';

const IntegratorPartner = (props: any) => {
    return (
        <>
            <Form.Group controlId="formBasicName">
                <Form.Label>Nome do Sócio Administrador</Form.Label>
                <Form.Control
                    className={'nexen-form-control'}
                    value={props.integrator.partnerName}
                    isInvalid={!!props.errors.partnerName}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.partnerName &&
                        !props.errors.partnerName &&
                        props.signUp
                    }
                    name="partnerName"
                    type="text"
                    placeholder="Informe o nome do sócio"
                />
                {props.errors.partnerName && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.partnerName}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Row>
                <Col sm="7">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>CPF</Form.Label>
                        <Form.Control
                            className={'nexen-form-control'}
                            value={props.integrator.partnerDocument}
                            isInvalid={!!props.errors.partnerDocument}
                            as={MaskedInput}
                            mask={cpfMask}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerDocument &&
                                !props.errors.partnerDocument &&
                                props.signUp
                            }
                            name="partnerDocument"
                            type="text"
                            placeholder="Informe o CPF"
                        />
                        {props.errors.partnerDocument && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerDocument}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col sm="5">
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Data de nascimento</Form.Label>
                        <Form.Control
                            max={new Date().toISOString().split('T')[0]}
                            className={'nexen-form-control'}
                            value={props.integrator.partnerBirthDate}
                            isInvalid={!!props.errors.partnerBirthDate}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.partnerBirthDate &&
                                !props.errors.partnerBirthDate &&
                                props.signUp
                            }
                            name="partnerBirthDate"
                            type="date"
                            placeholder="Informe a data de nascimento"
                        />
                        {props.errors.partnerBirthDate && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.partnerBirthDate}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formBasicName">
                <Form.Label>E-mail do Sócio Administrador</Form.Label>
                <Form.Control
                    className={'nexen-form-control'}
                    value={props.integrator.partnerEmail}
                    isInvalid={!!props.errors.partnerEmail}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.partnerEmail &&
                        !props.errors.partnerEmail &&
                        props.signUp
                    }
                    name="partnerEmail"
                    type="text"
                    placeholder="Informe o e-mail do sócio"
                />
                {props.errors.partnerEmail && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.partnerEmail}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicName">
                <Form.Label>WhatsApp do Sócio Administrador</Form.Label>
                <Form.Control
                    className={'nexen-form-control'}
                    value={props.integrator.partnerPhone}
                    isInvalid={!!props.errors.partnerPhone}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.partnerPhone &&
                        !props.errors.partnerPhone &&
                        props.signUp
                    }
                    name="partnerPhone"
                    as={MaskedInput}
                    mask={phoneMask}
                    type="tel"
                    placeholder={'Digite aqui...'}
                />
                {props.errors.partnerPhone && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.partnerPhone}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default IntegratorPartner;
