/* eslint-disable react/jsx-fragments */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Col, Row, Nav, Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { StyledNav, StyledNavLink, StyledTitle } from './styles';
import {
    pointGeneralInfoRoute,
    pointStatementRoute,
    pointStoreRoute,
    wishlistRoute,
    regulationRoute,
    pointManagementRoute,
} from '../../../routes/config';
import { usePoint } from '../../../contexts/pointContext';
import { ReactComponent as ReactArrowDown } from '../../../assets/icons/down-chevron.svg';
import { ReactComponent as ReactArrowUp } from '../../../assets/icons/up-chevron.svg';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { SellerValues, useSeller } from '../../../contexts/sellerContext';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';

const SideNavBar: React.FC = () => {
    const [accordionArrowDown, setAccordionArrowDown] = useState(true);
    const { regulationPage, setRegulationPage } = usePoint();
    const { seller }: SellerValues = useSeller();
    const { user }: AuthValues = useAuth();
    const history = useHistory();

    const isAdministrator = userHasRoles(user, [Role.Administrator]);
    const isAdministratorOrCommercial = userHasRoles(user, [
        Role.Commercial,
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.CustomerSuccess,
    ]);
    const isPointsAdministrator = userHasRoles(user, [
        Role.PointsAdministrator,
    ]);
    const isManager = userHasRoles(user, [Role.Manager]);

    const userNavPermissions = {
        integrator:
            user?.seller?.pointsEnabled ||
            (isAdministratorOrCommercial && seller?.pointsEnabled),
        management: isAdministrator || isPointsAdministrator,
        manager:
            user?.owner?.pointsEnabled ||
            (isManager && user?.owner?.pointsEnabled),
    };

    return (
        <React.Fragment>
            <Col lg={3} md={3} sm={3} xl={2}>
                <StyledTitle className="ml-4 mt-5">Pontos Nexen</StyledTitle>

                <StyledNav className="ml-n1">
                    <Accordion defaultActiveKey="0">
                        {(userNavPermissions.integrator ||
                            userNavPermissions.manager) && (
                            <>
                                <Row>
                                    <Col>
                                        <Nav.Item>
                                            <Accordion.Toggle
                                                as={StyledNavLink}
                                                eventKey="general"
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                    pointGeneralInfoRoute.path
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        pointGeneralInfoRoute.path,
                                                    );
                                                }}
                                            >
                                                Informativos gerais
                                            </Accordion.Toggle>
                                        </Nav.Item>
                                    </Col>
                                </Row>
                                <Nav.Item>
                                    <Accordion.Toggle
                                        as={StyledNavLink}
                                        eventKey="statement"
                                        selected={
                                            history.location.pathname ===
                                            pointStatementRoute.path
                                        }
                                        onClick={async () => {
                                            history.push(
                                                pointStatementRoute.path,
                                            );
                                        }}
                                    >
                                        Extrato de pontos
                                    </Accordion.Toggle>
                                </Nav.Item>
                                <Nav.Item>
                                    <Accordion.Toggle
                                        as={StyledNavLink}
                                        eventKey="store"
                                        selected={
                                            history.location.pathname ===
                                            pointStoreRoute.path
                                        }
                                        onClick={async () => {
                                            history.push(pointStoreRoute.path);
                                        }}
                                    >
                                        {' '}
                                        Produtos para troca
                                    </Accordion.Toggle>
                                </Nav.Item>
                                <Nav.Item>
                                    <Accordion.Toggle
                                        as={StyledNavLink}
                                        eventKey="wishlist"
                                        selected={
                                            history.location.pathname ===
                                            wishlistRoute.path
                                        }
                                        onClick={async () => {
                                            history.push(wishlistRoute.path);
                                        }}
                                    >
                                        Lista de desejos
                                    </Accordion.Toggle>
                                </Nav.Item>
                            </>
                        )}

                        {userNavPermissions.management && (
                            <Nav.Item className="ml-3 mt-2">
                                <Accordion.Toggle
                                    as={StyledNavLink}
                                    eventKey="management"
                                    className="ml-n3 mt-n2"
                                    selected={
                                        history.location.pathname ===
                                        pointManagementRoute.path
                                    }
                                    onClick={async () => {
                                        history.push(pointManagementRoute.path);
                                        setRegulationPage(0);
                                    }}
                                >
                                    Gerenciar Pontos
                                </Accordion.Toggle>
                            </Nav.Item>
                        )}
                        <Row className="align-items-center">
                            <Col>
                                <Nav.Item className="ml-3 mt-2">
                                    <Accordion.Toggle
                                        as={StyledNavLink}
                                        eventKey="regulament"
                                        className="ml-n3 mt-n2"
                                        selected={
                                            history.location.pathname ===
                                            regulationRoute.path
                                        }
                                        onClick={async () => {
                                            setAccordionArrowDown(
                                                !accordionArrowDown,
                                            );
                                            history.push(regulationRoute.path);
                                            setRegulationPage(0);
                                        }}
                                    >
                                        Regulamento
                                        {!accordionArrowDown && (
                                            <ReactArrowUp
                                                width={12}
                                                height={12}
                                                className="ml-4"
                                                fill="#707070"
                                            />
                                        )}
                                        {accordionArrowDown && (
                                            <ReactArrowDown
                                                width={12}
                                                height={12}
                                                className="ml-4"
                                                fill="#707070"
                                            />
                                        )}
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey="regulament">
                                        <>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 1
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(1);
                                                }}
                                            >
                                                Participação, validade e
                                                cancelamento
                                            </StyledNavLink>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 2
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(2);
                                                }}
                                            >
                                                Pontos adquiridos e pontuação
                                            </StyledNavLink>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 3
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(3);
                                                }}
                                            >
                                                Troca e qualificação de pontos
                                            </StyledNavLink>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 4
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(4);
                                                }}
                                            >
                                                Níveis, categorias e benefícios
                                            </StyledNavLink>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 5
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(5);
                                                }}
                                            >
                                                Prazos, vigência e privacidade
                                            </StyledNavLink>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 6
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(6);
                                                }}
                                            >
                                                Disposições gerais
                                            </StyledNavLink>
                                            <StyledNavLink
                                                selected={
                                                    history.location
                                                        .pathname ===
                                                        regulationRoute.path &&
                                                    regulationPage === 7
                                                }
                                                onClick={async () => {
                                                    history.push(
                                                        regulationRoute.path,
                                                    );
                                                    setRegulationPage(7);
                                                }}
                                            >
                                                Definições
                                            </StyledNavLink>
                                        </>
                                    </Accordion.Collapse>
                                </Nav.Item>
                            </Col>
                        </Row>
                    </Accordion>
                </StyledNav>
            </Col>
        </React.Fragment>
    );
};

export default SideNavBar;
