import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled.div`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 5px !important;
    width: 100%;
    :hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f2994a !important;
    }
`;

export const StyledCardText = styled(Card.Text)`
    font-size: 10px !important;
`;
