import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import {
    StyledConfirmButton,
    StyledOutlineButton,
} from '../../../styles/button';

interface InvoiceCancelmentModalProps {
    show: boolean;
    onHide: () => void;
    onCancel: (reason: string) => void;
}

export default function InvoiceCancelmentModal({
    show,
    onHide,
    onCancel,
}: InvoiceCancelmentModalProps) {
    const [cancelmentReason, setCancelmentReason] = useState('');

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body>
                <h3>Cancelar pedido</h3>
                <Form.Group>
                    <Form.Label>Informe o motivo do cancelamento</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        onChange={(event: any) => {
                            setCancelmentReason(event.target.value);
                        }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <StyledOutlineButton
                    variant="outline-primary"
                    onClick={onHide}
                    disabled={false}
                >
                    Cancelar
                </StyledOutlineButton>
                <StyledConfirmButton onClick={() => onCancel(cancelmentReason)}>
                    Salvar
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
}
