import StyledBadge from './styles';

interface AnalysisTypeBadgeProps {
    type: string;
}

export default function AnalysisTypeBadge({ type }: AnalysisTypeBadgeProps) {
    return (
        <StyledBadge status={type} variant="primary">
            {type === 'CONSULT' ? 'CONSULTA' : 'ANÁLISE'}
        </StyledBadge>
    );
}
