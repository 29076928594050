/* eslint-disable import/prefer-default-export */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { cnpjMask, phoneMask } from '../../../utils/masks';
import { StyledTitle } from './styles';

const CompanyData = (props: any) => {
    const { register } = useFormContext();

    return (
        <>
            <StyledTitle>Dados da empresa</StyledTitle>
            <p>
                Para atualizar os dados da sua empresa, entre em contato com
                um(a) consultor(a) Nexen através de nossos canais de atendimento
            </p>
            <hr />

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>Nome fantasia</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.fancyName"
                            readOnly
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>Inscrição estadual</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.ie"
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>Razão social</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.name"
                            readOnly
                            type="text"
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>CNPJ</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.cnpj"
                            as={MaskedInput}
                            mask={cnpjMask}
                            value={props.user?.seller?.cnpj}
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                    <Form.Group>
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control
                            ref={register}
                            name="seller.phone"
                            as={MaskedInput}
                            mask={phoneMask}
                            value={props.user?.seller?.phone}
                            type="text"
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};

export { CompanyData };
