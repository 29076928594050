import { ChangeEvent, useState } from 'react';
import { Form, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { LiaInfoCircleSolid } from 'react-icons/lia';
import { PiQuestion, PiSignOut, PiWarning } from 'react-icons/pi';
import { useMutation } from 'react-query';
import { NotificationIcon } from '../../constants/notificationIcon';
import { StyledCancelButton, StyledConfirmButton, StyledModal } from './styles';

interface ConfirmationDialogBaseProps {
    show: boolean;
    title: string;
    text: string;
    icon: string;
    onHide: () => void;
}

interface ConfirmationDialogProps extends ConfirmationDialogBaseProps {
    onConfirm: () => Promise<unknown>;
}

interface ConfirmationDialogWithFeedbackProps
    extends ConfirmationDialogBaseProps {
    required: boolean;
    placeholder?: string;
    onConfirmWithFeedback: (feedback: string) => Promise<unknown>;
}

interface ConfirmationDialogWithCheckboxProps
    extends ConfirmationDialogBaseProps {
    label: string;
    defaultValue?: boolean;
    onConfirmWithCheckbox: (isSelected: boolean) => Promise<unknown>;
}

type ConfirmationDialogType =
    | ConfirmationDialogProps
    | ConfirmationDialogWithFeedbackProps
    | ConfirmationDialogWithCheckboxProps;

export default function ConfirmationDialog({
    show,
    title,
    text,
    icon,
    onHide,
    ...props
}: ConfirmationDialogType) {
    const required = 'required' in props ? props.required : false;
    const placeholder =
        'placeholder' in props
            ? props.placeholder
            : 'Insira aqui seu comentário...';
    const label = 'label' in props ? props.label : undefined;
    const defaultValue = 'defaultValue' in props ? props.defaultValue : false;

    const [message, setMessage] = useState<string>('');
    const [selected, setSelected] = useState<boolean>(defaultValue);

    const handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const handleChangeSelected = (event: ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.checked);
    };

    const bodyIcon = {
        [NotificationIcon.Info]: <LiaInfoCircleSolid />,
        [NotificationIcon.Warning]: <PiWarning />,
        [NotificationIcon.Logout]: <PiSignOut />,
    }[icon] || <PiQuestion />;

    const confirmMutation = useMutation({
        mutationKey: ['dialog-confirm'],
        mutationFn: async () => {
            if ('onConfirm' in props && props.onConfirm) {
                await props.onConfirm();
            } else if (
                'onConfirmWithFeedback' in props &&
                props.onConfirmWithFeedback
            ) {
                await props.onConfirmWithFeedback(message.trim());
            } else if (
                'onConfirmWithCheckbox' in props &&
                props.onConfirmWithCheckbox
            ) {
                await props.onConfirmWithCheckbox(selected);
            }
        },
        onSettled: () => {
            onHide();
        },
    });

    const formIsInvalid = required && !message.trim().length;

    return (
        <StyledModal show={show} onHide={onHide} centered icon={icon}>
            <Modal.Body className="text-center">
                {bodyIcon}
                <Modal.Title>{title}</Modal.Title>
                <pre className="pre">{text}</pre>
                {'onConfirmWithFeedback' in props &&
                    props.onConfirmWithFeedback && (
                        <Form.Control
                            className="textarea"
                            as="textarea"
                            name="feedback"
                            value={message}
                            onChange={handleChangeMessage}
                            rows={5}
                            placeholder={placeholder}
                        />
                    )}
                {'onConfirmWithCheckbox' in props &&
                    props.onConfirmWithCheckbox && (
                        <Form.Check
                            className="mt-3 mb-3"
                            type="checkbox"
                            label={label}
                            onChange={handleChangeSelected}
                            checked={selected}
                        />
                    )}

                <StyledCancelButton
                    onClick={onHide}
                    disabled={confirmMutation.isLoading}
                >
                    Cancelar
                </StyledCancelButton>
                {formIsInvalid ? (
                    <OverlayTrigger
                        placement="right"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={
                            <Tooltip id="overlay-example">
                                O preenchimento do campo acima é obrigatório
                            </Tooltip>
                        }
                    >
                        <StyledConfirmButton disabled>
                            Confirmar
                        </StyledConfirmButton>
                    </OverlayTrigger>
                ) : (
                    <StyledConfirmButton
                        onClick={() => confirmMutation.mutate()}
                        disabled={confirmMutation.isLoading}
                    >
                        {confirmMutation.isLoading ? (
                            <Spinner animation="border" />
                        ) : (
                            'Confirmar'
                        )}
                    </StyledConfirmButton>
                )}
            </Modal.Body>
        </StyledModal>
    );
}
