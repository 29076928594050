/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

const StyledDatePicker = styled.div`
    .invalid-select {
        border: 1px solid #dc3545 !important;
    }
`;

const DatePicker = (props: any) => (
    <StyledDatePicker>
        <ReactDatePicker
            locale="br"
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/AAAA"
            disabled={props.disabled}
            className={
                props.isInvalid ? 'form-control invalid-select' : 'form-control'
            }
            selected={props.value}
            onSelect={props.onSelect}
            onChange={props.onChange}
            required={props.required}
            minDate={props.minDate && new Date(props.minDate)}
        />
    </StyledDatePicker>
);

export default DatePicker;
