/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Switch } from 'react-router-dom';
import { List } from '../../pages/CreditCardAnalysis/List';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const creditCardAnalysisListRoute = new Route('/analise');

export const CreditCardAnalysisRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={creditCardAnalysisListRoute.path}
            component={List}
            isPrivate={true}
            exact
        />
    </Switch>
);
