/* eslint-disable react/self-closing-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { phoneMask } from '../../../../utils/masks';

const CommercialReferences = (props: any) => (
    <Form
        style={{
            width: '100%',
        }}
    >
        <fieldset disabled={props.disabled}>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={props.financing.commercialRefName}
                            onChange={props.handleChanges}
                            name="commercialRefName"
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control
                            value={props.financing.commercialRefPhone}
                            onChange={props.handleChanges}
                            name="commercialRefPhone"
                            type="text"
                            as={MaskedInput}
                            mask={phoneMask}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Vínculo com a empresa</Form.Label>
                        <Form.Control
                            value={props.financing.commercialRefBond}
                            onChange={props.handleChanges}
                            name="commercialRefBond"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
                <Col></Col>
            </Row>
        </fieldset>
    </Form>
);

export default CommercialReferences;
