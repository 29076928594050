/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { waitingItems, installments } from '../../../../constants/onlineStore';

const FinancingData = (props: any) => (
    <Form
        style={{
            width: '100%',
        }}
    >
        <fieldset disabled={props.disabled}>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Valor do equipamento*</Form.Label>
                        <Form.Control
                            as={CurrencyInput}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            isInvalid={!!props.errors.equipmentValue}
                            value={props.financing.equipmentValue}
                            onValueChange={props.handleCurrencyChanges}
                            name="equipmentValue"
                        ></Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Valor de entrada*</Form.Label>
                        <Form.Control
                            as={CurrencyInput}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            isInvalid={!!props.errors.inputValue}
                            value={props.financing.inputValue}
                            onValueChange={props.handleCurrencyChanges}
                            name="inputValue"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Valor financiado*</Form.Label>
                        <Form.Control
                            as={CurrencyInput}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            value={
                                (props.financing.equipmentValue
                                    ? props.financing.equipmentValue?.replace(
                                          ',',
                                          '.',
                                      )
                                    : 0) -
                                (props.financing.inputValue
                                    ? props.financing.inputValue?.replace(
                                          ',',
                                          '.',
                                      )
                                    : 0)
                            }
                            onChange={props.handleChanges}
                            disabled
                            name="financedValue"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Carência*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.waitingPeriod}
                            value={props.financing.waitingPeriod}
                            onChange={props.handleChanges}
                            name="waitingPeriod"
                            type="number"
                            as={'select'}
                        >
                            <option value="">Selecione</option>
                            {waitingItems.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.desc}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Número de parcelas*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.installments}
                            value={props.financing.installments}
                            onChange={props.handleChanges}
                            name="installments"
                            as={'select'}
                        >
                            <option value="">Selecione</option>
                            {installments.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.desc}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Valor das parcelas*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.installmentsValue}
                            value={props.financing.installmentsValue}
                            disabled
                            onChange={props.handleChanges}
                            name="installmentsValue"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </fieldset>
    </Form>
);

export default FinancingData;
