/* eslint-disable import/prefer-default-export */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import {
    theme,
    tradeControl,
    optionsTheme,
} from '../../../styles/react-select-config';
import { getSelectInvoicePaycreditStatusText } from '../../../utils/getSelectInvoicePaycreditStatusText';
import { useInvoicePaycredit } from '../../../contexts/invoicePaycreditContext';

interface Props {
    toggleFilter: any;
}

const CreditCardAnalysisFilter: React.FC<Props> = ({
    toggleFilter,
    budget = false,
}: any) => {
    const { control } = useInvoicePaycredit();

    const [statusList] = useState(getSelectInvoicePaycreditStatusText());

    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Row>
                    <Col>
                        {toggleFilter && (
                            <Card className="mt-4 mx-2">
                                <Card.Body>
                                    <Card.Title>
                                        <strong>Filtros</strong>
                                    </Card.Title>
                                    <Row>
                                        {!budget && (
                                            <Col md={2}>
                                                <Form.Group controlId="formBasicStatus">
                                                    <Form.Label>
                                                        Status
                                                    </Form.Label>

                                                    <Controller
                                                        isClearable={false}
                                                        className="form-control-nexen"
                                                        as={Select}
                                                        control={control}
                                                        name="filterStatus"
                                                        options={statusList}
                                                        isMulti={true}
                                                        styles={{
                                                            control:
                                                                tradeControl,
                                                            option: optionsTheme,
                                                        }}
                                                        theme={theme}
                                                        placeholder="Selecione..."
                                                        defaultValue={null}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col className="ml-2 mr-2">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className="fas fa-search"></i>
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <Controller
                                as={
                                    <Form.Control placeholder="Digite aqui o que procura..." />
                                }
                                type="text"
                                name="filterTerm"
                                control={control}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export { CreditCardAnalysisFilter };
