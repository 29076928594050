/* eslint-disable no-return-await */
import Request from './request';

export default class CustomerHttpService {
    private static uri = 'erp/customers';

    public static readMany(params: {}) {
        return Request.get(this.uri, params);
    }

    public static create(data: {}) {
        return Request.post(this.uri, data);
    }
}
