import styled from 'styled-components';

const StyledBoxDelivery = styled.div`
    width: 100%;
    height: 40px;
    background: #fef7d6;
    border: 1px solid #f2c94c;
    padding-top: 10px;
    p {
        font-size: 12px !important;
    }
`;

export default StyledBoxDelivery;
