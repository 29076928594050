/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-useless-return */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as ReactInvoiceDone } from '../../../../assets/icons/timeline/done.svg';
import { ReactComponent as ReactInvoiceAnalysis } from '../../../../assets/icons/timeline/analysis.svg';
import { ReactComponent as ReactInvoicePaymentApproved } from '../../../../assets/icons/timeline/payment_approved.svg';
import { ReactComponent as ReactInvoiceSeparation } from '../../../../assets/icons/timeline/separation.svg';
import { ReactComponent as ReactInvoiceBilled } from '../../../../assets/icons/timeline/billed.svg';
import { ReactComponent as ReactInvoiceDispatched } from '../../../../assets/icons/timeline/dispatched.svg';
import { ReactComponent as ReactArrowRight } from '../../../../assets/icons/timeline/arrow_right.svg';
import { StyledSpan } from './styles';
import { InvoiceStatus } from '../../../../constants/invoiceStatus';

const Timeline = (props: any) => {
    const green = '#6FCF97';
    const grey = '#e0e0e0';

    const [invoiceDone, setInvoiceDone] = useState(grey);
    const [invoiceReview, setInvoiceReview] = useState(grey);
    const [invoicePaymentPending, setInvoicePaymentPending] = useState(grey);
    const [invoicePaymentApproved, setInvoicePaymentApproved] = useState(grey);
    const [invoiceBilled, setInvoiceBilled] = useState(grey);
    const [invoiceSeparate, setInvoiceSeparate] = useState(grey);
    const [invoiceDispatched, setInvoiceDispatched] = useState(grey);
    const [invoiceDelivered, setInvoiceDelivered] = useState(grey);

    useEffect(() => {
        function getStatus() {
            if (
                props.invoice?.status === InvoiceStatus.InvoiceCanceled ||
                props.invoice?.status === InvoiceStatus.InvoiceReproved
            ) {
                return;
            }

            setInvoiceDone(green);
            setInvoiceReview(green);

            if (
                props.invoice?.status === InvoiceStatus.InvoiceApproved ||
                props.invoice?.status === InvoiceStatus.InvoiceBilling ||
                props.invoice?.status === InvoiceStatus.InvoiceBillingErp
            ) {
                setInvoicePaymentPending(green);

                return;
            }

            if (
                props.invoice?.status === InvoiceStatus.InvoicePayed ||
                props.invoice?.status === InvoiceStatus.InvoicePayedErp
            ) {
                setInvoicePaymentPending(green);
                setInvoicePaymentApproved(green);

                return;
            }

            if (props.invoice?.status === InvoiceStatus.InvoiceSeparate) {
                setInvoicePaymentPending(green);
                setInvoicePaymentApproved(green);
                setInvoiceSeparate(green);

                return;
            }

            if (props.invoice?.status === InvoiceStatus.InvoiceBilled) {
                setInvoicePaymentPending(green);
                setInvoicePaymentApproved(green);
                setInvoiceSeparate(green);
                setInvoiceBilled(green);
            }

            if (props.invoice?.status === InvoiceStatus.InvoiceDispatched) {
                setInvoicePaymentPending(green);
                setInvoicePaymentApproved(green);
                setInvoiceSeparate(green);
                setInvoiceBilled(green);
                setInvoiceDispatched(green);

                return;
            }

            if (
                props.invoice?.status ===
                    InvoiceStatus.InvoiceDeliveryEnRoute ||
                props.invoice?.status === InvoiceStatus.InvoiceDeliveryInTransit
            ) {
                setInvoicePaymentPending(green);
                setInvoicePaymentApproved(green);
                setInvoiceSeparate(green);
                setInvoiceBilled(green);
                setInvoiceDispatched(green);

                return;
            }

            if (props.invoice?.status === InvoiceStatus.InvoiceDelivered) {
                setInvoicePaymentPending(green);
                setInvoicePaymentApproved(green);
                setInvoiceSeparate(green);
                setInvoiceBilled(green);
                setInvoiceDispatched(green);
                setInvoiceDelivered(green);

                return;
            }
        }

        if (props.invoice?.status) {
            getStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.invoice?.status]);

    return (
        <>
            <Row className="text-center">
                <Col>
                    <ReactInvoiceDone fill={invoiceDone} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pedido feito
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoiceReview} />
                </Col>

                <Col>
                    <ReactInvoiceAnalysis fill={invoiceReview} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pedido em análise
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoicePaymentPending} />
                </Col>

                <Col>
                    <ReactInvoiceAnalysis fill={invoicePaymentPending} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Aguardando pagamento
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoicePaymentApproved} />
                </Col>

                <Col>
                    <ReactInvoicePaymentApproved
                        fill={invoicePaymentApproved}
                    />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pagamento aprovado
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoiceSeparate} />
                </Col>

                <Col>
                    <ReactInvoiceSeparation fill={invoiceSeparate} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pedido em separação
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoiceBilled} />
                </Col>

                <Col>
                    <ReactInvoiceBilled fill={invoiceBilled} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pedido faturado
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoiceDispatched} />
                </Col>

                <Col>
                    <ReactInvoiceDispatched fill={invoiceDispatched} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pedido expedido
                    </StyledSpan>
                </Col>

                <Col className={'my-auto'}>
                    <ReactArrowRight fill={invoiceDelivered} />
                </Col>

                <Col>
                    <ReactInvoiceDone fill={invoiceDelivered} />
                    <StyledSpan className="d-block mt-2 text-center ">
                        Pedido entregue
                    </StyledSpan>
                </Col>
            </Row>
        </>
    );
};

export default Timeline;
