/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Dropdown, Button } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { StyledTh, StyledBadge } from './styles';
import { PointType } from '../../../constants/pointType';
import { invoicesListRoute } from '../../../routes/config/invoices.route';
import formatDate from '../../../utils/formatDate';
import { ReactComponent as More } from '../../../assets/icons/more.svg';
import { TradeModal } from '../TradeModal';

interface Props {
    infiniteRef?: any;
    points: any[];
    term?: string;
}

const PointTable: React.FC<Props> = ({ infiniteRef, points, term }: Props) => {
    const history = useHistory();

    const [pointVisualization, setPointVisualization] = useState(null);

    const handlePointVisualization = (id?: number) => {
        setPointVisualization(id);
    };

    function getConditionStyle(pointType: string) {
        switch (pointType) {
            case PointType.Credit:
                return {
                    text: 'Crédito',
                    color: 'rgb(78,172,118)',
                    backgroundColor: 'rgb(241,250,245)',
                };

            case PointType.Debit:
                return {
                    text: 'Débito',
                    color: '#ED6969',
                    backgroundColor: 'rgb(253,238,238)',
                };

            case PointType.Chargeback:
                return {
                    text: 'Estorno',
                    color: '#edbf69',
                    backgroundColor: '#fdfbee',
                };
        }
    }

    return (
        <React.Fragment>
            <Row className="ml-0">
                <Table
                    ref={infiniteRef}
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                >
                    <thead>
                        <tr>
                            <StyledTh>SALVO EM</StyledTh>
                            <StyledTh>ORIGEM</StyledTh>
                            <StyledTh>PONTOS</StyledTh>
                            <StyledTh>QUALIFICÁVEL</StyledTh>
                            <StyledTh>CLIENTE</StyledTh>
                            <StyledTh>CONDIÇÃO</StyledTh>
                            <StyledTh>EXPIRA EM</StyledTh>
                            <StyledTh></StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {points?.map((item: any, index: any) => (
                            <tr key={index}>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[term]}
                                        textToHighlight={formatDate(
                                            item.createdAt,
                                        )}
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[term]}
                                        textToHighlight={
                                            item.invoice?.id?.toString() ??
                                            ' MANUAL '
                                        }
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[term]}
                                        textToHighlight={item.value.toString()}
                                    />
                                </td>
                                <td>{item?.qualifiable ? 'Sim' : 'Não'}</td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[term]}
                                        textToHighlight={item?.seller?.name}
                                    />
                                </td>
                                <td>
                                    <StyledBadge
                                        style={{
                                            backgroundColor: getConditionStyle(
                                                item?.type,
                                            ).backgroundColor,

                                            color: getConditionStyle(item?.type)
                                                .color,
                                        }}
                                        variant={'primary'}
                                    >
                                        {getConditionStyle(item?.type).text}
                                    </StyledBadge>
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[term]}
                                        textToHighlight={
                                            formatDate(item.expirationDate) ??
                                            ' - '
                                        }
                                    />
                                </td>
                                <td>
                                    <Dropdown key="left">
                                        <Dropdown.Toggle
                                            bsPrefix="nexen"
                                            className="mt-n3 mb-n3"
                                            as={Button}
                                            variant="text"
                                        >
                                            <More
                                                fill="#bdbdbd"
                                                width="10"
                                                height="20"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                disabled={!item.invoice?.id}
                                                onClick={() => {
                                                    if (item.invoice?.id) {
                                                        history.push(
                                                            `${
                                                                invoicesListRoute.path
                                                            }/${item.invoice?.id.toString()}`,
                                                        );
                                                    }
                                                }}
                                            >
                                                {!item.invoice?.id
                                                    ? 'Pedido manual'
                                                    : 'Ver Pedido Completo'}
                                            </Dropdown.Item>
                                            {item.type === PointType.Debit &&
                                                item.trade && (
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handlePointVisualization(
                                                                item.id,
                                                            )
                                                        }
                                                    >
                                                        Visualizar
                                                    </Dropdown.Item>
                                                )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <TradeModal
                show={!!pointVisualization}
                onHide={() => handlePointVisualization(null)}
                pointVisualization={pointVisualization}
                setPointVisualization={setPointVisualization}
            />
        </React.Fragment>
    );
};

PointTable.propTypes = {
    infiniteRef: PropTypes.any,
    points: PropTypes.arrayOf(PropTypes.any),
    term: PropTypes.string,
};

export default PointTable;
