/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Form, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { formatCurrency } from '../../../../utils/strings';

interface Props {
    invoice: any;
    disabled: any;
}

let timeout: any = null;

const BraspagCreditCard: React.FC<Props> = ({ invoice, disabled }) => {
    let { setCart } = useOnlineStore();
    const [cards, setCards] = useState(invoice.cards);
    const [cardTab, setCardTab] = useState(1);

    return (
        <>
            <Form
                style={{
                    width: '100%',
                }}
            >
                <Form.Group
                    className={'pt-3 pl-4 pr-4'}
                    controlId="formBasicCep"
                >
                    <Form.Label>
                        Selecione a quantidade de cartões para este pagamento
                    </Form.Label>
                    <Form.Control
                        disabled={disabled}
                        as="select"
                        custom
                        name="perPage"
                        value={cards}
                        onChange={async (event) => {
                            setCardTab(parseInt(event.target.value, 10));

                            invoice.cards = event.target.value;

                            const resp: any = await InvoiceHttpService.update(
                                invoice,
                            ).catch(() => toast.error('Erro ao salvar!'));

                            setCards(resp?.data?.cards);

                            setCart(resp.data);
                        }}
                    >
                        <option value={0}>Selecione</option>
                        <option value={1}>Pagar em 1 cartão</option>
                        <option value={2}>Pagar em 2 cartões</option>
                    </Form.Control>
                </Form.Group>

                {+cards !== 0 && (
                    <Form.Row className={'pb-3 pl-4 pr-4'}>
                        <Nav
                            fill
                            variant="tabs"
                            activeKey={cardTab}
                            onSelect={async (selectedKey: any) => {
                                setCardTab(parseInt(selectedKey, 10));
                            }}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey={1}>Cartão 1</Nav.Link>
                            </Nav.Item>
                            {parseInt(cards, 10) === 2 && (
                                <Nav.Item>
                                    <Nav.Link eventKey={2}>Cartão 2</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Form.Row>
                )}

                {+cardTab === 1 && (
                    <>
                        {' '}
                        <Form.Group
                            className={'pl-4 pr-4'}
                            controlId="formBasicName"
                        >
                            <Form.Label>
                                Insira o valor que deseja pagar com este cartão{' '}
                            </Form.Label>
                            <Form.Control
                                disabled={
                                    (cards &&
                                        cards.toString() === (1).toString()) ||
                                    disabled
                                }
                                as={CurrencyInput}
                                intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                }}
                                value={
                                    cards === 2
                                        ? invoice.card1value
                                              ?.toString()
                                              .replace('.', ',')
                                        : invoice.card1value
                                        ? invoice.card1value
                                        : 0
                                }
                                onValueChange={async (value: any) => {
                                    value = value && value.replace(',', '.');
                                    invoice.card1value = value;

                                    const newInvoice = {
                                        ...invoice,
                                        ...{ card1value: value },
                                    };

                                    setCart(newInvoice);

                                    clearTimeout(timeout);
                                    timeout = setTimeout(async () => {
                                        try {
                                            const resp =
                                                await InvoiceHttpService.update(
                                                    newInvoice,
                                                );

                                            setCart(resp.data);
                                        } catch (error) {
                                            toast.error('Erro ao salvar!');
                                        }
                                    }, 500);
                                }}
                                name="value"
                                placeholder={'999'}
                            />
                        </Form.Group>
                        <Form.Group
                            className={'pl-4 pr-4 mb-5'}
                            controlId="formBasicName"
                        >
                            <Form.Label>Parcelamento</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                onChange={async (event: any) => {
                                    invoice.card1installments =
                                        event.target.value;

                                    try {
                                        const resp =
                                            await InvoiceHttpService.update(
                                                invoice,
                                            );

                                        setCart(resp.data);
                                    } catch (error) {
                                        toast.error(
                                            'Erro ao salvar parcelas do cartao 1!',
                                        );
                                    }
                                }}
                                name="installments"
                                type="text"
                                value={invoice.card1installments}
                                as="select"
                            >
                                {invoice.card1value > 0 && (
                                    <>
                                        {' '}
                                        <option value="1">
                                            1x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 1,
                                            )}
                                        </option>
                                        <option value="2">
                                            2x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 2,
                                            )}
                                        </option>
                                        <option value="3">
                                            3x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 3,
                                            )}
                                        </option>
                                        <option value="4">
                                            4x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 4,
                                            )}
                                        </option>
                                        <option value="5">
                                            5x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 5,
                                            )}
                                        </option>
                                        <option value="6">
                                            6x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 6,
                                            )}
                                        </option>
                                        <option value="7">
                                            7x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 7,
                                            )}
                                        </option>
                                        <option value="8">
                                            8x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 8,
                                            )}
                                        </option>
                                        <option value="9">
                                            9x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 9,
                                            )}
                                        </option>
                                        <option value="10">
                                            10x de{' '}
                                            {formatCurrency(
                                                invoice.card1value / 10,
                                            )}
                                        </option>
                                    </>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </>
                )}
                {cardTab === 2 && (
                    <>
                        {' '}
                        <Form.Group
                            className={'pl-4 pr-4'}
                            controlId="formBasicName"
                        >
                            <Form.Label>
                                Insira o valor que deseja pagar com este cartão{' '}
                            </Form.Label>
                            <Form.Control
                                disabled={true}
                                as={CurrencyInput}
                                intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                }}
                                value={
                                    Math.round(invoice.card2value * 100) / 100
                                }
                                name="value"
                                placeholder={'999'}
                            />
                        </Form.Group>
                        <Form.Group
                            className={'pl-4 pr-4 mb-5'}
                            controlId="formBasicName"
                        >
                            <Form.Label>Parcelamento</Form.Label>
                            <Form.Control
                                disabled={disabled}
                                onChange={async (event: any) => {
                                    invoice.card2installments =
                                        event.target.value;
                                    invoice.cards = cards;

                                    try {
                                        const resp =
                                            await InvoiceHttpService.update(
                                                invoice,
                                            );

                                        setCart(resp.data);
                                    } catch (error) {
                                        toast.error('Erro ao salvar parcelas!');
                                    }
                                }}
                                name="installments"
                                type="text"
                                value={invoice.card2installments}
                                as="select"
                            >
                                {invoice.card2value > 0 && (
                                    <>
                                        <option value="0">Selecione</option>
                                        <option value="1">
                                            1x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 1,
                                            )}
                                        </option>
                                        <option value="2">
                                            2x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 2,
                                            )}
                                        </option>
                                        <option value="3">
                                            3x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 3,
                                            )}
                                        </option>
                                        <option value="4">
                                            4x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 4,
                                            )}
                                        </option>
                                        <option value="5">
                                            5x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 5,
                                            )}
                                        </option>
                                        <option value="6">
                                            6x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 6,
                                            )}
                                        </option>
                                        <option value="7">
                                            7x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 7,
                                            )}
                                        </option>
                                        <option value="8">
                                            8x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 8,
                                            )}
                                        </option>
                                        <option value="9">
                                            9x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 9,
                                            )}
                                        </option>
                                        <option value="10">
                                            10x de{' '}
                                            {formatCurrency(
                                                invoice.card2value / 10,
                                            )}
                                        </option>
                                    </>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </>
                )}
            </Form>
        </>
    );
};

BraspagCreditCard.propTypes = {
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
};

export default BraspagCreditCard;
