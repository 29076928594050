/* eslint-disable prefer-template */
import { CRM_URL, LIBRARIES_URL, LOGISTICS_URL } from '../config/crm';
import { Role } from './role';

const pages = [
    {
        name: 'Gestão',
        icon: 'fas fa-cog',
        piIcon: 'PiGear',
        url: CRM_URL,
        roles: [
            Role.Administrator,
            Role.PointsAdministrator,
            Role.Integrator,
            Role.Commercial,
            Role.Financial,
            Role.Logistics,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.Contributor,
            Role.Manager,
            Role.Production,
            Role.Registration,
        ],
    },
    {
        name: 'Logística & Produção',
        icon: 'fas fa-truck',
        piIcon: 'PiTruck',
        url: LOGISTICS_URL + '/dashboard',
        roles: [
            Role.Administrator,
            Role.Logistics,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.ShippingCompany,
            Role.Production,
        ],
    },
    {
        name: 'Biblioteca',
        icon: 'fas fa-book',
        piIcon: 'PiBooks',
        url: LIBRARIES_URL,
        roles: [],
    },
];

export default pages;
