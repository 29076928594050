/* eslint-disable import/prefer-default-export */
import { PaymentMethod } from '../constants/paymentMethod';

export function getPaymentMethodText(method: string) {
    if (method === PaymentMethod.CreditCardAndCreditCard) {
        return 'Dois cartões de crédito';
    }

    if (method === PaymentMethod.CreditCardAndBarcode) {
        return 'Boleto e Cartão';
    }

    if (method === PaymentMethod.Barcode) {
        return 'Boleto';
    }

    if (method === PaymentMethod.CreditCard) {
        return 'Cartão de crédito';
    }

    if (method === PaymentMethod.Deposit) {
        return 'Depósito';
    }

    if (method === PaymentMethod.Financing) {
        return 'Financiamento';
    }

    if (method === PaymentMethod.Others) {
        return 'Outros';
    }

    if (method === PaymentMethod.Pix) {
        return 'Pix';
    }

    return '';
}
