/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createContext, useContext, useEffect } from 'react';
import { getSelectedSeller } from '../utils/getSelectedSeller';

export interface SellerValues {
    seller: any;
    setSeller: any;
}

const SellerContext = createContext<any>({});
const useSeller = () => useContext(SellerContext);

function SellerProvider(props: any) {
    const [seller, setSeller] = useState({} as any);

    useEffect(() => {
        if (!seller?.id) {
            setSeller(getSelectedSeller());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <SellerContext.Provider value={{ seller, setSeller }} {...props} />;
}

export { SellerProvider, useSeller };
