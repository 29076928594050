/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Card, Col, Image, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { budgetsListRoute, invoicesListRoute } from '../../../../routes/config';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import history from '../../../../services/history';
import InvoiceHttpService from '../../../../services/http/invoice-http';

import {
    StyledCardBody,
    StyledCardText,
    StyledCardBudget,
    StyledCardInvoice,
    StyledCancelButton,
    StyledConfirmButton,
} from './styles';

import confirmBudget from '../../../../assets/icons/confirmBudget.svg';
import confirmBudgetActive from '../../../../assets/icons/confirmBudgetActive.svg';

import confirmInvoice from '../../../../assets/icons/confirmInvoice.svg';
import confirmInvoiceActive from '../../../../assets/icons/confirmInvoiceActive.svg';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';
import { InvoiceStatus } from '../../../../constants/invoiceStatus';
import ConfigurationHttpService from '../../../../services/http/configuration-http';
import ConfigurationReference from '../../../../constants/configurationReference';

const ConfirmInvoiceModal = (props: any) => {
    const [status, setStatus] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const { setStep, setGroupId, productType, setCart } = useOnlineStore();

    const handleModalSelection = async () => {
        setLoadingButton(true);

        try {
            const response = await ConfigurationHttpService.readOne(
                ConfigurationReference.SalesCampaign,
            );

            if (productType === 'BF' && response.data.value === '0') {
                toast.error(
                    'Não é possível continuar pois a promoção está cancelada',
                );

                setStatus('');
                setCart({});
                setLoadingButton(false);

                return;
            }

            await InvoiceHttpService.setStatus(props?.invoiceId, { status });

            toast.success('Pedido confirmado com sucesso!');

            setCart({});
            // setReload((reload: boolean) => !reload);

            if (productType === 'PA' || productType === 'BF') {
                setStep(2);
                setGroupId('');
            } else {
                setStep(2);
                setGroupId('1001');
            }

            if (status === InvoiceStatus.Invoice) {
                history.push(invoicesListRoute.path);
            }

            if (status === InvoiceStatus.Budget) {
                history.push(budgetsListRoute.path);
            }

            setLoadingButton(false);

            props.onHide();
        } catch (err) {
            let error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                setLoadingButton(false);

                return;
            }

            setStatus('');
            setLoadingButton(false);

            toast.error('Um erro ocorreu');
        }
    };

    const cancel = () => {
        setStatus('');
        props.onHide();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={() => setStatus('')}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Como deseja finalizar a operação?
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className="mt-4">
                    {props.type !== 'BF' && (
                        <Col md={6}>
                            <StyledCardBudget
                                status={status}
                                onClick={() => {
                                    setStatus('BUDGET');
                                }}
                            >
                                <StyledCardBody>
                                    <Row className="align-items-center">
                                        <Col md={3}>
                                            {status === 'BUDGET' ? (
                                                <Image
                                                    src={confirmBudgetActive}
                                                    width={65}
                                                />
                                            ) : (
                                                <Image
                                                    src={confirmBudget}
                                                    width={65}
                                                />
                                            )}
                                        </Col>
                                        <Col>
                                            <Card.Subtitle className="mb-2 text-muted">
                                                <strong>
                                                    Salvar como orçamento
                                                </strong>
                                            </Card.Subtitle>
                                            <StyledCardText>
                                                Salve o orçamento para fechar o
                                                pedido mais tarde. O orçamento
                                                pode ser compartilhado com seus
                                                clientes. É importante lembrar
                                                que os valores podem sofrer
                                                alterações e os equipamentos
                                                dependem da disponibilidade em
                                                estoque.
                                            </StyledCardText>
                                        </Col>
                                    </Row>
                                </StyledCardBody>
                            </StyledCardBudget>
                        </Col>
                    )}
                    <Col md={6}>
                        <StyledCardInvoice
                            status={status}
                            onClick={() => {
                                setStatus('INVOICE');
                            }}
                        >
                            <StyledCardBody>
                                <Row className="align-items-center">
                                    <Col md={3}>
                                        {status === 'INVOICE' ? (
                                            <Image
                                                src={confirmInvoiceActive}
                                                width={65}
                                            />
                                        ) : (
                                            <Image
                                                src={confirmInvoice}
                                                width={65}
                                            />
                                        )}
                                    </Col>
                                    <Col>
                                        <Card.Subtitle className="mb-2 text-muted">
                                            <strong>
                                                Confirmar como pedido
                                            </strong>
                                        </Card.Subtitle>
                                        <StyledCardText>
                                            Seu gerador está do jeito que
                                            precisa? Continue agora mesmo e
                                            envie para a nexen o seu pedido.
                                            Nossa equipe irá revisar o pedido e
                                            você receberá uma notificação para o
                                            continuar com o pagamento de sua
                                            compra.
                                        </StyledCardText>
                                    </Col>
                                </Row>
                            </StyledCardBody>
                        </StyledCardInvoice>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className="mt-4">
                <StyledCancelButton onClick={cancel} disabled={loadingButton}>
                    Cancelar
                </StyledCancelButton>
                <StyledConfirmButton
                    disabled={status === '' || loadingButton}
                    onClick={handleModalSelection}
                >
                    {loadingButton ? (
                        <Spinner animation={'border'} />
                    ) : (
                        'Confirmar'
                    )}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmInvoiceModal;
