import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UseFormMethods, Controller } from 'react-hook-form';
import { InvoiceValidationFields } from '../InvoiceValidationForm';

interface TriangulationToggleProps {
    form: UseFormMethods<InvoiceValidationFields>;
    disabled: boolean;
    invoice: any;
}

export default function TriangulationToggle({
    form,
    disabled,
    invoice,
}: TriangulationToggleProps) {
    const { isTriangulation, isFutureSale } = form.watch();

    const canActiveTriangulation =
        invoice.seller.canDoTriangulation &&
        invoice.customerType === 'INTEGRATOR' &&
        !isFutureSale;

    if (canActiveTriangulation) {
        return (
            <Form.Group controlId="formBasicIsTriangulation">
                <Form.Label style={{ margin: 0 }}>
                    Operação de venda por conta e ordem
                </Form.Label>
                <Controller
                    control={form.control}
                    name="isTriangulation"
                    render={({ value }) => (
                        <Form.Check
                            type="switch"
                            className="float-left"
                            onChange={() => {
                                form.setValue(
                                    'isTriangulation',
                                    !isTriangulation,
                                );
                            }}
                            checked={value}
                            disabled={disabled}
                        />
                    )}
                />
            </Form.Group>
        );
    }

    return (
        <OverlayTrigger
            placement="left"
            delay={{
                show: 250,
                hide: 400,
            }}
            overlay={
                <Tooltip id="overlay-example">
                    {!invoice.seller.canDoTriangulation &&
                        'Operação não liberada para este integrador'}
                    {invoice.customerType !== 'INTEGRATOR' &&
                        'Operação permitida somente para pedidos realizados para o próprio CNPJ'}
                    {isFutureSale &&
                        'Operação não permitida para pedidos que se enquadram como venda futura'}
                </Tooltip>
            }
        >
            <Form.Group controlId="formBasicIsTriangulation">
                <Form.Label style={{ margin: 0 }}>
                    Operação de venda por conta e ordem
                </Form.Label>
                <Controller
                    control={form.control}
                    name="isTriangulation"
                    render={() => (
                        <Form.Check
                            type="switch"
                            className="float-left"
                            checked={false}
                            disabled
                        />
                    )}
                />
            </Form.Group>
        </OverlayTrigger>
    );
}
