/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

import { Col, Row, Button, InputGroup, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { StyledRow, StyledTitle, StyledBreadcrumbTitle } from './styles';

import BreadcrumbItem from '../../../components/Breadcrumb';
import BaseLayout from '../../../components/BaseLayout';
import SideNavBar from '../../../components/Points/SideNavBar/SideNavBar';
import PointTable from '../../../components/Points/PointTable/PointTable';
import PointManager from '../../../components/Points/Manager/PointManager';
import { usePointManagement } from '../../../contexts/pointManagementContext';
import PointHttpService from '../../../services/http/point-http';

const PointManagement: React.FC = () => {
    let rowsPerPage = 25;
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [term, setTerm] = useState('');
    const [points, setPoints] = useState([]);
    const {
        showPointManager,
        setShowPointManager,
        setSelectedIntegrator,
        hasNewRow,
        setHasNewRow,
    } = usePointManagement();

    async function loadPoints(): Promise<void> {
        setLoading(true);

        try {
            const response = await PointHttpService.index({
                term: term,
                skip: page - 1,
                take: rowsPerPage,
            });
            const totalItems = response.data.meta.total;

            setPoints((points: any) => [...points, ...response.data.points]);

            setHasNextPage(+points?.length >= +totalItems ? false : true);
            setLoading(false);
        } catch (error) {
            toast.error('Erro ao carregar pontos.');
            setHasNextPage(false);
        }
    }

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage((p: any) => (p += 1)),
    });

    useEffect(() => {
        if (hasNewRow) {
            setPoints([]);
            setPage(1);
            loadPoints();
            setHasNewRow(false);
        }
    }, [hasNewRow]);

    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setPoints([]);
                setPage(1);
                loadPoints();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadPoints();
        }
    }, [page]);

    useEffect(() => {
        setPoints([]);
        setPage(1);
        loadPoints();
        setIsFirstRender(false);
    }, []);

    return (
        <React.Fragment>
            <BaseLayout>
                <PointManager
                    show={showPointManager}
                    onHide={() => {
                        setSelectedIntegrator({});
                        setShowPointManager(false);
                    }}
                />
                <BreadcrumbItem>
                    <StyledBreadcrumbTitle className="float-left ml-4 mt-3">
                        Últimos pedidos realizados no sistema
                    </StyledBreadcrumbTitle>
                    <Col className="mr-2">
                        <Button
                            className="float-right mr-2"
                            onClick={() => {
                                setShowPointManager(true);
                            }}
                            style={{ width: '150px' }}
                        >
                            Gerenciar Pontos
                        </Button>
                    </Col>
                </BreadcrumbItem>
                <Row style={{ height: '100vh' }}>
                    <SideNavBar />

                    <Col className="ml-5 mt-5 mb-5 mr-5">
                        <StyledRow>
                            <StyledTitle className="ml-3">
                                Pesquisa Avançada
                            </StyledTitle>
                        </StyledRow>
                        <StyledRow className="ml-0">
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search"></i>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={term}
                                    onChange={(event) =>
                                        setTerm(event.target.value)
                                    }
                                    placeholder="Digite para filtrar por Integrador, Nota, Cliente ou Condição"
                                />
                            </InputGroup>
                        </StyledRow>
                        <PointTable
                            infiniteRef={infiniteRef}
                            points={points}
                            term={term}
                        />

                        <StyledRow className="mx-4">
                            <br />
                            {loading && hasNextPage && (
                                <Col md={12} className="text-center">
                                    <Spinner animation={'border'} />
                                </Col>
                            )}

                            {!hasNextPage && !loading && (
                                <Col md={12} className="text-center">
                                    <strong style={{ color: '#adadad' }}>
                                        Sem mais itens para carregar
                                    </strong>
                                </Col>
                            )}
                        </StyledRow>
                    </Col>
                </Row>
            </BaseLayout>
        </React.Fragment>
    );
};

export { PointManagement };
