/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import ISaveInvoiceItemDto from '../../dtos/ISaveInvoiceItemDto';
import { StyledNumericInput } from '../../pages/OnlineStore/styles';
import InvoiceHttpService from '../../services/http/invoice-http';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { InvoiceStatus } from '../../constants/invoiceStatus';
import { Role } from '../../constants/role';
import { useAuth } from '../../contexts/authContext';
import userHasRoles from '../../utils/userHasRoles';

interface Props {
    item: any;
    tableItem: any;
    disabled: boolean;
    onBudgetEditing?: boolean;
    invoice?: any;
    setInvoice?: (invoice: any) => void;
}

let timeout: any = null;

const NumericInput: React.FC<Props> = ({
    item,
    tableItem,
    disabled,
    onBudgetEditing,
    invoice,
    setInvoice,
}) => {
    const {
        cart,
        setCart,
        productType,
        modifiyngProductId,
        setModifiyngProductId,
    } = useOnlineStore();

    const { user } = useAuth();

    const isAdministrator = userHasRoles(user, [Role.Administrator]);
    const isCommercial = userHasRoles(user, [Role.Commercial]);
    const isCommercialSupervisor = userHasRoles(user, [
        Role.CommercialSupervisor,
    ]);

    const checkDisabled = () => {
        if (!modifiyngProductId) {
            return false;
        }

        if (modifiyngProductId === item?.productErpCode) {
            return false;
        }

        return true;
    };

    const updateLocalCart = async (quantity: any) => {
        const newCart = cart;
        const product = cart?.invoiceItems?.find(
            (obj: any) => item.productErpCode === obj.productErpCode,
        );

        if (productType === 'BF') {
            setCart({ ...newCart });

            if (
                (newCart?.invoiceItems.length > 0 && quantity > 0) ||
                quantity > 1
            ) {
                return 'Nâo é possivel adicionar mais de um produto promocional!';
            }

            return;
        }

        if (product) {
            newCart?.invoiceItems.map(async (obj: any) => {
                if (obj.productErpCode === product?.productErpCode) {
                    if (quantity > obj.quantity) {
                        obj.quantity = quantity;
                        obj.totalValue = quantity * parseFloat(obj.unitValue);
                    }

                    if (quantity < obj.quantity) {
                        obj.quantity = quantity;
                        obj.totalValue = quantity * parseFloat(obj.unitValue);
                    }

                    if (+quantity === 0) {
                        obj.quantity = 0;
                        obj.totalValue = 0;
                    }
                }
            });
            setCart({ ...cart, ...newCart });

            return;
        }

        const invoiceItem: any = {
            unitValue: item?.unitValue,
            totalValue: item?.unitValue * quantity,
            quantity: quantity,
            productErpCode: item?.productErpCode,
        };

        newCart?.invoiceItems?.push(invoiceItem);

        setCart({ ...cart, ...newCart });
    };

    const mutationAddItem = useMutation(
        async (item: any) => {
            if (onBudgetEditing) {
                let totalValueItems = 0;
                let newInvoiceItems = [];

                if (!invoice && cart) {
                    invoice = cart;
                }

                if (!invoice) {
                    return;
                }

                if (invoice.invoiceItems) {
                    newInvoiceItems = invoice.invoiceItems.map(
                        (invoiceItem: any) => {
                            const newItem = {
                                ...item,
                                ...invoiceItem,
                                isCart: false,
                                unitValue: parseFloat(invoiceItem.unitValue),
                                totalValue:
                                    parseFloat(invoiceItem.unitValue) *
                                    invoiceItem.quantity,
                            };

                            return item.id
                                ? invoiceItem.id === item.id
                                    ? newItem
                                    : invoiceItem
                                : newItem;
                        },
                    );
                } else {
                    const newItem = {
                        ...item,
                        isCart: false,
                        unitValue: parseFloat(item.unitValue),
                        totalValue: parseFloat(item.unitValue) * item.quantity,
                    };

                    newInvoiceItems = [newItem];
                }

                for (let invoiceItem of newInvoiceItems) {
                    totalValueItems += invoiceItem.totalValue;
                }

                return setInvoice({
                    ...invoice,
                    totalInvoice: totalValueItems,
                    invoiceItems: newInvoiceItems,
                });
            }

            await InvoiceHttpService.addItem(item);
        },
        {
            onError: (err: any) => {
                tableItem.quantity = 0;

                if (isFriendlyHttpError(err)) {
                    toast.error(err.message);
                }
            },
            onSuccess: async () => {
                if (onBudgetEditing) {
                    return;
                }

                if (item?.status && item?.status === InvoiceStatus.Cart) {
                    const response =
                        await InvoiceHttpService.showByUserIdAndStatus({
                            status: item?.status,
                        });

                    setCart(response.data.data);

                    return;
                }

                const response = await InvoiceHttpService.show(item?.invoiceId);

                setCart(response.data.data);
            },
            onSettled: () => {
                setModifiyngProductId('');
            },
        },
    );

    const isCable = () => item?.productErpCode?.substring(0, 4) === '1020';

    const getStep = () => (isCable() ? 10 : 1);

    const multiplesOfTenValidate = (value: number) => {
        if (!isCable()) {
            return;
        }

        if (value % 10 !== 0) {
            tableItem.quantity = Math.ceil(tableItem.quantity / 10) * 10;

            if (onBudgetEditing) {
                const [unitValue, totalValue] = [
                    parseFloat(tableItem.VLR_TOT),
                    parseFloat(tableItem.VLR_TOT) * tableItem.quantity,
                ];

                setInvoice({
                    ...invoice,
                    invoiceItems: invoice.invoiceItems.map((item: any) => {
                        if (item.productErpCode !== tableItem.B1_COD) {
                            return item;
                        }

                        return {
                            ...item,
                            unitValue,
                            totalValue,
                            quantity: tableItem.quantity,
                        };
                    }),
                });
            }
        }
    };

    const handleInputChangeAction = async (value: number) => {
        if (invoice?.status === InvoiceStatus.Invoice) {
            if (invoice?.type === 'MP') {
                const haveInverter = ['1010'].includes(
                    item.productErpCode.substring(0, 4),
                );
                const havePanel = ['1001'].includes(
                    item.productErpCode.substring(0, 4),
                );

                if ((haveInverter || havePanel) && value <= 0) {
                    toast.error(
                        'Para que sua compra seja autorizada, é necessário adicionar no mínimo 1 inversor e 1 Painel solar em seu pedido.',
                    );

                    return;
                }
            }

            if (
                value <= 0 &&
                !(isAdministrator || isCommercial || isCommercialSupervisor)
            ) {
                toast.error(
                    'Você não tem permissão para remover este item do pedido.',
                );

                return;
            }

            if (invoice?.invoiceItems.length === 1 && value <= 0) {
                toast.error('Não é possível deixar um pedido sem itens.');

                return;
            }
        }

        const continueOrNot = await updateLocalCart(value);

        if (!continueOrNot) {
            setModifiyngProductId(item?.productErpCode);

            tableItem.quantity = value;

            multiplesOfTenValidate(value);

            let invoiceItem: ISaveInvoiceItemDto = {
                status: item?.status,
                invoiceId: item?.invoiceId,
                id: item?.id,
                productErpCode: item?.productErpCode,
                productName: item?.productName,
                productCategory: item?.productCategory,
                unitValue: item?.unitValue,
                quantity: tableItem.quantity,
                type: productType,
                isCart: item?.isCart,
                availability: item?.availability,
            };

            mutationAddItem.mutateAsync(invoiceItem);

            setModifiyngProductId('');
        } else {
            toast.error(continueOrNot);
        }
    };

    const handleInputChange = (value: number) => {
        if (!value) value = 0;

        if (onBudgetEditing) {
            return handleInputChangeAction(value);
        } else {
            clearTimeout(timeout);
            timeout = setTimeout(() => handleInputChangeAction(value), 1000);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/^\s*$|^\d*$/.test(event.key)) {
            event.preventDefault();
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedText = event.clipboardData.getData('text/plain');

        if (!/^\s*$|^\d*$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    return (
        <StyledNumericInput
            type={'number'}
            disabled={checkDisabled() || disabled}
            className={'react-numeric-input-small'}
            onChange={(value) => handleInputChange(value)}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            value={tableItem.quantity}
            min={0}
            size={5}
            step={getStep}
            mobile
        />
    );
};

NumericInput.propTypes = {
    item: PropTypes.object,
    tableItem: PropTypes.object,
    disabled: PropTypes.bool,
};

export default NumericInput;
