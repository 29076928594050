/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import MaskedInput from 'react-text-mask';
import {
    activityTimeMask,
    cnpjMask,
    phoneMask,
    cepMask,
} from '../../../../utils/masks';

const JuridicPerson = (props: any) => (
    <Form
        style={{
            width: '100%',
        }}
    >
        <fieldset disabled={props.disabled}>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Nome empresarial*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityCompanyName}
                            value={props.financing.entityCompanyName}
                            onChange={props.handleChanges}
                            name="entityCompanyName"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Nome fantasia*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityFancyName}
                            value={props.financing.entityFancyName}
                            onChange={props.handleChanges}
                            name="entityFancyName"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>CNPJ*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityCnpj}
                            value={props.financing.entityCnpj}
                            onChange={props.handleChanges}
                            name="entityCnpj"
                            type="text"
                            as={MaskedInput}
                            mask={cnpjMask}
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Capital Social*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityCapitalStock}
                            as={CurrencyInput}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            value={props.financing.entityCapitalStock}
                            onValueChange={props.handleCurrencyChanges}
                            name="entityCapitalStock"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Faturamento do último ano*</Form.Label>
                        <Form.Control
                            as={CurrencyInput}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            isInvalid={!!props.errors.entityLastYearRevenue}
                            value={props.financing.entityLastYearRevenue}
                            onValueChange={props.handleCurrencyChanges}
                            name="entityLastYearRevenue"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Tempo de atividade*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityUptime}
                            as={MaskedInput}
                            placeholder={'01 Anos e 01 Meses'}
                            mask={activityTimeMask}
                            value={props.financing.entityUptime}
                            onChange={props.handleChanges}
                            name="entityUptime"
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Atividade principal*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityMainActivity}
                            value={props.financing.entityMainActivity}
                            onChange={props.handleChanges}
                            name="entityMainActivity"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>E-mail*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityEmail}
                            value={props.financing.entityEmail}
                            onChange={props.handleChanges}
                            name="entityEmail"
                            type="email"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Telefone com DDD*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityPhone}
                            as={MaskedInput}
                            mask={phoneMask}
                            value={props.financing.entityPhone}
                            onChange={props.handleChanges}
                            name="entityPhone"
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Celular com DDD*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityCellphone}
                            as={MaskedInput}
                            mask={phoneMask}
                            value={props.financing.entityCellphone}
                            onChange={props.handleChanges}
                            name="entityCellphone"
                            type="text"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Endereço*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityAddress}
                            value={props.financing.entityAddress}
                            onChange={props.handleChanges}
                            name="entityAddress"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Bairro*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityNeighborhood}
                            value={props.financing.entityNeighborhood}
                            onChange={props.handleChanges}
                            name="entityNeighborhood"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Cidade/Estado*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityCityState}
                            placeholder={'Criciúma / Santa Catarina'}
                            value={props.financing.entityCityState}
                            onChange={props.handleChanges}
                            name="entityCityState"
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Imóvel próprio ou alugado*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityOwnProperty}
                            value={props.financing.entityOwnProperty}
                            onChange={props.handleChanges}
                            name="entityOwnProperty"
                            as={'select'}
                        >
                            <option value="">Selecione</option>
                            <option value="true">Próprio</option>
                            <option value="false">Alugado</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>CEP*</Form.Label>
                        <Form.Control
                            isInvalid={!!props.errors.entityAddressCep}
                            as={MaskedInput}
                            mask={cepMask}
                            value={props.financing.entityAddressCep}
                            onChange={props.handleChanges}
                            name="entityAddressCep"
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col></Col>
            </Row>
        </fieldset>
    </Form>
);

export default JuridicPerson;
