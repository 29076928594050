import React, { useState, useEffect } from 'react';
import {
    Image,
    NavDropdown,
    OverlayTrigger,
    Row,
    Tooltip,
    Col,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ReactProfileIcon } from '../../../assets/icons/profile.svg';
import { ReactComponent as ReactLogoutIcon } from '../../../assets/icons/logout.svg';
import {
    StyledDropdownButton,
    StyledNavDropdownItemText,
    StyledNavDropdownItem,
    StyledDropdownTitle,
    StyledDropdownSubtitle,
    StyledTextRole,
} from '../styles';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { SellerValues, useSeller } from '../../../contexts/sellerContext';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';
import { companyProfileRoute, userProfileRoute } from '../../../routes/config';
import ChangeAvatarModal from '../../Profile/ChangeAvatarModal';
import LoginService from '../../../services/login';
import { getRolesNames } from '../../../utils/getRolesNames';
import CategoryMeter from './categoryMeter';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import PointHttpService from '../../../services/http/point-http';
import ConfirmationDialog from '../../ConfirmationDialog';
import { NotificationIcon } from '../../../constants/notificationIcon';
import ConfigurationReference from '../../../constants/configurationReference';

const UserDropdownMenu: React.FC = () => {
    const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
    const [actualSeason, setActualSeason] = useState(null);

    const { user }: AuthValues = useAuth();
    const { seller }: SellerValues = useSeller();
    const history = useHistory();

    const isFinancial = userHasRoles(user, [Role.Financial]);
    const isCommercialOrAdministrator = userHasRoles(user, [
        Role.Commercial,
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.CustomerSuccess,
    ]);
    const isIntegrator = userHasRoles(user, [Role.Integrator]);
    const isManager = userHasRoles(user, [Role.Manager]);

    const userPermissions = {
        pointCategoryMeter:
            (isCommercialOrAdministrator && seller?.pointsEnabled) ||
            (!isFinancial && user?.seller?.pointsEnabled) ||
            (isManager && user?.owner?.pointsEnabled),
        companyMenu: isIntegrator,
    };

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    const logout = async () => {
        await LoginService.logout();
    };

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const getActualSeason = async () => {
        try {
            const response = await ConfigurationHttpService.readOne(
                ConfigurationReference.ActiveSeason,
            );

            if (response.data.value !== null) {
                const season = await PointHttpService.getSeason(
                    response.data.value,
                );

                setActualSeason(season.data);
            }
        } catch (error) {
            const err = error as any;

            toast.error(err.message.toString());
        }
    };

    useEffect(() => {
        getActualSeason();
    }, []);

    return (
        <>
            <StyledDropdownButton
                className="mr-1"
                size="lg"
                menuAlign={{ lg: 'right' }}
                title={
                    <>
                        {avatar ? (
                            <Image
                                className="mt-n2 mr-n3 avatar-border"
                                width="48"
                                src={avatar}
                                roundedCircle
                            />
                        ) : (
                            <ReactProfileIcon
                                className="mt-n1 mr-n3"
                                width={38}
                                height={38}
                                fill="#ffffff"
                            />
                        )}
                    </>
                }
            >
                <NavDropdown.Header style={{ width: '400px' }}>
                    <Row className="align-items-center ma-0 pa-0">
                        <Col md={2} className="text-left">
                            <OverlayTrigger
                                key="left"
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        Altere seu avatar
                                    </Tooltip>
                                }
                            >
                                {avatar ? (
                                    <Image
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setShowChangeAvatarModal(true)
                                        }
                                        width="60"
                                        src={avatar}
                                        roundedCircle
                                    />
                                ) : (
                                    <ReactProfileIcon
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setShowChangeAvatarModal(true)
                                        }
                                        width={60}
                                        height={60}
                                        fill="#cccccc"
                                    />
                                )}
                            </OverlayTrigger>
                        </Col>
                        <Col md={10}>
                            <StyledDropdownTitle className="d-block text-wrap pl-2">
                                {user.name}
                            </StyledDropdownTitle>
                            <StyledDropdownSubtitle className="d-block text-wrap pl-2">
                                {user.email}
                            </StyledDropdownSubtitle>
                            <StyledTextRole className="text-wrap pl-2">
                                {getRolesNames(user.roles)}
                            </StyledTextRole>
                        </Col>
                    </Row>
                </NavDropdown.Header>

                {userPermissions.pointCategoryMeter && actualSeason && (
                    <CategoryMeter />
                )}

                <NavDropdown.Divider />
                <StyledNavDropdownItemText>
                    Configurações
                </StyledNavDropdownItemText>
                <StyledNavDropdownItem
                    onClick={() => history.push(userProfileRoute.path)}
                >
                    Meu perfil
                </StyledNavDropdownItem>
                {userPermissions.companyMenu && (
                    <StyledNavDropdownItem
                        onClick={() => history.push(companyProfileRoute.path)}
                    >
                        Minha empresa
                    </StyledNavDropdownItem>
                )}
                <NavDropdown.Divider />
                <StyledNavDropdownItem
                    onClick={() => setShowConfirmation(true)}
                >
                    <ReactLogoutIcon
                        width={20}
                        height={20}
                        fill="#707070"
                        className="mr-2"
                    />
                    Sair
                </StyledNavDropdownItem>
            </StyledDropdownButton>

            <ChangeAvatarModal
                show={showChangeAvatarModal}
                onHide={() => {
                    setShowChangeAvatarModal(false);
                }}
            />

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={logout}
                    title="Deseja realmente sair?"
                    text="Você irá sair do sistema"
                    icon={NotificationIcon.Logout}
                />
            )}
        </>
    );
};

export default UserDropdownMenu;
