/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table, Spinner } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';
import {
    StyledTitle,
    StyledSubtitle,
    StyledText,
    StyledCard,
    StyledCardBody,
    StyledCardTitle,
    StyledCardPriceText,
} from './styles';

import BalanceCard from '../BalanceCard/BalanceCard';
import WishlistHttpService from '../../../services/http/wishlist-http';
import { usePoint } from '../../../contexts/pointContext';
import { IMG_SRC_URL } from '../../../config/webservice';
import SellerData from './Trade';

const Detail = (props: any) => {
    const [loading, setLoading] = useState(false);

    const {
        selectedProduct,
        setSelectedProduct,
        showDetailModal,
        tradeableBalance,
        setShowTradeInfoModal,
        showTradeInfoModal,
        loadBalanceAndCategory,
        setShowDetailModal,
    } = usePoint();

    const loadProduct = async () => {
        if (showTradeInfoModal) {
            return;
        }

        setLoading(true);

        const response = await WishlistHttpService.show(
            selectedProduct?.URL_ID,
        );

        setSelectedProduct(response.data.data.product[0]);

        setLoading(false);
    };

    useEffect(() => {
        if (showDetailModal) {
            loadBalanceAndCategory();
            loadProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDetailModal]);

    function calculateNewBalance(price: number) {
        const tradeable = Number(tradeableBalance?.split('.').join(''));

        return tradeable - price;
    }

    return (
        <>
            <SellerData
                show={showTradeInfoModal}
                onHide={() => {
                    setShowTradeInfoModal(false);
                    setShowDetailModal(true);
                }}
            />
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Trocar pontos por produto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100 mr-3'}>
                    {loading ? (
                        <Spinner
                            style={{
                                marginLeft: '48%',
                            }}
                            animation={'border'}
                        />
                    ) : (
                        <div>
                            <Row>
                                <Col className={'col-sm-8 ml-3 mr-n5'}>
                                    <Row>
                                        <h6
                                            className={'ml-2'}
                                            style={{
                                                borderBottom:
                                                    '2px solid #F2994A',
                                                paddingBottom: '10px',
                                                color: '#F2994A',
                                                fontWeight: 600,
                                                fontSize: 13,
                                            }}
                                        >
                                            Ficha Técnica
                                        </h6>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <br />
                                            <Row>
                                                <ImageGallery
                                                    thumbnailPosition={'left'}
                                                    showNav={false}
                                                    showFullscreenButton={false}
                                                    showPlayButton={false}
                                                    items={[
                                                        {
                                                            original:
                                                                selectedProduct?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${selectedProduct?.IMG_CAPA}`
                                                                    : '',
                                                            thumbnail:
                                                                selectedProduct?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${selectedProduct?.IMG_CAPA}`
                                                                    : '',
                                                        },
                                                        {
                                                            original:
                                                                selectedProduct?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${selectedProduct?.IMG_CAPA}`
                                                                    : '',
                                                            thumbnail:
                                                                selectedProduct?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${selectedProduct?.IMG_CAPA}`
                                                                    : '',
                                                        },
                                                    ]}
                                                />
                                            </Row>

                                            <div className={'mt-3 ml-3'}>
                                                <Row>
                                                    <StyledTitle
                                                        decoration={'none'}
                                                        style={{
                                                            color: '#707070',
                                                        }}
                                                        className="w-75"
                                                    >
                                                        {
                                                            selectedProduct?.B1_DESC
                                                        }
                                                    </StyledTitle>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <StyledSubtitle>
                                                        Descrição
                                                    </StyledSubtitle>
                                                </Row>
                                                <Row>
                                                    <StyledText className="w-75">
                                                        {
                                                            selectedProduct?.DETALHES
                                                        }
                                                    </StyledText>
                                                </Row>
                                                <br />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="mt-1 mr-4">
                                    <BalanceCard tradeable />
                                    <StyledCard className={'mt-3'}>
                                        <StyledCardBody>
                                            <StyledCardTitle>
                                                Pontos necessários
                                            </StyledCardTitle>
                                            <StyledCardPriceText>
                                                {selectedProduct?.VLR_TOT?.toString().replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1.',
                                                )}
                                            </StyledCardPriceText>
                                        </StyledCardBody>
                                    </StyledCard>
                                    <StyledSubtitle className={'ml-0 mt-3'}>
                                        {calculateNewBalance(
                                            selectedProduct?.VLR_TOT,
                                        ) >= 0
                                            ? `Após a troca, seu saldo será ${calculateNewBalance(
                                                  selectedProduct?.VLR_TOT,
                                              )}`
                                            : 'Saldo Insuficiente para troca'}
                                    </StyledSubtitle>
                                    <Button
                                        onClick={() => {
                                            setShowTradeInfoModal(true);
                                            setShowDetailModal(false);
                                        }}
                                        className="mt-3 w-100 mb-3"
                                        disabled={
                                            calculateNewBalance(
                                                selectedProduct?.VLR_TOT,
                                            ) < 0
                                        }
                                    >
                                        Quero trocar
                                    </Button>
                                </Col>
                            </Row>
                            <Row className={'ml-3 mt-2 mr-4'}>
                                <StyledSubtitle>Ficha Técnica</StyledSubtitle>
                            </Row>
                            <Row>
                                <Col className={'ml-3 mt-2 mr-4'}>
                                    <Table striped>
                                        <thead></thead>
                                        <tbody>
                                            {selectedProduct?.FT &&
                                                selectedProduct?.FT.map(
                                                    (item: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{`${item.ATRIB} - ${item.VALOR}`}</td>
                                                        </tr>
                                                    ),
                                                )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </>
    );
};

export default Detail;
