enum NotificationType {
    Invoice = 'INVOICE',
    InvoiceExpiration = 'INVOICE_EXPIRATION',
    Budget = 'BUDGET',
    BudgetExpiration = 'BUDGET_EXPIRATION',
    SellerRegister = 'SELLER_REGISTER',
    SellerDigitalDocument = 'SELLER_DIGITAL_DOCUMENT',
}

export default NotificationType;
