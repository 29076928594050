import styled from 'styled-components';
import { Badge } from 'react-bootstrap';

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledBadge = styled(Badge)`
    font-size: 16px;
    background-color: #f2f2f2;
`;
