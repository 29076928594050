import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { PaymentMethod } from '../../../constants/onlineStore';
import { formatCurrency } from '../../../utils/strings';
import StyledBoxDelivery from './styles';
import getFreightValue from '../../../utils/getFreightValue';
import getMaxAvailabilityDate from '../../../utils/getMaxAvailabilityDate';
import getSubTotalInvoice from '../../../utils/getSubTotalInvoice';
import FreightsHttpService from '../../../services/http/freights-http';

interface InvoiceSummaryProps {
    invoice: any;
    discountPercentage?: number;
    showInstallmentRow?: boolean;
}

export default function InvoiceSummary({
    invoice,
    discountPercentage,
    showInstallmentRow = true,
}: InvoiceSummaryProps) {
    const [freightPercentage, setFreightPercentage] = useState(0);

    const commission = Number(invoice.grossCommission);
    const subtotal = getSubTotalInvoice(invoice);
    const percentage = discountPercentage || Number(invoice.percentage) || 0;

    const discount = (subtotal * percentage) / 100;

    const interest =
        (subtotal + commission - discount) *
        (Number(invoice.interestPercentage) / 100);

    const netInvoiceValue = subtotal + commission + interest - discount;

    const willPickup = invoice.addressType === 'pickup';

    const freightValue = !willPickup
        ? getFreightValue(netInvoiceValue, freightPercentage)
        : 0;

    const total = netInvoiceValue + freightValue;

    const installmentPaymentMethods = [
        PaymentMethod.CreditCard,
        PaymentMethod.CreditCardAndBarcode,
    ];

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const fetchFreightPercentage = async () => {
                try {
                    const response =
                        await FreightsHttpService.getFreightPercentageByOrderValue(
                            netInvoiceValue,
                            invoice.deliveryAddressErpState,
                        );

                    setFreightPercentage(response?.data ?? 0);
                } catch (error) {
                    setFreightPercentage(0);
                }
            };

            fetchFreightPercentage();
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [netInvoiceValue, invoice.deliveryAddressErpState]);

    const noDeliveryMessage = willPickup
        ? `Disponível para entrega em ${getMaxAvailabilityDate(invoice)}`
        : 'Prazo de entrega indisponível';

    return (
        <>
            <h5 className="mt-4">
                <strong>Resumo do pedido</strong>
            </h5>
            <Table size="sm" className="mt-2">
                <tbody>
                    <tr>
                        <td className="text-left">Subtotal</td>
                        <td className="text-right">
                            {formatCurrency(subtotal)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Comissão</td>
                        <td className="text-right">
                            {formatCurrency(commission)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Acréscimos</td>
                        <td className="text-right">
                            {formatCurrency(interest)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Desconto</td>
                        <td className="text-right">
                            {formatCurrency(discount)}
                        </td>
                    </tr>
                    {showInstallmentRow &&
                        installmentPaymentMethods.includes(
                            invoice.paymentMethod,
                        ) && (
                            <tr>
                                <td>Parcelamento</td>
                                <td className="text-right">
                                    <strong>
                                        {invoice.cardMaxInstallmentNumber}x{' '}
                                    </strong>
                                </td>
                            </tr>
                        )}
                    {!willPickup && (
                        <tr>
                            <td className="text-left">Frete</td>
                            <td className="text-right">
                                {formatCurrency(freightValue)}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td className="text-left">Total</td>
                        <td className="text-right">
                            <strong>{formatCurrency(total)}</strong>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <StyledBoxDelivery className="text-center">
                <p>
                    <strong>
                        {invoice.deliveryDeadline && !willPickup
                            ? `Previsão de entrega: ${invoice.deliveryDeadline} dias úteis`
                            : noDeliveryMessage}
                    </strong>
                </p>
            </StyledBoxDelivery>
        </>
    );
}
