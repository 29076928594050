/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Switch } from 'react-router-dom';
import { List } from '../../pages/Dashboard';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const dashboardListRoute = new Route('/');

export const DashboardRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={dashboardListRoute.path}
            component={List}
            isPrivate={true}
            exact
        />
    </Switch>
);
