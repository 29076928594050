/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-const */
import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { useContact } from '../contexts/contactContext';
import {
    dashboardListRoute,
    DashboardRoutesComponent,
    OnlineRoutesComponent,
    listGeneratorsRoute,
    listReadyKits,
    detailRoute,
    invoicesListRoute,
    InvoicesRoutesComponent,
    invoiceDetailRoute,
    budgetsListRoute,
    BudgetsRoutesComponent,
    userProfileRoute,
    companyProfileRoute,
    ProfileRoutesComponent,
    pointRoutesComponent,
    pointGeneralInfoRoute,
    pointStatementRoute,
    pointStoreRoute,
    wishlistRoute,
    regulationRoute,
    pointManagementRoute,
    listBlackFridayRoute,
    creditCardAnalysisListRoute,
    CreditCardAnalysisRoutesComponent,
} from './config';
import FloatConsultantContactButton from '../components/FloatButton';
import {
    OthersPaymentMethodsListRouteComponent,
    othersPaymentMethodsListRoute,
} from './config/othersPaymentMethodsList.route';

const AppRoutes: React.FC = () => {
    const auth = useAuth();
    const { consultants } = useContact();
    const [showFloat, setShowFloat] = useState(true);

    let user = {
        ...auth.user,
    };

    let userState = user && user.seller && user.seller.erpState;
    let consultantInformation: any = null;

    if (userState && consultants.length > 0) {
        consultantInformation = consultants.find(
            (consultant: any) => consultant.state === userState,
        );
    }

    const handleToggleFloat = () => {
        setShowFloat((showFloat) => !showFloat);
    };

    return (
        <>
            <Switch>
                <Route
                    path={dashboardListRoute.path}
                    component={DashboardRoutesComponent}
                    exact
                />
                <Route
                    path={listReadyKits.path}
                    component={OnlineRoutesComponent}
                />
                <Route
                    path={listGeneratorsRoute.path}
                    component={OnlineRoutesComponent}
                />
                <Route
                    path={detailRoute.path}
                    component={OnlineRoutesComponent}
                />
                <Route
                    path={invoicesListRoute.path}
                    component={InvoicesRoutesComponent}
                />
                <Route
                    path={invoiceDetailRoute.path}
                    component={InvoicesRoutesComponent}
                />
                <Route
                    path={budgetsListRoute.path}
                    component={BudgetsRoutesComponent}
                />
                <Route
                    path={creditCardAnalysisListRoute.path}
                    component={CreditCardAnalysisRoutesComponent}
                />
                <Route
                    path={pointGeneralInfoRoute.path}
                    component={pointRoutesComponent}
                />
                <Route
                    path={pointStatementRoute.path}
                    component={pointRoutesComponent}
                />
                <Route
                    path={pointStoreRoute.path}
                    component={pointRoutesComponent}
                />

                <Route
                    path={wishlistRoute.path}
                    component={pointRoutesComponent}
                />

                <Route
                    path={regulationRoute.path}
                    component={pointRoutesComponent}
                />

                <Route
                    path={pointManagementRoute.path}
                    component={pointRoutesComponent}
                />

                <Route
                    path={userProfileRoute.path}
                    component={ProfileRoutesComponent}
                />
                <Route
                    path={companyProfileRoute.path}
                    component={ProfileRoutesComponent}
                />
                <Route
                    path={listBlackFridayRoute.path}
                    component={OnlineRoutesComponent}
                />
                <Route
                    path={othersPaymentMethodsListRoute.path}
                    component={OthersPaymentMethodsListRouteComponent}
                />
            </Switch>
            {consultantInformation && (
                <>
                    <FloatConsultantContactButton
                        consultantNumber={
                            consultantInformation.consultantWhatsapp
                        }
                        showFloat={showFloat}
                        toggle={handleToggleFloat}
                    />
                </>
            )}
        </>
    );
};

export default AppRoutes;
