/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from 'react';
import {
    Col,
    Form,
    InputGroup,
    Nav,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useInfiniteQuery } from 'react-query';
import { toast } from 'react-toastify';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import ISaveInvoiceItemDto from '../../../dtos/ISaveInvoiceItemDto';
import { StyledTdLink, StyledTh } from '../../../pages/OnlineStore/styles';
import ProductHttpService from '../../../services/http/product-http';
import { formatToQueryParam } from '../../../utils/strings';
import { toFixed } from '../../../utils/toFixed';
import NumericInput from '../../NumericInput';
import {
    steps as generatorSteps,
    readyKitsSteps,
} from '../../../constants/onlineStore';
import { ReactComponent as ReactArrowRight } from '../../../assets/icons/timeline/arrow_right.svg';
import { StyledSquare, StyledTitleNav } from '../../OnlineStore/Base/styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { rowsPerPage } from '../../../constants/pagination';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import AvailabilityBadge from '../../AvailabilityBadge';

interface Props {
    invoice: any;
    step: number;
    setStep: (step: number) => void;
}

export default function ProductsList({ invoice, step, setStep }: Props) {
    const [term, setTerm] = useState('');
    const [tab, setTab] = useState('0');
    const [steps, setSteps] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupId, setGroupId] = useState(null);

    const { setCart } = useOnlineStore();

    const size = useWindowSize();
    const loadMoreRef = useRef();

    const { invoiceItems } = invoice;

    async function loadProducts({ pageParam = 1 }): Promise<any> {
        try {
            const params = {
                sellerId: invoice.seller ? invoice.seller.erpCode : '',
                groupId: invoice.type === 'MP' ? groupId || 1001 : '',
                term: term ? encodeURIComponent(term) : '',
                skip: pageParam,
                take: 25,
                type: invoice.type,
                invoiceId: invoice.id,
            };

            const response = await ProductHttpService.index(params);

            if (invoice.type === 'MP' && groups.length === 0) {
                setGroups(response?.data?.data?.meta.GROUPS);
            }

            return {
                data: response.data.data.products,
                currentPage: pageParam,
                pages: Math.ceil(response.data.data.meta.TOTAL / rowsPerPage),
            };
        } catch (err) {
            toast.error('Não foi possível carregar os produtos.');
        }
    }

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        refetch,
        isFetchingNextPage,
        isFetching,
    } = useInfiniteQuery('budgetsToRefresh', loadProducts, {
        getNextPageParam: (response: any) => {
            if (response.currentPage + 1 < response.pages) {
                return response.currentPage + 1;
            }

            return undefined;
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });

    const handleGroup = (id: number) => {
        setGroupId(id);
    };

    const nextStep = (item: any) => {
        if (invoiceItems && invoiceItems.length > 0) {
            setStep(item.number);
        } else if (item.number > 2) {
            setStep(step);
        } else {
            setStep(item.number);
        }
    };

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, groupId]);

    useEffect(() => {
        const generators = generatorSteps.filter(
            (item: any) => item.number !== 1 && item.number !== 4,
        );
        const kits = readyKitsSteps.filter((item: any) => item.number !== 4);

        setSteps(invoice.type === 'MP' ? generators : kits);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Col>
                <Row className="align-items-center">
                    <Row>
                        {steps.map((item: any, index: any) => (
                            <div key={index}>
                                <Nav.Item>
                                    <Nav.Link
                                        style={
                                            step === item.number
                                                ? {
                                                      color: '#F2994A',
                                                      fontWeight: 600,
                                                  }
                                                : {
                                                      color: 'rgba(112, 112, 112, 0.3)',
                                                  }
                                        }
                                        eventKey={item.number}
                                        onClick={async () => {
                                            nextStep(item);
                                        }}
                                    >
                                        <Row>
                                            <Col xl={1}>
                                                <StyledSquare
                                                    background={
                                                        step === item.number
                                                            ? '#F2994A'
                                                            : '#fbf3e6'
                                                    }
                                                >
                                                    <h5
                                                        style={{
                                                            fontSize: 38,
                                                            fontWeight: 600,
                                                            color:
                                                                step ===
                                                                item.number
                                                                    ? '#F2994A'
                                                                    : 'rgba(112, 112, 112, 0.3)',
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </h5>
                                                </StyledSquare>
                                            </Col>

                                            <Col className="my-auto">
                                                <StyledTitleNav
                                                    className={
                                                        size.width < 1500 &&
                                                        invoice.type === 'MP'
                                                            ? 'mr-n4'
                                                            : 'ml-3'
                                                    }
                                                    color={
                                                        step === item.number
                                                            ? '#F2994A'
                                                            : 'rgba(112, 112, 112, 0.3)'
                                                    }
                                                >
                                                    {item.title}
                                                </StyledTitleNav>
                                            </Col>
                                            {item.number !== 3 && (
                                                <ReactArrowRight
                                                    style={{
                                                        position: 'relative',
                                                        top: '-3px',
                                                    }}
                                                    className={`my-auto ${
                                                        size.width < 1500 &&
                                                        invoice.type === 'MP'
                                                            ? ''
                                                            : 'mr-4'
                                                    }  `}
                                                    fill={
                                                        step === item.number
                                                            ? '#F2994A'
                                                            : 'rgba(112, 112, 112, 0.3)'
                                                    }
                                                />
                                            )}
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            </div>
                        ))}
                    </Row>
                </Row>
            </Col>
            <Row>
                <Col>
                    <Nav
                        activeKey={tab}
                        className="ma-0 pt-3 ml-n3"
                        onSelect={(selectedKey: string) => setTab(selectedKey)}
                    >
                        {groups.map((item: any, index: any) => (
                            <Nav.Item key={index}>
                                <Nav.Link
                                    style={
                                        parseInt(tab, 10) === index
                                            ? {
                                                  color: '#F2994A',
                                                  fontWeight: 600,
                                              }
                                            : {
                                                  color: '#707070',
                                              }
                                    }
                                    eventKey={index}
                                    onClick={() => handleGroup(item.BM_GRUPO)}
                                >
                                    {item.BM_DESC}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md="6" lg="12">
                    <Form>
                        <Form.Group>
                            <InputGroup size="lg">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search" />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={term}
                                    onChange={(event) =>
                                        setTerm(event.target.value)
                                    }
                                    style={{ borderColor: '#F2F2F2' }}
                                    placeholder="Digite aqui o que procura..."
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row className="mx-0">
                <Table hover bordered className="mx-0 text-center" size="sm">
                    <thead>
                        <tr>
                            <StyledTh>CÓDIGO</StyledTh>
                            <StyledTh>DESCRIÇÃO</StyledTh>
                            <StyledTh>DISPONIBILIDADE</StyledTh>
                            <StyledTh>VALOR UNITÁRIO</StyledTh>
                            <StyledTh>QUANTIDADE</StyledTh>
                            <StyledTh>VALOR TOTAL</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.pages &&
                            data?.pages.map((group: any) =>
                                group?.data.map((item: any, index: any) => {
                                    const itemFromInvoice = invoiceItems.find(
                                        (invoiceItem: any) =>
                                            item.B1_COD ===
                                            invoiceItem.productErpCode,
                                    );

                                    if (itemFromInvoice) {
                                        item = {
                                            ...item,
                                            quantity: itemFromInvoice.quantity,
                                        };
                                    }

                                    return (
                                        <tr
                                            style={{
                                                backgroundColor:
                                                    item?.quantity > 0
                                                        ? 'rgba(242, 153, 74, 0.08)'
                                                        : '#ffffff',
                                            }}
                                            key={index}
                                        >
                                            <td>
                                                <Highlighter
                                                    autoEscape
                                                    highlightClassName="highlight-term"
                                                    searchWords={[
                                                        formatToQueryParam(
                                                            term,
                                                        ),
                                                    ]}
                                                    textToHighlight={
                                                        item?.B1_COD
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <StyledTdLink
                                                    onClick={() => {
                                                        // setSelectedProduct(item);
                                                        // setSelectedProductId(item?.URL_ID);
                                                        // setShowDetailsModal(true);
                                                    }}
                                                >
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            formatToQueryParam(
                                                                term,
                                                            ),
                                                        ]}
                                                        textToHighlight={
                                                            item?.B1_DESC
                                                        }
                                                    />
                                                </StyledTdLink>
                                            </td>
                                            <td>
                                                <AvailabilityBadge
                                                    product={item}
                                                />
                                            </td>
                                            <td>
                                                {toFixed(
                                                    item?.VLR_TOT,
                                                    2,
                                                ).toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle',
                                                    width: '10%',
                                                }}
                                            >
                                                <NumericInput
                                                    onBudgetEditing
                                                    disabled={false}
                                                    tableItem={item ?? {}}
                                                    item={
                                                        {
                                                            status: InvoiceStatus.Budget,
                                                            isCart: true,
                                                            invoiceId:
                                                                invoice.id,
                                                            id: item?.INVOICE_ITEM_ID,
                                                            productErpCode:
                                                                item?.B1_COD,
                                                            productName:
                                                                item?.B1_DESC,
                                                            productCategory:
                                                                item?.CAT_DESC,
                                                            unitValue:
                                                                item?.VLR_TOT,
                                                            availability:
                                                                item?.boardingDate,
                                                        } as ISaveInvoiceItemDto
                                                    }
                                                    invoice={invoice}
                                                    setInvoice={setCart}
                                                />
                                            </td>
                                            <td>
                                                {(
                                                    item?.quantity *
                                                    item?.VLR_TOT
                                                )?.toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}
                                            </td>
                                        </tr>
                                    );
                                }),
                            )}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col md={12} className="text-center" ref={loadMoreRef}>
                    {(isLoading || isFetchingNextPage || isFetching) && (
                        <Col md={12} className="text-center">
                            <Spinner
                                animation="border"
                                className="mr-2 mt-1"
                                size="sm"
                            />
                            Carregando...
                        </Col>
                    )}
                    {data?.pages[0].data.length === 0 && (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem produtos para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>
        </>
    );
}
