/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Switch } from 'react-router-dom';
import { Detail } from '../../pages/Budgets/Detail';
import List from '../../pages/Budgets/List';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const budgetsListRoute = new Route('/orcamentos');
export const budgetsDetailRoute = new Route('/orcamentos/:id');

export const BudgetsRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={budgetsListRoute.path}
            component={List}
            isPrivate={true}
            exact
        />
        <RouteWrapper
            path={budgetsDetailRoute.path}
            component={Detail}
            isPrivate={true}
            exact
        />
    </Switch>
);
