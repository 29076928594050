import styled from 'styled-components';

export const StyledTitle = styled.h3`
    font-size: 30px !important;
    font-weight: bolder !important;
    color: #707070;
`;

export const StyledLink = styled.h6`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    margin-top: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #707070;
`;
