/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
import { InvoicePaycreditStatus } from '../constants/invoiceStatus';
import getInvoiceStatusText from './getInvoiceStatusText';

export function getSelectInvoicePaycreditStatusText() {
    let newArr: any = [];

    Object.values(InvoicePaycreditStatus).map((key: any) => {
        if (
            key === InvoicePaycreditStatus.InvoiceAwaitingAnalysis ||
            key === InvoicePaycreditStatus.InvoiceAnalysisDone ||
            key === InvoicePaycreditStatus.InvoiceFinishedAnalysis
        ) {
            return newArr.push({
                label: getInvoiceStatusText(key),
                value: key,
            });
        }

        return null;
    });

    return newArr;
}
