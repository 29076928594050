import styled from 'styled-components';

export const StyledLinkAddLine = styled.a`
    color: #2f80ed;
    font-size: 0.75rem;
    cursor: pointer;
`;

export const StyledCloseButton = styled.span`
    margin: -1.5px !important;

    :hover {
        cursor: pointer;
        font-weight: bolder;
    }
`;
