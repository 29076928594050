export default function normalizeString(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function formatCurrency(value: any): string {
    const safeValue =
        typeof value === 'number' && !Number.isNaN(value) ? value : 0;
    return safeValue.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
    });
}

export function formatToQueryParam(str: any) {
    return str.replace(/[*+\\()?]/, '');
}
