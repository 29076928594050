import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledConfirmButton = styled(Button)`
    width: 200px;
`;

export const StyledOutlineButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #f2994a;
    color: #f2994a !important;
    :hover {
        background-color: ${(props) => !props.$disabled && '#f2994a'};
        color: ${(props) => !props.$disabled && '#ffffff !important'};
    }
    :active {
        color: #ffffff !important;
    }
`;
