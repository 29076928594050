import { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { Col, Button, Spinner, ProgressBar } from 'react-bootstrap';
import InvoiceHttpService from '../../../services/http/invoice-http';
import BaseLayout from '../../../components/BaseLayout';
import {
    StyledHeader,
    StyledPageTitle,
    StyledButtonArea,
    StyledGrid,
    StyledLoadingRow,
} from './styles';
import { invoiceAttachmentsListRoute } from '../../../routes/config';
import AttachmentFilePreview from '../../../components/Invoices/InvoiceAttachments/AttachmentFilePreview';
import NewAttachmentForm, {
    AttachmentFields,
} from '../../../components/Invoices/InvoiceAttachments/NewAttachmentForm';
import InvoiceAttachmentStatus from '../../../constants/InvoiceAttachmentStatus';
import AttachmentStatusBadge from '../../../components/Invoices/InvoiceAttachments/AttachmentStatusBadge';
import useInvoiceAttachmentPageAccess from '../../../hooks/useInvoiceAttachmentPageAccess';

interface RouteParams {
    id: string;
    attachmentId: string;
}

export default function Edit() {
    const { id: invoiceId, attachmentId } = useParams<RouteParams>();

    const history = useHistory();

    const invoiceQuery = useInvoiceAttachmentPageAccess(invoiceId);

    const goToAttachmentList = () =>
        history.push(invoiceAttachmentsListRoute.build({ id: invoiceId }));

    const [attachmentFields, setAttachmentFields] =
        useState<AttachmentFields | null>(null);

    const attachmentQuery = useQuery({
        queryKey: ['invoice', invoiceId, 'attachment', attachmentId],
        queryFn: async () => {
            const response = await InvoiceHttpService.getAttachment(
                invoiceId,
                attachmentId,
            );

            return response.data;
        },
        onSuccess: (attachment) => {
            setAttachmentFields({
                bankAccount: attachment.bankAccount?.id || null,
                number: attachment.voucherNumber || '',
                amount: attachment.voucherAmount?.toString(),
                date: attachment.voucherDate
                    ? new Date(attachment.voucherDate)
                    : null,
            });
        },
        onError: () => {
            toast.error('Erro ao buscar informações do comprovante');
            goToAttachmentList();
        },
        enabled: Boolean(invoiceId && attachmentId && !invoiceQuery.isLoading),
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const updateAllowed =
        attachmentQuery.data?.status === InvoiceAttachmentStatus.Unsent;

    const updateAttachmentMutation = useMutation({
        mutationKey: ['update-attachment', attachmentId],
        mutationFn: async () => {
            await InvoiceHttpService.updateAttachment(invoiceId, attachmentId, {
                type: 'DEPOSIT',
                voucherNumber: attachmentFields.number || null,
                voucherDate: attachmentFields.date?.toISOString() || null,
                voucherAmount: attachmentFields.amount
                    ? parseFloat(attachmentFields.amount.replace(',', '.'))
                    : null,
                bankAccount: attachmentFields.bankAccount,
            });
        },
        onSuccess: async () => {
            await attachmentQuery.refetch();
            toast.success('Comprovante atualizado com sucesso');
        },
        onError: () => {
            toast.error('Erro ao atualizar comprovante');
        },
    });

    return (
        <BaseLayout>
            <StyledHeader>
                {attachmentQuery.data && (
                    <Col>
                        <StyledPageTitle>
                            {updateAllowed
                                ? 'Editar Comprovante'
                                : `Comprovante Nº ${attachmentQuery.data?.voucherNumber}`}
                        </StyledPageTitle>
                        {attachmentQuery?.data && !updateAllowed && (
                            <AttachmentStatusBadge
                                status={attachmentQuery.data.status}
                            />
                        )}
                    </Col>
                )}
                <Col>
                    <StyledButtonArea>
                        <Button
                            onClick={goToAttachmentList}
                            variant="outline-primary"
                            className="pl-4 pr-4 mr-2"
                            style={{ minWidth: 125 }}
                        >
                            {updateAllowed ? 'Cancelar' : 'Voltar'}
                        </Button>
                        {updateAllowed && (
                            <Button
                                onClick={() =>
                                    updateAttachmentMutation.mutate()
                                }
                                variant="primary"
                                className="pl-4 pr-4 mr-2"
                                style={{ minWidth: 125 }}
                                disabled={updateAttachmentMutation.isLoading}
                            >
                                {updateAttachmentMutation.isLoading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    'Salvar'
                                )}
                            </Button>
                        )}
                    </StyledButtonArea>
                </Col>
            </StyledHeader>
            {attachmentQuery.isLoading || !attachmentFields ? (
                <StyledLoadingRow>
                    <Col>
                        <ProgressBar
                            animated
                            now={100}
                            label="Carregando dados do comprovante..."
                        />
                    </Col>
                </StyledLoadingRow>
            ) : (
                <StyledGrid>
                    <NewAttachmentForm
                        mode="edit"
                        onValuesChange={setAttachmentFields}
                        values={attachmentFields}
                        disabled={!updateAllowed}
                    />
                    <AttachmentFilePreview
                        file={attachmentQuery.data.filename}
                    />
                </StyledGrid>
            )}
        </BaseLayout>
    );
}
