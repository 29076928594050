/* eslint-disable import/no-useless-path-segments */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { StyledRow } from './styles';

import { usePoint } from '../../../../contexts/pointContext';
import { Role } from '../../../../constants/role';
import userHasRoles from '../../../../utils/userHasRoles';
import WishlistHttpService from '../../../../services/http/wishlist-http';
import PointHttpService from '../../../../services/http/point-http';

import {
    pointStatementRoute,
    pointStoreRoute,
    pointManagementRoute,
} from '../../../../routes/config';
import Detail from '../../../../components/Points/ProductModals/Detail';
import { List } from '../../../../pages/Integrators/List';
import BaseLayout from '../../../../components/BaseLayout';
import SideNavBar from '../../../../components/Points/SideNavBar/SideNavBar';
import BalanceCard from '../../../../components/Points/BalanceCard/BalanceCard';
import TitleLink from '../../../../components/Points/TitleLink/TitleLink';
import GeneratorsCard from '../../../../components/Points/GeneratorsCard/GeneratorsCard';
import PointTable from '../../../../components/Points/PointTable/PointTable';
import { BANNERS_SRC_URL } from '../../../../config/webservice';
import { AuthValues, useAuth } from '../../../../contexts/authContext';
import { SellerValues, useSeller } from '../../../../contexts/sellerContext';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import BannerLocation from '../../../../constants/bannerLocation';
import BannerHttpService from '../../../../services/http/banner-http';

const GeneralInfo: React.FC = () => {
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [showSellersModal, setShowSellersModal] = useState(false);
    const [points, setPoints] = useState([]);
    const history = useHistory();
    const {
        setProducts,
        showDetailModal,
        setShowDetailModal,
        tradeableBalance,
    } = usePoint();
    const { seller }: SellerValues = useSeller();
    const { user }: AuthValues = useAuth();
    const [banners, setBanners] = useState([]);

    async function getBanners() {
        try {
            const response = await BannerHttpService.readMany({
                active: true,
                location: BannerLocation.pointsInformations,
            });

            setBanners(response.data.results);
        } catch (error) {}
    }

    async function loadPoints(): Promise<void> {
        try {
            if (seller) {
                const response = await PointHttpService.index({
                    take: 5,
                    sellerId: seller.id,
                });

                setPoints(response.data.points);
            } else {
                const response = await PointHttpService.index({
                    take: 5,
                });

                setPoints(response.data.points);
            }
        } catch (error) {}
    }

    async function loadProducts(): Promise<void> {
        try {
            setProducts([]);

            const response = await WishlistHttpService.index({
                term: '',
                skip: 1,
                take: 4,
            });

            setProducts(response.data.data.products);
        } catch (error) {}
    }

    useEffect(() => {
        if (seller || user?.seller?.id || user?.owner?.id) {
            getBanners();
            loadPoints();
            loadProducts();
        }
    }, [tradeableBalance]);

    useEffect(() => {
        getBanners();

        if (
            !seller &&
            userHasRoles(user, [
                Role.Commercial,
                Role.Administrator,
                Role.CommercialSupervisor,
            ])
        ) {
            setShowSellersModal(true);
        }

        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            if (!user?.seller?.id && !seller?.pointsEnabled) {
                history.push(pointManagementRoute.path);
            }
        }
    }, [seller]);

    return (
        <div>
            <List
                show={showSellersModal}
                onHide={() => {
                    setShowSellersModal(false);
                    loadProducts();
                }}
            ></List>
            <Detail
                show={showDetailModal}
                onHide={() => {
                    setShowDetailModal(false);
                }}
            />
            <BaseLayout>
                <Row style={{ height: '100vh' }}>
                    <SideNavBar />

                    <Col className="ml-5 mt-5 mb-5 mr-5">
                        <StyledRow>
                            <Carousel
                                showStatus={false}
                                showThumbs={false}
                                autoPlay={true}
                                infiniteLoop={true}
                            >
                                {banners?.map((item: any) => (
                                    <>
                                        {item?.referLink ? (
                                            <a
                                                href={item.referLink.toString()}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <div key={item?.id}>
                                                    {
                                                        <img
                                                            src={`${BANNERS_SRC_URL}/${item?.fileName}.${item?.format}`}
                                                            alt=""
                                                        />
                                                    }
                                                </div>
                                            </a>
                                        ) : (
                                            <div key={item?.id}>
                                                {
                                                    <img
                                                        src={`${BANNERS_SRC_URL}/${item?.fileName}.${item?.format}`}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                        )}
                                    </>
                                ))}
                            </Carousel>
                        </StyledRow>

                        <StyledRow className="mt-5 mb-3 mr-1">
                            <TitleLink
                                text="Extrato de pontos"
                                link={pointStatementRoute.path}
                            />
                        </StyledRow>

                        <StyledRow className="ml-1 mb-5 w-75">
                            <BalanceCard tradeable qualifiable />
                        </StyledRow>

                        <StyledRow className="mt-5 mb-3">
                            <TitleLink
                                text="Produtos disponíveis para troca"
                                link={pointStoreRoute.path}
                            />
                        </StyledRow>

                        <StyledRow className="ml-3">
                            <GeneratorsCard col={3} />
                        </StyledRow>

                        <StyledRow className="mt-4 mb-3">
                            <TitleLink
                                text="Últimos pontos computados"
                                link={pointStatementRoute.path}
                            />
                        </StyledRow>

                        <PointTable points={points} />
                    </Col>
                </Row>
            </BaseLayout>
        </div>
    );
};

export { GeneralInfo };
