/* eslint-disable import/prefer-default-export */
export function getRolesNames(roles: any) {
    let data = '';
    let cont = 1;

    roles?.forEach((item: any) => {
        data += item.name;

        if (roles.length !== cont) {
            data += ', ';
            cont += 1;
        }
    });

    return data;
}
