/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

export const companyInfoRules = {
    question1: Yup.string().required('Campo é obrigatório'),
    question2: Yup.string().required('Campo é obrigatório'),
    question3: Yup.string().required('Campo é obrigatório'),
    question4: Yup.string().required('Campo é obrigatório'),
    question5: Yup.string().required('Campo é obrigatório'),
    question6: Yup.string().required('Campo é obrigatório'),
};
