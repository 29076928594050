import React from 'react';
import { NavDropdown, Row, Col } from 'react-bootstrap';
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';
import {
    StyledNavDropdownText,
    StyledNavDropdownTitle,
    StyledNavBlueValueText,
    StyledNavValueText,
} from '../styles';
import 'react-circular-progressbar/dist/styles.css';
import { usePoint } from '../../../contexts/pointContext';

const CategoryMeter: React.FC = () => {
    const { sellerCategory, qualifiableBalance } = usePoint();

    const categoryValues = {
        name: sellerCategory?.name,
        nextName: sellerCategory?.nextCategoryName,
        currentBalance: Number(qualifiableBalance?.replaceAll('.', '')),
        targetBalance: Number(
            sellerCategory?.nextCategoryRequirement?.replaceAll('.', ''),
        ),
        pointsRequiredToNext:
            Number(
                sellerCategory?.nextCategoryRequirement?.replaceAll('.', ''),
            ) - Number(qualifiableBalance?.replaceAll('.', '')),
    };

    return (
        <>
            <NavDropdown.Divider />
            <Row className="ml-2">
                <Row>
                    <StyledNavDropdownTitle className="float-left ml-1 mb-1">
                        Sua categoria atual é{' '}
                        <span style={{ color: '#2f81ed' }}>
                            {categoryValues?.name}{' '}
                        </span>
                    </StyledNavDropdownTitle>
                </Row>

                {sellerCategory?.name && (
                    <Row>
                        <Col sm={4} className="ml-1 mb-1">
                            <CircularProgressbarWithChildren
                                className="ml-1"
                                value={categoryValues?.currentBalance}
                                maxValue={categoryValues?.targetBalance}
                                styles={buildStyles({
                                    pathColor: '#2f81ed',
                                    trailColor: '#d6d6d6',
                                })}
                            >
                                <div>
                                    {sellerCategory.name === 'Diamante' ? (
                                        <div style={{ marginTop: '-10px' }}>
                                            <Row className="mt-1 mb-n3">
                                                <StyledNavBlueValueText
                                                    font={
                                                        qualifiableBalance.length
                                                    }
                                                >
                                                    {qualifiableBalance}
                                                </StyledNavBlueValueText>
                                            </Row>
                                        </div>
                                    ) : (
                                        <Row className="mt-1 mb-n3">
                                            <StyledNavBlueValueText
                                                font={qualifiableBalance.length}
                                            >
                                                {qualifiableBalance}
                                            </StyledNavBlueValueText>
                                        </Row>
                                    )}
                                    <Row className="mb-2">
                                        {sellerCategory.name !== 'Diamante' && (
                                            <StyledNavValueText>
                                                {
                                                    sellerCategory?.nextCategoryRequirement
                                                }
                                            </StyledNavValueText>
                                        )}
                                    </Row>
                                </div>
                            </CircularProgressbarWithChildren>
                        </Col>
                        <Col>
                            {sellerCategory.name === 'Diamante' ? null : (
                                <StyledNavDropdownText className="float-left mt-2 ml-n3">
                                    Alcance a categoria{' '}
                                    {categoryValues?.nextName} acumulando mais{' '}
                                    {categoryValues?.pointsRequiredToNext
                                        ?.toString()
                                        .replace(
                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                            '$1.',
                                        )}{' '}
                                    pontos
                                </StyledNavDropdownText>
                            )}
                        </Col>
                    </Row>
                )}
            </Row>
        </>
    );
};

export default CategoryMeter;
