/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createContext, useContext } from 'react';

export interface RouterValues {
    lastRoute: string;
    setLastRoute: any;
}

const RouterContext = createContext<any>({});
const useRouter = () => useContext(RouterContext);

function RouterProvider(props: any) {
    const [lastRoute, setLastRoute] = useState(['']);

    return (
        <RouterContext.Provider
            value={{ lastRoute, setLastRoute }}
            {...props}
        />
    );
}

export { RouterProvider, useRouter };
