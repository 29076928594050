import styled from 'styled-components';

const StyledDivInvoiceType = styled.div`
    background: #f2f2f2;
    width: 140px;
    float: left;
    padding: 4px;
    font-size: 12px;
    min-height: 63px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default StyledDivInvoiceType;
