import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRange = styled(Form.Control)`
    ::-webkit-slider-thumb {
        background: gray;
    }

    ::-moz-range-thumb {
        background: gray;
    }

    ::-ms-thumb {
        background: gray;
    }
`;

export const StyledConfirmButton = styled(Button)`
    width: 200px;
`;

export const StyledCancelButton = styled(Button)`
    width: 200px;
    background-color: #ffffff;
    border: 1px solid solid #f2994a;
    color: #f2994a !important;

    :hover {
        background-color: #f2994a;
        color: #ffffff !important;
    }
`;
