import getSubTotalInvoice from './getSubTotalInvoice';

/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */

export default function getTotalInvoice(invoice: any, percentage?: any) {
    let discountPercentage = invoice.percentage || percentage;
    let totalInvoice = getSubTotalInvoice(invoice);
    let interestPercentage = invoice.interestPercentage;

    if (discountPercentage) {
        totalInvoice -= totalInvoice * (Number(discountPercentage) / 100);
    }

    if (invoice.grossCommission) {
        totalInvoice += Number(invoice.grossCommission);
    }

    if (interestPercentage) {
        totalInvoice += totalInvoice * (Number(interestPercentage) / 100);
    }

    return Math.round(totalInvoice * 100) / 100;
}
