import { Controller, UseFormMethods } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { InvoiceValidationFields } from '../InvoiceValidationForm';
import getSanitizedDiscount from '../../../../utils/getSanitizedDiscount';

interface DiscountInputProps {
    form: UseFormMethods<InvoiceValidationFields>;
    disabled: boolean;
    maxValue: number;
}

export default function DiscountInput({
    form,
    disabled,
    maxValue,
}: DiscountInputProps) {
    return (
        <Form.Group controlId="formBasicDiscount">
            <Form.Label>Desconto</Form.Label>
            <Controller
                control={form.control}
                name="discount"
                render={({ value }) => (
                    <CurrencyInput
                        className="form-control"
                        prefix="R$ "
                        placeholder="R$ #.###,##"
                        allowDecimals
                        allowNegativeValue={false}
                        decimalSeparator=","
                        disabled={disabled}
                        value={value}
                        onValueChange={(newValue: string) => {
                            if (getSanitizedDiscount(newValue) > maxValue) {
                                return;
                            }

                            form.setValue('discount', newValue);
                        }}
                    />
                )}
                rules={{
                    required: 'Campo obrigatório',
                }}
            />
            {form.errors.discount && (
                <Form.Text className="text-danger">
                    {form.errors.discount.message}
                </Form.Text>
            )}
        </Form.Group>
    );
}
