/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Row, Col, InputGroup, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledRow, StyledTitle } from './styles';

import { ReactComponent as GridIcon } from '../../../../assets/icons/gridIcon.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/listIcon.svg';

import { usePoint } from '../../../../contexts/pointContext';
import WishlistHttpService from '../../../../services/http/wishlist-http';

import Detail from '../../../../components/Points/ProductModals/Detail';
import BaseLayout from '../../../../components/BaseLayout';
import SideNavBar from '../../../../components/Points/SideNavBar/SideNavBar';
import BalanceCard from '../../../../components/Points/BalanceCard/BalanceCard';
import GeneratorsCard from '../../../../components/Points/GeneratorsCard/GeneratorsCard';
import GeneratorsTable from '../../../../components/Points/GeneratorsTable/GeneratorsTable';

const PointStore: React.FC = () => {
    let rowsPerPage = 25;
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);

    const {
        viewType,
        setViewType,
        term,
        setTerm,
        setProducts,
        products,
        showDetailModal,
        setShowDetailModal,
    } = usePoint();

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage((p: any) => (p += 1)),
    });

    async function loadProducts(): Promise<void> {
        setLoading(true);

        try {
            const response = await WishlistHttpService.index({
                term: term,
                skip: page,
                take: rowsPerPage,
            });
            const totalItems = response.data.data.meta.TOTAL;

            setProducts((products: any) => [
                ...products,
                ...response.data.data.products,
            ]);

            setHasNextPage(+products?.length >= +totalItems ? false : true);
        } catch (error) {
            toast.error('Erro ao carregar produtos.');
            setHasNextPage(false);
        }

        setLoading(false);
    }

    useEffect(() => {
        setIsFirstRender(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            const timer = setTimeout(() => {
                setProducts([]);
                setPage(1);
                loadProducts();
            }, 500);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        setProducts([]);
        setPage(1);
        loadProducts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Detail
                show={showDetailModal}
                onHide={() => {
                    setShowDetailModal(false);
                }}
            />
            <BaseLayout>
                <Row style={{ height: '100vh' }}>
                    <SideNavBar />

                    <Col className="ml-5 mr-5 mt-5 mb-5">
                        <StyledRow className="ml-0">
                            <BalanceCard tradeable slim />
                        </StyledRow>
                        <StyledRow>
                            <StyledTitle className="mt-5 ml-3">
                                Procure o produto
                            </StyledTitle>
                        </StyledRow>
                        <StyledRow>
                            <Col className="float-left align-top mb-3 ml-0">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <i className="fas fa-search"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        value={term}
                                        onChange={(event) =>
                                            setTerm(event.target.value)
                                        }
                                    />
                                </InputGroup>
                            </Col>

                            <ListIcon
                                className="float-right align-top ml-2"
                                fill={
                                    viewType === 'list'
                                        ? '#F2994A'
                                        : 'rgba(112, 112, 112, 0.3)'
                                }
                                style={{
                                    marginTop: '2px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setViewType('list');
                                }}
                            />
                            <GridIcon
                                className="float-right align-top ml-2"
                                fill={
                                    viewType === 'list'
                                        ? 'rgba(112, 112, 112, 0.3)'
                                        : '#F2994A'
                                }
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setViewType('card');
                                }}
                            />
                        </StyledRow>

                        <StyledRow className="ml-1" ref={infiniteRef}>
                            {viewType === 'card' ? (
                                <GeneratorsCard />
                            ) : (
                                <GeneratorsTable />
                            )}
                        </StyledRow>

                        <StyledRow className="mx-4">
                            <br />
                            {loading && hasNextPage && (
                                <Col md={12} className="text-center">
                                    <Spinner animation={'border'} />
                                </Col>
                            )}

                            {!hasNextPage && !loading && (
                                <Col md={12} className="text-center">
                                    <strong style={{ color: '#adadad' }}>
                                        Sem mais itens para carregar
                                    </strong>
                                </Col>
                            )}
                        </StyledRow>
                    </Col>
                </Row>
            </BaseLayout>
        </div>
    );
};

export { PointStore };
