import BaseLayout from '../../components/BaseLayout';
import BudgetsFilter from '../../components/Invoices/FilterSection';
import BudgetTable from '../../components/Budgets/BudgetTable';
import BudgetHeader from '../../components/Budgets/BudgetHeader';

export default function List() {
    return (
        <BaseLayout>
            <BudgetHeader />
            <BudgetsFilter isBudgetFilter />
            <BudgetTable />
        </BaseLayout>
    );
}
