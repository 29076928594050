/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;

    padding: 5px;
    border-radius: 100%;

    background-color: #ef8526;
`;
