/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Card, Modal, Row, Button, Spinner } from 'react-bootstrap';
import yesNoDialog from '../../../utils/yesNoDialog';
import PointHttpService from '../../../services/http/point-http';
import { usePoint } from '../../../contexts/pointContext';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { SellerValues, useSeller } from '../../../contexts/sellerContext';

const SellerData = (props: any) => {
    const { seller }: SellerValues = useSeller();
    const { user }: AuthValues = useAuth();
    const [loading, setLoading] = useState(false);
    const {
        products,
        selectedProduct,
        loadBalanceAndCategory,
        setShowTradeInfoModal,
        setShowDetailModal,
    } = usePoint();

    async function tradeProduct(wishlist?: boolean) {
        setLoading(true);

        await PointHttpService.trade(selectedProduct?.URL_ID, {
            wishlist,
        });

        loadBalanceAndCategory();

        if (wishlist) {
            let index = products.findIndex(
                (item: any) => item.B1_COD === selectedProduct.B1_COD,
            );

            products[index].wishlist = false;
        }

        setLoading(false);

        setShowTradeInfoModal(false);

        return toast.success('Solicitação realizada com sucesso!');
    }

    return (
        <>
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Endereço de entrega
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100'}>
                    <Col sm={9}>
                        <Row className="ml-0">
                            <Card.Text>
                                CEP{' '}
                                {user?.seller?.addressCep ||
                                    seller?.addressCep ||
                                    user?.owner?.addressCep}
                            </Card.Text>
                        </Row>
                        <Row className="ml-0">
                            <Card.Text>
                                {`${
                                    user?.seller?.addressDescription ||
                                    seller?.addressDescription ||
                                    user?.owner?.addressDescription
                                }, ${
                                    user?.seller?.addressNumber ||
                                    seller?.addressNumber ||
                                    user?.owner?.addressNumber
                                }, ${
                                    user?.seller?.addressNeighborhood ||
                                    seller?.addressNeighborhood ||
                                    user?.owner?.addressNeighborhood
                                }`}
                            </Card.Text>
                        </Row>
                        <Row className="ml-0">
                            <Card.Text>
                                {`${
                                    user?.seller?.erpCityName ||
                                    seller?.erpCityName ||
                                    user?.owner?.erpCityName
                                } ${
                                    user?.seller?.erpState ||
                                    seller?.erpState ||
                                    user?.owner?.erpState
                                } - Brasil`}
                            </Card.Text>
                        </Row>
                    </Col>
                    <Row className="mt-4">
                        <Col className="mr-5">
                            <Button
                                className="w-20 float-right"
                                onClick={async () => {
                                    if (selectedProduct?.wishlist) {
                                        const confirm: any = await yesNoDialog(
                                            'Lista de Desejo',
                                            'Deseja remover este produto da lista de desejos?',
                                            'info',
                                        );

                                        if (confirm) {
                                            tradeProduct(true);
                                        } else {
                                            tradeProduct(false);
                                        }
                                    } else {
                                        tradeProduct();
                                    }
                                }}
                            >
                                {'Concluir troca'}
                                {loading && (
                                    <Spinner
                                        className="mr-2"
                                        animation={'border'}
                                        size={'sm'}
                                    />
                                )}
                            </Button>
                            <Button
                                className="width-auto float-right mr-2"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowTradeInfoModal(false);
                                    setShowDetailModal(true);
                                }}
                            >
                                Voltar
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </>
    );
};

export default SellerData;
