import { toast } from 'react-toastify';
import ApiBasicErrorResponse from '../shared/interfaces/api-basic-error-response.interface';
import ApiErrorResponse from '../shared/interfaces/api-error-response.interface';

function isApiErrorResponse(data: unknown): data is ApiErrorResponse {
    return (
        typeof data === 'object' &&
        'timestamp' in data &&
        'statusCode' in data &&
        'error' in data &&
        'message' in data &&
        'path' in data
    );
}

function isApiBasicErrorResponse(data: unknown): data is ApiBasicErrorResponse {
    return (
        typeof data === 'object' && 'statusCode' in data && 'message' in data
    );
}

function getResponseErrorMessage(
    error: unknown,
    defaultMessage: string,
): string | string[] {
    if (isApiErrorResponse(error)) {
        return typeof error.message === 'string' || Array.isArray(error.message)
            ? error.message
            : defaultMessage;
    }

    return isApiBasicErrorResponse(error) ? error.message : defaultMessage;
}

export default function handleResponseError(
    error: unknown,
    defaultMessage: string,
) {
    const errorMessage = getResponseErrorMessage(error, defaultMessage);

    if (Array.isArray(errorMessage)) {
        const tenSeconds = 10000;

        errorMessage.forEach((message, messagesDisplayed) =>
            toast.error(message, {
                autoClose: (messagesDisplayed + 1) * tenSeconds,
            }),
        );

        return;
    }

    toast.error(errorMessage);
}
