import OtherPaymentMethodsOverview from '../../pages/OthersPaymentMethodsOverview';
import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const othersPaymentMethodsListRoute = new Route(
    '/outros-metodos-de-pagamento',
);

export function OthersPaymentMethodsListRouteComponent() {
    return (
        <RouteWrapper
            path={othersPaymentMethodsListRoute.path}
            exact
            isPrivate
            component={OtherPaymentMethodsOverview}
        />
    );
}
