/* eslint-disable import/prefer-default-export */
export enum Role {
    Administrator = 'ADMINISTRATOR',
    PointsAdministrator = 'POINTS_ADMINISTRATOR',
    Integrator = 'INTEGRATOR',
    Commercial = 'COMMERCIAL',
    Financial = 'FINANCIAL',
    Logistics = 'LOGISTICS',
    CommercialSupervisor = 'COMMERCIAL_SUPERVISOR',
    CustomerSuccess = 'CUSTOMER_SUCCESS',
    Manager = 'MANAGER',
    Contributor = 'CONTRIBUTOR',
    ShippingCompany = 'SHIPPING_COMPANY',
    Production = 'PRODUCTION',
    Registration = 'REGISTRATION',
    Purchase = 'PURCHASE',
    Marketing = 'MARKETING',
}
