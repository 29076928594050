/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Nav, Row, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import hearthIcon from '../../../assets/icons/Path.png';
import whiteHearthIcon from '../../../assets/icons/whiteHearth.png';

import boxIcon from '../../../assets/icons/Group.png';
import greyBoxIcon from '../../../assets/icons/greyBox.png';
import salesIcon from '../../../assets/icons/sales.svg';
import greySales from '../../../assets/icons/greySales.svg';

import panel from '../../../assets/icons/panel.svg';
import orangePanel from '../../../assets/icons/orange_panel.svg';

import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { StyledNav } from '../../../pages/OnlineStore/styles';
import InvoiceHttpService from '../../../services/http/invoice-http';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import SalesCampaignHttpService from '../../../services/http/sales-campaign-http';
import ConfigurationReference from '../../../constants/configurationReference';

const SideNavBar: React.FC = () => {
    const {
        viewType,
        setViewType,
        groupId,
        tab,
        setTab,
        groups,
        productType,
        setGroupId,
        setCart,
        cart,
        setStep,
    } = useOnlineStore();
    const [isBlackFriday, setIsBlackFriday] = useState(false);
    const [salesCampaignName, setSalesCampaignName] = useState('');

    const history = useHistory();

    const loadBlackFriday = async () => {
        const response = await ConfigurationHttpService.readOne(
            ConfigurationReference.SalesCampaign,
        );

        setIsBlackFriday(response.data.value && response.data.value !== '0');

        if (response.data.value && response.data.value !== '0') {
            const active = await SalesCampaignHttpService.indexSeason({
                id: response.data.value,
            });

            setSalesCampaignName(active?.data?.data[0]?.name || 'PROMOÇÃO');
        }
    };

    useEffect(() => {
        loadBlackFriday();
    }, []);

    return (
        <>
            <Row className="mt-4 mb-5">
                <Col sm={12}>
                    <h6
                        className={'ml-3'}
                        style={{
                            fontWeight: 700,
                            color: '#95969B',
                        }}
                    >
                        OPÇÕES
                    </h6>

                    <Button
                        variant={productType === 'MP' ? 'primary' : 'light'}
                        className="text-truncate mr-2 text-left"
                        block
                        onClick={async () => {
                            if (cart?.type !== 'MP') {
                                const resp =
                                    await InvoiceHttpService.changeCartType(
                                        'MP',
                                    );

                                setCart(resp.data);
                            }

                            setGroupId('1001');

                            history.push('monte-seu-gerador');
                        }}
                    >
                        <Image
                            className="mr-2"
                            src={
                                productType === 'MP'
                                    ? whiteHearthIcon
                                    : hearthIcon
                            }
                        />
                        MONTE SEU GERADOR
                    </Button>
                    <Button
                        variant={productType === 'PA' ? 'primary' : 'light'}
                        onClick={async () => {
                            if (cart?.type !== 'PA') {
                                const resp =
                                    await InvoiceHttpService.changeCartType(
                                        'PA',
                                    );

                                setCart(resp.data);
                            }

                            history.push('kits-prontos');
                        }}
                        className="text-truncate text-left"
                        block
                    >
                        <Image
                            className="mr-2"
                            src={productType === 'PA' ? boxIcon : greyBoxIcon}
                        />
                        KITS PRONTOS
                    </Button>
                    {isBlackFriday && (
                        <Button
                            variant={productType === 'BF' ? 'primary' : 'light'}
                            onClick={async () => {
                                if (cart?.type !== 'BF') {
                                    const resp =
                                        await InvoiceHttpService.changeCartType(
                                            'BF',
                                        );

                                    setCart(resp.data);
                                }

                                history.push('sales');
                            }}
                            className="text-truncate text-left"
                            block
                        >
                            <Image
                                className="mr-2"
                                src={
                                    productType === 'BF' ? salesIcon : greySales
                                }
                            />
                            {salesCampaignName.toUpperCase()}
                        </Button>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {productType === 'PA' && (
                        <h6
                            style={{
                                fontWeight: 700,
                                color: '#95969B',
                                marginBottom: -15,
                            }}
                            className="ml-3 mt-2"
                        >
                            FILTRAR POR KIT
                        </h6>
                    )}

                    {productType === 'PA' && (
                        <StyledNav
                            activeKey={tab}
                            onSelect={(selectedKey: string) =>
                                setTab('' + selectedKey)
                            }
                        >
                            <Nav.Item
                                style={{ width: '100%', lineHeight: '32px' }}
                            >
                                <Nav.Link
                                    style={{
                                        color:
                                            groupId === ''
                                                ? '#F2994A'
                                                : '#707070',
                                    }}
                                    eventKey={'TODOS'}
                                    onClick={() => {
                                        setGroupId('');
                                        setStep(2);

                                        if (viewType === 'detail') {
                                            setViewType('card');

                                            history.goBack();
                                        }
                                    }}
                                >
                                    <Image
                                        className={'mr-2'}
                                        src={
                                            groupId === '' ? orangePanel : panel
                                        }
                                    />
                                    TODOS
                                </Nav.Link>
                            </Nav.Item>
                            {groups &&
                                groups.map((item: any, index: any) => (
                                    <Nav.Item
                                        style={{
                                            width: '100%',
                                            lineHeight: '32px',
                                        }}
                                        key={index}
                                    >
                                        <Nav.Link
                                            key={index}
                                            style={{
                                                color:
                                                    groupId === item.CAT_COD
                                                        ? '#F2994A'
                                                        : '#707070',
                                            }}
                                            eventKey={index}
                                            onClick={() => {
                                                setStep(2);
                                                setGroupId(item.CAT_COD);
                                            }}
                                        >
                                            <Image
                                                key={index}
                                                className={'mr-2'}
                                                src={
                                                    groupId === item.CAT_COD
                                                        ? orangePanel
                                                        : panel
                                                }
                                            />
                                            {item.CAT_DESC}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                        </StyledNav>
                    )}
                </Col>
            </Row>
        </>
    );
};

SideNavBar.propTypes = {
    content: PropTypes.object,
};

export default SideNavBar;
