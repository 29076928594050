import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background-color: #ffffff !important;
`;

export const StyledTh = styled.th`
    background-color: #fafafa;
`;

export const StyledTr = styled.tr`
    background-color: #fbfbfb;
    font-weight: bolder;
`;

export const StyledTdText = styled.h5`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #696a72;
`;

export const StyledTdLink = styled.a`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #696a72;
    :hover {
        cursor: pointer !important;
        text-decoration: underline;
        color: #828282;
    }
`;

export const StyledTitle = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #707070;
`;
