import { useHistory } from 'react-router';
import { ReactComponent as ReadyKitsIcon } from '../assets/icons/readyKits.svg';
import { ReactComponent as SolarPanelIcon } from '../assets/icons/solarPanel.svg';
import { ReactComponent as InvoiceIcon } from '../assets/icons/invoice.svg';
import { ReactComponent as HeadsetIcon } from '../assets/icons/headset-solid.svg';
import { HomeCardProps } from '../components/Dashboard/HomeCard';
import { LIBRARIES_URL } from '../config/crm';
import { Role } from '../constants/role';
import { AuthValues, useAuth } from '../contexts/authContext';
import { useOnlineStore } from '../contexts/onlineStoreContext';
import { SellerValues, useSeller } from '../contexts/sellerContext';
import {
    pointManagementRoute,
    pointGeneralInfoRoute,
    listReadyKits,
    listGeneratorsRoute,
    invoicesListRoute,
    budgetsListRoute,
} from '../routes/config';
import InvoiceHttpService from '../services/http/invoice-http';
import userHasRoles from '../utils/userHasRoles';

export default function useAccessibleCards(): HomeCardProps[] {
    const history = useHistory();

    const { user }: AuthValues = useAuth();
    const { seller }: SellerValues = useSeller();
    const { setCart, cart, setGroupId } = useOnlineStore();

    const isFinancial = userHasRoles(user, [Role.Financial]);
    const isIntegrator = userHasRoles(user, [Role.Integrator]);
    const isAdministrator = userHasRoles(user, [Role.Administrator]);
    const isCommercialOrAdministrator = userHasRoles(user, [
        Role.Commercial,
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.CustomerSuccess,
    ]);
    const isPointsAdministrator = userHasRoles(user, [
        Role.PointsAdministrator,
    ]);
    const isContributorOrManager = userHasRoles(user, [
        Role.Contributor,
        Role.Manager,
    ]);

    const cards: Array<
        HomeCardProps & {
            userHasPermission: boolean;
        }
    > = [
        {
            onClick: () => {
                history.push(
                    (isAdministrator && seller && !seller?.pointsEnabled) ||
                        (isPointsAdministrator && !isCommercialOrAdministrator)
                        ? pointManagementRoute.path
                        : pointGeneralInfoRoute.path,
                );
            },
            title: 'Pontos',
            description: 'Programa de recompensas para integradores Nexen',
            icon: <InvoiceIcon />,
            userHasPermission:
                isCommercialOrAdministrator ||
                isPointsAdministrator ||
                (!isFinancial && user?.seller?.pointsEnabled),
        },
        {
            onClick: async () => {
                if (cart?.type !== 'PA') {
                    const resp = await InvoiceHttpService.changeCartType('PA');
                    setCart(resp.data);
                }

                history.push(listReadyKits.path);
            },
            title: 'Kits Prontos',
            description:
                'Nosso suporte técnico preparou diversas combinações de geradores, adequando a potência do inversor e módulo de acordo com a capacidade. O objetivo é otimizar a escolha de acordo com o consumo do seu cliente.',
            icon: <ReadyKitsIcon />,
            userHasPermission:
                isCommercialOrAdministrator ||
                isIntegrator ||
                isContributorOrManager,
        },
        {
            onClick: async () => {
                if (cart?.type !== 'MP') {
                    const resp = await InvoiceHttpService.changeCartType('MP');
                    setGroupId('1001');
                    setCart(resp.data);
                }

                history.push(listGeneratorsRoute.path);
            },
            title: 'Monte seu gerador',
            description:
                'Nossa plataforma está habilitada para você montar seu pedido conforme necessidade, após concluir essa etapa, você pode avançar para as opções de gerar orçamento ou concluir pedido.',
            icon: <SolarPanelIcon />,
            userHasPermission:
                isCommercialOrAdministrator ||
                isIntegrator ||
                isContributorOrManager,
        },
        {
            onClick: () => {
                history.push(invoicesListRoute.path);
            },
            title: 'Pedidos',
            description:
                'Todos os pedidos realizados ficam registrados, contribuindo para o acompanhamento e controle dos nossos integradores. Nesta opção também é possível gerar novo pedido a partir do histórico.',
            icon: <InvoiceIcon />,
            userHasPermission:
                isCommercialOrAdministrator ||
                isIntegrator ||
                isContributorOrManager ||
                isFinancial,
        },
        {
            onClick: () => {
                history.push(budgetsListRoute.path);
            },
            title: 'Orçamentos',
            description:
                'Com registro de orçamentos realizados, é possível consultar todo e qualquer cotação efetuada anteriormente, por tanto, quando um cliente retomar contato estes registros podem contribuir com a negociação.',
            icon: <InvoiceIcon />,
            userHasPermission:
                isCommercialOrAdministrator ||
                isIntegrator ||
                isContributorOrManager,
        },
        {
            onClick: () => {
                window.location.href = LIBRARIES_URL;
            },
            title: 'Biblioteca',
            description:
                'Encontre ajuda para os principais problemas, resolva suas dúvidas sobre o setor comercial, sobre a plataforma e ache documentos e vídeos de forma rápida e didática sobre os nossos produtos.',
            icon: <HeadsetIcon />,
            userHasPermission: true,
        },
    ];

    return cards.reduce((accumulator, current) => {
        if (current.userHasPermission) {
            const { onClick, title, description, icon } = current;

            return [...accumulator, { onClick, title, description, icon }];
        }

        return accumulator;
    }, []);
}
