import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface UseDelayedStateOptions {
    delay?: number;
}

const defaultOptions: UseDelayedStateOptions = { delay: 300 };

export default function useDelayedState<T>(
    initialValue: T,
    options: UseDelayedStateOptions = defaultOptions,
): [T, Dispatch<SetStateAction<T>>, T] {
    const [value, setValue] = useState<T>(initialValue);
    const [delayedValue, setDelayedValue] = useState<T>(initialValue);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDelayedValue(value);
        }, options.delay);

        return () => clearTimeout(timeoutId);
    }, [value, options, initialValue]);

    return [delayedValue, setValue, value];
}
