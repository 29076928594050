import AuthHttpService from './http/auth-http';
import { CRM_URL } from '../config/crm';

export default class LoginService {
    public static async logout() {
        localStorage.removeItem('seller');
        sessionStorage.clear();

        await AuthHttpService.logout();

        window.location.href = `${CRM_URL}/login`;
    }
}
