import styled from 'styled-components';

export const StyledPreview = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledPlaceholder = styled.span`
    font-size: 1rem;
    text-align: center;
    color: #707070;
`;

export const StyledObject = styled.object`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 550px;
    object-fit: contain;
    object-position: 0 0;
`;

export const StyledButtonLink = styled.button`
    font-size: 1rem;
    text-align: center;
    color: #707070;
    background: transparent;
    border: none;
    cursor: pointer;
    height: auto;
    width: auto;

    :hover {
        text-decoration: underline;
    }
`;
