export default function getSubTotalInvoice(invoice: any) {
    if (!invoice.invoiceItems) {
        return 0;
    }

    const subTotal = invoice.invoiceItems.reduce(
        (soma: number, item: any) => soma + item.totalValue,
        0,
    );

    return subTotal;
}
