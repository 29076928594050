/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, Image } from 'react-bootstrap';
import {
    PiBooks,
    PiCaretDown,
    PiCaretLeftBold,
    PiCheckSquareOffset,
    PiCube,
    PiGear,
    PiHouse,
    PiPackage,
    PiQuestion,
    PiShoppingBagOpen,
    PiSignOut,
    PiStorefront,
    PiTrophy,
    PiTruck,
} from 'react-icons/pi';
import { useHistory } from 'react-router-dom';
import userIcon from '../../assets/icons/user.svg';
import pages from '../../constants/pages';
import { useAuth } from '../../contexts/authContext';
import { dashboardListRoute, userProfileRoute } from '../../routes/config';
import LoginService from '../../services/login';
import MenuService, { Option } from '../../services/menu';
import userHasRoles from '../../utils/userHasRoles';
import Responsable from '../OnlineStore/Responsable';
import {
    StyledCardBody,
    StyledCardHeader,
    StyledContentDiv,
    StyledFillerDiv,
    StyledHeaderDiv,
    StyledListDiv,
    StyledMainDiv,
    StyledOptionDiv,
    StyledUserDiv,
    StyledUsernameDiv,
} from './styles';
import ConfirmationDialog from '../ConfirmationDialog';
import { NotificationIcon } from '../../constants/notificationIcon';

interface Props {
    show: boolean;
    onHide: () => void;
}

const Sidebar = (props: Props) => {
    const { show, onHide } = props;
    const { user } = useAuth();

    const history = useHistory();
    const componentIsMounted = useRef(true);

    const [menu, setMenu] = useState<Option[]>(MenuService.get(user));

    const userAllowedPages = pages.filter((page) =>
        page.roles.length > 0 ? userHasRoles(user, page.roles) : true,
    );

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    const getIcon = (iconName: string) => {
        if (iconName === 'PiHouse') {
            return <PiHouse />;
        }

        if (iconName === 'PiStorefront') {
            return <PiStorefront />;
        }

        if (iconName === 'PiPackage') {
            return <PiPackage />;
        }

        if (iconName === 'PiShoppingBagOpen') {
            return <PiShoppingBagOpen />;
        }

        if (iconName === 'PiTrophy') {
            return <PiTrophy />;
        }

        if (iconName === 'PiCheckSquareOffset') {
            return <PiCheckSquareOffset />;
        }

        if (iconName === 'PiGear') {
            return <PiGear />;
        }

        if (iconName === 'PiTruck') {
            return <PiTruck />;
        }

        if (iconName === 'PiBooks') {
            return <PiBooks />;
        }

        return <PiQuestion />;
    };

    const goToPath = (path: string) => {
        history.push(path);
        onHide();
    };

    const logout = async () => {
        await LoginService.logout();
    };

    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (!componentIsMounted.current) {
            const menuOptions = MenuService.get(user);

            setMenu(menuOptions);
        }
    }, [user]);

    useEffect(() => {
        componentIsMounted.current = false;
    });

    if (!show) {
        return <Responsable hidden />;
    }

    return (
        <StyledMainDiv>
            <StyledContentDiv>
                <StyledHeaderDiv>
                    <span onClick={() => goToPath(dashboardListRoute.path)}>
                        nexen
                    </span>
                    <PiCaretLeftBold onClick={onHide} />
                </StyledHeaderDiv>

                <StyledUserDiv>
                    <Image
                        className="avatar-border"
                        src={avatar || userIcon}
                        height="63px"
                        roundedCircle
                        onClick={() => goToPath(userProfileRoute.path)}
                        style={{ borderColor: '#545454' }}
                    />
                    <StyledUsernameDiv>
                        <p onClick={() => goToPath(userProfileRoute.path)}>
                            <span>{user.name}</span>
                        </p>
                        <p>
                            <span>{user.email}</span>
                        </p>
                    </StyledUsernameDiv>
                </StyledUserDiv>

                <hr />

                <Responsable sidebar />

                <Accordion>
                    <Card style={{ border: 'none' }}>
                        <Accordion.Toggle
                            as={StyledCardHeader}
                            variant="link"
                            eventKey="0"
                        >
                            <PiCube size={24} />
                            <span>Módulos</span>
                            <PiCaretDown
                                size={24}
                                style={{ marginLeft: 'auto' }}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <StyledCardBody>
                                {userAllowedPages.map(
                                    (page: any, index: number) => (
                                        <a
                                            key={index}
                                            href={page.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {getIcon(page.piIcon)}
                                            <span>{page.name}</span>
                                        </a>
                                    ),
                                )}
                            </StyledCardBody>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <hr />

                <StyledListDiv>
                    {menu.map((option: Option, index: number) => (
                        <StyledOptionDiv
                            key={index}
                            onClick={() => goToPath(option.path)}
                        >
                            {getIcon(option.icon)}
                            <span>{option.text}</span>
                        </StyledOptionDiv>
                    ))}
                </StyledListDiv>

                <StyledOptionDiv
                    onClick={() => setShowConfirmation(true)}
                    style={{ marginTop: 'auto' }}
                >
                    <PiSignOut />
                    <span>Sair</span>
                </StyledOptionDiv>
            </StyledContentDiv>

            <StyledFillerDiv onClick={onHide} />

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={logout}
                    title="Deseja realmente sair?"
                    text="Você irá sair do sistema"
                    icon={NotificationIcon.Logout}
                />
            )}
        </StyledMainDiv>
    );
};

export default Sidebar;
