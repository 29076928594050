import { Col, Row } from 'react-bootstrap';
import { StyledPageSubTitle, StyledPageTitle } from './styles';

export default function OthersPaymentMethodsHeader() {
    return (
        <Row className="header align-items-center pr-2 pl-2">
            <Col sm={6}>
                <StyledPageTitle className="mt-2">
                    Outras formas de pagamento
                </StyledPageTitle>
                <StyledPageSubTitle>
                    Todas as opções disponíveis para o método de pagamento
                    OUTROS
                </StyledPageSubTitle>
            </Col>
        </Row>
    );
}
