import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EraserIcon } from '../../../assets/icons/eraser-solid.svg';
import { ReactComponent as ReactFilterIcon } from '../../../assets/icons/filter.svg';
import { listReadyKits } from '../../../routes/config';
import { StyledPageTitle, StyledPageSubTitle } from '../../../styles/pageTitle';
import {
    invoiceOrderingControl,
    optionsTheme,
    theme,
} from '../../../styles/react-select-config';
import useInvoices from '../../../hooks/useInvoices';
import Select from '../../Select';

export default function InvoiceHeader() {
    const history = useHistory();

    const {
        filterForm,
        showFilterSection,
        setShowFilterSection,
        setFilterTerm,
        filterTerm,
    } = useInvoices();

    const showCleanFiltersButton = filterForm.formState.isDirty || filterTerm;

    return (
        <Row className="header align-items-center pr-2 pl-2">
            <Col sm={6}>
                <StyledPageTitle className="mt-2">Pedidos</StyledPageTitle>
                <StyledPageSubTitle>
                    Todas as informações dos pedidos em um só lugar.
                </StyledPageSubTitle>
            </Col>
            <Col className="text-right" />
            <Col className="mr-n3 mb-1">
                <Form.Group controlId="formBasicOrder">
                    <Form.Label />
                    <Controller
                        isClearable
                        className="form-control-nexen w-100"
                        as={Select}
                        control={filterForm.control}
                        name="filterOrder"
                        options={[
                            {
                                label: 'Data de aprovação',
                                value: 'approvedAt',
                            },
                            {
                                label: 'Data de finalização',
                                value: 'finishedAt',
                            },
                            {
                                label: 'Data de criação',
                                value: 'createdAt',
                            },
                        ]}
                        isMulti={false}
                        styles={{
                            control: invoiceOrderingControl,
                            option: optionsTheme,
                        }}
                        theme={theme}
                        placeholder="Ordenar por..."
                        defaultValue={null}
                    />
                </Form.Group>
            </Col>
            <Col className="mr-n4 mb-1">
                <Form.Group controlId="formBasicOrderType">
                    <Form.Label />
                    <Controller
                        isClearable={false}
                        className="form-control-nexen w-100 mb-1"
                        as={Select}
                        control={filterForm.control}
                        name="filterOrderType"
                        options={[
                            {
                                label: 'Decrescente',
                                value: 'DESC',
                            },
                            {
                                label: 'Crescente',
                                value: 'ASC',
                            },
                        ]}
                        isMulti={false}
                        styles={{
                            control: invoiceOrderingControl,
                            option: optionsTheme,
                        }}
                        theme={theme}
                        placeholder="Decrescente"
                        defaultValue={null}
                    />
                </Form.Group>
            </Col>
            <Col className="text-right">
                <ButtonGroup>
                    {showCleanFiltersButton && (
                        <Button
                            style={{
                                color: '#2F80ED',
                            }}
                            className="mr-2"
                            variant="text"
                            onClick={() => {
                                setFilterTerm('');
                                filterForm.reset();
                            }}
                        >
                            <EraserIcon
                                fill="#2F80ED"
                                className="mr-2"
                                width={18}
                            />{' '}
                            Limpar filtros
                        </Button>
                    )}
                    <Button
                        className="mr-1"
                        variant="light"
                        style={{ backgroundColor: '#EEEEEE' }}
                        onClick={() => setShowFilterSection(!showFilterSection)}
                    >
                        <ReactFilterIcon
                            fill="#BDBDBD"
                            width="16"
                            height="16"
                        />
                    </Button>
                    <Button
                        className="float-right"
                        onClick={() => history.push(listReadyKits.path)}
                        style={{ width: '150px' }}
                    >
                        Novo Pedido
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    );
}
