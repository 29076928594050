import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import BaseLayout from '../../components/BaseLayout';
import OthersPaymentMethodsHeader from '../../components/OnlineStore/OthersPaymentMethodsOverview/OthersPaymentMethodsHeader';
import OthersPaymentMethodsFilter from '../../components/OnlineStore/OthersPaymentMethodsOverview/OthersPaymentMethodsFilter';
import OthersPaymentMethodsTable from '../../components/OnlineStore/OthersPaymentMethodsOverview/OthersPaymentMethodsTable';
import { Role } from '../../constants/role';
import userHasRoles from '../../utils/userHasRoles';
import { useAuth } from '../../contexts/authContext';
import { dashboardListRoute } from '../../routes/config/dashboard.route';

export interface OthersPaymentMethodsFilterFields {
    filterTerm: string;
}

export default function OthersPaymentMethodsOverview() {
    const { user } = useAuth();

    const isInternalUser = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.Logistics,
        Role.CustomerSuccess,
        Role.Registration,
        Role.Purchase,
        Role.Production,
        Role.PointsAdministrator,
        Role.Marketing,
        Role.Financial,
    ]);

    const canAcessOthersPaymentMethodOptions = isInternalUser;

    if (!canAcessOthersPaymentMethodOptions) {
        const history = useHistory();
        history.push(dashboardListRoute.path);
    }

    const filterForm = useForm<OthersPaymentMethodsFilterFields>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
        },
    });

    return (
        <BaseLayout>
            <OthersPaymentMethodsHeader />
            <OthersPaymentMethodsFilter setFilter={filterForm.setValue} />
            <OthersPaymentMethodsTable filterForm={filterForm} />
        </BaseLayout>
    );
}
