/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { NEXEN_BUCKET_SRC_URL } from '../../../config/webservice';
import {
    StyledButton,
    StyledDisplayDiv,
    StyledFileDiv,
    StyledFilesDiv,
    StyledLink,
    StyledMainDiv,
    StyledNewSpan,
    StyledP,
} from './styles';

export interface AttachmentProps {
    id?: number;
    name: string;
    awsKey?: string;
    deletedAt?: Date;
    file?: string;
}

interface Props {
    attachments: AttachmentProps[];
    setAttachments: React.Dispatch<React.SetStateAction<AttachmentProps[]>>;
    disabled: boolean;
    readOnly: boolean;
}

const FileDisplay = (props: Props) => {
    const [selectedAttachment, setSelectedAttachment] =
        useState<AttachmentProps>(null);

    const handleRemove = (e: any, file: AttachmentProps) => {
        if (props.disabled) {
            e.preventDefault();

            return;
        }

        if (file.id) {
            const index = props.attachments.indexOf(file);

            props.attachments[index].deletedAt = new Date();

            props.setAttachments((previousAttachments: AttachmentProps[]) => [
                ...previousAttachments,
            ]);
        } else {
            props.setAttachments(
                props.attachments.filter(
                    (attachment: AttachmentProps) => attachment !== file,
                ),
            );
        }
    };

    const handleClick = (file: string) => {
        const fileSource = `data:application/pdf;base64,${file}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = fileSource;
        downloadLink.download = 'file';
        downloadLink.click();
    };

    useEffect(() => {
        if (
            (selectedAttachment?.awsKey &&
                props.attachments?.length &&
                !props.attachments.find(
                    (attachment: AttachmentProps) =>
                        attachment.awsKey === selectedAttachment.awsKey &&
                        !attachment.deletedAt,
                )) ||
            (selectedAttachment?.file &&
                props.attachments?.length &&
                !props.attachments.find(
                    (attachment: AttachmentProps) =>
                        attachment.file === selectedAttachment.file,
                ))
        ) {
            setSelectedAttachment(null);
        }
    }, [props.attachments, selectedAttachment]);

    return (
        <StyledMainDiv>
            <StyledFilesDiv>
                {props.attachments?.length > 0 &&
                    props.attachments
                        .filter(
                            (attachment: AttachmentProps) =>
                                !attachment.deletedAt,
                        )
                        .map((attachment: AttachmentProps, index: number) => (
                            <StyledFileDiv
                                key={index}
                                selected={
                                    attachment === selectedAttachment ||
                                    (attachment.awsKey &&
                                        attachment.awsKey ===
                                            selectedAttachment?.awsKey)
                                }
                            >
                                <StyledLink
                                    onClick={(e) => {
                                        if (props.disabled && !props.readOnly) {
                                            e.preventDefault();
                                        } else {
                                            setSelectedAttachment(attachment);
                                        }
                                    }}
                                    style={{ paddingRight: 10 }}
                                >
                                    {!attachment.id && (
                                        <StyledNewSpan>NOVO</StyledNewSpan>
                                    )}
                                    {attachment.name}
                                </StyledLink>
                                {!props.readOnly && (
                                    <StyledLink
                                        onClick={(e) =>
                                            handleRemove(e, attachment)
                                        }
                                        close
                                    >
                                        <i
                                            className="fas fa-times-circle"
                                            style={{ fontSize: 14 }}
                                        />
                                    </StyledLink>
                                )}
                            </StyledFileDiv>
                        ))}
            </StyledFilesDiv>

            <StyledDisplayDiv>
                {selectedAttachment ? (
                    <object
                        data={
                            selectedAttachment.awsKey
                                ? NEXEN_BUCKET_SRC_URL +
                                  selectedAttachment.awsKey
                                : `data:application/pdf;base64,${selectedAttachment.file}`
                        }
                        type={'application/pdf'}
                        width="100%"
                        height={props.readOnly ? '650' : '550'}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        {selectedAttachment.awsKey ? (
                            <a
                                href={
                                    NEXEN_BUCKET_SRC_URL +
                                    selectedAttachment.awsKey
                                }
                                target="_blank"
                                rel="noopener noreferrer nofollow external"
                                style={{ marginTop: 20 }}
                            >
                                Realizar download do arquivo
                            </a>
                        ) : (
                            <StyledButton
                                onClick={() =>
                                    handleClick(selectedAttachment.file)
                                }
                            >
                                Realizar download do arquivo
                            </StyledButton>
                        )}
                    </object>
                ) : props.attachments?.length &&
                  props.attachments.find(
                      (attachment: AttachmentProps) => !attachment.deletedAt,
                  ) ? (
                    <StyledP sizeUp={props.readOnly}>
                        Clique sobre o nome de um documento para visualizá-lo
                    </StyledP>
                ) : (
                    <StyledP sizeUp={props.readOnly}>
                        Nenhum documento disponível para visualização
                    </StyledP>
                )}
            </StyledDisplayDiv>
        </StyledMainDiv>
    );
};

export default FileDisplay;
