/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/array-type */
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import CustomerSelection, { CustomerProps } from '../CustomerSelection';
import FileDisplay, { AttachmentProps } from '../FileDisplay';
import {
    StyledDiv,
    StyledDropzoneSection,
    StyledDropzoneTitle,
    StyledMainDiv,
} from './styles';

export interface InvoiceTriangulationProps {
    id?: number;
    customerDocument: string;
    customerName: string;
    addressCity: string;
    addressState: string;
    totalValue: number;
    additionalData: string;
    status?: string;
    attachments: Array<AttachmentProps>;
    originalCustomerDocument?: string;
    originalCustomerName?: string;
    originalAddressCity?: string;
    originalAddressState?: string;
    originalTotalValue?: number;
    originalAdditionalData?: string;
    originalAttachments?: Array<AttachmentProps>;
}

interface Props {
    invoiceTriangulation: InvoiceTriangulationProps;
    setInvoiceTriangulation: React.Dispatch<
        React.SetStateAction<InvoiceTriangulationProps>
    >;
    sellerErpCode: string;
    customer: CustomerProps;
    setCustomer: React.Dispatch<React.SetStateAction<CustomerProps>>;
    disabled: boolean;
    setDisabledByCustomersList: React.Dispatch<React.SetStateAction<boolean>>;
    readOnly: boolean;
}

const maxSize = 5242880;
const maxFiles = 10;

const InvoiceTriangulation = (props: Props) => {
    const componentIsMounted = useRef(true);
    const invoiceTriangulation = props.invoiceTriangulation;
    const setInvoiceTriangulation = props.setInvoiceTriangulation;
    const customer = props.customer;
    const setCustomer = props.setCustomer;

    const [totalValue, setTotalValue] = useState(
        invoiceTriangulation.originalTotalValue?.toFixed(2),
    );

    const [attachments, setAttachments] = useState<AttachmentProps[]>(
        invoiceTriangulation.originalAttachments,
    );

    useEffect(() => {
        setAttachments(invoiceTriangulation.originalAttachments);
        setTotalValue(invoiceTriangulation.originalTotalValue?.toFixed(2));
    }, [
        invoiceTriangulation.originalAttachments,
        invoiceTriangulation.originalTotalValue,
    ]);

    useEffect(() => {
        const updatedAttachments = {
            attachments: attachments,
        };

        setInvoiceTriangulation((prevInvoiceTriangulation) => ({
            ...prevInvoiceTriangulation,
            ...updatedAttachments,
        }));
    }, [attachments, setInvoiceTriangulation]);

    useEffect(() => {
        if (!componentIsMounted.current) {
            const updatedTotalValue = {
                totalValue: totalValue
                    ? parseFloat(totalValue.replace(',', '.'))
                    : 0,
            };

            setInvoiceTriangulation((prevInvoiceTriangulation) => ({
                ...prevInvoiceTriangulation,
                ...updatedTotalValue,
            }));
        }
    }, [totalValue, setInvoiceTriangulation]);

    useEffect(() => {
        componentIsMounted.current = false;
    });

    const handleAdditionalDataChanges = (value: string) => {
        const updatedAdditionalData = {
            additionalData: value,
        };

        setInvoiceTriangulation((prevInvoiceTriangulation) => ({
            ...prevInvoiceTriangulation,
            ...updatedAdditionalData,
        }));
    };

    function arrayBufferToBase64(buffer: any) {
        const bytes = new Uint8Array(buffer);
        let binary = '';

        bytes.forEach((byte) => {
            binary += String.fromCharCode(byte);
        });

        return window.btoa(binary);
    }

    const updateAttachments = (files: File[]) => {
        let attachment: AttachmentProps;

        try {
            files.forEach((file: any) => {
                const reader = new FileReader();

                reader.readAsArrayBuffer(file);

                reader.onload = () => {
                    const buffer = reader.result;

                    attachment = {
                        name: file.name.slice(0, file.name.lastIndexOf('.')),
                        file: arrayBufferToBase64(buffer),
                    };

                    setAttachments((prevAttachments: AttachmentProps[]) => [
                        ...prevAttachments,
                        attachment,
                    ]);
                };
            });
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar arquivos');
        }
    };

    return (
        <StyledMainDiv>
            <StyledDiv>
                <CustomerSelection
                    sellerErpCode={props.sellerErpCode}
                    customer={customer}
                    setCustomer={setCustomer}
                    disabled={props.disabled || props.readOnly}
                    setDisabledByCustomersList={
                        props.setDisabledByCustomersList
                    }
                />

                <Form.Group controlId="formBasicTotalValue">
                    <Form.Label>Valor total da nota fiscal</Form.Label>
                    <CurrencyInput
                        disabled={props.disabled}
                        value={totalValue}
                        name="totalValue"
                        decimalsLimit={2}
                        prefix={'R$'}
                        onValueChange={(value) => setTotalValue(value)}
                        allowDecimals={true}
                        allowNegativeValue={false}
                        placeholder="R$9999,99"
                        className="form-control"
                        decimalSeparator={','}
                        readOnly={props.readOnly}
                    />
                </Form.Group>

                <Form.Group
                    className="pt-1 mb-0"
                    controlId="formBasicAdditionalData"
                >
                    <Form.Label>Dados adicionais da Nota Fiscal</Form.Label>
                    <Form.Control
                        disabled={props.disabled}
                        as="textarea"
                        name="additionalData"
                        onChange={(event: any) => {
                            handleAdditionalDataChanges(
                                event.target.value.replace(/[<>"'&]/g, ''),
                            );
                        }}
                        value={
                            invoiceTriangulation.additionalData
                                ? invoiceTriangulation.additionalData
                                : ''
                        }
                        rows={6}
                        readOnly={props.readOnly}
                    ></Form.Control>
                </Form.Group>
            </StyledDiv>

            <StyledDiv>
                <Form.Group
                    className="pt-1 mb-0"
                    controlId="formBasicAttachments"
                >
                    <Form.Label>
                        Adicionar nota fiscal ou outros documentos
                    </Form.Label>
                </Form.Group>

                {!props.readOnly && (
                    <Dropzone
                        accept={['.pdf']}
                        onDropRejected={() => {
                            toast.error(
                                'Arquivo muito grande ou formato incorreto',
                            );
                        }}
                        multiple
                        maxFiles={maxFiles}
                        maxSize={maxSize}
                        onDrop={(files) => {
                            updateAttachments(files);
                        }}
                        disabled={props.disabled}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <StyledDropzoneSection className="custom-file-upload">
                                <div
                                    {...getRootProps()}
                                    style={{ height: '100%' }}
                                >
                                    <input {...getInputProps()} />
                                    <StyledDropzoneTitle className="dropzone-title p-4 mt-2">
                                        Clique ou arraste para enviar arquivos
                                    </StyledDropzoneTitle>
                                </div>
                            </StyledDropzoneSection>
                        )}
                    </Dropzone>
                )}
                <FileDisplay
                    attachments={attachments}
                    setAttachments={setAttachments}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                />
            </StyledDiv>
        </StyledMainDiv>
    );
};

export default InvoiceTriangulation;
