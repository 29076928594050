import { Badge } from 'react-bootstrap';
import InvoiceAttachmentStatus from '../../../../constants/InvoiceAttachmentStatus';

interface AttachmentStatusBadgeProps {
    status: InvoiceAttachmentStatus;
}

export default function AttachmentStatusBadge({
    status,
}: AttachmentStatusBadgeProps) {
    const style = {
        [InvoiceAttachmentStatus.Unsent]: {
            color: '#707070',
            backgroundColor: 'rgba(112, 112, 112, 0.1)',
        },
        [InvoiceAttachmentStatus.Processing]: {
            color: '#B87D00',
            backgroundColor: 'rgba(184, 125, 0, 0.1)',
        },
        [InvoiceAttachmentStatus.Sent]: {
            color: '#219653',
            backgroundColor: 'rgba(33, 150, 83, 0.1)',
        },
    }[status];

    const text = {
        [InvoiceAttachmentStatus.Unsent]: 'NÃO ENVIADO',
        [InvoiceAttachmentStatus.Processing]: 'EM PROCESSAMENTO',
        [InvoiceAttachmentStatus.Sent]: 'ENVIADO',
    }[status];

    return <Badge style={{ fontSize: '14px', ...style }}>{text}</Badge>;
}
