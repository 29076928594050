import React from 'react';
import { Switch } from 'react-router-dom';
import { Detail } from '../../pages/Invoices/Detail';
import List from '../../pages/Invoices/List';
import { Triangulation } from '../../pages/Invoices/Triangulation';
import AttachmentList from '../../pages/Invoices/Attachments/List';
import AttachmentDetails from '../../pages/Invoices/Attachments/Edit';
import AttachmentNew from '../../pages/Invoices/Attachments/New';

import Route from '../../services/route';
import RouteWrapper from '../RouteWrapper';

export const invoicesListRoute = new Route('/pedidos');
export const invoiceDetailRoute = new Route('/pedidos/:id');
export const invoiceTriangulationRoute = new Route(
    '/pedidos/:id/conta-e-ordem',
);
export const invoiceAttachmentsListRoute = new Route(
    '/pedidos/:id/comprovantes',
);
export const invoiceAttachmentsNewRoute = new Route(
    '/pedidos/:id/comprovantes/novo',
);
export const invoiceAttachmentsEditRoute = new Route(
    '/pedidos/:id/comprovantes/:attachmentId',
);

export const InvoicesRoutesComponent: React.FC = () => (
    <Switch>
        <RouteWrapper
            path={invoicesListRoute.path}
            component={List}
            isPrivate
            exact
        />
        <RouteWrapper
            path={invoiceDetailRoute.path}
            component={Detail}
            isPrivate
            exact
        />
        <RouteWrapper
            path={invoiceTriangulationRoute.path}
            component={Triangulation}
            isPrivate
            exact
        />
        <RouteWrapper
            path={invoiceAttachmentsListRoute.path}
            component={AttachmentList}
            isPrivate
            exact
        />
        <RouteWrapper
            path={invoiceAttachmentsNewRoute.path}
            component={AttachmentNew}
            isPrivate
            exact
        />
        <RouteWrapper
            path={invoiceAttachmentsEditRoute.path}
            component={AttachmentDetails}
            isPrivate
            exact
        />
    </Switch>
);
