import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import { useMutation } from 'react-query';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { DOCUMENTS_SRC_URL } from '../../../config/webservice';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';

interface CommissionProps {
    invoice: any;
    disabled: boolean;
}

export default function Commission({ invoice, disabled }: CommissionProps) {
    const { setCart } = useOnlineStore();
    const [grossCommission, setGrossCommission] = useState<string>(
        invoice.grossCommission?.toString() || '0',
    );

    const updateGrossCommissionMutation = useMutation({
        mutationKey: ['update-gross-commission'],
        mutationFn: async () =>
            InvoiceHttpService.update({
                ...invoice,
                grossCommission: Number(grossCommission.replace(',', '.')),
            }),
        onError: (error) => {
            if (isFriendlyHttpError(error)) {
                toast.error((error as { message: string }).message);
                return;
            }

            toast.error('Ocorreu um erro ao atualizar o valor da comissão');

            setGrossCommission(invoice.grossCommission?.toString() || '0');
        },
        onSuccess: (data) => {
            setCart(data.data);
        },
    });

    const commissionHasBeenUpdated =
        Number(grossCommission.replace(',', '.')) !== invoice.grossCommission;

    if (!invoice.paymentMethod) {
        return (
            <div
                style={{
                    display: 'flex',
                    marginTop: '60px',
                    marginBottom: '50px',
                    justifyContent: 'center',
                }}
            >
                Para adição do valor de comissão, é necessário selecionar o
                método de pagamento do pedido
            </div>
        );
    }

    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <Form.Group
                            className="pt-4"
                            controlId="formBasicGrossCommission"
                        >
                            <Form.Label>Valor da comissão</Form.Label>
                            <Form.Control
                                name="grossCommission"
                                disabled={disabled}
                                as={CurrencyInput}
                                intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                }}
                                decimalScale={2}
                                value={grossCommission}
                                onValueChange={(value: string) =>
                                    setGrossCommission(value || '0')
                                }
                                onBlur={() => {
                                    if (commissionHasBeenUpdated) {
                                        updateGrossCommissionMutation.mutateAsync();
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group
                            className="pt-4"
                            controlId="formBasicNetCommission"
                        >
                            <Form.Label>Valor líquido da comissão</Form.Label>
                            <Form.Control
                                name="netCommission"
                                disabled
                                as={CurrencyInput}
                                intlConfig={{
                                    locale: 'pt-BR',
                                    currency: 'BRL',
                                }}
                                decimalScale={2}
                                value={invoice.netCommission || '0'}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <p>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${DOCUMENTS_SRC_URL}/instrucoes_pagamento_integrador.pdf?v=novo`}
                            >
                                Clique aqui
                            </a>{' '}
                            para ver as instruções sobre pagamento de prestação
                            de serviço do integrador.
                        </p>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
