import { AxiosResponse } from 'axios';
import Request from './request';
import OthersPaymentMethod from '../../shared/interfaces/otherPaymentMethods.interface';
import PaginatedResponse from '../../shared/interfaces/paginated-response.interface';

export default class PaymentHttpService {
    private static uri = 'payments';

    public static generateCheckout(invoiceId: string) {
        return Request.post(`${this.uri}/invoice/${invoiceId}/checkout`);
    }

    public static generatePix(invoiceId: string) {
        return Request.post(`${this.uri}/invoice/${invoiceId}/pix`);
    }

    public static listProtheusPaymentMethods(erpTenantId: any) {
        return Request.get(`erp/${this.uri}`, erpTenantId);
    }

    public static listPaymentMethods() {
        return Request.get(`${this.uri}`);
    }

    public static listBankAccounts() {
        return Request.get(`${this.uri}/bank-accounts`);
    }

    public static readManyOthersPaymentMethods(params: {
        skip?: number;
        take?: number;
        term?: string;
    }): Promise<AxiosResponse<PaginatedResponse<OthersPaymentMethod>>> {
        return Request.get(`${this.uri}/others`, params);
    }
}
