import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledHeader = styled(Row)`
    background-color: #ffffff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
    align-items: center;
    padding-block: 1rem;
`;

export const StyledPageTitle = styled.h3`
    font-size: 22px;
    font-weight: bolder;
    margin: 0;
    margin-bottom: 0.5rem;
`;

export const StyledButtonArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const StyledTotalizersRow = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
`;

export const StyledTotalizer = styled.span`
    color: ${(props) => props.color};
`;

export const StyledLoadingRow = styled(Row)`
    align-items: center;
    padding-top: 2rem;
`;

export const StyledGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding-block: 1rem;
    margin-top: 2rem;
`;
