/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import { Modal, ModalFooter, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import IntegratorPartner from '../../components/Integrator/IntegratorPartner';
import IntegratorCompanyInfo from '../../components/Integrator/IntegratorCompanyInfo';
import { partnerRules } from '../../validations/partnerRules';
import { companyInfoRules } from '../../validations/companyInfoRules';
import getValidationsErrors from '../../utils/getValidationsErrors';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { AuthValues, useAuth } from '../../contexts/authContext';
import SellerHttpService from '../../services/http/seller-http';

interface Props {
    show: boolean;
    onHide: Function;
}

const IntegratorPartnerModal: React.FC<Props> = (props: Props) => {
    const { user, setUser }: AuthValues = useAuth();

    const newIntegrator = () => ({
        partnerName: '',
        partnerDocument: '',
        partnerBirthDate: '',
        partnerEmail: '',
        partnerPhone: '',
    });

    const [errors, setErrors] = useState(newIntegrator());
    const [integrator, setIntegrator] = useState(newIntegrator());

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};

        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });

        setIntegrator({ ...integrator, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const prepareData = (data: any) => {
        const companyInfo = {
            companyInfo: {
                partnerName: data.partnerName,
                partnerDocument: data.partnerDocument,
                partnerBirthDate: data.partnerBirthDate,
                partnerEmail: data.partnerEmail,
                partnerPhone: data.partnerPhone,
                question1: data.question1,
                question2: data.question2,
                question3: data.question3,
                question4: data.question4,
                question5: data.question5,
                question6: data.question6,
            },
        };

        return companyInfo;
    };

    const handleSubmit = async () => {
        try {
            const schemaPartner = Yup.object().shape(partnerRules);

            await schemaPartner.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });

            const schemaCompany = Yup.object().shape(companyInfoRules);

            await schemaCompany.validate(integrator, {
                abortEarly: false,
                context: integrator,
            });
        } catch (error) {
            let err = error as any;

            setErrors(getValidationsErrors(err) as any);

            return;
        }

        try {
            const data = prepareData(integrator);

            const response = await SellerHttpService.updateAuth(data);

            setUser({
                ...user,
                seller: {
                    ...user.seller,
                    companyInfo: response.data.companyInfo,
                },
            });

            props.onHide();
        } catch (error) {
            let err = error as any;

            if (isFriendlyHttpError(err)) {
                return toast.error(err.message);
            }

            return toast.error('Erro ao salvar dados do integrador!');
        }
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Preencha os dados
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'ml-3 mr-3'}>
                    <p>Preencha os dados para acessar o sistema</p>
                    <IntegratorPartner
                        signUp={false}
                        errors={errors}
                        integrator={integrator}
                        handleChanges={handleChanges}
                    ></IntegratorPartner>
                    <IntegratorCompanyInfo
                        signUp={false}
                        errors={errors}
                        integrator={integrator}
                        seller={user.seller}
                        handleChanges={handleChanges}
                    ></IntegratorCompanyInfo>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Finalizar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export { IntegratorPartnerModal };
