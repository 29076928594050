import BankAccount from '../shared/interfaces/bankAccount.interface';

export default function getBankAccountString({
    bankNumber,
    bankName,
    branchCode,
    bankAccount,
}: BankAccount) {
    return `${bankNumber} ${bankName}, AGÊNCIA ${branchCode}, CONTA ${bankAccount}`;
}
