import { CSSProperties } from 'react';
import { Nav } from 'react-bootstrap';
import Brand from '../../../shared/interfaces/brand.interface';

interface BrandMenuItemProps {
    brand: Brand;
    active: boolean;
}

export default function BrandMenuItem({ brand, active }: BrandMenuItemProps) {
    const activeStyle: CSSProperties = {
        color: '#F2994A',
        fontWeight: 600,
    };

    const inactiveStyle: CSSProperties = {
        color: '#707070',
    };

    const style = active ? activeStyle : inactiveStyle;

    return (
        <Nav.Item>
            <Nav.Link style={style} eventKey={brand.ZAM_COD}>
                {brand.ZAM_DESC}
            </Nav.Link>
        </Nav.Item>
    );
}
