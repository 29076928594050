import Request from './request';

export default class ReturnHttpService {
    private static uri = 'returns';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static saveReturn(id: string, params: { observation: string }) {
        return Request.post(`${this.uri}/${id}/return`, params);
    }
}
