import Request from './request';

export default class kitHttpService {
    private static uri = 'kits';

    public static getModules(data: any) {
        return Request.get(`${this.uri}/modules`, data);
    }

    public static getRoofTypes(data: any) {
        return Request.get(`${this.uri}/rooftypes`, data);
    }

    public static addByStructureCalculationKit(item: any, budget?: boolean) {
        let retorno;

        if (budget) {
            retorno = Request.post(`${this.uri}/structures/budget`, item);
        } else {
            retorno = Request.post(`${this.uri}/structures`, item);
        }

        return retorno;
    }

    public static getFilters() {
        return Request.get(`${this.uri}/modules/filters`);
    }

    public static getInverterBrands(params?: any) {
        return Request.get(`${this.uri}/inverterBrands`, params);
    }

    public static getInverters(params?: any) {
        return Request.get(`${this.uri}/inverters`, params);
    }
}
