import Request from './request';

export default class IntegrationHttpService {
    private static uri = 'integrations';

    public static getSintegraData(cnpj: string) {
        return Request.get(`${this.uri}/sintegra/${cnpj}`);
    }

    public static getReceitaData(cnpj: string) {
        return Request.get(`${this.uri}/receita/${cnpj}`);
    }
}
