import { SelectOption } from '../components/Select';
import { getPaymentMethodText } from '../utils/getPaymentMethodText';
import { PaymentMethod } from './paymentMethod';

const paymentMethodOptions: Array<SelectOption<string>> = Object.values(
    PaymentMethod,
).map((status) => ({
    label: getPaymentMethodText(status),
    value: status,
}));

export default paymentMethodOptions;
