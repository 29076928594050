import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled.div`
    background: #ffffff;
    border: 1px solid #e3e7ee;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    height: 90%;
    border: 1px solid #e3e7ee;
    border-radius: 1px !important;
`;

export const StyledCardBody = styled(Card.Body)`
    min-height: 150px;
`;

export const StyledCardTitle = styled(Card.Title)`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #707070;
`;

export const StyledCardSmallText = styled(Card.Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #707070;
`;

export const StyledCardText = styled(Card.Text)`
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    color: #333333;
`;
