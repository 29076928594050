/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Modal, Nav, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as Yup from 'yup';
import { finacingNavItems } from '../../../../constants/onlineStore';
import FinancingData from '../FinancingData';
import JuridicPerson from '../JuridicPerson';
import Representative from '../Representative';
import CommercialReferences from '../CommercialReferences';
import BankData from '../BankData';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';
import { StyledModalSub } from '../../Base/styles';
import {
    financingDataRules,
    juridicPersonRules,
    representativeRules,
} from '../../../../validations/financing';
import bv from '../../../../assets/bv.png';
import rightArrow from '../../../../assets/icons/rightarrow.png';
import smallCricle from '../../../../assets/icons/smallGreyCircle.png';
import getValidationsErrors from '../../../../utils/getValidationsErrors';

const FinancingModal = (props: any) => {
    dayjs.extend(utc);

    const { setReloadCart } = useOnlineStore();

    const newFinancing = () => ({
        id: '',
        invoiceId: props.invoice ? props.invoice?.id : '',
        equipmentValue: 0,
        inputValue: 0,
        financedValue: 0,
        waitingPeriod: 0,
        installments: 0,
        installmentsValue: 0,
        entityCompanyName: '',
        entityFancyName: '',
        entityCnpj: '',
        entityCapitalStock: 0,
        entityLastYearRevenue: 0,
        entityUptime: '',
        entityMainActivity: '',
        entityEmail: '',
        entityPhone: '',
        entityCellphone: '',
        entityAddress: '',
        entityNeighborhood: '',
        entityCityState: '',
        entityOwnProperty: true,
        entityAddressCep: '',
        personName: '',
        personEmail: '',
        personCpf: '',
        personRg: '',
        personRgIssuer: '',
        personBirthdate: new Date(),
        personSex: '',
        personCivilStatus: '',
        personBirthStateCity: '',
        personFatherName: '',
        personMotherName: '',
        personSchooling: '',
        personPatrimony: 0,
        personMontlyIncome: 0,
        personPhone: '',
        personCellphone: '',
        personProfession: '',
        personProfessionNature: '',
        personCompanyName: '',
        personCompanyCnpj: '',
        commercialRefName: '',
        commercialRefPhone: '',
        commercialRefBond: '',
        bank: '',
        bankAgency: '',
        bankAccount: '',
        bankAccountType: '',
    });

    const [financingItem, setFinancingItem] = useState(1);
    const [financing, setFinancing] = useState(newFinancing());
    const [errors, setErrors] = useState(newFinancing());
    const [type, setType] = useState('');

    useEffect(() => {
        if (props.invoice?.financing) {
            setFinancing(props.invoice?.financing);
        } else {
            setFinancing(newFinancing());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invoice]);

    const clearErrors = () => setErrors(newFinancing());

    const goToNextStep = async () => {
        let schema;

        switch (financingItem) {
            case 1:
                schema = Yup.object().shape(financingDataRules);
                break;
            case 2:
                schema = Yup.object().shape(juridicPersonRules);
                break;
            case 3:
                schema = Yup.object().shape(representativeRules);
                break;
            default:
                setFinancingItem((financingItem) => financingItem + 1);
                break;
        }

        clearErrors();

        try {
            await schema.validate(financing, {
                abortEarly: false,
                context: financing,
            });

            if (type === 'person' && financingItem === 1) {
                setFinancingItem((financingItem) => financingItem + 1);
            }

            setFinancingItem((financingItem) => financingItem + 1);
        } catch (error) {
            setErrors(getValidationsErrors(error) as any);
        }
    };

    const handleCurrencyChanges = (value: any, name: any) => {
        const changes: any = {};
        const newErrors: any = {};

        changes[name] = value;

        newErrors[name] = undefined;

        setFinancing({ ...financing, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};

        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            changes[item.target.name] = item.target.value;

            newErrors[item.target.name] = undefined;
        });

        setFinancing({ ...financing, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const prepareData = (data: any) => {
        let financing = data;

        const personBirthdate = new Date(financing.personBirthdate);

        financing.personBirthdate = personBirthdate.setHours(
            personBirthdate.getHours() + 2,
        );

        return financing;
    };

    const save = async () => {
        try {
            const data = prepareData(financing);

            await InvoiceHttpService.addFinancing(data);

            setReloadCart((reload: boolean) => !reload);
            toast.success('Dados de financiamento salvos com sucesso!');

            props.onHide();
        } catch (err) {
            toast.error('Erro ao salvar informações de financiamento!');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        await save();
    };

    return (
        <Modal {...props} size="xl" onExit={() => setType('')} centered>
            <Modal.Header closeButton>
                <Modal.Title
                    className={'d-flex'}
                    id="contained-modal-title-vcenter"
                >
                    <Image
                        src={bv}
                        className={'mt-2'}
                        style={{ height: '100%' }}
                    />

                    <div className={'ml-4'}>
                        <div>BV Financeira: Análise de Crédito</div>

                        <div>
                            <StyledModalSub>
                                Informe os dados obrigatórios para avançar nos
                                passos da análise.
                            </StyledModalSub>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'w-100 mr-3'}>
                {type === '' && (
                    <>
                        <Card
                            as={'a'}
                            onClick={() => setType('person')}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #E0E0E0',
                                boxShadow:
                                    '0px 4px 14px 1px rgba(0, 0, 0, 0.04)',
                                borderRadius: '6px',
                            }}
                            className="pl-2 mr-5 ml-5 mt-4"
                        >
                            <Card.Body>
                                <Row>
                                    <Col sm={1}>
                                        <Image
                                            className="mt-4"
                                            src={smallCricle}
                                            roundedCircle
                                        />
                                    </Col>
                                    <Col>
                                        <Card.Subtitle className="mb-2 text-muted">
                                            <strong>Pessoa Física</strong>
                                        </Card.Subtitle>
                                        <Card.Text>
                                            Selecione esta opção se a análise de
                                            crédito é para uma pessoa física.
                                        </Card.Text>
                                    </Col>
                                    <Col>
                                        <Image
                                            className="mt-4 mr-3 float-right"
                                            src={rightArrow}
                                            roundedCircle
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card
                            as={'a'}
                            style={{
                                cursor: 'pointer',
                                border: '1px solid #E0E0E0',
                                boxShadow:
                                    '0px 4px 14px 1px rgba(0, 0, 0, 0.04)',
                                borderRadius: '6px',
                            }}
                            onClick={() => setType('entity')}
                            className="mb-5 pl-2 mr-5 ml-5 mt-3"
                        >
                            <Card.Body>
                                <Row>
                                    <Col sm={1}>
                                        <Image
                                            className="mt-4"
                                            src={smallCricle}
                                            roundedCircle
                                        />
                                    </Col>
                                    <Col>
                                        <Card.Subtitle className="mb-2 text-muted">
                                            <strong>Pessoa Jurídica</strong>
                                        </Card.Subtitle>
                                        <Card.Text>
                                            Selecione esta opção se a análise de
                                            crédito é para uma pessoa jurídica.
                                        </Card.Text>
                                    </Col>
                                    <Col>
                                        <Image
                                            className="mt-4 mr-3 float-right"
                                            src={rightArrow}
                                            roundedCircle
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </>
                )}
                {type !== '' && (
                    <div>
                        <div
                            // className={'mr-5 ml-4'}
                            style={{
                                borderBottom: '1px solid #E0E0E0',
                            }}
                        >
                            <Nav activeKey={financingItem}>
                                {finacingNavItems.map((item, index: any) => {
                                    if (type === 'person' && item.step === 2) {
                                        return null;
                                    }

                                    if (type === 'person' && item.step === 3) {
                                        item.name = 'Dados de pessoa física';
                                    }

                                    return (
                                        <Nav.Item key={index}>
                                            <Nav.Link
                                                style={
                                                    financingItem === item.step
                                                        ? {
                                                              borderBottom:
                                                                  '2px solid #F2994A',

                                                              color: '#F2994A',
                                                              fontWeight: 600,
                                                          }
                                                        : {
                                                              color: '#707070',
                                                          }
                                                }
                                                eventKey={item.name}
                                                // onClick={() => {
                                                //     setFinancingItem(item.step);
                                                // }}
                                            >
                                                {item.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav>
                        </div>
                        <Row className="pl-2 pr-2 mt-4 light-scrollbar">
                            <Col>
                                {financingItem === 1 && (
                                    <FinancingData
                                        disabled={props.disabled}
                                        errors={errors}
                                        financing={financing}
                                        handleChanges={handleChanges}
                                        handleCurrencyChanges={
                                            handleCurrencyChanges
                                        }
                                    />
                                )}
                                {financingItem === 2 && (
                                    <JuridicPerson
                                        disabled={props.disabled}
                                        errors={errors}
                                        financing={financing}
                                        handleChanges={handleChanges}
                                        handleCurrencyChanges={
                                            handleCurrencyChanges
                                        }
                                    />
                                )}
                                {financingItem === 3 && (
                                    <Representative
                                        disabled={props.disabled}
                                        type={type}
                                        errors={errors}
                                        financing={financing}
                                        handleChanges={handleChanges}
                                        handleCurrencyChanges={
                                            handleCurrencyChanges
                                        }
                                    />
                                )}
                                {financingItem === 4 && (
                                    <CommercialReferences
                                        disabled={props.disabled}
                                        errors={errors}
                                        financing={financing}
                                        handleChanges={handleChanges}
                                        handleCurrencyChanges={
                                            handleCurrencyChanges
                                        }
                                    />
                                )}
                                {financingItem === 5 && (
                                    <BankData
                                        disabled={props.disabled}
                                        errors={errors}
                                        financing={financing}
                                        handleChanges={handleChanges}
                                        handleCurrencyChanges={
                                            handleCurrencyChanges
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col>
                        <Button
                            className={'mr-n3'}
                            onClick={() => {
                                if (financingItem !== 1) {
                                    if (
                                        type === 'person' &&
                                        financingItem === 3
                                    ) {
                                        setFinancingItem(
                                            (financingItem) =>
                                                financingItem - 1,
                                        );
                                    }

                                    setFinancingItem(
                                        (financingItem) => financingItem - 1,
                                    );
                                } else {
                                    setType('');
                                }
                            }}
                            style={{
                                width: '134px',
                                height: '42px',
                                backgroundColor: '#ffffff',
                                border: '1px solid #F2994A',
                                color: '#F2994A',
                            }}
                            variant="light"
                            size={'lg'}
                        >
                            {financingItem === 1 ? 'Cancelar' : 'Voltar'}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={(event: any) => {
                                if (financingItem > 4) {
                                    handleSubmit(event);
                                } else {
                                    goToNextStep();
                                }
                            }}
                            style={{
                                width: '134px',
                                height: '42px',
                            }}
                            size={'lg'}
                            variant={'primary'}
                        >
                            {financingItem > 4 ? 'Concluir Análise' : 'Próximo'}
                        </Button>{' '}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default FinancingModal;
