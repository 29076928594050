import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
    background-color: #ffffff !important;
`;

export const StyledPageTitle = styled.h1`
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 16px;
    color: #484848;
`;

export const StyledTitle = styled.h3`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #707070;
`;

export const StyledSubtitle = styled.h3`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #707070;
`;

export const StyledTdText = styled.h5`
    font-weight: normal;
    font-size: 0.9rem;
    letter-spacing: 0.05em;
    color: #828282;
`;
