/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { ListGroup, Badge, Image, Col, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { ReactComponent as ReactProfileIcon } from '../../../assets/icons/profile.svg';
import InvoiceHttpService from '../../../services/http/invoice-http';

interface Props {
    invoice: any;
}

export function Logs({ invoice }: Props) {
    const { user }: AuthValues = useAuth();
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadLogs() {
        try {
            const { data } = await InvoiceHttpService.logs(invoice.id);

            setLogs(
                data.map((log: any) => ({
                    ...log,
                    user:
                        log.user &&
                        log.user.avatar &&
                        Buffer.isBuffer(log.user.avatar)
                            ? {
                                  avatar: Buffer.from(
                                      user.avatar,
                                      'base64',
                                  ).toString('ascii'),
                              }
                            : log.user,
                })),
            );
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line
    }, []);

    return (
        <ListGroup as="ol" variant="flush">
            {logs.map((log) => (
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-center"
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 2,
                        }}
                    >
                        {log.user && typeof log.user.avatar === 'string' ? (
                            <Image
                                width={40}
                                height={40}
                                roundedCircle
                                src={log.user.avatar}
                            />
                        ) : (
                            <ReactProfileIcon
                                width={40}
                                height={40}
                                fill="#cccccc"
                            />
                        )}
                        <div
                            style={{ flex: 1, marginLeft: 20, marginRight: 20 }}
                            className="pl-2"
                        >
                            {log.description}
                        </div>
                    </div>
                    <Badge variant="info" pill>
                        {moment(log.createdAt)
                            .local()
                            .format('DD/MM/YYYY [às] H:mm[h]')}
                    </Badge>
                </ListGroup.Item>
            ))}
            {loading && (
                <Col md={12} className="text-center pt-4">
                    <Spinner animation={'border'} />
                    <p>Carregando logs</p>
                </Col>
            )}
            {!loading && logs.length === 0 && (
                <Col md={12} className="pt-4">
                    Não foi achado nenhum log para este pedido.
                </Col>
            )}
        </ListGroup>
    );
}
