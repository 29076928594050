import Request from './request';

export default class AuthHttpService {
    private static uri = 'auth';

    public static getUser() {
        return Request.get(`${this.uri}/user`);
    }

    public static async logout() {
        return Request.post(`${this.uri}/logout`);
    }

    public static updateUser(data: {}) {
        return Request.patch(`${this.uri}/user`, data);
    }
}
