import { Modal, Row, Col, ProgressBar } from 'react-bootstrap';

interface LoadingModalBodyProps {
    message: string;
}

export default function LoadingModalBody({ message }: LoadingModalBodyProps) {
    return (
        <Modal.Body className="p-4">
            <Row className="align-items-center py-3">
                <Col>
                    <ProgressBar animated now={100} label={message} />
                </Col>
            </Row>
        </Modal.Body>
    );
}
