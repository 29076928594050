import styled from 'styled-components';

export const StyledDisabledDiv = styled.div`
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffffff;
    opacity: 1;
    box-sizing: border-box;
    padding: 0;
    border: 1.5px solid #d0d0d0;
    border-radius: 0.15rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    i {
        color: #fa6161;
    }
`;

export const StyledAbleDiv = styled.div`
    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #ffffff;
    opacity: 1;
    box-sizing: border-box;
    padding: 0;
    border: 1.5px solid #d0d0d0;
    border-radius: 0.15rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    i {
        color: #00c16a;
    }
`;
