import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledDisplayDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
`;

export const StyledButton = styled(Button)`
    margin-top: 8px;
    background-color: #fff !important;
    font-size: 16px;
    color: #f2994a !important;
    border: none !important;
    font-weight: normal;
    padding: 0px;

    :hover {
        color: #df7210 !important;
        text-decoration: underline;
    }

    :focus {
        border: none !important;
        box-shadow: none !important;
    }
`;
