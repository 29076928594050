/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { banksItems } from '../../../../constants/onlineStore';

const BankData = (props: any) => (
    <Form
        style={{
            width: '100%',
        }}
    >
        <fieldset disabled={props.disabled}>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Banco</Form.Label>
                        <Form.Control
                            value={props.financing.bank}
                            onChange={props.handleChanges}
                            name="bank"
                            as={'select'}
                        >
                            <option value="">Selecione</option>
                            {banksItems &&
                                banksItems.map((item, index: any) => (
                                    <option key={index} value={item.value}>
                                        {`${item.value} - ${item.name}`}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Agência</Form.Label>
                        <Form.Control
                            value={props.financing.bankAgency}
                            onChange={props.handleChanges}
                            name="bankAgency"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Conta</Form.Label>
                        <Form.Control
                            value={props.financing.bankAccount}
                            onChange={props.handleChanges}
                            name="bankAccount"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>Tipo de conta</Form.Label>
                        <Form.Control
                            value={props.financing.bankAccountType}
                            onChange={props.handleChanges}
                            name="bankAccountType"
                            type="text"
                            max={200}
                            min={0}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </fieldset>
    </Form>
);

export default BankData;
