import { useQuery } from 'react-query';
import FreightsHttpService from '../services/http/freights-http';

export default function useInvoiceFreight(
    invoiceId: string,
    invoiceFreightRate: number | null,
    orderValue?: number,
    deliveryState?: string,
) {
    return useQuery(
        ['invoiceFreight', invoiceId, orderValue, deliveryState],
        async () => {
            await new Promise((resolve) => setTimeout(resolve, 500));

            if (invoiceFreightRate !== null) {
                return invoiceFreightRate;
            }

            const response =
                await FreightsHttpService.getFreightPercentageByInvoiceId(
                    invoiceId,
                );
            return response?.data ?? 0;
        },
        {
            enabled: Boolean(invoiceId),
            staleTime: 1000,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );
}
