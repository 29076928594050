/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import CustomerHttpService from '../../../services/http/customer-http';
import { cepMask, cnpjMask, cpfMask } from '../../../utils/masks';
import { New } from '../../OnlineStore/Customers/New';
import { control, StyledButton, theme } from './styles';

export interface CustomerProps {
    value: string;
    label: string;
    addressDescription: string;
    addressNeighborhood: string;
    addressNumber: string;
    addressComplement: string;
    addressCity: string;
    addressState: string;
    addressCep: string;
    legalName: string;
    fantasyName: string;
}

interface Props {
    sellerErpCode: string;
    customer: CustomerProps;
    setCustomer: React.Dispatch<React.SetStateAction<CustomerProps>>;
    disabled: boolean;
    setDisabledByCustomersList: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerSelection = (props: Props) => {
    const sellerErpCode = props.sellerErpCode;
    const setDisabledByCustomersList = props.setDisabledByCustomersList;

    const componentIsMounted = useRef(true);
    const [customers, setCustomers] = useState(null);
    const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);

    const loadCustomers = useCallback(async () => {
        try {
            const { data } = await CustomerHttpService.readMany({
                sellerCode: sellerErpCode,
            });

            const sellerCustomers = data.map((customer: any) => {
                const customerInfo: CustomerProps = {
                    label: `${customer.name} / ${customer.fancyName}`,
                    value: customer.document,
                    addressDescription: customer.addressDescription,
                    addressNeighborhood: customer.addressNeighborhood,
                    addressNumber: customer.addressNumber,
                    addressComplement: customer.addressComplement,
                    addressCity: customer.erpCityName,
                    addressState: customer.erpState,
                    addressCep: customer.addressCep,
                    legalName: customer.name,
                    fantasyName: customer.fancyName,
                };

                return customerInfo;
            });

            setCustomers(sellerCustomers);
            setDisabledByCustomersList(false);
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar listagem de clientes do ERP');
        }
    }, [sellerErpCode, setDisabledByCustomersList]);

    useEffect(() => {
        if (componentIsMounted.current) {
            loadCustomers();
        }
    });

    useEffect(() => {
        componentIsMounted.current = false;
    });

    const handleChanges = async (customer: CustomerProps) => {
        props.setCustomer(customer);
    };

    return (
        <>
            <Row className="align-items-end pb-3">
                <Col>
                    <Form.Group
                        controlId="formBasicCustomerSelection"
                        style={{ marginBottom: 0 }}
                    >
                        <Form.Label>Selecione um cliente</Form.Label>
                        <Form.Control
                            className={'nexen-form-control'}
                            style={{ display: 'none' }}
                        />
                        <Select
                            placeholder="Selecione..."
                            noOptionsMessage={() => 'Nenhum cliente cadastrado'}
                            name={'customerDocument'}
                            onChange={handleChanges}
                            options={customers}
                            isMulti={false}
                            styles={control}
                            theme={theme}
                            value={props.customer}
                            isDisabled={props.disabled}
                        />
                    </Form.Group>
                </Col>

                <Col md={2}>
                    <StyledButton
                        onClick={() => setShowNewCustomerModal(true)}
                        disabled={props.disabled}
                    >
                        <i
                            className="fas fa-user-plus"
                            style={{ fontSize: 14 }}
                        />
                    </StyledButton>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Nome/Razão Social</Form.Label>

                        <Form.Control
                            name="customerName"
                            type="text"
                            value={props.customer?.legalName || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicDocument">
                        <Form.Label>CPF/CNPJ</Form.Label>

                        <Form.Control
                            name="customerDocument"
                            type="text"
                            as={MaskedInput}
                            mask={
                                props.customer?.value?.length <= 11
                                    ? cpfMask
                                    : cnpjMask
                            }
                            value={props.customer?.value || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicAddressDesc">
                        <Form.Label>Logradouro</Form.Label>

                        <Form.Control
                            name="customerAddress"
                            type="text"
                            value={props.customer?.addressDescription || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <Form.Group controlId="formBasicAddressNumber">
                        <Form.Label>Número</Form.Label>

                        <Form.Control
                            name="customerNumber"
                            type="text"
                            value={props.customer?.addressNumber || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="formBasicAddressComp">
                        <Form.Label>Complemento</Form.Label>

                        <Form.Control
                            name="customerComplement"
                            type="text"
                            value={props.customer?.addressComplement || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formBasicAddressNhood">
                        <Form.Label>Bairro</Form.Label>

                        <Form.Control
                            name="customerNeighborhood"
                            type="text"
                            value={props.customer?.addressNeighborhood || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicAddressCity">
                        <Form.Label>Cidade</Form.Label>

                        <Form.Control
                            name="customerCity"
                            type="text"
                            value={props.customer?.addressCity || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group controlId="formBasicAddressState">
                        <Form.Label>Estado</Form.Label>

                        <Form.Control
                            name="customerState"
                            type="text"
                            value={props.customer?.addressState || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="formBasicCep">
                        <Form.Label>CEP</Form.Label>

                        <Form.Control
                            name="customerCep"
                            type="text"
                            as={MaskedInput}
                            mask={cepMask}
                            value={props.customer?.addressCep || ''}
                            readOnly
                        />
                    </Form.Group>
                </Col>
            </Row>

            <New
                show={showNewCustomerModal}
                onHide={() => {
                    setShowNewCustomerModal(false);
                    loadCustomers();
                }}
            />
        </>
    );
};

export default CustomerSelection;
