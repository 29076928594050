import { SelectOption } from '../components/Select';
import getInvoiceStatusText from '../utils/getInvoiceStatusText';
import { InvoiceStatus } from './invoiceStatus';

const unSelectableStatus = [
    InvoiceStatus.FutureSale,
    InvoiceStatus.Cart,
    InvoiceStatus.Budget,
    InvoiceStatus.BudgetExpired,
];

const invoiceStatusOptions: Array<SelectOption<string>> = Object.values(
    InvoiceStatus,
)
    .filter((status) => !unSelectableStatus.includes(status))
    .map((status) => ({
        label: getInvoiceStatusText(status),
        value: status,
    }));

export default invoiceStatusOptions;
