/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable default-case */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Col,
    Modal,
    Row,
    Nav,
    Form,
    Button,
    Tab,
    Spinner,
    Tooltip,
    OverlayTrigger,
    Image,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';
import ReactDatePicker from 'react-datepicker';
import {
    tradeControl,
    theme,
    optionsTheme,
} from '../../../styles/react-select-config';
import {
    StyledRow,
    StyledTitle,
    StyledNavLink,
} from '../../../pages/Points/PointManagement/styles';
import { PointType } from '../../../constants/pointType';
import { usePointManagement } from '../../../contexts/pointManagementContext';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import SellerHttpService from '../../../services/http/seller-http';
import PointHttpService from '../../../services/http/point-http';
import ConfigurationHttpService from '../../../services/http/configuration-http';
import info from '../../../assets/icons/info.svg';
import ConfigurationReference from '../../../constants/configurationReference';
import { SellerStatus } from '../../../constants/sellerStatus';

interface IntegratorManagerForm {
    id: number;
    name: string;
    active: { value: string; label: string };
    tradeableBalance: number;
    qualifiableBalance: number;
    amount: number;
    operation: { value: string; label: string };
    nature: { value: string; label: string };
    expirationDate: Date;
    motive: string;
}

const PointManager = (props: any) => {
    const { user }: AuthValues = useAuth();
    const [loading, setLoading] = useState(false);
    const {
        selectedIntegrator,
        setSelectedIntegrator,
        setShowPointManager,
        setHasNewRow,
    } = usePointManagement();
    const [tab, setTab] = useState('integrator');
    const { register, watch, control, setValue, reset } =
        useForm<IntegratorManagerForm>();
    const enabledOptions = [
        { value: false, label: 'Não' },
        { value: true, label: 'Sim' },
    ];
    const operationOptions = [
        {
            value: PointType.Credit,
            label: 'Crédito',
        },
        {
            value: PointType.Debit,
            label: 'Débito',
        },
    ];
    const natureOptions = [
        {
            value: false,
            label: 'Não Qualificável',
        },
        {
            value: true,
            label: 'Qualificável',
        },
    ];
    const [integratorOptions, setIntegratorOptions] = useState([]);
    const [actualSeason, setActualSeason] = useState(null);

    const formData = watch();

    async function loadIntegratorOptions(): Promise<void> {
        const response = await SellerHttpService.readMany({
            statusFilter: SellerStatus.Approved,
            isShortList: true,
        });

        const options: any = [];

        response?.data?.results.map((item: any) => {
            const newOption = { value: item.id, label: item.name };

            options.push(newOption);
        });

        setIntegratorOptions(options);
    }

    async function loadIntegrator(): Promise<void> {
        const response = await PointHttpService.showSellerInfo(
            selectedIntegrator.value ?? selectedIntegrator.id,
        );

        setSelectedIntegrator(response?.data?.seller);

        setValue('id', response?.data?.seller?.id);
        setValue('name', response?.data?.seller?.name);
        setValue(
            'active',
            response?.data?.seller?.pointsEnabled
                ? enabledOptions[1]
                : enabledOptions[0],
        );
        setValue(
            'tradeableBalance',
            Math.round(response?.data?.tradeableBalance.value ?? 0),
        );
        setValue(
            'qualifiableBalance',
            response?.data?.qualifiableBalance.value ?? 0,
        );
    }

    async function savePoints(formData: any): Promise<void> {
        setLoading(true);

        try {
            formData.originUserId = user.id;
            await PointHttpService.store(formData);

            toast.success('Pontos lançados com sucesso!');

            setHasNewRow(true);

            setValue('amount', 0);
            setValue('motive', '');
        } catch (error) {
            let err = error as any;

            toast.error(err.message.toString());
        }

        setLoading(false);
    }

    async function save(): Promise<void> {
        await savePoints({
            sellerId: watch().id,
            value: watch().amount,
            type: watch().operation.value,
            qualifiable: watch().nature.value,
            expirationDate: watch().expirationDate,
            motive: watch().motive,
        });

        setShowPointManager(false);
    }

    const getActualSeason = async () => {
        setLoading(true);

        try {
            const response = await ConfigurationHttpService.readOne(
                ConfigurationReference.ActiveSeason,
            );

            setActualSeason(response.data.value);
        } catch (error) {
            let err = error as any;

            toast.error(err.message.toString());
        }

        setLoading(false);
    };

    useEffect(() => {
        getActualSeason();
    }, []);

    useEffect(() => {
        if (formData?.operation?.value === 'DEBIT') {
            setValue('nature', natureOptions[0]);
        }
    }, [formData?.operation?.value]);

    useEffect(() => {
        if (selectedIntegrator?.value || selectedIntegrator?.id) {
            loadIntegrator();
        }
    }, [tab]);

    useEffect(() => {
        if (selectedIntegrator?.value) {
            loadIntegrator();
        }
    }, [selectedIntegrator?.value]);

    useEffect(() => {
        loadIntegratorOptions();
    }, [reset]);

    return (
        <React.Fragment>
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="xl"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Gerenciamento de Pontos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100'}>
                    <Row className="mt-1">
                        <Col className="float-left">
                            <Nav
                                fill
                                variant="tabs"
                                activeKey={tab}
                                onSelect={(selectedKey: string) =>
                                    setTab('' + selectedKey)
                                }
                            >
                                <Nav.Item>
                                    <StyledNavLink
                                        eventKey="integrator"
                                        onClick={() => setTab('integrator')}
                                    >
                                        Integrador
                                    </StyledNavLink>
                                </Nav.Item>
                                <Nav.Item>
                                    <StyledNavLink
                                        eventKey="points"
                                        onClick={() => setTab('points')}
                                    >
                                        Lançar pontos
                                    </StyledNavLink>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>

                    <Tab.Container activeKey={tab} onSelect={(k) => setTab(k)}>
                        <StyledRow className="ml-0 mt-4 mr-1">
                            <Col>
                                <StyledTitle>Escolha o Integrador</StyledTitle>
                            </Col>
                        </StyledRow>
                        <StyledRow className="ml-0 mt-2 mr-1">
                            <Col>
                                <Form.Control
                                    ref={register}
                                    name="id"
                                    type="number"
                                    hidden={true}
                                    disabled={true}
                                />
                                <Form.Control
                                    ref={register}
                                    name="name"
                                    type="text"
                                    hidden={true}
                                    disabled={true}
                                />
                                <ReactSelect
                                    className="form-control-nexen"
                                    placeholder="Digite aqui para procurar..."
                                    isSearchable={true}
                                    options={integratorOptions}
                                    onChange={(eventValue) => {
                                        setSelectedIntegrator(eventValue);
                                    }}
                                    type="text"
                                    isMulti={false}
                                    styles={{
                                        control: tradeControl,
                                        option: optionsTheme,
                                    }}
                                    theme={theme}
                                />
                            </Col>
                        </StyledRow>
                        <Tab.Content>
                            <Tab.Pane eventKey="integrator" title="Integrador">
                                <StyledRow className="ml-0 mt-5 mr-1">
                                    <Col>
                                        <StyledTitle>
                                            Habilitado para programa de pontos?
                                        </StyledTitle>
                                    </Col>
                                </StyledRow>
                                <Row className="ml-0 mt-2 mr-1">
                                    <Col>
                                        <Controller
                                            className="form-control-nexen"
                                            defaultValue=""
                                            as={ReactSelect}
                                            control={control}
                                            name="active"
                                            options={enabledOptions}
                                            isMulti={false}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder="-"
                                            isDisabled
                                        />
                                    </Col>
                                </Row>
                                <StyledRow className="ml-0 mt-4 mr-1">
                                    <Col>
                                        <StyledTitle>
                                            Quantidade de Pontos Acumulados
                                        </StyledTitle>
                                    </Col>
                                </StyledRow>
                                <Row className="ml-0 mt-2 mr-1">
                                    <Col>
                                        <Form.Control
                                            ref={register}
                                            name="qualifiableBalance"
                                            type="number"
                                            className="form-control-nexen"
                                            disabled={true}
                                            defaultValue={0}
                                        />
                                    </Col>
                                </Row>
                                <StyledRow className="ml-0 mt-3 mr-1">
                                    <Col>
                                        <StyledTitle>
                                            Quantidade de Pontos Disponíveis
                                            para troca
                                        </StyledTitle>
                                    </Col>
                                </StyledRow>
                                <Row className="ml-0 mt-2 mr-1">
                                    <Col>
                                        <Form.Control
                                            ref={register}
                                            name="tradeableBalance"
                                            type="number"
                                            className="form-control-nexen"
                                            disabled={true}
                                            defaultValue={0}
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="points">
                                <StyledRow className="ml-0 mt-5 mr-1">
                                    <Col>
                                        <StyledTitle>
                                            Quantidade de Pontos
                                        </StyledTitle>
                                    </Col>
                                </StyledRow>
                                <Row className="ml-0 mt-2 mr-1">
                                    <Col>
                                        <Form.Control
                                            ref={register}
                                            name="amount"
                                            type="number"
                                            className="form-control-nexen"
                                            placeholder="Digite..."
                                            defaultValue={0}
                                        />
                                    </Col>
                                </Row>
                                <StyledRow className="ml-0 mt-3 mr-1">
                                    <Col>
                                        <StyledTitle>Operação</StyledTitle>
                                    </Col>
                                </StyledRow>
                                <Row className="ml-0 mt-2 mr-1">
                                    <Col>
                                        <Controller
                                            className="form-control-nexen"
                                            as={ReactSelect}
                                            control={control}
                                            name="operation"
                                            options={operationOptions}
                                            isMulti={false}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder="Selecione..."
                                            defaultValue=""
                                        />
                                    </Col>
                                </Row>
                                <StyledRow className="ml-0 mt-3 mr-1">
                                    <Col>
                                        <StyledRow>
                                            <Col>
                                                <StyledTitle>
                                                    Natureza
                                                </StyledTitle>
                                            </Col>
                                        </StyledRow>
                                        <Row className="mt-2">
                                            <Col>
                                                <Controller
                                                    className="form-control-nexen"
                                                    as={
                                                        <ReactSelect
                                                            isDisabled={
                                                                formData
                                                                    ?.operation
                                                                    ?.value ===
                                                                'DEBIT'
                                                            }
                                                        ></ReactSelect>
                                                    }
                                                    control={control}
                                                    name="nature"
                                                    options={natureOptions}
                                                    isMulti={false}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    noOptionsMessage={() =>
                                                        'Sem opções'
                                                    }
                                                    defaultValue={
                                                        natureOptions[0]
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <StyledRow>
                                            <Col>
                                                <StyledTitle>
                                                    Data de expiração
                                                </StyledTitle>
                                            </Col>
                                        </StyledRow>
                                        <Row className="mt-2">
                                            <Col>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <ReactDatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    disabled={
                                                                        formData
                                                                            ?.operation
                                                                            ?.value ===
                                                                            'DEBIT' ||
                                                                        actualSeason ===
                                                                            null
                                                                    }
                                                                    placeholderText="DD/MM/AAAA"
                                                                    selected={
                                                                        value
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    minDate={
                                                                        new Date()
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                        control={control}
                                                        name="expirationDate"
                                                        defaultValue=""
                                                    />
                                                    {!actualSeason && (
                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{
                                                                show: 250,
                                                                hide: 400,
                                                            }}
                                                            overlay={
                                                                <Tooltip id="overlay-example">
                                                                    Não é
                                                                    possível
                                                                    lançar
                                                                    pontos, pois
                                                                    não há uma
                                                                    temporada de
                                                                    pontos
                                                                    ativa.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Image
                                                                className="float-right ml-2 mt-1"
                                                                src={info}
                                                            ></Image>
                                                        </OverlayTrigger>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="ml-0 mt-3 mr-1">
                                    <Col>
                                        <StyledTitle>Motivo</StyledTitle>
                                    </Col>
                                </StyledRow>
                                <StyledRow className="ml-0 mt-2 mr-1">
                                    <Col>
                                        <Form.Control
                                            ref={register}
                                            name="motive"
                                            style={{ height: '100px' }}
                                            as="textarea"
                                            className="form-control-nexen"
                                        />
                                    </Col>
                                </StyledRow>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    {tab === 'points' && (
                        <StyledRow className="ml-0 mt-5 mr-1">
                            <Col sm={6} />
                            <Col>
                                <Button
                                    className="w-100 float-right"
                                    variant="outline-primary"
                                    onClick={() => {
                                        setSelectedIntegrator({});
                                        setShowPointManager(false);
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    disabled={
                                        loading ||
                                        selectedIntegrator.pointsEnabled ===
                                            undefined ||
                                        actualSeason === null
                                    }
                                    className="w-100 float-right"
                                    onClick={() => {
                                        save();
                                    }}
                                >
                                    Salvar{'   '}
                                    {loading && (
                                        <Spinner
                                            animation={'border'}
                                            size={'sm'}
                                        />
                                    )}
                                </Button>
                            </Col>
                        </StyledRow>
                    )}
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default PointManager;
