import { Card, Row, Col, Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import invoiceStatusOptions from '../../../constants/invoiceStatusOptions';
import paymentMethodOptions from '../../../constants/paymentMethodOptions';
import triangulationStatusOptions from '../../../constants/triangulationStatusOptions';
import {
    tradeControl,
    optionsTheme,
    theme,
} from '../../../styles/react-select-config';
import useInvoices from '../../../hooks/useInvoices';
import { useAuth } from '../../../contexts/authContext';
import { Role } from '../../../constants/role';
import userHasRoles from '../../../utils/userHasRoles';
import kitHttpService from '../../../services/http/kit-http';
import RegionHttpService from '../../../services/http/region-http';
import SellerHttpService from '../../../services/http/seller-http';
import futureSaleOptions from '../../../constants/futureSaleOptions';
import Select from '../../Select';
import useDelayedState from '../../../hooks/useDelayedState';
import invoiceAttachmentsOptions from '../../../constants/invoiceAttachmentsOptions';
import { SellerStatus } from '../../../constants/sellerStatus';

interface FilterCardProps {
    isBudgetFilter?: boolean;
}

export default function FilterCard({
    isBudgetFilter = false,
}: FilterCardProps) {
    const { user } = useAuth();

    const { filterForm } = useInvoices();

    const canAccessBaseFilters = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Financial,
        Role.Commercial,
        Role.CustomerSuccess,
    ]);

    const [sellersTerm, setSellersTerm] = useDelayedState<string>('');

    const isIntegrator = userHasRoles(user, [Role.Integrator]);

    const queryConfigs = {
        enabled: !isIntegrator,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 86400000,
    };

    const minSellersTermLength = 3;

    const sellersQuery = useQuery({
        queryKey: ['sellers', sellersTerm],
        queryFn: async () => {
            if (sellersTerm.length < minSellersTermLength) return [];

            const { data } = await SellerHttpService.readMany({
                statusFilter: SellerStatus.Approved,
                term: sellersTerm,
                isShortList: true,
            });

            return data?.results?.map((seller: any) => {
                return {
                    label: seller.name,
                    value: seller.id,
                };
            });
        },
        ...queryConfigs,
    });

    const regionsQuery = useQuery({
        queryKey: ['regions'],
        queryFn: async () => {
            const response = await RegionHttpService.readMany({
                term: '',
            });

            return response.data.map((region: any) => ({
                label: region.name,
                value: region.id,
                states: region.states,
            }));
        },
        ...queryConfigs,
    });

    const modulesQuery = useQuery({
        queryKey: ['modules'],
        queryFn: async () => {
            const { data } = await kitHttpService.getFilters();

            return data.map(({ name, erpCode }: any) => ({
                label: name,
                value: erpCode,
            }));
        },
        ...queryConfigs,
    });

    const invertersQuery = useQuery({
        queryKey: ['inverters'],
        queryFn: async () => {
            const { data } = await kitHttpService.getInverters({});

            return data.data.map(({ inverterErpCode, inverterName }: any) => ({
                label: inverterName,
                value: inverterErpCode,
            }));
        },
        ...queryConfigs,
    });

    return (
        <Card className="mt-4 mx-2">
            <Card.Body>
                <Card.Title>
                    <strong>Filtros</strong>
                </Card.Title>
                <Row>
                    {!isBudgetFilter && (
                        <Col md={2}>
                            <Form.Group controlId="formBasicStatus">
                                <Form.Label>Status</Form.Label>
                                <Controller
                                    isClearable
                                    className="form-control-nexen"
                                    as={Select}
                                    control={filterForm.control}
                                    name="filterStatus"
                                    options={invoiceStatusOptions}
                                    isMulti
                                    styles={{
                                        control: tradeControl,
                                        option: optionsTheme,
                                    }}
                                    theme={theme}
                                    placeholder="Selecione..."
                                    defaultValue={null}
                                />
                            </Form.Group>
                        </Col>
                    )}
                    {canAccessBaseFilters && (
                        <>
                            <Col md={4}>
                                <Form.Group controlId="formBasicSeller">
                                    <Form.Label>Integrador</Form.Label>
                                    <Controller
                                        isClearable
                                        className="form-control-nexen"
                                        as={Select}
                                        control={filterForm.control}
                                        name="filterSeller"
                                        options={sellersQuery.data || []}
                                        isMulti
                                        styles={{
                                            control: tradeControl,
                                            option: optionsTheme,
                                        }}
                                        theme={theme}
                                        placeholder="Selecione..."
                                        defaultValue={null}
                                        onInputChange={setSellersTerm}
                                        noOptionsMessage={
                                            sellersTerm.length <
                                            minSellersTermLength
                                                ? 'Digite ao menos 3 caracteres para iniciar a busca'
                                                : 'Sem opções'
                                        }
                                        isLoading={sellersQuery.isLoading}
                                        filterOption={() => true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group controlId="formBasicRegion">
                                    <Form.Label>Regiões</Form.Label>
                                    <Controller
                                        isClearable
                                        className="form-control-nexen"
                                        as={Select}
                                        control={filterForm.control}
                                        name="filterRegion"
                                        options={regionsQuery.data || []}
                                        isMulti
                                        styles={{
                                            control: tradeControl,
                                            option: optionsTheme,
                                        }}
                                        theme={theme}
                                        placeholder="Selecione..."
                                        defaultValue={null}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formBasicModules">
                                    <Form.Label>Tipo de módulo</Form.Label>
                                    <Controller
                                        isClearable
                                        className="form-control-nexen"
                                        as={Select}
                                        control={filterForm.control}
                                        name="filterModules"
                                        options={modulesQuery.data || []}
                                        isMulti
                                        styles={{
                                            control: tradeControl,
                                            option: optionsTheme,
                                        }}
                                        theme={theme}
                                        placeholder="Selecione..."
                                        defaultValue={null}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formBasicInverters">
                                    <Form.Label>Tipo de Inversor</Form.Label>
                                    <Controller
                                        isClearable
                                        className="form-control-nexen"
                                        as={Select}
                                        control={filterForm.control}
                                        name="filterInverters"
                                        options={invertersQuery.data || []}
                                        isMulti
                                        styles={{
                                            control: tradeControl,
                                            option: optionsTheme,
                                        }}
                                        theme={theme}
                                        placeholder="Selecione..."
                                        defaultValue={null}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group controlId="formBasicPaymentMethod">
                                    <Form.Label>Tipo de pagamento</Form.Label>
                                    <Controller
                                        isClearable
                                        className="form-control-nexen"
                                        as={Select}
                                        control={filterForm.control}
                                        name="filterPaymentMethod"
                                        options={paymentMethodOptions}
                                        isMulti
                                        styles={{
                                            control: tradeControl,
                                            option: optionsTheme,
                                        }}
                                        theme={theme}
                                        placeholder="Selecione..."
                                        defaultValue={null}
                                    />
                                </Form.Group>
                            </Col>
                            {!isBudgetFilter && (
                                <Col md={2}>
                                    <Form.Group controlId="formBasicFutureSale">
                                        <Form.Label>Venda Futura</Form.Label>

                                        <Controller
                                            isClearable
                                            className="form-control-nexen"
                                            as={Select}
                                            control={filterForm.control}
                                            name="filterFutureSale"
                                            options={futureSaleOptions}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder="Selecione..."
                                            defaultValue={null}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                            {!isBudgetFilter && (
                                <Col md={4}>
                                    <Form.Group controlId="formBasicTriangulationStatus">
                                        <Form.Label>Conta e Ordem</Form.Label>
                                        <Controller
                                            isClearable
                                            className="form-control-nexen"
                                            as={Select}
                                            control={filterForm.control}
                                            name="filterTriangulationStatus"
                                            options={triangulationStatusOptions}
                                            isMulti
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder="Selecione..."
                                            defaultValue={null}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                            {!isBudgetFilter && (
                                <Col md={2}>
                                    <Form.Group controlId="formBasicAttachments">
                                        <Form.Label>Comprovantes</Form.Label>
                                        <Controller
                                            isClearable
                                            className="form-control-nexen"
                                            as={Select}
                                            control={filterForm.control}
                                            name="filterAttachment"
                                            options={invoiceAttachmentsOptions}
                                            styles={{
                                                control: tradeControl,
                                                option: optionsTheme,
                                            }}
                                            theme={theme}
                                            placeholder="Selecione..."
                                            defaultValue={null}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
}
