import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTitle = styled.h4`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #383838;
`;

export const StyledSubtitle = styled.h5`
    font-size: 0.75rem;
    line-height: 20px;

    color: #707070;
`;

export const StyledButton = styled(Button)`
    line-height: 20px;
    color: #707070;
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
`;
