import { Spinner } from 'react-bootstrap';
import StyledApproveButton from './styles';

interface ValidateButtonProps {
    isSubmitting: boolean;
}

export default function ValidateButton({ isSubmitting }: ValidateButtonProps) {
    return (
        <StyledApproveButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
                <>
                    <Spinner animation="border" size="sm" />
                    <span>Validando...</span>
                </>
            ) : (
                'Validar'
            )}
        </StyledApproveButton>
    );
}
